import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import BuyerMasterList from './BuyerMasterList';
import BuyerMasterInput from './BuyerMasterInput';

export default function BuyerMaster() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/list`} component={BuyerMasterList} />
                <Route path={`${path}/input/:id`} component={BuyerMasterInput} />
                <Route path={`${path}/input`} component={BuyerMasterInput} />

                <Redirect path={`${path}`} to={`${path}/list`} />
            </Switch>
        </>
    );
}
