
import * as DOM from "../DOM.js";
import Dialog from "./Dialog.js";

import 'css/PickerDialog.css';

import * as API from 'components/API';

export class PickerDialog extends Dialog {

    constructor(params) {
        super(params);

        const RENDERING_LIMIT = 100;

        // 一覧作成時の部分一致前提条件
        this.filterKeyword = params.keyword || null;
        // console.log(`filterKeyword = ${this.filterKeyword}`);

        // 一覧作成時の項目指定の前提条件
        this.conditionMap = params.condition || null;
        // console.log(`conditionMap = ${this.conditionMap}`);

        // 1度に複数選択するときはmultiple=true
        this.multiple = params.multiple || false;

        this.keys = params.keys || {};

        // 項目選択時に呼び出すコールバック
        this.callback = params.callback || null;

        // データが見つからなかった際のメッセージ
        this.nodata = DOM.div('該当するデータがありません', 'noDataLabel');
        this.nodata.style.textAlign = 'center';

        // liのテキストを返す(code,name)
        const defaultGetTextFunciton = (row) => {
            let lihtml = '';
            if (this.keys.icon) {
                lihtml += this.keys.icon; // bulletアイコンあれば
            }
            lihtml += row[this.keys.code] + '(' + row[this.keys.name] + ')';
            return lihtml;
        };
        // フィルタ対象データ配列を返す(code,name)
        const defaultGetFilterTargets = (row) => {
            return [
                row[this.keys.code],
                row[this.keys.name]
            ];
        };

        // 画面に表示するliのテキストを返すfunctionをユーザー指定可能にする
        this.getTextFunction = params.getTextFunction || defaultGetTextFunciton;
        // フィルタ条件にマッチさせる対象のデータを返すfunctionをユーザー指定可能にする
        this.getFilterTargets = params.getFilterTargets || defaultGetFilterTargets;
        // 二重選択防止用の除外確認function
        this.exceptFunction = params.exceptFunction;

        // 非同期通信時のURL
        let q = [];
        if (this.filterKeyword != null) {
            q.push('search='+this.filterKeyword);
        }
        // 特定の条件
        if (this.conditionMap != null) {
            for (const [key, value] of Object.entries(this.conditionMap)) {
                q.push(key + '=' + value);
            }
        }
        this.url = params.url;
        if (q.length) {
            this.url += '?' + q.join('&');
        }

        this.container.classList.add('PickerDialog');
        this.dialogHeader.innerHTML = params.title;

        this.filter = document.createElement('input');
        this.filter.type = 'text';
        this.filter.placeholder = 'キーワードによるフィルター';
        this.filter.spellcheck = false;
        this.filter.autocomplete = false;
        this.filter.readonly = true; // あとではずす

        let filterTimeoutId = null;
        this.filter.addEventListener('keydown', (e)=>{
            if (filterTimeoutId) {
                clearTimeout(filterTimeoutId);
                filterTimeoutId = null;
            }
            filterTimeoutId = setTimeout(()=>{

                this.filteredData = filteringData(this.filter.value);
                if (this.filteredData) {
                    // RENDERING_LIMITを超える場合は分割してあとでappend
                    const sliceMax = Math.min(0 + RENDERING_LIMIT, this.filteredData.length);
                    const renderData = this.filteredData.slice(0, sliceMax);

                    this.main.textContent = ''; // clear

                    if (renderData.length === 0) {
                        // dataが一つもない場合はメッセージ表示
                        this.main.appendChild(this.nodata);
                    } else {
                        const fragment = this.createListFragment(renderData, 0);
                        this.main.appendChild(DOM.ul([fragment]));
                    }
                    this.visibleIndex = sliceMax;

                    // 件数表示
                    this.filterCounter.textContent = this.filteredData.length + '/' + this.listData.length;
                }
            }, 50);
        }, false);

        this.main = DOM.div([], 'PickerDialog_body')

        let scrollTimeoutId = null;
        this.main.addEventListener('scroll', (e)=>{
            // console.log(this.main.scrollHeight, this.main.scrollTop, this.main.clientHeight);
            let mainScrollMax80 = (this.main.scrollHeight - this.main.clientHeight) * 0.8;
            if (mainScrollMax80 > this.main.scrollTop) {
                // スクロールがMAXの80%に満たない場合(まだ最後の方までスクロールしていない)は追加処理をしない
                return;
            }
            if (scrollTimeoutId) {
                clearTimeout(scrollTimeoutId);
                scrollTimeoutId = null;
            }
            scrollTimeoutId = setTimeout(()=>{
                // 150ms経過したとき、まだfilteredDataにデータが残っていたらね
                if (this.filteredData) {
                    const sliceMax = Math.min(this.visibleIndex + RENDERING_LIMIT, this.filteredData.length);
                    const renderData = this.filteredData.slice(this.visibleIndex, sliceMax);
                    if (renderData.length === 0) {
                        // 出尽くしたら終わり
                        return;
                    }
                    // console.log(this.visibleIndex, sliceMax);
                    const ul = this.main.firstChild;
                    const fragment = this.createListFragment(renderData, this.visibleIndex);
                    ul.appendChild(fragment);

                    this.visibleIndex = sliceMax;
                    // 件数表示
                    this.filterCounter.textContent = this.filteredData.length + '/' + this.listData.length;
                }
            }, 150);
        }, false);

        this.filterCounter = DOM.div([], 'PickerDialog_filterCounter');

        const contents = DOM.div([
            DOM.div([
                DOM.div([
                    DOM.div('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/></svg>', 'PickerDialog_filterIcon'),
                    DOM.div([
                        this.filter
                    ], 'PickerDialog_filterText'),
                    this.filterCounter,
                ], 'PickerDialog_filterWrap')
            ], 'PickerDialog_header'),
            this.main
        ], 'PickerDialog_contents');
        this.setContents(contents);

        this.visibleIndex = 0;
        this.listData = [];
        this.selectedData = null;
        this.filteredData = null;

        const selectedFunction = (e)=>{
            if (this.multiple) {
                const row = this.filteredData[e.target.dataset.index];
                this.selectedData.push(row);
            } else {
                // singleの場合は選択時に閉じる
                if (this.callback) {
                    const row = this.filteredData[e.target.dataset.index];
                    this.callback(row);
                }
                this.hide();
            }
        };

        const filteringData = (text)=>{
            if (!text || text.length === 0) {
                return this.listData;
            }
            console.time('filteringData time [' + text + ']');

            // 条件に一致して表示されるデータ
            let matches = [];

            if (this.listData) {
                // 正規表現における特殊文字はエスケープする
                text = text.replace(/[.*+?^=!:${}()|[\]\\]/g, '\\$&'); // $&はマッチした部分文字列全体を意味します
                const regex = new RegExp(text,'iu');

                for (let i = 0; i < this.listData.length; i++) {
                    const row = this.listData[i];

                    let hit = false;
                    let filterTargets = this.getFilterTargets(row);
                    for (let j = 0; j < filterTargets.length; j++) {
                        if (regex.test(filterTargets[j])) {
                            hit = true;
                            break;
                        }
                    }
                    if (hit) {
                        matches.push(row);
                    }
                }
            }

            console.timeEnd('filteringData time [' + text + ']');

            return matches;
        };

        const clear = ()=>{
            this.filter.value = '';
            this.main.textContent = '';
            this.visibleIndex = 0;
            this.listData = [];
            this.selectedData = [];
            this.filteredData = [];
        };
        this.beforeHide = clear;

        this.beforeShow = ()=>{
            // loading表示
            this.main.innerHTML = '<div class="Loader Part"><div class="LoaderMark"></div></div>';
            this.selectedData = [];

            const updateListData = (newdata)=>{
                // 除外処理があれば
                if (this.exceptFunction) {
                    newdata = newdata.filter(n => !this.exceptFunction(n));
                }

                this.listData = newdata;
                this.filteredData = newdata;
                let renderData = this.filteredData.slice(0, RENDERING_LIMIT);

                this.main.textContent = ''; // clear

                if (renderData.length === 0) {
                    // dataが一つもない場合はメッセージ表示
                    this.main.appendChild(this.nodata);
                } else {
                    const fragment = this.createListFragment(renderData, 0);
                    this.main.appendChild(DOM.ul([fragment]));
                }
                this.visibleIndex = renderData.length;

                // 件数表示
                this.filterCounter.textContent = this.filteredData.length + '/' + this.listData.length;
                // データが揃ったら検索可能にする
                this.filter.readonly = false;
            };

            console.time('Get selectable values asynchronously');
            API.get(this.url)
            .then((result)=>{
                // console.log(result);
                if (result) {
                    if (result.Error?.length) {
                        console.error(result.Error);
                    } else {
                        updateListData(result.Result.Data);
                        // データがそろった後で中央寄せしておく(dialogのサイズが変わるから)
                        this.centering();
                    }
                } else {
                    // console.log();
                }
            })
            .catch((error)=>{
                console.error(error);
            })
            .finally(()=>{
                console.timeEnd('Get selectable values asynchronously');
            });
        };

        this.createListFragment = (data, offsetIndex)=>{
            const fragment = document.createDocumentFragment();
            for (let i = 0; i < data.length; i++) {
                const row = data[i];

                const li = document.createElement('li');
                li.dataset.index = (i + offsetIndex);
                li.innerHTML = this.getTextFunction(row);

                li.addEventListener('click', selectedFunction, false);
                fragment.appendChild(li);
            }
            return fragment;
        };
    }
    setSelectedCallback(callback) {
        this.callback = callback;
        return this;
    }
}

export function encode(str, defaultValue=null) {
    if (str) {
        return str.replace(/[\u00A0-\u9999<>]/gim, function(i) {
            return '&#'+i.charCodeAt(0)+';';
        });
    } else {
        if (defaultValue) {
            return defaultValue;
        }
    }
    return '';
}

// // 顧客データのピッカー
// export function createCustomerPicker(userparam) {
//     let param = {
//         title: '<h3>顧客を選択</h3>',
//         url: '/table/customer/picker',
//         // liのテキストを返す(code,name)
//         getTextFunction: (row)=>{
//             // エンコード追加
//             // let encodedId = encode(row['Id']);
//             let encodedOrderId = encode(row['OrderId']);
//             let encodedOrderDate = encode(row['OrderDate']);
//             let encodedItemId = encode(row['ItemId']);
//             let encodedItemName = encode(row['ItemName']);
//             let encodedOrderNote = encode(row['OrderNote']);
//             let encodedFittingNote = encode(row['FittingNote']);
//             let encodedCustomerFirstName = encode(row['CustomerFirstName']);
//             let encodedCustomerLastName = encode(row['CustomerLastName']);
//             let encodedCustomerFirstRuby = encode(row['CustomerFirstRuby']);
//             let encodedCustomerLastRuby = encode(row['CustomerLastRuby']);
//             let encodedCustomerPostalCode = encode(row['CustomerPostalCode']);
//             let encodedCustomerAddress1 = encode(row['CustomerAddress1']);
//             let encodedCustomerTel = encode(row['CustomerTel']);
//             let encodedCustomerMail = encode(row['CustomerMail']);
//             let encodedCustomerNote = encode(row['CustomerNote']);

//             let displayHtml = '';

//             displayHtml += '<div class="customerAndOrder">'

//                 displayHtml += '<div class="customer">';
//                     displayHtml += '<div class="names">';
//                         displayHtml += '<div class="ruby"><span>' + encodedCustomerLastRuby + '</span><span>' + encodedCustomerFirstRuby + '</span></div>';
//                         displayHtml += '<div class="name"><span>' + encodedCustomerLastName + '</span><span>' + encodedCustomerFirstName + '</span></div>';
//                     displayHtml += '</div>';
//                     displayHtml += '<div>';
//                     if (encodedCustomerPostalCode) {
//                         displayHtml += '<div class="postal">〒' + encodedCustomerPostalCode + '</div>';
//                     }
//                     if (encodedCustomerAddress1) {
//                         displayHtml += '<div class="address">' + encodedCustomerAddress1 + '</div>';
//                     }
//                     if (encodedCustomerTel) {
//                         displayHtml += '<div class="tel">';
//                             displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>';
//                             displayHtml += encodedCustomerTel;
//                         displayHtml += '</div>';
//                     }
//                     if (encodedCustomerMail) {
//                         displayHtml += '<div class="mail">';
//                             displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>';
//                             displayHtml += encodedCustomerMail;
//                         displayHtml += '</div>';
//                     }
//                     if (encodedFittingNote) {
//                         displayHtml += '<div class="note">' + encodedFittingNote + '</div>';
//                     }
//                     displayHtml += '</div>';
//                 displayHtml += '</div>'; // .customer end

//                 displayHtml += '<div class="order">';
//                     displayHtml += '<div class="key">';
//                         displayHtml += '<div class="orderId">' + encodedOrderId + '</div>';
//                         displayHtml += '<div class="orderDate">' + encodedOrderDate + '</div>';
//                     displayHtml += '</div>';
//                     displayHtml += '<div>';
//                         displayHtml += '<div class="item">';
//                             displayHtml += '<div class="itemId">' + encodedItemId + '</div>';
//                             displayHtml += '<div class="itemName">' + encodedItemName + '</div>';
//                         displayHtml += '</div>';
//                         displayHtml += '<div class="note">' + encodedOrderNote + '</div>';
//                     displayHtml += '</div>';
//                 displayHtml += '</div>'; // .order end

//             displayHtml += '</div>'; // .customerAndOrder end

//             return displayHtml;
//         },
//         // フィルタ対象データ配列を返す(code,name)
//         getFilterTargets: (row)=>{
//             return [
//                 // row['Id'],
//                 row['OrderId'],
//                 row['CustomerFirstName'],
//                 row['CustomerLastName'],
//                 row['CustomerLastName']+row['CustomerFirstName'],
//                 row['CustomerFirstRuby'],
//                 row['CustomerLastRuby'],
//                 row['CustomerLastRuby']+row['CustomerFirstRuby'],
//                 row['CustomerPostalCode'],
//                 row['CustomerAddress1'],
//                 row['CustomerTel'],
//                 row['CustomerMail'],
//                 row['CustomerNote'],
//             ];
//         },
//         exceptFunction: (row)=>{
//             // 二重選択を防ぐための除外リスト
//             const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
//             for (let i = 0; i < exceptList.length; i++) {
//                 if (exceptList[i] === row['Id']) {
//                     return true; // 除外
//                 }
//             }
//             return false;
//         }
//     }
//     if (userparam) {
//         param = Object.assign(userparam, param);
//     }
//     let p = new PickerDialog(param);
//     return p;
// }

// ユーザーのピッカー
export function createUserPicker(userparam) {
    let param = {
        title: '<h3>ユーザーを選択</h3>',
        url: '/master/user/picker',
        // liのテキストを返す(code,name)
        getTextFunction: (row)=>{
            // エンコード追加
            let encodedUserId = encode(row['UserId']);
            let encodedUserName = encode(row['UserName']);
            let encodedUserNote = encode(row['UserNote']);
            let displayHtml = '';
            displayHtml += '<div>' + row['Id'] + '</div>';
            displayHtml += '<div>';
            displayHtml += '<span>' + encodedUserId + '</span>';
            displayHtml += '<span>' + encodedUserName + '</span>';
            displayHtml += '<span>' + encodedUserNote + '</span>';
            displayHtml += '</div>';
            return displayHtml;
        },
        // フィルタ対象データ配列を返す(code,name)
        getFilterTargets: (row)=>{
            return [
                row['Id'],
                row['UserId'],
                row['UserName'],
                row['UserNote'],
            ];
        },
        exceptFunction: (row)=>{
            // 二重選択を防ぐための除外リスト
            const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
            for (let i = 0; i < exceptList.length; i++) {
                if (exceptList[i] === row['Id']) {
                    return true; // 除外
                }
            }
            return false;
        }
    }
    if (userparam) {
        param = Object.assign(userparam, param);
    }
    let p = new PickerDialog(param);
    return p;
}

// 発注者のピッカー
export function createBuyerPicker(userparam) {
    let param = {
        title: '<h3>発注者を選択</h3>',
        url: '/master/buyer/picker',
        // liのテキストを返す(code,name)
        getTextFunction: (row)=>{
            // エンコード追加
            let encodedBuyerId = encode(row['BuyerId']);
            let encodedBuyerPartyName = encode(row['BuyerPartyName']);
            let encodedBuyerPlaceName = encode(row['BuyerPlaceName']);
            let encodedBuyerPlaceId = encode(row['BuyerPlaceId']);
            let encodedBuyerCategory = encode(row['BuyerCategory']);
            let encodedBuyerPostalCode = encode(row['BuyerPostalCode'], '---');
            let encodedBuyerAddress1 = encode(row['BuyerAddress1'], '---');
            let encodedBuyerTel = encode(row['BuyerTel'], '---');
            let encodedBuyerMail = encode(row['BuyerMail'], '---');
            let encodedBuyerNote = encode(row['BuyerNote']);

            let categories = encodedBuyerCategory.split(',');

            let displayHtml = '';
            displayHtml += '<div class="buyer">';
            displayHtml += '<div class="id">' + row['Id'] + '</div>';

            displayHtml += '<div class="flexrow">';
                displayHtml += '<div>';
                    displayHtml += '<div class="key">';
                        displayHtml += '<span class="buyerid">' + encodedBuyerId + '</span>';
                        displayHtml += '<span class="name">' + encodedBuyerPartyName + '</span>';
                    displayHtml += '</div>';

                    displayHtml += '<div class="key">';
                        displayHtml += '<span class="placeid">' + encodedBuyerPlaceId + '</span>';
                        displayHtml += '<span class="name">' + encodedBuyerPlaceName + '</span>';
                    displayHtml += '</div>';
                displayHtml += '</div>';
                displayHtml += '<div class="categories">';
                for (let i = 0; i < categories.length; i++) {
                    displayHtml += '<span class="category '+categories[i]+'">'+categories[i]+'</span>';
                }
                displayHtml += '</div>';
            displayHtml += '</div>';

            displayHtml += '<div class="flexrow">';
                displayHtml += '<div class="postal">';
                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z"/></svg>';
                displayHtml += encodedBuyerPostalCode;
                displayHtml += '</div>';
                displayHtml += '<div class="address">' + encodedBuyerAddress1 + '</div>';
            displayHtml += '</div>';

            displayHtml += '<div class="flexrow">';
                displayHtml += '<div class="tel">';
                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>';
                displayHtml += encodedBuyerTel;
                displayHtml += '</div>';
                displayHtml += '<div class="mail">';
                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>';
                displayHtml += encodedBuyerMail;
                displayHtml += '</div>';
            displayHtml += '</div>';

            displayHtml += '<div class="note">' + encodedBuyerNote + '</div>';

            displayHtml += '</div>'; // .buyer end
            return displayHtml;
        },
        // フィルタ対象データ配列を返す(code,name)
        getFilterTargets: (row)=>{
            return [
                row['Id'],
                row['BuyerId'],
                row['BuyerPartyName'],
                row['BuyerPlaceId'],
                row['BuyerPlaceName'],
                row['BuyerPostalCode'],
                row['BuyerAddress1'],
                row['BuyerTel'],
                row['BuyerMail'],
                row['BuyerNote'],
            ];
        },
        exceptFunction: (row)=>{
            // 二重選択を防ぐための除外リスト
            const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
            for (let i = 0; i < exceptList.length; i++) {
                if (exceptList[i] === row['Id']) {
                    return true; // 除外
                }
            }
            return false;
        }
    }
    if (userparam) {
        param = Object.assign(userparam, param);
    }
    let p = new PickerDialog(param);
    return p;
}

// 発注会社のピッカー
export function createBuyerPartyPicker(userparam) {
    let param = {
        title: '<h3>発注会社を選択</h3>',
        url: '/master/buyer/picker',
        // liのテキストを返す(code,name)
        getTextFunction: (row)=>{
            // エンコード追加
            let encodedBuyerId = encode(row['BuyerId']);
            let encodedBuyerPartyName = encode(row['BuyerPartyName']);
            // let encodedBuyerPlaceName = encode(row['BuyerPlaceName']);
            // let encodedBuyerPlaceId = encode(row['BuyerPlaceId']);
            let encodedBuyerPostalCode = encode(row['BuyerPostalCode'], '---');
            let encodedBuyerAddress1 = encode(row['BuyerAddress1'], '---');
            let encodedBuyerTel = encode(row['BuyerTel'], '---');
            let encodedBuyerMail = encode(row['BuyerMail'], '---');
            let encodedBuyerNote = encode(row['BuyerNote']);

            let displayHtml = '';
            displayHtml += '<div class="buyer">';
            displayHtml += '<div class="id">' + row['Id'] + '</div>';

            displayHtml += '<div class="key">';
                displayHtml += '<span class="buyerid">' + encodedBuyerId + '</span>';
                displayHtml += '<span class="name">' + encodedBuyerPartyName + '</span>';
            displayHtml += '</div>';

            // displayHtml += '<div class="key">';
            //     displayHtml += '<span class="placeid">' + encodedBuyerPlaceId + '</span>';
            //     displayHtml += '<span class="name">' + encodedBuyerPlaceName + '</span>';
            // displayHtml += '</div>';

            displayHtml += '<div class="flexrow">';
                displayHtml += '<div class="postal">';
                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z"/></svg>';
                displayHtml += encodedBuyerPostalCode;
                displayHtml += '</div>';
                displayHtml += '<div class="address">' + encodedBuyerAddress1 + '</div>';
            displayHtml += '</div>';

            displayHtml += '<div class="flexrow">';
                displayHtml += '<div class="tel">';
                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>';
                displayHtml += encodedBuyerTel;
                displayHtml += '</div>';
                displayHtml += '<div class="mail">';
                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>';
                displayHtml += encodedBuyerMail;
                displayHtml += '</div>';
            displayHtml += '</div>';

            displayHtml += '<div class="note">' + encodedBuyerNote + '</div>';

            displayHtml += '</div>'; // .buyer end
            return displayHtml;
        },
        // フィルタ対象データ配列を返す(code,name)
        getFilterTargets: (row)=>{
            return [
                row['Id'],
                row['BuyerId'],
                row['BuyerPartyName'],
                row['BuyerPlaceId'],
                row['BuyerPlaceName'],
                row['BuyerPostalCode'],
                row['BuyerAddress1'],
                row['BuyerTel'],
                row['BuyerMail'],
                row['BuyerNote'],
            ];
        },
        exceptFunction: (row)=>{
            // 二重選択を防ぐための除外リスト
            const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
            for (let i = 0; i < exceptList.length; i++) {
                if (exceptList[i] === row['Id']) {
                    return true; // 除外
                }
            }
            return false;
        }
    }
    if (userparam) {
        param = Object.assign(userparam, param);
    }
    let p = new PickerDialog(param);
    return p;
}

// メーカーのピッカー
export function createSellerPicker(userparam) {
    let param = {
        title: '<h3>メーカーを選択</h3>',
        url: '/master/seller/picker',
        // liのテキストを返す(code,name)
        getTextFunction: (row)=>{
            // エンコード追加
            let encodedSellerCategory = encode(row['SellerCategory']);
            let encodedSellerId = encode(row['SellerId']);
            let encodedSellerName = encode(row['SellerName']);
            let encodedSellerPostalCode = encode(row['SellerPostalCode']);
            let encodedSellerAddress1 = encode(row['SellerAddress1']);
            let encodedSellerTel = encode(row['SellerTel']);
            let encodedSellerNote = encode(row['SellerNote']);

            let displayHtml = '';
            displayHtml += '<div class="seller">';
            displayHtml += '<div class="id">' + row['Id'] + '</div>';

            displayHtml += '<div class="key">';
                displayHtml += '<span class="sellerid">' + encodedSellerId + '</span>';
                displayHtml += '<span class="name">' + encodedSellerName + '</span>';
                displayHtml += '<span class="category '+encodedSellerCategory+'">' + encodedSellerCategory + '</span>';
            displayHtml += '</div>';

            displayHtml += '<div class="flexrow">';
                displayHtml += '<div class="postal">';
                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z"/></svg>';
                displayHtml += encodedSellerPostalCode;
                displayHtml += '</div>';
                displayHtml += '<div class="address">' + encodedSellerAddress1 + '</div>';
            displayHtml += '</div>';

            displayHtml += '<div class="tel">';
            displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>';
            displayHtml += encodedSellerTel;
            displayHtml += '</div>';

            displayHtml += '<div class="note">' + encodedSellerNote + '</div>';

            displayHtml += '</div>'; // .seller end
            return displayHtml;
        },
        // フィルタ対象データ配列を返す(code,name)
        getFilterTargets: (row)=>{
            return [
                row['Id'],
                row['SellerCategory'],
                row['SellerId'],
                row['SellerName'],
                row['SellerPostalCode'],
                row['SellerAddress1'],
                row['SellerTel'],
                row['SellerNote'],
            ];
        },
        exceptFunction: (row)=>{
            // 二重選択を防ぐための除外リスト
            const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
            for (let i = 0; i < exceptList.length; i++) {
                if (exceptList[i] === row['Id']) {
                    return true; // 除外
                }
            }
            return false;
        }
    }
    if (userparam) {
        param = Object.assign(userparam, param);
    }
    let p = new PickerDialog(param);
    return p;
}

// 色コードのピッカー
export function createColorPicker(userparam) {
    let param = {
        title: '<h3>色コードを選択</h3>',
        url: '/master/color/picker',
        // liのテキストを返す(code,name)
        getTextFunction: (row)=>{
            // エンコード追加
            let encodedColorId = encode(row['ColorKey']);
            let encodedColorName = encode(row['ColorName']);
            let encodedColorPreview = encode(row['ColorPreview']);
            let encodedColorNote = encode(row['ColorNote']);

            let displayHtml = '';
            displayHtml += '<div class="color">';
            displayHtml += '<div class="id">' + row['Id'] + '</div>';
            displayHtml += '<div class="data">';
                displayHtml += '<span class="preview" style="background-color:'+encodedColorPreview+'"></span>';
                displayHtml += '<span class="colorid">' + encodedColorId + '</span>';
                displayHtml += '<span class="name">' + encodedColorName + '</span>';
                displayHtml += '<span class="note">' + encodedColorNote + '</span>';
            displayHtml += '</div>';

            displayHtml += '</div>'; // .color end
            return displayHtml;
        },
        // フィルタ対象データ配列を返す(code,name)
        getFilterTargets: (row)=>{
            return [
                row['Id'],
                row['ColorKey'],
                row['ColorName'],
                row['ColorNote'],
            ];
        },
        exceptFunction: (row)=>{
            // 二重選択を防ぐための除外リスト
            const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
            for (let i = 0; i < exceptList.length; i++) {
                if (exceptList[i] === row['Id']) {
                    return true; // 除外
                }
            }
            return false;
        }
    }
    if (userparam) {
        param = Object.assign(userparam, param);
    }
    let p = new PickerDialog(param);
    return p;
}

// 木型のピッカー
export function createLastPicker(userparam) {
    let seller = userparam.SellerId || '';
    let sellerCategory = userparam.SellerCategory || '';

    let param = {
        title: '<h3>木型を選択</h3>',
        url: '/master/last/picker?SellerId='+seller+'&SellerCategory='+sellerCategory,
        // liのテキストを返す(code,name)
        getTextFunction: (row)=>{
            // エンコード追加
            let encodedLastId = encode(row['LastId']);
            // let encodedLastCode = encode(row['LastCode']);
            let encodedLastName = encode(row['LastName']);
            // let encodedLastNote = encode(row['LastNote']);

            let displayHtml = '';
            displayHtml += '<div class="last">';
            displayHtml += '<div class="id">' + row['Id'] + '</div>';
            displayHtml += '<div class="data">';
                displayHtml += '<span class="lastid">' + encodedLastId + '</span>';
                displayHtml += '<span class="name">' + encodedLastName + '</span>';
                // displayHtml += '<span class="note">' + encodedLastNote + '</span>';
            displayHtml += '</div>';

            displayHtml += '</div>'; // .last end
            return displayHtml;
        },
        // フィルタ対象データ配列を返す(code,name)
        getFilterTargets: (row)=>{
            return [
                row['Id'],
                row['LastId'],
                row['LastCode'],
                row['LastName'],
                row['LastNote'],
            ];
        },
        exceptFunction: (row)=>{
            // 二重選択を防ぐための除外リスト
            const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
            for (let i = 0; i < exceptList.length; i++) {
                if (exceptList[i] === row['Id']) {
                    return true; // 除外
                }
            }
            return false;
        }
    }
    if (userparam) {
        param = Object.assign(userparam, param);
    }
    let p = new PickerDialog(param);
    return p;
}


// 革素材のピッカー
export function createLeatherPicker(userparam) {
    let leatherType = userparam.LeatherType;
    let leatherCategory = userparam.LeatherCategory || '';

    let param = {
        title: '<h3>革素材を選択</h3>',
        url: '/master/leather/picker?LeatherType=' + leatherType + '&LeatherCategory=' + leatherCategory,
        // liのテキストを返す(code,name)
        getTextFunction: (row)=>{
            // エンコード追加
            let encodedLeatherId = encode(row['LeatherId']);
            let encodedLeatherName = encode(row['LeatherName']);
            let encodedLeatherNote = encode(row['LeatherNote']);

            let displayHtml = '';
            displayHtml += '<div class="leather">';
            displayHtml += '<div class="id">' + row['Id'] + '</div>';
            displayHtml += '<div class="data">';
                displayHtml += '<span class="leatherid">' + encodedLeatherId + '</span>';
                displayHtml += '<span class="name">' + encodedLeatherName + '</span>';
                displayHtml += '<span class="note">' + encodedLeatherNote + '</span>';
            displayHtml += '</div>';
            return displayHtml;
        },
        // フィルタ対象データ配列を返す(code,name)
        getFilterTargets: (row)=>{
            return [
                row['Id'],
                row['LeatherId'],
                row['LeatherName'],
                row['LeatherNote'],
            ];
        },
        exceptFunction: (row)=>{
            // 二重選択を防ぐための除外リスト
            const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
            for (let i = 0; i < exceptList.length; i++) {
                if (exceptList[i] === row['Id']) {
                    return true; // 除外
                }
            }
            return false;
        }
    }
    if (userparam) {
        param = Object.assign(userparam, param);
    }
    let p = new PickerDialog(param);
    return p;
}

// 発注データ作成用
// のピッカー
export function createDesignPicker(userparam) {

    let seller = userparam.SellerId || '';

    let param = {
        title: '<h3>デザインを選択</h3>',
        url: '/design/picker?SellerId='+seller,
        // liのテキストを返す(code,name)
        getTextFunction: (row)=>{
            // エンコード追加
            let encodedItemId = encode(row['ItemId']);
            let encodedItemName = encode(row['ItemName']);

            let encodedLastId = encode(row['LastId']);
            let encodedLastCode = encode(row['LastCode']);
            let encodedLastName = encode(row['LastName']);

            let displayHtml = '';
            displayHtml += '<div class="design">';
            displayHtml += '<img src="'+API.BASE_URL+'/data/file/upload/find?'+ row['ItemFileId'] +'">';
            displayHtml += '<div>';
            displayHtml += '<span>' + encodedItemId + '</span>';
            displayHtml += '<span>' + encodedItemName + '</span>';
            displayHtml += '<span>' + encodedLastId + '</span>';
            displayHtml += '<span>' + encodedLastCode + '</span>';
            displayHtml += '<span>' + encodedLastName + '</span>';
            displayHtml += '</div>';
            displayHtml += '</div>';
            return displayHtml;
        },
        // フィルタ対象データ配列を返す(code,name)
        getFilterTargets: (row)=>{
            return [
                row['ItemId'],
                row['ItemName'],
                row['LastId'],
                row['LastCode'],
                row['LastName'],
            ];
        },
        exceptFunction: (row)=>{
            // 二重選択を防ぐための除外リスト
            const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
            for (let i = 0; i < exceptList.length; i++) {
                if (exceptList[i] === row['Id']) {
                    return true; // 除外
                }
            }
            return false;
        }
    }
    if (userparam) {
        param = Object.assign(userparam, param);
    }
    let p = new PickerDialog(param);
    return p;
}




console.log('PickerDialog.js loaded.');
