import React from 'react';

export default function FittingInput() {

    return (
        <div id="FittingInput">
            FittingInput
        </div>
    );
}
