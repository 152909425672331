import { PickerDialog, encode } from './PickerDialog';
import * as API from 'components/API';

import 'css/DesignPickerDialog.css';

export default class DesignPickerDialog extends PickerDialog {

    constructor(userparam) {

        let seller = userparam.SellerId || '';
        let sellerCategory = userparam.SellerCategory || '';

        let param = {
            title: '<h3>デザインを選択</h3>',
            url: '/design/picker?SellerId='+seller+'&SellerCategory='+sellerCategory,
            // liのテキストを返す(code,name)
            getTextFunction: (row)=>{
                // エンコード追加
                let encodedItemId = encode(row['ItemId']);
                let encodedItemName = encode(row['ItemName']);
                let encodedLastId = encode(row['LastId']);
                let encodedLastCode = encode(row['LastCode']);
                let encodedLastName = encode(row['LastName']);

                let displayHtml = '';
                displayHtml += '<div class="design">';

                displayHtml += '<div class="spec">';
                    displayHtml += '<div class="key">';
                        displayHtml += '<div class="ItemId">' + encodedItemId + '</div>';
                        displayHtml += '<div class="Name">' + encodedItemName + '</div>';
                    displayHtml += '</div>';
                    displayHtml += '<div class="key">';
                        displayHtml += '<div class="LastId">' + encodedLastId + '</div>';
                        displayHtml += '<div class="LastCode">' + encodedLastCode + '</div>';
                        displayHtml += '<div class="Name">' + encodedLastName + '</div>';
                    displayHtml += '</div>';
                displayHtml += '</div>'; // .spec end

                displayHtml += '<div class="pict">';
                displayHtml += '<img src="'+API.BASE_URL+'/data/file/upload/find?id='+ row['ItemFileId'] +'">';
                displayHtml += '</div>'; // .pict end

                displayHtml += '</div>'; // .design end
                return displayHtml;
            },
            // フィルタ対象データ配列を返す(code,name)
            getFilterTargets: (row)=>{
                return [
                    row['ItemId'],
                    row['ItemName'],
                    row['LastId'],
                    row['LastCode'],
                    row['LastName'],
                ];
            },
            exceptFunction: (row)=>{
                // 二重選択を防ぐための除外リスト
                const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
                for (let i = 0; i < exceptList.length; i++) {
                    if (exceptList[i] === row['Id']) {
                        return true; // 除外
                    }
                }
                return false;
            }
        }
        if (userparam) {
            param = Object.assign(userparam, param);
        }

        super(param);

        this.container.id = 'DesignPickerDialog';
    }
}