import { useEffect, useReducer, createContext, useContext } from 'react';
import Loader from 'components/Loader';

export const FormContext = createContext();
export const FormProvider = FormContext.Provider;

export function useFormContext() {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
}

const INITIAL_STATE = {
    loading: false,  // submit中にtrueにします
    reset: false,    // このstateを初期化したい場合はtrueにしてください
    fields: {},      // handleChangeで収集したデータがname属性をキーに保持されます
    errors: {}       // validationなどで表示したいメッセージを保持します
}

export function withFormField(WrappedComponent) {

    return (props) => {

        const formReducer = (state, event) => {
            if (event.reset) {
                // console.log('state クリア!');
                // 新しいオブジェクトでクリアする
                return {...INITIAL_STATE};
            }

            let newloading = state.loading;
            if ('loading' in event) {
                newloading = event.loading;
            }
            let newfields = {...state.fields};
            if ('fields' in event) {
                newfields = Object.assign(newfields, event.fields);
            }
            let newerrors = state.errors;
            if ('errors' in event) {
                // キーがあれば置き換え
                newerrors = event.errors;
            }

            return {
                loading: newloading,
                reset: false,
                fields: newfields,
                errors: newerrors
            };
        }

        // このフォームの入力値=stateの管理
        const [state, setFormState] = useReducer(formReducer, {...INITIAL_STATE});

        // 画面のrenderが実行されるたびに呼ばれる
        useEffect(() => {
            // 登録画面などで、入力時にEnterキーを押すとsubmitされてしまうのを防ぎたい
            // しかし、ログイン画面ではEnterキーでログインしたい...
            // ということで、苦しいが input[type=password] だけは除外しておくか
            const cancelEnter = (e) => {
                if (e.key === 'Enter') e.preventDefault();
            }
            let inputs = document.querySelectorAll('input[type=text],input[type=number],input[type=date],input[type=time],input[type=email],input[type=tel],input[type=url]');
            for (let i = 0; i < inputs?.length; i++) {
                inputs[i].addEventListener('keydown', cancelEnter, false);
            }
            return () => {
                // clean up state
                setFormState({ reset: true }); // 初期化
                for (let i = 0; i < inputs?.length; i++) {
                    inputs[i].removeEventListener('keydown', cancelEnter, false);
                }
            }
        }, []);

        let submitCallback = null;
        const handleSubmit = async (callback) => {
            submitCallback = callback;
        }

        // event = SyntheticBaseEvent
        const onSubmit = async (event) => {
            event.preventDefault();
            setFormState({ loading: true });

            const timekey = 'P' + Date.now() + ' data accessing latency';
            console.time(timekey);
            try {
                if (submitCallback) {
                    await submitCallback();
                    // TODO この戻り値でなんか挙動を変化させる必要がでてくるかも
                }
            } finally {
                console.timeEnd(timekey);
                setFormState({ loading: false });
            }
        }

        // 基本的にこのfunctionをinputやselect,textareaのonChangeにセットしとけばOK
        const handleChange = (event) => {
            let value = null;

            if (event.target.type === 'checkbox') {
                // console.log('checkbox value = ', event.target);
                // checkboxの場合は複数あるので配列にしたい
                // とりあえず既存データを確認
                let before = [];
                if (event.target.name in state.fields) {
                    let arr = state.fields[event.target.name];
                    if (arr) {
                        if (Array.isArray(arr)) {
                            before = arr;
                        } else {
                            before = [arr];
                        }
                    }
                }
                if (event.target.checked) {
                    // チェックされてるなら値を既存データに追加
                    value = before.concat([event.target.value]);
                } else {
                    // チェックされてないなら同じ値を削除
                    value = before.filter(v => v !== event.target.value);
                }
            }
            // チェックボックス以外のフォーム部品
            else {
                value = event.target.value;
            }
            // console.log('handleChange', event.target.name, value);

            setFormState({
                fields: {
                    [event.target.name]: value
                }
            });
        }

        const setErrors = (errors) => {
            if (errors) {
                setFormState({
                    errors: errors
                });
            } else {
                console.error('え？setErrorsが呼び出しといて引数がないってどうゆうこと');
            }
        }

        // 任意のタイミングで初期化したいときに使う
        const clearState = () => {
            setFormState({ reset: true }); // 初期化
        }

        return (
            <form onSubmit={onSubmit}>
                <fieldset disabled={state.loading}>
                    <FormProvider value={{
                        state,
                        setFormState,
                        setErrors,
                        handleChange,
                        handleSubmit,
                        clearState
                    }}>
                        <WrappedComponent {...props} />
                    </FormProvider>
                </fieldset>
                {state.loading && <Loader />}
            </form>
        );
    }
}
