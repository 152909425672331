import React, { useEffect } from 'react';
import 'css/CompanyOverview.css';

export default function CompanyOverview() {

    useEffect(() => {
        document.title = `会社概要 - UPMOST -`;
        let site = document.getElementById('Site');
        if (site) {
            site.scrollTo(0,0);
        }
    }, []);

    return (
        <div id="CompanyOverview">
            <h1>会社概要</h1>

            <h2>代表挨拶</h2>
            <p>
                私は２３年間に亘り、足を足データ化することに取り組んで参りました。
                構想２年、最初の特許出願が２０００年１月２６日ですので２３年です。
                当時、弁理士との打ち合わせで「将来、通販等で、遠隔地間で足データをやりとりする時代が来る」と書いています。
                通販がＥコマースに変わりましたが、ズバリ的中です！２３年間に、足の骨格アライメントの法則を見つけ特許取得し、靴のコロシ（締め具合）の換算式も考案しました。
                外反母趾角による補正方法、ソックスによる採寸法、３Ｄ足データによるオーダー靴製造法、これらも特許取得しました。
                後は…足データで、世界中に日本の靴を売る！これを実現するだけです！ご指導ください！
            </p>
            <div style={{textAlign: 'right'}}>
                <p>代表取締役　大島　昇</p>
                {/* <img src="static/images/president_200x200.jpg" alt="代表取締役"/> */}
            </div>

            <h2>企業プロフィール</h2>
            <table>
                <tbody>
                    <tr><th>社名</th>
                        <td>株式会社大島商事</td></tr>
                    <tr><th>法人番号</th>
                        <td>9180001085394</td></tr>
                    <tr><th>本社所在地</th>
                        <td>〒494-0003<br />
                            愛知県一宮市三条字賀37-1 サンジョーホーム2F</td></tr>
                    <tr><th>創業</th>
                        <td>2006年（平成18年）</td></tr>
                    <tr><th>代表取締役</th>
                        <td>大島　昇</td></tr>
                    <tr><th>資本金</th>
                        <td>1000万円</td></tr>
                    <tr><th>従業員</th>
                        <td>2名</td></tr>
                    <tr><th>事業内容</th>
                        <td>靴卸売業</td></tr>
                </tbody>
            </table>

            <h2>経済産業省・当社紹介ＨＰ（リンク）</h2>
            <div style={{padding:'1rem 0'}}>
                <h3>中部発きらり企業紹介 Vol.93</h3>
                <p>
                    <a href="https://www.chubu.meti.go.jp/koho/kigyo/093_oshima/index.html" target="_blank" rel="noreferrer">
                        https://www.chubu.meti.go.jp/koho/kigyo/093_oshima/index.html</a><br />
                </p>
            </div>

        </div>
    );
}
