import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import FittingList from './FittingList';
import FittingInput from './FittingInput';

export default function Fitting() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/list`} component={FittingList} />
                <Route path={`${path}/input/:id`} component={FittingInput} />
                <Route path={`${path}/input`} component={FittingInput} />

                <Redirect path={`${path}`} to={`${path}/list`} />
            </Switch>
        </>
    );
}
