import React, { useState, useRef, useEffect, useCallback } from 'react';

import 'css/UploadFile.css';

import * as Util from 'components/Util.js';
import * as API from 'components/API';

export default function UploadFile(props) {

    const inputFile = document.createElement('input');
    inputFile.type = 'file';
    inputFile.name = 'upload_file';
    inputFile.setAttribute('capture', 'camera'); // これでスマホのカメラ起動

    const [active, setActive] = useState(false);
    const viewer = useRef(null);
    const closer = useRef(null);

    const [fileName, setFileName] = useState(null);
    const [fileSize, setFileSize] = useState(null);
    const [fileMime, setFileMime] = useState(null);
    const [fileRect, setFileRect] = useState(null);

    const handleFileSelect = () => {
        inputFile.click();
    }

    const handleFileDelete = () => {
        // また会いましょう
        setActive(false);
        inputFile.value = '';
        viewer.current.textContent = '';
        setFileName('');
        setFileSize('');
        setFileMime('');
        setFileRect('');

        if (props.deleteCallback) {
            props.deleteCallback();
        }
    }

    const handleViewClose = (e) => {
        e.stopPropagation();
        e.target.classList.toggle('on');
        viewer.current.classList.toggle('close');
        // console.log('handleViewClose');
    }

    const upload = async function(filedata) {
        const timeKey = filedata.name + ' uploading...';
        console.time(timeKey);

        let data = null;
        try {
            const formData = new FormData();
            // @see UploadForm.php
            formData.append('upload_file', filedata);
            formData.append('file_name', filedata.name);

            const url = '/data/file/upload/update';
            let res = await API.post(url, formData);
            if (res.result <= 0) {
                const description = `message:${res.message}`;
                throw new Error(description);
            }

            data = res.Result;

        } catch (error) {
            console.error(error);
            alert('アップロードに失敗しました');
        } finally {
            console.timeEnd(timeKey);
        }
        return data;
    }

    const getAttribute = async function(id) {
        const timeKey = 'Get file attribute ['+id+']...';
        console.time(timeKey);

        let data = null;
        try {
            const url = '/data/file/upload/find?id='+id+'&attr=1';
            data = await API.get(url);

        } catch (error) {
            console.error(error);
            alert('ファイルデータの取得に失敗しました');
        } finally {
            console.timeEnd(timeKey);
        }
        return data;
    }

    const getData = async function(id) {
        const timeKey = 'Get file data ['+id+']...';
        console.time(timeKey);

        let data = null;
        try {
            const url = '/data/file/upload/find?id='+id;
            data = await API.getBlob(url);

        } catch (error) {
            console.error(error);
            alert('ファイルデータの取得に失敗しました\nもう一度やり直してみてください。');
        } finally {
            console.timeEnd(timeKey);
        }
        return data;
    }

    const setUploadedFile = useCallback(async function(id) {
        // console.log('レッツプレビュー', id);
        setActive(true);

        // 先に属性情報
        let model = await getAttribute(id);
        if (!model) {
            // ばいばい
            return;
        }
        console.log(model);

        closer.current.classList.add('on');
        viewer.current.classList.remove('close');

        setFileName(model.FileName);
        setFileSize(Util.bytesToSize(model.FileSize));
        setFileMime(model.FileMime);

        // image/*
        if (model.FileMime.indexOf('image') >= 0) {
            // プレビューしましょう
            let blob = await getData(id);
            const image = new Image();
            image.src = (window.URL || window.webkitURL).createObjectURL(blob);
            viewer.current.textContent = '';
            viewer.current.appendChild(image);

            let intervalId = setInterval(()=>{
                if (image.complete) {
                    setFileRect(`[${image.naturalWidth}x${image.naturalHeight}]`);
                    clearInterval(intervalId);
                }
            }, 400);
        }
        // 他のファイル
        else {
            // リンクを作る
            const atag = document.createElement('a');
            atag.target = '_blank';
            atag.href = API.BASE_URL+'/data/file/upload/find?id='+id;
            atag.title = 'ファイルをダウンロードします';
            // pdf
            if (model.FileMime.indexOf('pdf') >= 0) {
                atag.classList.add('pdf');
                atag.innerHTML = '<i class="fas fa-fw fa-file-pdf"></i>'+this.fileName.textContent;
            }
            // excel
            else if (model.FileMime.indexOf('spreadsheet') >= 0 || model.FileMime.indexOf('excel') >= 0) {
                atag.classList.add('excel');
                atag.innerHTML = '<i class="fas fa-fw fa-file-excel"></i>'+this.fileName.textContent;
            }
            // word
            else if (model.FileMime.indexOf('word') >= 0) {
                atag.classList.add('word');
                atag.innerHTML = '<i class="fas fa-fw fa-file-word"></i>'+this.fileName.textContent;
            }
            // powerpoint
            else if (model.FileMime.indexOf('presentation') >= 0 || model.FileMime.indexOf('powerpoint') >= 0) {
                atag.classList.add('powerpoint');
                atag.innerHTML = '<i class="fas fa-fw fa-file-powerpoint"></i>'+this.fileName.textContent;
            }
            // ???
            else {
                atag.classList.add('file');
                atag.innerHTML = '<i class="fas fa-fw fa-file"></i>'+this.fileName.textContent;
            }
            viewer.current.appendChild(atag);
        }
    }, []);

    useEffect(()=>{
        if (props.value) {
            setUploadedFile(props.value);
        }
    }, [props.value, setUploadedFile]);

    const fileChangeFunction = async () => {
        if (inputFile.files.length > 0) {
            try {
                // 先頭の１つだけ
                const selectedFile = inputFile.files.item(0);
                let data = await upload(selectedFile);
                if (data) {
                    // 大成功したよ！
                    if (props.uploadCallback) {
                        props.uploadCallback(data);
                    }
                    // レッツプレビュー
                    setUploadedFile(data.Id);
                } else {
                    // アップロードに失敗
                    alert('アップロードに失敗しました。サポートしている画像フォーマットが正しいことを確認してください。');
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log('キャンセルされたかな...');
        }
    }
    inputFile.addEventListener('change', fileChangeFunction, false);

    return (
        <div className="UploadFile">
            <div className={"UploaderAgent" + (active ? " active" : "")}>
                <div className="FileControl">
                    <button type="button" className="PrimaryButton SmallButton FileChooseButton" onClick={handleFileSelect}>ファイルを選択</button>
                    <button type="button" className="DeleteButton SmallButton FileDeleteButton" onClick={handleFileDelete}>ファイルを削除</button>
                    <div className="FileAttribute">
                        <span className="FileName">{fileName}</span>
                        <span className="FileSize">{fileSize}</span>
                        <span className="FileMime">{fileMime}</span>
                        <span className="FileRect">{fileRect}</span>
                    </div>
                    {/* <div className="agentRemover">✖</div> */}
                    <div className="FileViewCloser on" ref={closer} onClick={handleViewClose}>❮</div>
                </div>
                <div className="FileViewer" ref={viewer}></div>
            </div>
        </div>
    )
}