import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import * as API from 'components/API';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import { useAuthUser } from 'components/Authenticator';

import 'css/ChangePassword.css';
import LogoImage from 'image/logo.png';


function ChangePassword() {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();
    const session = useAuthUser();

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.UserPassword) {
            errors.UserPassword = {
                Message: '現在のパスワードは必須入力です。'
            }
        }
        if (!fields.NewPassword) {
            errors.NewPassword = {
                Message: '新しいパスワードは必須入力です。'
            }
        }
        if (!fields.RetypeNewPassword) {
            errors.RetypeNewPassword = {
                Message: '新しいパスワード(確認)は必須入力です。'
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    // 送信するボタン
    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.postAsFormData('/password/change/update', {
            'UserId': session.authUser?.UserId,
            'UserPassword': state.fields?.UserPassword || '',
            'NewPassword': state.fields?.NewPassword || '',
            'RetypeNewPassword': state.fields?.RetypeNewPassword || '',
            'UpdateDatetime': session.authUser?.UpdateDatetime,
        });
        if (result?.Result) {
            // [OK] メニューにもどる
            history.push('/business/main');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result?.Errors);
        }
    });

    useEffect(() => {
        document.title = `パスワードの変更 - UPMOST -`;
    }, []);

    return (
        <div id="ChangePasswordContents">
            <div id="ChangePassword">
                <div className="title">
                    <h2>パスワード変更</h2>
                </div>
                <div className="controls">
                    <label htmlFor="UserPassword">現在のパスワード</label>
                    <input type="password" id="UserPassword" name="UserPassword" placeholder="現在のパスワード"
                        className={(state.errors?.UserPassword) ? 'invalid' : '' }
                        onChange={handleChange}
                        value={state.fields?.UserPassword || ''}
                        tabIndex="1"
                        autoFocus={true}
                    />
                    {state.errors?.UserPassword &&
                        <span className="messageTip error">{state.errors?.UserPassword.Message}</span>
                    }
                </div>
                <div className="controls">
                    <label htmlFor="UserPassword">新しいパスワード</label>
                    <input type="password" id="NewPassword" name="NewPassword" placeholder="新しいパスワード"
                        className={(state.errors?.NewPassword) ? 'invalid' : '' }
                        onChange={handleChange}
                        value={state.fields?.NewPassword || ''}
                        tabIndex="2"
                    />
                    {state.errors?.NewPassword &&
                        <span className="messageTip error">{state.errors?.NewPassword.Message}</span>
                    }
                </div>
                <div className="controls">
                    <label htmlFor="RetypeNewPassword">新しいパスワード(確認)</label>
                    <input type="password" id="RetypeNewPassword" name="RetypeNewPassword"
                        placeholder="再度新しいパスワードを入力"
                        className={(state.errors?.RetypeNewPassword) ? 'invalid' : '' }
                        onChange={handleChange}
                        value={state.fields?.RetypeNewPassword || ''}
                        tabIndex="3"
                    />
                    {state.errors?.RetypeNewPassword &&
                        <span className="messageTip error">{state.errors?.RetypeNewPassword.Message}</span>
                    }
                </div>
                <div>
                    <button type="submit" className="PrimaryButton" tabIndex="4">変更する</button>
                </div>
                <div className="backLink">
                    <Link to="/business/main">メニューに戻る</Link>
                </div>
            </div>
            <div className="upMOSTLink">
                <Link to="/">
                    <img src={LogoImage} alt="upMOST" />
                    <p>靴 カスタムメイド・オーダーシューズの企画・製造・販売</p>
                    <span>&copy;{(new Date()).getFullYear()}</span>
                </Link>
            </div>
        </div>
    );
}
export default withFormField(ChangePassword);
