import Dialog from './Dialog';

import 'css/MessageDialog.css';

export default class MessageDialog extends Dialog {

    constructor(params) {

        params = params || {};
        params.afterShow = function () {
            MessageDialog.LastShowedMessageDialog = this;
        }

        if (params.afterHide) {
            let userAfterHide = params.afterHide;
            params.afterHide = function () {
                MessageDialog.LastShowedMessageDialog = null;
                userAfterHide();
            }
        } else {
            params.afterHide = function () {
                MessageDialog.LastShowedMessageDialog = null;
            }
        }

        // 強制モーダル(外をクリックしても閉じない、Escape押しても閉じない)
        params.modal = true;
        super(params);
        // メッセージダイアログの場合は背景は暗めにしたい
        // this.shadow.classList.add('darker50');

        // 複数メッセージ表示される可能性を考えるとidにすべきか要検討
        // そもそも複数表示させないようにshowメソッドの中ですでにあるMessageDialogは削除してもいいが、、、
        this.container.id = 'MessageDialog';
        this.container.classList.add('effect-dropIn');

        if (params.className) {
            this.container.classList.add(params.className);
        }

        if (params.icon) {
            let dialogIcon = document.createElement('div');
            dialogIcon.classList.add('MessageIcon');
            dialogIcon.innerHTML = params.icon;
            this.dialogHeader.appendChild(dialogIcon);
        }
        let h1 = document.createElement('h1');
        h1.textContent = params.title;
        this.dialogHeader.appendChild(h1);

        const contents = document.createElement('div');
        contents.classList.add('contents');
        contents.innerHTML = params.message;

        this.okButton.textContent = '閉じる';
        this.setContents(contents);
    }
}

// 直近で表示されているDialog
MessageDialog.LastShowedMessageDialog = null;

/** Static class fields are a stage 3 proposal */
MessageDialog.show = (title, text, hideCallback) => {
    MessageDialog.hideLastMessage();
    let d = new MessageDialog({
        title: title,
        message: '<p>' + text + '</p>',
        afterHide: hideCallback
    });
    d.show();
}
MessageDialog.Error = (title, text, hideCallback) => {
    MessageDialog.hideLastMessage();
    let d = new MessageDialog({
        title: title,
        message: '<p>' + text + '</p>',
        afterHide: hideCallback,
        className: 'Error',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>'
    });
    d.show();
}
MessageDialog.Success = (title, text, hideCallback) => {
    MessageDialog.hideLastMessage();
    let d = new MessageDialog({
        title: title,
        message: '<p>' + text + '</p>',
        afterHide: hideCallback,
        className: 'Success',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>'
    });
    d.show();
}

MessageDialog.hideLastMessage = () => {
    if (MessageDialog.LastShowedMessageDialog) {
        MessageDialog.LastShowedMessageDialog.hide();
        MessageDialog.LastShowedMessageDialog = null;
    }
}
MessageDialog.showDemandPermission = () => {
    // 同じメッセージは表示しない(する必要ない)
    if (MessageDialog.LastShowedMessageDialog !== MessageDialog.DemandPermissionDialog) {
        MessageDialog.DemandPermissionDialog.show();
    }
}
MessageDialog.showCameraNotFound = () => {
    // 同じメッセージは表示しない(する必要ない)
    if (MessageDialog.LastShowedMessageDialog !== MessageDialog.CameraNotFoundDialog) {
        MessageDialog.CameraNotFoundDialog.show();
    }
}
MessageDialog.showNoticeBlockingCamera = () => {
    // 同じメッセージは表示しない(する必要ない)
    if (MessageDialog.LastShowedMessageDialog !== MessageDialog.NoticeBlockingCameraDialog) {
        MessageDialog.NoticeBlockingCameraDialog.show();
    }
}
MessageDialog.showNoticeBlockingMicrophone = () => {
    // 同じメッセージは表示しない(する必要ない)
    if (MessageDialog.LastShowedMessageDialog !== MessageDialog.NoticeBlockingMicrophoneDialog) {
        MessageDialog.NoticeBlockingMicrophoneDialog.show();
    }
}
MessageDialog.showNoticeBlocking = () => {
    // 同じメッセージは表示しない(する必要ない)
    if (MessageDialog.LastShowedMessageDialog !== MessageDialog.NoticeBlockingDialog) {
        MessageDialog.NoticeBlockingDialog.show();
    }
}
MessageDialog.showNotReadableDevice = () => {
    // 同じメッセージは表示しない(する必要ない)
    if (MessageDialog.LastShowedMessageDialog !== MessageDialog.NotReadableDeviceDialog) {
        MessageDialog.NotReadableDeviceDialog.show();
    }
}
MessageDialog.showNotAvailableMeeting = () => {
    // 同じメッセージは表示しない(する必要ない)
    if (MessageDialog.LastShowedMessageDialog !== MessageDialog.NotAvailableMeetingDialog) {
        MessageDialog.NotAvailableMeetingDialog.show();
    }
}
MessageDialog.DemandPermissionDialog = new MessageDialog({
    icon:
        '<i class="icon" style="transform: rotate(180deg);">' +
        '<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">' +
        '<path d="M15.603,29L30,48l14.396-19  H35C35,0,6,1,6,1s19,1.373,19,28H15.603z"/>' +
        '</svg>' +
        '</i>',
    title: 'マイクとカメラの使用を許可してください',
    message:
        '<p>' +
        '他の参加者に自分の映像と音声を届けるには、マイクとカメラへのアクセスを許可する必要があります。' +
        '使用するブラウザやパソコンごとにこの設定を行う必要があります。' +
        '</p>'
});
MessageDialog.CameraNotFoundDialog = new MessageDialog({
    title: 'カメラが見つかりません',
    message:
        '<p>' +
        'システム設定で、カメラが使用できる状態にあることを確認します。' +
        '使用できない場合は、カメラを設定してください。設定後、ブラウザの再起動が必要な場合があります。' +
        '</p>'
});
MessageDialog.NoticeBlockingDialog = new MessageDialog({
    title: 'マイクまたはカメラがブロックされています',
    message:
        '<p>アプリを利用するには、マイクとカメラへのアクセスを許可する必要があります。</p>' +
        '<ul>' +
        '<li>ブラウザのアドレスバー上にあるブロックされたカメラアイコンをクリックします。</li>' +
        '<li>カメラとマイクへのアクセスを許可してください。</li>' +
        '</ul>' +
        '<p>ブラウザのサイトの設定などから変更することもできます。</p>'
});
MessageDialog.NotReadableDeviceDialog = new MessageDialog({
    title: 'マイクまたはカメラにアクセスできません',
    message:
        '<p style="margin-bottom: .75rem;">' +
        'マイクまたはカメラデバイスの接続に問題があるか、すでに別のソフトで使用されている可能性があります。' +
        'デバイスを使用できる状態にしてから再読み込みしてください。' +
        '</p><a href="./">再読み込み</a>'
});
MessageDialog.NotAvailableMeetingDialog = new MessageDialog({
    title: 'そのミーティングIDは利用できません',
    message: '<p>入力されたミーティングは参加できない状態にあるか、削除されている可能性があります。</p>'
});