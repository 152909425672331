import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import 'css/CustomerList.css';

import * as API from 'components/API';
import Loader from 'components/Loader';
import OmniSearch from 'components/OmniSearch';
import Pagination from 'components/Pagination';
import ListSettingDialog from 'components/Dialog/ListSettingDialog';

import SortableHeader from 'components/SortableHeader';

let o = 0;
const DEFAULT_COLUMN_LIST = [
    { index: o++, key: 'Id',                 visible: false, width: 160, search: false, type: '',              name: 'ID'},
    { index: o++, key: 'OrderId',            visible: true,  width: 140, search: true,  type: '',              name: '発注番号'},
    { index: o++, key: 'OrderCategory1',     visible: false, width: 140, search: true,  type: '',              name: '発注カテゴリ1'},
    { index: o++, key: 'CustomerLastName',   visible: true,  width: 120, search: true,  type: '',              name: '氏名(姓)'},
    { index: o++, key: 'CustomerFirstName',  visible: true,  width: 120, search: true,  type: '',              name: '氏名(名)'},
    { index: o++, key: 'CustomerLastRuby',   visible: true,  width: 140, search: true,  type: '',              name: 'ふりがな(姓)'},
    { index: o++, key: 'CustomerFirstRuby',  visible: true,  width: 140, search: true,  type: '',              name: 'ふりがな(名)'},
    { index: o++, key: 'CustomerBirthday',   visible: true,  width: 120, search: true,  type: 'DateRange',     name: '生年月日'},
    { index: o++, key: 'CustomerHeight',     visible: true,  width: 80,  search: true,  type: 'NumberFormula', name: '身長'},
    { index: o++, key: 'CustomerWeight',     visible: true,  width: 80,  search: true,  type: 'NumberFormula', name: '体重'},
    { index: o++, key: 'CustomerPostalCode', visible: true,  width: 120, search: true,  type: '',              name: '郵便番号'},
    { index: o++, key: 'CustomerAddress1',   visible: true,  width: 240, search: true,  type: '',              name: '住所1'},
    { index: o++, key: 'CustomerAddress2',   visible: true,  width: 240, search: true,  type: '',              name: '住所2'},
    { index: o++, key: 'CustomerTel',        visible: true,  width: 160, search: true,  type: '',              name: '電話番号'},
    { index: o++, key: 'CustomerOffice',     visible: true,  width: 260, search: true,  type: '',              name: '勤務先等'},
    { index: o++, key: 'CustomerMail',       visible: true,  width: 240, search: true,  type: '',              name: 'メールアドレス'},
    { index: o++, key: 'CustomerNote',       visible: false, width: 340, search: true, type: '',              name: '備考'},
    { index: o++, key: 'CreateDatetime',     visible: true,  width: 180, search: true,  type: 'DateRange',     name: '作成日時'},
    { index: o++, key: 'UpdateDatetime',     visible: true,  width: 180, search: true,  type: 'DateRange',     name: '更新日時'},
];

const DEFAULT_CONDITION = {
    filters: [],
    keywords: [],
    sort: {
        column: '',
        direction: 'none',
    },
    limit: 20,
    offset: 0,
    lastRefresh: null,
}
const INITIAL_STATE = {
    columns: DEFAULT_COLUMN_LIST,
    ...DEFAULT_CONDITION
}

function stateReducer(state, event) {
    return {...state, ...event};
}

function createParam(key, value) {
    return '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
}


function TableCell(props) {
    let row = props.row;
    let column = props.column;
    // ↓ここで、列ごとに個別対応が必要なものを書いていく

    // 身長にはcmを付ける
    if (column.key === 'CustomerHeight') {
        if (row[column.key]) {
            return (<td><div>{row[column.key]}<span className="unit">cm</span></div></td>);
        }
    }
    // 体重にはkgを付ける
    if (column.key === 'CustomerWeight') {
        if (row[column.key]) {
            return (<td><div>{row[column.key]}<span className="unit">kg</span></div></td>);
        }
    }
    // ステータス
    if (column.key === 'CustomerStatus') {
        return (
            <td>

            </td>
        );
    }

    return <td><div>{row[column.key]}</div></td>;
}

export default function CustomerList() {
    const COMPONENT_NAME = 'CustomerList';

    const settingClick = e => {
        let listSetting = new ListSettingDialog({
            form: COMPONENT_NAME,
            columns: state.columns,
            limit: state.limit,
            doAction: (params) => {
                // フィルターも並びもページングもリセットが必要
                if (params.reset) {
                    setState({ columns: [...DEFAULT_COLUMN_LIST] });
                } else {
                    setState({ ...DEFAULT_CONDITION, ...params });
                }
            }
        });
        listSetting.show();
    }

    const [listData, setListData] = useState({});
    const [listLoading, setListLoading] = useState(true);
    const [state, setState] = useReducer(stateReducer, {...INITIAL_STATE});
    const mounted = useRef(false);

    const refreshData = () => {
        setState({ lastRefresh: Date.now() });
    }

    // ユーザー固有の設定を反映させる
    useEffect(()=>{
        mounted.current = true;
        API.get('/data/user/find?DataKey=' + COMPONENT_NAME+'.ListSetting')
        .then(result => {
            if (result.Result) {
                if (mounted.current) {
                    try {
                        const userData = JSON.parse(result.Result.DataValue);
                        setState({ ...userData });
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        });
        return () => {
            mounted.current = false;
        }
    }, []);

    const getListData = useCallback(async ()=>{
        let url = '/table/customer/list?';
        // filters
        for (let i = 0; i < state.filters.length; i++) {
            let f = state.filters[i];
            url += createParam('filterColumn[]', f.filterColumn);
            url += createParam('filterValue[]', f.filterValue);
            url += createParam('filterFormula[]', f.filterFormula);
        }
        // keywords
        for (let i = 0; i < state.keywords.length; i++) {
            let k = state.keywords[i];
            url += createParam('searchKeyword[]', k.searchKeyword);
        }
        // sorts
        if (state.sort.column) {
            url += createParam('sortColumnName', state.sort.column);
        }
        if (state.sort.direction) {
            url += createParam('sortDirection', state.sort.direction);
        }

        // limit,offset
        url += createParam('limit', state.limit);
        url += createParam('offset', state.offset);

        let data = await API.get(url);
        if (mounted.current) {
            setListData(data || {});
        }

        // sessionStorageにstateを保存しておく
        window.sessionStorage.setItem(COMPONENT_NAME, JSON.stringify(state));

        setListLoading(false);

    }, [state]);

    useEffect(() => {
        setListLoading(true);
        getListData();

    }, [state, getListData]);

    return (
        <>
            <h1>顧客データ<span className="counter">({listData.Result?.TotalCount})</span></h1>
            <div id="CustomerList" className="ListContents">

                <div id="SubHeader">
                    {/* <button type="button" id="AddItemComponent" className="PrimaryButton" onClick={addItemClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                        <span>顧客データを作成</span>
                    </button> */}
                    <OmniSearch
                        columns={state.columns}
                        state={state}
                        setState={setState}
                        filterUrl={`/table/customer/filter`}
                    />
                    <button type="button" id="RefreshComponent" className="LinkButton IconButton" onClick={refreshData}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                    </button>
                    <button type="button" id="SettingComponent" className="LinkButton IconButton" onClick={settingClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>
                    </button>
                </div>
                <section>
                    <div className="TableWrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th className="EditLinkColumn"></th>
{
state.columns.map(column => {
    return (column.visible) ?
        <SortableHeader key={column.key}
            column={column}
            state={state}
            setState={setState}
        >
            <div>{column.name}</div>
        </SortableHeader>
    : null;
})
}
                                <th className="EndColumn"></th>
                                </tr>
                            </thead>
                            <tbody>
{
listData?.Result?.Data.map(row => (
    <tr key={row?.Id}>
        <td className="EditLinkColumn">
            <div>
                <Link to={`/business/customer/input/${row?.Id}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
                </Link>
            </div>
        </td>
        {
        state.columns.map(column => {
            return (column.visible) ?
                <TableCell key={'key'+row?.Id+'-'+column.key} column={column} row={row} />
            : null;
        })
        }
        <td className="EndColumn"></td>
    </tr>
))
}
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    {listData.Result?.TotalCount === 0 && <div className="noDataLabel">該当するデータはありません。</div>}
                    {listLoading && <Loader /> }
                    </div>
                    <div id="SubFooter">
                        <Pagination
                            listCount={listData?.Result?.ListCount}
                            totalCount={listData?.Result?.TotalCount}
                            state={state}
                            setState={setState}
                        />
                    </div>
                </section>
            </div>
        </>
    )
}
