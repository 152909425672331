import React from 'react';

export default function Checkbox(props) {

    const checkboxId = 'checkboxId-'+props.name+'-'+props.value;
    const selected = props.selected;

    let checked = false;
    if (selected && Array.isArray(selected)) {
        // console.log(selected);
        // 文字で評価
        let list = selected.map(v => String(v));
        let value = String(props.value);
        checked = list.includes(value);
    } else {
        // 文字で評価
        checked = String(selected) === String(props.value);
    }

    // console.log(checked);
    return (
        <div className="checkbox">
            <input type="checkbox"
                id={checkboxId}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                checked={checked}
                tabIndex={props.tabIndex || 0}
            />
            <label htmlFor={checkboxId}>{props.label}</label>
        </div>
    );
}
