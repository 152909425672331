import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import BillingList from './BillingList';

export default function Billing() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/list`} component={BillingList} />

                <Redirect path={`${path}`} to={`${path}/list`} />
            </Switch>
        </>
    );
}
