

export const FOOT_TYPE = [
    {key: 'EGYPT', value: 'エジプト型'},
    {key: 'GREECE', value: 'ギリシャ型'},
    {key: 'SQUARE', value: 'スクエア型'},
];
export const getFootTypeName = (value) => {
    if (value) {
        for (let i = 0; i < FOOT_TYPE.length; i++) {
            if (FOOT_TYPE[i].key === value)
                return FOOT_TYPE[i].value;
        }
    }
    return value;
}

export const FOOT_DISEASE = [
    {key: 'DEFORM', value: '外反母趾'},
    {key: 'NAIL', value: '陥入爪'},
    {key: 'TOE', value: 'ハンマートウ'},
];
export const getFootDiseaseName = (value) => {
    if (value) {
        for (let i = 0; i < FOOT_DISEASE.length; i++) {
            if (FOOT_DISEASE[i].key === value)
                return FOOT_DISEASE[i].value;
        }
    }
    return value;
}

export const FOOT_WIDTH = ['D', 'E', 'EE', '3E', '4E', 'F'];

export const FAVORITE_SIZE = [
    {key: 'SMALLER', value: '小さめ'},
    {key: 'JUST', value: 'ジャストサイズ'},
    {key: 'BIGGER', value: '大きめ'},
];
export const getFavoriteSizeName = (value) => {
    if (value) {
        for (let i = 0; i < FAVORITE_SIZE.length; i++) {
            if (FAVORITE_SIZE[i].key === value)
                return FAVORITE_SIZE[i].value;
        }
    }
    return value;
}

export const FOOT_LONGER = [
    {key: 'LEFT', value: '左足'},
    {key: 'SAME', value: 'ほぼ同じ'},
    {key: 'RIGHT', value: '右足'},
];
export const getFootLongerName = (value) => {
    if (value) {
        for (let i = 0; i < FOOT_LONGER.length; i++) {
            if (FOOT_LONGER[i].key === value)
                return FOOT_LONGER[i].value;
        }
    }
    return value;
}

// export const MENS_SOCKS_TYPE = [
//     {key: 'BAREFOOT', value: '素足'},
//     {key: 'THIN', value: '薄手'},
//     {key: 'COTTON', value: '綿'},
//     {key: 'THICK', value: '厚手'},
// ];

// export const LADIES_SOCKS_TYPE = [
//     {key: 'BAREFOOT', value: '素足'},
//     {key: 'THIN', value: '薄手'},
//     {key: 'COTTON', value: '綿'},
//     {key: 'TIGHTS', value: 'ストッキング'},
// ];


export const SOCKS_TYPE = [
    {key: 'BAREFOOT', value: '素足'},
    {key: 'THIN', value: '薄手'},
    {key: 'COTTON', value: '綿'},
    {key: 'THICK', value: '厚手'},
    {key: 'TIGHTS', value: 'ストッキング'},
];
export const getSocksTypeName = (value) => {
    if (value) {
        for (let i = 0; i < SOCKS_TYPE.length; i++) {
            if (SOCKS_TYPE[i].key === value)
                return SOCKS_TYPE[i].value;
        }
    }
    return value;
}

export const DUE_DATE_TYPE = [
    {key: '21', value: '3週間後...21日後'},
    {key: '28', value: '4週間後...28日後'},
    {key: '35', value: '5週間後...35日後'},
    {key: '42', value: '6週間後...42日後'},
    {key: '49', value: '7週間後...49日後'},
    {key: '56', value: '8週間後...56日後'},
    {key: '63', value: '9週間後...63日後'},
];
export const getDueDateTypeName = (value) => {
    if (value) {
        for (let i = 0; i < DUE_DATE_TYPE.length; i++) {
            if (DUE_DATE_TYPE[i].key === value)
                return DUE_DATE_TYPE[i].value;
        }
    }
    return value;
}


export const ORDER_STATUS = [
    {key: 'ORDERED', value: '発注済'},
    {key: 'PROCESSING', value: '製造中'},
    {key: 'COMPLETED', value: '製造完了'},
    {key: 'CANCEL', value: 'キャンセル'},
];
export const getOrderStatusName = (value) => {
    if (value) {
        for (let i = 0; i < ORDER_STATUS.length; i++) {
            if (ORDER_STATUS[i].key === value)
                return ORDER_STATUS[i].value;
        }
    }
    return value;
}

export const MASTER_STATUS = [
    {key: 'ENABLED', value: '有効'},
    {key: 'DISABLED', value: '無効'},
];
export const getMasterStatusName = (value) => {
    if (value) {
        for (let i = 0; i < MASTER_STATUS.length; i++) {
            if (MASTER_STATUS[i].key === value)
                return MASTER_STATUS[i].value;
        }
    }
    return value;
}

export const CATEGORY = [
    {key: 'ALL', value: 'すべて'},
    {key: 'MENS', value: 'メンズ'},
    {key: 'LADIES', value: 'レディス'},
];
export const getCategoryName = (value) => {
    if (value) {
        for (let i = 0; i < CATEGORY.length; i++) {
            if (CATEGORY[i].key === value)
                return CATEGORY[i].value;
        }
    }
    return value;
}

export const SIZE_CATEGORY = [
    {key: 'MENS', value: 'メンズ'},
    {key: 'LADIES', value: 'レディス'},
];

export const ORDER_CATEGORY1 = [
    {key: 'MENS', value: 'メンズ'},
    {key: 'LADIES', value: 'レディス'},
];
export const ORDER_CATEGORY2 = [
    {key: 'LEATHER_SHOES', value: '革靴'},
    {key: 'SNEAKERS', value: 'スニーカー'},
    {key: 'GOLF_SHOES', value: 'ゴルフシューズ'},
];

// データベースはsmallintなのでkeyは数字だけにする
export const HEEL_HEIGHT = [
    {key: '90', value: '90mmヒール'},
    {key: '60', value: '60mmヒール'},
    {key: '40', value: '40mmヒール'},
    {key: '20', value: '20mmヒール'},
];

export const LEATHER_TYPE = [
    {key: 'UPPER', value: '甲革素材'},
    {key: 'LINING', value: '裏革素材'},
];
export const getLeatherTypeName = (value) => {
    if (value) {
        for (let i = 0; i < LEATHER_TYPE.length; i++) {
            if (LEATHER_TYPE[i].key === value)
                return LEATHER_TYPE[i].value;
        }
    }
    return value;
}

export const USER_LEVEL = [
    {key: 'NORMAL', value: '一般'},
    {key: 'ADMINISTRATOR', value: '管理者'}
];
export const getUserLevelName = (value) => {
    if (value) {
        for (let i = 0; i < USER_LEVEL.length; i++) {
            if (USER_LEVEL[i].key === value)
                return USER_LEVEL[i].value;
        }
    }
    return value;
}