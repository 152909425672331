import React from 'react';

export default function Radio(props) {

    const radioId = 'radioId-'+props.name+'-'+props.value;

    let isChecked = false;
    if (typeof props.checked === 'boolean') {
        isChecked = props.checked;
    } else {
        isChecked = props.checked === props.value;
    }

    return (
        <div className="radio">
            <input type="radio" id={radioId}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                checked={isChecked}
                disabled={props.disabled}
            />
            <label htmlFor={radioId}>{props.label}</label>
        </div>
    );
}