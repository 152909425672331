import React, { useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import 'css/SellerMasterInput.css';

import * as API from 'components/API';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import Radio from 'components/Radio';
import LinkButton from 'components/LinkButton';
import { CATEGORY } from 'components/Resource';

function SellerMasterInput(props) {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setFormState = form.setFormState;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        if (param.id) {
            let result = await API.get('/master/seller/input?id='+param.id);
            if (mounted.current) {
                setFormState({
                    fields: result.Data
                });
            }
        } else {
            // ID指定がない新規入力
        }
    }, [param.id, setFormState]);

    const handleMainScroll = (e) => {
        const h1tag = document.querySelector('.InputContents h1');
        if (h1tag) {
            if (e.target.scrollTop > 0) {
                h1tag.classList.add('small');
            } else {
                h1tag.classList.remove('small');
            }
        }
    }

    // フォーカス時にtext全選択状態にするやつ
    const selectOnFocus = (e) => e.target.select();

    useEffect(() => {
        mounted.current = true;

        const main = document.querySelector('#View main');
        main.addEventListener('scroll', handleMainScroll, false);
        if (param.id) {
            // 編集時はデータ取得
            findData();
        } else {
            // 新規登録時の初期値セット
            setFormState({
                fields: {
                    SellerStatus: 'ENABLED'
                }
            });
        }
        return () => {
            mounted.current = false;
            main.removeEventListener('scroll', handleMainScroll, false);
        }
    }, [param.id, findData, setFormState]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.SellerCategory) {
            errors.SellerCategory = {
                Message: 'カテゴリは必須入力です。'
            }
        }
        if (!fields.SellerId) {
            errors.SellerId = {
                Message: 'メーカーIDは必須入力です。'
            }
        }
        if (!fields.SellerName) {
            errors.SellerName = {
                Message: 'メーカー名は必須入力です。'
            }
        }
        if (!fields.SellerStatus) {
            errors.SellerStatus = {
                Message: 'ステータスは必須入力です。'
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.postAsFormData('/master/seller/update', {
            'Id': param.id,
            'SellerCategory': state.fields?.SellerCategory || '',
            'SellerId': state.fields?.SellerId || '',
            'SellerName': state.fields?.SellerName || '',
            'SellerPostalCode': state.fields?.SellerPostalCode || '',
            'SellerAddress1': state.fields?.SellerAddress1 || '',
            'SellerAddress2': state.fields?.SellerAddress2 || '',
            'SellerTel': state.fields?.SellerTel || '',
            'SellerStatus': state.fields?.SellerStatus || '',
            'SellerNote': state.fields?.SellerNote || '',
            'UpdateDatetime': state.fields?.UpdateDatetime || '',
        });
        if (result?.Result) {
            // [OK] 一覧画面に遷移する
            history.push('/master/seller/list');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result.Errors);
        }
    });

    const handlePostalCodeInput = async e => {
        let result = await API.searchZip(e.target.value);
        if (result) {
            let fullAddress = result.Address1 + result.Address2 + result.Address3;
            setFormState({
                fields: {
                    SellerPostalCode: result.Zipcode.slice(0,3) + '-' + result.Zipcode.slice(3,7),
                    SellerAddress1: fullAddress
                }
            });
        }
    }

    return (
        <div id="SellerMasterInput" className="InputContents">
            <h1>
                <Link to="/master/seller/list">
                    <svg xmlns="http://www.w3.org/2000/svg"><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
                </Link>
                <span>メーカーマスタ</span>
                <div className="RequireHint"><span className="RequireMark">✱</span>必須入力</div>
            </h1>
            {state.errors?.Page?.map(message => (
                <div className="message error">{message.Message}</div>
            ))}
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">カテゴリ</div>
                    <div className="control">
                        <select name="SellerCategory" onChange={handleChange}
                            value={state.fields?.SellerCategory || ''}
                            className={state.errors?.SellerCategory && 'invalid'}
                        >
                            <option value="">選択してください</option>
                            {CATEGORY.map((category, index) => (
                                <option key={category.key} value={category.key}>{category.value}</option>
                            ))}
                        </select>
                        {state.errors?.SellerCategory &&
                            <span className="MessageTip error">{state.errors?.SellerCategory.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">メーカーID</div>
                    <div className="control">
                        <input type="text" id="SellerId" name="SellerId"
                            placeholder="メーカーID"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.SellerId || ''}
                            className={state.errors?.SellerId && 'invalid'}
                        />
                        {state.errors?.SellerId &&
                            <span className="MessageTip error">{state.errors?.SellerId.Message}</span>
                        }
                        <p className="description">
                            カテゴリとメーカーIDの組み合わせを一意に登録してください。同じ組み合わせのものは登録できません。
                        </p>
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">メーカー名</div>
                    <div className="control">
                        <input type="text" id="SellerName" name="SellerName"
                            placeholder="メーカー名"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.SellerName || ''}
                            style={{width: '28rem'}}
                            className={state.errors?.SellerName && 'invalid'}
                        />
                        {state.errors?.SellerName &&
                            <span className="MessageTip error">{state.errors?.SellerName.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">郵便番号</div>
                    <div className="control">
                        <span className="zipmark">〒</span>
                        <input type="text" id="SellerPostalCode" name="SellerPostalCode"
                            placeholder="例）480-0101"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            onInput={handlePostalCodeInput}
                            value={state.fields?.SellerPostalCode || ''}
                            className={state.errors?.SellerPostalCode && 'invalid'}
                        />
                        {state.errors?.SellerPostalCode &&
                            <span className="MessageTip error">{state.errors?.SellerPostalCode.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">住所</div>
                    <div className="control">
                        <input type="text" id="SellerAddress1" name="SellerAddress1"
                            placeholder="都道府県市区町村（郵便番号より自動引用）"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.SellerAddress1 || ''}
                            style={{width: '28rem'}}
                            className={state.errors?.SellerAddress1 && 'invalid'}
                        />
                        {state.errors?.SellerAddress1 &&
                            <span className="MessageTip error">{state.errors?.SellerAddress1.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label"></div>
                    <div className="control">
                        <input type="text" id="SellerAddress2" name="SellerAddress2"
                            placeholder="番地ビル建物名（他残りの住所）"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.SellerAddress2 || ''}
                            style={{width: '28rem'}}
                            className={state.errors?.SellerAddress2 && 'invalid'}
                        />
                        {state.errors?.SellerAddress2 &&
                            <span className="MessageTip error">{state.errors?.SellerAddress2.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">電話番号</div>
                    <div className="control">
                        <input type="text" id="SellerTel" name="SellerTel"
                            placeholder="
                            例）090-1234-5678"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.SellerTel || ''}
                            className={state.errors?.SellerTel && 'invalid'}
                        />
                        {state.errors?.SellerTel &&
                            <span className="MessageTip error">{state.errors?.SellerTel.Message}</span>
                        }
                        <p className="description">電話番号はハイフンを付けてください。</p>
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">ステータス</div>
                    <div className="control">
                        <div className="radioGroup row">
                            <Radio label="有効" name="SellerStatus" value="ENABLED" onChange={handleChange}
                                checked={state.fields?.SellerStatus === 'ENABLED'}
                            />
                            <Radio label="無効" name="SellerStatus" value="DISABLED" onChange={handleChange}
                                checked={state.fields?.SellerStatus === 'DISABLED'}
                            />
                        </div>
                        {state.errors?.SellerStatus &&
                            <span className="MessageTip error">{state.errors?.SellerStatus.Message}</span>
                        }
                        <p className="description">このデータが必要なくなったときはステータスを「無効」にします。「無効」にすると新しくこのデータを関連付けたりすることができなくなります。</p>
                    </div>
                </div>
                <div className="row">
                    <div className="label">備考</div>
                    <div className="control">
                        <textarea id="SellerNote" name="SellerNote" cols="30" rows="10"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.SellerNote || ''} >
                        </textarea>
                        {state.errors?.SellerNote &&
                            <span className="MessageTip error">{state.errors?.SellerNote.Message}</span>
                        }
                        <p className="description">このデータに関する覚書きや参考情報などを入力してください。一覧画面ではこの内容を検索して利用することも可能です。</p>
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/master/seller/list">キャンセル</LinkButton>
                <button type="submit" className="PrimaryButton">メーカーを登録</button>
            </div>
        </div>
    )
}
export default withFormField(SellerMasterInput);
