const DOCUMENT_ROOT = ''; // 本番
// const DOCUMENT_ROOT = '/ordering';

// APIのドキュメントルート(Router.phpのAPP_PREFIXと合わせる)
export const BASE_URL = '//' + window.location.host.split(':')[0] + ''+DOCUMENT_ROOT+'/api';

const _STORAGE_AUTH_KEY = window.location.host + '.authkey';
function getSessionUser() {

    let tokenString = localStorage.getItem(_STORAGE_AUTH_KEY);
    // console.log('tokenString from localStorage', tokenString);
    if (!tokenString) {
        // localStorageに無ければsessionStorageから
        tokenString = sessionStorage.getItem(_STORAGE_AUTH_KEY);
        // console.log('tokenString from sessionStorage', tokenString);
    }
    if (tokenString) {
        return JSON.parse(tokenString);
    }
}


function ngresponse(status) {

    if (status >= 400 && status < 500) {
        if (status === 401 || status === 403) {
            // unauthorize
            let user = getSessionUser();
            if (user) {
                sessionStorage.removeItem(_STORAGE_AUTH_KEY, null);
                localStorage.removeItem(_STORAGE_AUTH_KEY, null);
            }
            alert('セッションがタイムアウトしました。再度認証を行ってください。');
            window.location.reload();
        }
        else if (status === 408) {
            // request timeout
            alert('ただいまアクセスが集中し、サイトが大変混雑しております。 誠に申し訳ございません。しばらくたってから再度お試しください。408');
        }
        else {
            alert(`ただいまアクセスが集中し、サイトが大変混雑しております。 誠に申し訳ございません。しばらくたってから再度お試しください。${status}`);
        }
    }
    else if (status >= 500 && status < 600) {
        if (status === 503) {
            // service unavailable
            alert('ただいまアクセスが集中し、サイトが大変混雑しております。 誠に申し訳ございません。しばらくたってから再度お試しください。503');
        }
        else {
            alert(`サーバー処理中にエラーが発生しました。${status}`);
        }
    }
    else {
        console.error(status);
        throw new Error(`server response status ${status}`);
    }
}


export async function get(url, option={}) {

    let base = {
        mode: 'cors',
        credentials: 'include', // クッキーを送る(PHPSESSIONID用)
    }
    let param = Object.assign(base, option);

    if (url.lastIndexOf('?') >= 0) {
        url += '&t=' + (Date.now());
    } else {
        url += '?t=' + (Date.now());
    }

    const timeKey = `GET url=${url} at ` + Date.now();
    console.time(timeKey);

    const requestPath = BASE_URL + url;
    // const requestPath = encodeURIComponent(BASE_URL + url);
    try {
        let response = await fetch(requestPath, param);
        if (response.ok) {
            let json = await response.json();
            return json;
        } else {
            // responseが 200 OK じゃないとき
            ngresponse(response.status);
        }
    } catch (error) {
        console.error(error);
        return {};
    } finally {
        console.timeEnd(timeKey);
    }
}

export async function getBlob(url, option={}) {

    let base = {
        mode: 'cors',
        credentials: 'include', // クッキーを送る(PHPSESSIONID用)
    }
    let param = Object.assign(base, option);

    const timeKey = `GET url=${url}`;
    console.time(timeKey);

    const requestPath = BASE_URL + url;
    // const requestPath = encodeURIComponent(BASE_URL + url);
    try {
        let response = await fetch(requestPath, param);
        if (response.ok) {
            let blob = await response.blob();
            return blob;
        } else {
            // responseが 200 OK じゃないとき
            ngresponse(response.status);
        }
    } catch (error) {
        console.error(error);
        return {};
    } finally {
        console.timeEnd(timeKey);
    }
}

export async function post(url, data, option={}) {

    let base = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include', // クッキーを送る(PHPSESSIONID用)
        headers: {
            // 'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded', これがあると$_POSTなどで受け取れない！！
        },
        body: data
    }

    let param = Object.assign(base, option);

    const timeKey = `POST url=${url}`;
    console.time(timeKey);

    const requestPath = BASE_URL + url;
    // const requestPath = encodeURIComponent(BASE_URL + url);
    try {
        let response = await fetch(requestPath, param);
        if (response.ok) {
            let json = await response.json();
            return json;
        } else {
            // responseが 200 OK じゃないとき
            ngresponse(response.status);
        }
    } catch (error) {
        console.error(error);
        return {};
    } finally {
        console.timeEnd(timeKey);
    }
}

export async function postAsJson(url, data, option={}) {

    // dataはJSONとして送信する
    let jsonData = JSON.stringify(data);
    let baseHeaders = {
        'Content-Type': 'application/json'
    };
    let newOption = Object.assign(baseHeaders, option);

    return post(url, jsonData, newOption);
}

export async function postAsFormData(url, data, option={}) {

    // dataはFormDataとして送信する
    let formData = new FormData();
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = data[key];
        if (value === null) {
            // 値がないものは送らないよ
            continue;
        }
        // nullをappendすると文字列nullに変換されてしまいます
        formData.append(key, value);
    }

    return post(url, formData, option);
}

export async function searchZip(postalCode) {
    try {
        if (postalCode) {
            // ハイフンは取り除いておく
            let code = postalCode.replace(/-/g, '');
            if (code.length === 7) {
                let result = await get('/zip?Code=' + postalCode);
                // console.log('searchZip', result);
                if (!Object.keys(result).length) {
                    return null;
                }
                if (result.Error) {
                    console.error(result.Error);
                    return null;
                }
                return result;
            }
        }
    } catch (err) {
        console.error(err);
    }
    return null;
}


export async function auth(data, option={}) {

    let url = '/authorize';

    // dataはFormDataとして送信する
    let formData = new FormData();
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = data[key];
        if (value === null) {
            // 値がないものは送らないよ
            continue;
        }
        // nullをappendすると文字列nullに変換されてしまいます
        formData.append(key, value);
    }

    let base = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include', // クッキーを送る(PHPSESSIONID用)
        headers: {
            // 'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded', これがあると$_POSTなどで受け取れない！！
        },
        body: formData
    }

    let param = Object.assign(base, option);

    const timeKey = `POST url=${url}`;
    console.time(timeKey);

    const requestPath = BASE_URL + url;
    // const requestPath = encodeURIComponent(BASE_URL + url);
    try {
        let response = await fetch(requestPath, param);
        if (response.ok) {
            let json = await response.json();
            return json;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    } finally {
        console.timeEnd(timeKey);
    }
}
