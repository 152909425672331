import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';

import 'css/BillingList.css';

import * as API from 'components/API';
import Loader from 'components/Loader';
import Calendar from 'components/Calendar';

import Pagination from 'components/Pagination';
import ListSettingDialog from 'components/Dialog/ListSettingDialog';
import * as PickerDialog from 'components/Dialog/PickerDialog';

import SortableHeader from 'components/SortableHeader';
import { useLocation } from 'react-router-dom';


const d = new Calendar();
const DEFAULT_CONDITION = {
    conditions: {
        Range: {
            Start: d.format('Y-m-01'),
            End: d.format('Y-m-t')
        },
        BuyerId: '',
        BuyerPartyName: '',
    },
    sort: {
        column: '',
        direction: 'none',
    },
    limit: 20,
    offset: 0,
    lastRefresh: null,
}
const INITIAL_STATE = {
    columns: [],
    ...DEFAULT_CONDITION
}

function stateReducer(state, event) {
    return {...state, ...event};
}

function createParam(key, value) {
    return '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
}


function TableCell(props) {
    let row = props.row;
    let column = props.column;
    // ↓ここで、列ごとに個別対応が必要なものを書いていく

    if (column.key === 'UserId') {

    }

    return <td key={column.key}><div>{row[column.key]}</div></td>;
}

export default function BillingList() {

    const COMPONENT_NAME = 'BillingListForm';

    const settingClick = e => {
        let listSetting = new ListSettingDialog({
            form: COMPONENT_NAME,
            columns: state.columns,
            limit: state.limit,
            doAction: (params) => {
                // フィルターも並びもページングもリセットが必要
                if (params.reset) {

                    API.get('/data/user/find?DataKey=' + COMPONENT_NAME + '.ListSetting&form=' + COMPONENT_NAME)
                    .then(result => {
                        if (result.Result) {
                            if (mounted.current) {
                                try {
                                    // console.log(result);
                                    const userData = JSON.parse(result.Result.DataValue);
                                    setState({ ...userData });
                                } catch (error) {
                                    console.error(error);
                                }
                                console.log('load state.columns from server!');
                            }
                        }
                    });
                } else {
                    setState({ ...DEFAULT_CONDITION, ...params });
                }
            }
        });
        listSetting.show();
    }

    const [listData, setListData] = useState({});
    const [listLoading, setListLoading] = useState(true);
    const [state, setState] = useReducer(stateReducer, {...INITIAL_STATE});
    const mounted = useRef(false);

    const refreshData = () => {
        setState({ lastRefresh: Date.now() });
    }

    // ユーザー固有の設定を反映させる
    useEffect(()=>{
        mounted.current = true;

        // sessionStorageにstateが保存されていればそれを使う
        let sessionState = window.sessionStorage.getItem(COMPONENT_NAME);
        if (sessionState) {
            let savedState = JSON.parse(sessionState);
            setState({ ...savedState });
            console.log('load state from session!');
        }
        // sessionStorageにstateが保存されていなければ、サーバーに保存されたものを使う
        else {
            API.get('/data/user/find?DataKey=' + COMPONENT_NAME + '.ListSetting&form=' + COMPONENT_NAME)
            .then(result => {
                if (result.Result) {
                    if (mounted.current) {
                        try {
                            // console.log(result.Result.DataValue);
                            const userData = JSON.parse(result.Result.DataValue);
                            setState({ ...userData });
                        } catch (error) {
                            console.error(error);
                        }
                        console.log('load state.columns from server!');
                    }
                }
            });
        }
        return () => {
            mounted.current = false;
        }
    }, []);

    const location = useLocation();
    const InitFilterRange = location.state?.Range;

    useEffect(() => {
        if (InitFilterRange) {
            const ym = new Calendar(InitFilterRange);
            setState({
                conditions: {
                    Range: {
                        Start: ym.format('Y-m-01'),
                        End: ym.format('Y-m-t')
                    },
                    BuyerId: ''
                }
            });
        }
    }, [InitFilterRange]);

    const getListData = useCallback(async ()=>{
        let url = '/billing/list?';
        // conditions
        if (state.conditions) {
            // 期間
            url += createParam('RangeStart', state.conditions.Range.Start);
            url += createParam('RangeEnd', state.conditions.Range.End);
            // 発注者
            url += createParam('BuyerId', state.conditions.BuyerId);
            // url += createParam('BuyerPlaceId', state.conditions.BuyerPlaceId);
        }
        // sorts
        if (state.sort.column) {
            url += createParam('sortColumnName', state.sort.column);
        }
        if (state.sort.direction) {
            url += createParam('sortDirection', state.sort.direction);
        }

        // limit,offset
        url += createParam('limit', state.limit);
        url += createParam('offset', state.offset);

        let data = await API.get(url);
        if (mounted.current) {
            console.log(data);
            setListData(data || {});
        }
        setListLoading(false);

    }, [state.conditions, state.sort, state.limit, state.offset]);

    useEffect(() => {
        setListLoading(true);
        getListData();
    }, [
        state.conditions, state.sort, state.limit, state.offset,
        state.columns, state.lastRefresh, getListData
    ]);

    useEffect(() => {
        // データが更新されたらスクロールをもとに戻しておく
        let scrollElement = document.querySelector('.TableWrapper');
        // 縦方向だけリセット
        scrollElement.scrollTo(scrollElement.scrollLeft, 0);
    }, [listData]);

    const handleBuyerClick = (e) => {
        const picker = PickerDialog.createBuyerPartyPicker();
        picker.setSelectedCallback((selected)=>{
            console.log(selected);
            setState({
                conditions: {
                    ...state.conditions,
                    BuyerId: selected.BuyerId,
                    BuyerPartyName: selected.BuyerPartyName,
                }
            });
        });
        picker.show();
    }

    const handleRangeStartChange = (e) => {
        setState({
            conditions: {
                ...state.conditions,
                Range: {
                    Start: e.target.value,
                    End: state.conditions.Range.End
                }
            }
        })
    }
    const handleRangeEndChange = (e) => {
        setState({
            conditions: {
                ...state.conditions,
                Range: {
                    Start: state.conditions.Range.Start,
                    End: e.target.value
                }
            }
        })
    }

    const handleCSVDownload = async(e) => {
        API.getBlob('/billing/download')
        .then(blob => {
            // ファイル名をつくる
            let fileName = '';
            let rangeStart = new Calendar(state.conditions.Range.Start);
            // let rangeEnd = new Calendar(state.conditions.Range.End);
            fileName += '課金表';
            fileName += '_'+state.conditions.BuyerId;
            fileName += '_'+rangeStart.format('Y_m');
            // fileName += '_'+rangeEnd.format('Y_m');
            fileName += '.csv';

            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.download = fileName;
            a.href = url;
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        });

    }

    return (
        <>
        <div id="BillingList" className="ListContents">
            <h1>課金データ<span className="counter">({listData.Result?.TotalCount})</span></h1>
            <div id="SubHeader">
                <div className="grow1">
                    <div className="listFilter">
                        <span className="subtitle">期間</span>
                        <input type="date"
                            onChange={handleRangeStartChange}
                            value={state.conditions.Range.Start || ''}
                            style={{width: '10rem'}}
                        />
                        <span>～</span>
                        <input type="date"
                            onChange={handleRangeEndChange}
                            value={state.conditions.Range.End || ''}
                            style={{width: '10rem'}}
                        />
                    </div>
                    <div className="listFilter" style={{marginTop:'.5rem'}}>
                        <span className="subtitle">発注会社</span>
                        <input type="text"
                            placeholder="発注者を選択"
                            onClick={handleBuyerClick}
                            style={{width: '25rem'}}
                            value={
                                state.conditions.BuyerId
                                ? ((state.conditions.BuyerPartyName || '') + '' + (state.conditions.BuyerPlaceName || ''))
                                : ''
                            }
                            className={state.errors?.BuyerId && 'invalid'}
                            readOnly={true}
                        />
                        <button type="button" className="PrimaryButton" onClick={handleCSVDownload}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"/></svg>
                            <span>CSV出力</span>
                        </button>
                        <div style={{marginLeft:'1rem'}}>
                            試算回数: <span style={{fontSize:'1.25rem'}}>{listData.Result?.EstimateCount || 0}</span>
                        </div>
                    </div>
                </div>

                <button type="button" id="RefreshComponent" className="LinkButton IconButton" onClick={refreshData}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                </button>
                <button type="button" id="SettingComponent" className="LinkButton IconButton" onClick={settingClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>
                </button>

            </div>
            <section>
                <div className="TableWrapper">
                    <table>
                        <thead>
                            <tr>
{
state.columns?.map(column => {
    return (column.visible) ?
        <SortableHeader key={column.key}
            column={column}
            state={state}
            setState={setState}
        >
            <div>{column.name}</div>
        </SortableHeader>
    : null;
})
}
                            <th className="EndColumn"></th>
                            </tr>
                        </thead>
                        <tbody>
{
listData.Result?.Data.map(row => (
    <tr key={row?.Id}>
        {
        state.columns.map(column => {
            return (column.visible) ?
                <TableCell key={column.index} column={column} row={row} />
            : null;
        })
        }
        <td className="EndColumn"></td>
    </tr>
))
}
                        </tbody>
                        <tfoot></tfoot>
                    </table>
                    {(!listData.Result || listData.Result?.TotalCount === 0) &&
                        <div className="noDataLabel">該当するデータはありません。</div>}
                    {listLoading && <Loader /> }
                </div>
                <div id="SubFooter">
                    <Pagination
                        listCount={listData?.Result?.ListCount}
                        totalCount={listData?.Result?.TotalCount}
                        state={state}
                        setState={setState}
                    />
                </div>
            </section>
        </div>
        </>
    );

}
