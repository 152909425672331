import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import 'css/OrderList.css';

import * as API from 'components/API';
import Loader from 'components/Loader';
import OmniSearch from 'components/OmniSearch';
import Pagination from 'components/Pagination';
import ListSettingDialog from 'components/Dialog/ListSettingDialog';

import SortableHeader from 'components/SortableHeader';
import * as ListColumn from 'components/ListColumn';

import OrderMemoDialog from 'components/Dialog/OrderMemoDialog';
import { getOrderStatusName, ORDER_CATEGORY2 } from 'components/Resource';


let o = 0;
// ↓これを動的に取得したい
const DEFAULT_COLUMN_LIST = [
    // { index: o++,  key: 'Id',                visible: false, width: 120, search: false, type: '',              name: 'ID'},
    { index: o++,  key: 'OrderId',           visible: true,  width: 120, search: true,  type: '',              name: '発注番号'},
    { index: o++,  key: 'OrderCategory1',     visible: false,  width: 120, search: true,  type: '',              name: 'カテゴリ1'},
    { index: o++,  key: 'OrderCategory2',     visible: false,  width: 120, search: true,  type: '',              name: 'カテゴリ2'},

    { index: o++,  key: 'Customer',           visible: true,  width: 160,  search: true,  type: '',             name: 'お客様'},
    { index: o++,  key: 'CustomerLastName',   visible: false,  width: 100, search: true,  type: '',              name: '氏名(姓)'},
    { index: o++,  key: 'CustomerFirstName',  visible: false,  width: 100, search: true,  type: '',              name: '氏名(名)'},
    { index: o++,  key: 'CustomerLastRuby',   visible: false,  width: 120, search: true,  type: '',              name: 'ふりがな(姓)'},
    { index: o++,  key: 'CustomerFirstRuby',  visible: false,  width: 120, search: true,  type: '',              name: 'ふりがな(名)'},
    { index: o++,  key: 'CustomerBirthday',   visible: false,  width: 120, search: true,  type: '',              name: '生年月日'},
    // { index: o++,  key: 'CustomerHeight',     visible: false,  width: 120, search: true,  type: 'NumberFormula',  name: '身長'},
    // { index: o++,  key: 'CustomerWeight',     visible: false,  width: 120, search: true,  type: 'NumberFormula',  name: '体重'},
    { index: o++,  key: 'CustomerPostalCode', visible: false,  width: 120, search: true,  type: '',              name: '郵便番号'},
    { index: o++,  key: 'CustomerAddress1',   visible: false,  width: 200, search: true,  type: '',              name: '住所1'},
    { index: o++,  key: 'CustomerAddress2',   visible: false,  width: 200, search: true,  type: '',              name: '住所2'},
    { index: o++,  key: 'CustomerTel',        visible: false,  width: 120, search: true,  type: '',              name: '電話番号'},
    { index: o++,  key: 'CustomerOffice',     visible: false,  width: 200, search: true,  type: '',              name: '勤務先等'},
    { index: o++,  key: 'CustomerMail',       visible: false,  width: 240, search: true,  type: '',              name: 'メールアドレス'},
    // { index: o++,  key: 'CustomerNote',      visible: false,  width: 120, search: true,  type: '',              name: '備考'},

    { index: o++, key: 'ItemId',            visible: true,  width: 100, search: true,  type: '',              name: '品番'},
    { index: o++, key: 'ItemName',          visible: true,  width: 240, search: true,  type: '',              name: '品名'},

    { index: o++,  key: 'Seller',            visible: true,   width: 140, search: true,  type: '',              name: 'メーカー'},
    { index: o++,  key: 'SellerId',          visible: false,  width: 100, search: true,  type: '',             name: 'メーカーID'},
    // { index: o++,  key: 'SellerCategory',    visible: false,  width: 140, search: true,  type: '',             name: 'メーカーカテゴリ'},
    { index: o++,  key: 'SellerName',        visible: false,  width: 140, search: true,  type: '',             name: 'メーカー名'},

    { index: o++, key: 'ColorCode1',        visible: false,  width: 140, search: true,  type: '',              name: '色コード1'},
    { index: o++, key: 'ColorKey1',         visible: false,  width: 140, search: true,  type: '',              name: '色キー1'},
    { index: o++, key: 'ColorName1',        visible: false,  width: 140, search: true,  type: '',              name: '色名1'},

    { index: o++, key: 'ColorCode2',        visible: false,  width: 140, search: true,  type: '',              name: '色コード2'},
    { index: o++, key: 'ColorKey2',         visible: false,  width: 140, search: true,  type: '',              name: '色キー2'},
    { index: o++, key: 'ColorName2',        visible: false,  width: 140, search: true,  type: '',              name: '色名2'},

    { index: o++, key: 'OutsoleId',         visible: false,  width: 140, search: true,  type: '',              name: '本底素材ID'},
    { index: o++, key: 'OutsoleName',       visible: false,  width: 140, search: true,  type: '',              name: '本底素材名'},

    { index: o++, key: 'LastId',            visible: false,  width: 140, search: true,  type: '',              name: '木型ID'},
    { index: o++, key: 'LastName',          visible: false,  width: 140, search: true,  type: '',              name: '木型名'},

    { index: o++, key: 'UpperLeatherId',    visible: false,  width: 140, search: true,  type: '',              name: '甲革素材ID'},
    { index: o++, key: 'UpperLeatherName',  visible: false,  width: 140, search: true,  type: '',              name: '甲革素材名'},

    { index: o++, key: 'LiningLeatherId',   visible: false,  width: 140, search: true,  type: '',              name: '裏革素材ID'},
    { index: o++, key: 'LiningLeatherName', visible: false,  width: 140, search: true,  type: '',              name: '裏革素材名'},

    { index: o++,  key: 'Buyer',             visible: false,   width: 160, search: true,  type: '',              name: '発注者'},
    { index: o++,  key: 'BuyerId',           visible: false,  width: 120, search: true,  type: '',              name: '発注者ID'},
    { index: o++,  key: 'BuyerPartyName',    visible: false,  width: 160, search: true,  type: '',              name: '発注者会社名'},
    { index: o++,  key: 'BuyerPlaceId',      visible: false,  width: 120, search: true,  type: '',              name: '発注者店ID'},
    { index: o++,  key: 'BuyerPlaceName',    visible: false,  width: 160, search: true,  type: '',              name: '発注者店名'},

    { index: o++, key: 'OrderDate',         visible: true,  width: 120, search: true,  type: 'DateRange',     name: '発注日'},
    { index: o++, key: 'DueDate',           visible: true,  width: 120, search: true,  type: 'DateRange',     name: '納期'},
    { index: o++, key: 'OrderNote',         visible: false, width: 340, search: true,  type: '',              name: '備考'},

    { index: o++, key: 'ProductStartDate',  visible: true,  width: 120, search: true,  type: 'DateRange',     name: '製造開始日'},
    { index: o++, key: 'ProductEndDate',    visible: true,  width: 120, search: true,  type: 'DateRange',     name: '製造完了日'},

    { index: o++, key: 'OrderStatus',       visible: true,  width: 120, search: true,  type: '',              name: '状況'},
    { index: o++, key: 'OrderInsole',       visible: false,  width: 160, search: true,  type: '',              name: '中敷補正'},
    { index: o++, key: 'SellingPrice',      visible: false,  width: 160, search: true,  type: 'NumberFormula', name: '販売価格'},
    { index: o++, key: 'WholesalePrice',    visible: false,  width: 160, search: true,  type: 'NumberFormula', name: '仕切価格'},

    { index: o++, key: 'CreateUserId',      visible: false,  width: 160, search: true,  type: '',              name: '作成ユーザー'},
    { index: o++, key: 'UpdateUserId',      visible: false,  width: 160, search: true,  type: '',              name: '更新ユーザー'},
    { index: o++, key: 'CreateDatetime',    visible: true,  width: 160, search: true,  type: 'DateRange',     name: '作成日時'},
    { index: o++, key: 'UpdateDatetime',    visible: true,  width: 160, search: true,  type: 'DateRange',     name: '更新日時'},
];

const DEFAULT_CONDITION = {
    filters: [],
    keywords: [],
    sort: {
        column: '',
        direction: 'none',
    },
    limit: 20,
    offset: 0,
    lastRefresh: null,
}
const INITIAL_STATE = {
    columns: DEFAULT_COLUMN_LIST,
    ...DEFAULT_CONDITION
}

function stateReducer(state, event) {
    return {...state, ...event};
}

function createParam(key, value) {
    return '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
}


function TableCell(props) {
    let row = props.row;
    let column = props.column;
    // ↓ここで、列ごとに個別対応が必要なものを書いていく

    // 発注番号
    if (column.key === 'OrderId') {
        return (
            <td key={column.key}>
                <div><Link to={`/business/order/view/${row?.Id}`}>{row[column.key]}</Link></div>
            </td>
        );
    }

    // カテゴリ(複数カンマ区切り)
    if (column.key === 'OrderCategory1') {
        if (row[column.key]) {
            let category = row[column.key].split(',');
            let jsx = [];
            for (let i = 0; i < category.length; i++) {
                jsx.push(<span key={i} className={`category ${category[i]}`}>{category[i]}</span>);
            }
            return (<td><div>{jsx}</div></td>);
        }
    }

    // カテゴリ1
    // if (column.key === 'OrderCategory1') {
    //     return <td key={column.key}><ListColumn.Choice value={row[column.key]} list={ORDER_CATEGORY1} /></td>;
    // }
    // カテゴリ2
    if (column.key === 'OrderCategory2') {
        return <td key={column.key}><ListColumn.Choice value={row[column.key]} list={ORDER_CATEGORY2} /></td>;
    }
    // お客様
    if (column.key === 'Customer') {
        return <td key={column.key}><ListColumn.Customer row={row} /></td>;
    }
    // 発注者
    if (column.key === 'Buyer') {
        return <td key={column.key}><ListColumn.Buyer row={row} /></td>;
    }
    // 受注者
    if (column.key === 'Seller') {
        return <td key={column.key}><ListColumn.Seller row={row} /></td>;
    }
    // ステータス
    if (column.key === 'OrderStatus') {
        return (
            <td key={column.key} className={`status status_`+row[column.key]}>
                <ListColumn.OrderStatus value={row[column.key]} />
            </td>
        );
    }

    return <td key={column.key}><div>{row[column.key]}</div></td>;
}

export default function OrderList() {
    const COMPONENT_NAME = 'OrderList';

    const routerHistory = useHistory();
    const addItemClick = e => {
        routerHistory.push('/business/order/input');
    }
    const settingClick = e => {
        let listSetting = new ListSettingDialog({
            form: COMPONENT_NAME,
            columns: state.columns,
            limit: state.limit,
            doAction: (params) => {
                // フィルターも並びもページングもリセットが必要
                if (params.reset) {
                    setState({ columns: [...DEFAULT_COLUMN_LIST] });
                } else {
                    setState({ ...DEFAULT_CONDITION, ...params });
                }
            }
        });
        listSetting.show();
    }

    const [listData, setListData] = useState({});
    const [listLoading, setListLoading] = useState(true);
    const [state, setState] = useReducer(stateReducer, {...INITIAL_STATE});
    const mounted = useRef(false);

    const refreshData = () => {
        setState({ lastRefresh: Date.now() });
    }

    const location = useLocation();
    const InitFilterStatus = location.state?.OrderStatus;

    // ユーザー固有の設定を反映させる
    useEffect(()=>{
        mounted.current = true;

        // 外部からORDER_STATUSの条件が渡されたら初期値としてフィルタにセット
        if (InitFilterStatus) {
            setState({
                filters: [{
                    filterName: '状況',
                    filterColumn: 'OrderStatus',
                    filterFormula: '=',
                    filterValue: InitFilterStatus,
                    filterText: getOrderStatusName(InitFilterStatus),
                }],
                offset: 0
            });
        }

        // sessionStorageにstateが保存されていればそれを使う
        let sessionState = window.sessionStorage.getItem(COMPONENT_NAME);
        if (sessionState) {
            let savedState = JSON.parse(sessionState);
            setState({ ...savedState });
            console.log('load state from session!');
        }
        // sessionStorageにstateが保存されていなければ、サーバーに保存されたものを使う
        else {
            API.get('/data/user/find?DataKey=' + COMPONENT_NAME+'.ListSetting')
            .then(result => {
                if (mounted.current) {
                    if (result.Result) {
                        try {
                            const userData = JSON.parse(result.Result.DataValue);
                            setState({ ...userData });
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            });
        }
        return () => {
            mounted.current = false;
        }
    }, [InitFilterStatus]);

    const loadTimeoutId = useRef(null);
    const getListData = useCallback(()=>{
        if (loadTimeoutId.current) {
            clearTimeout(loadTimeoutId.current);
            loadTimeoutId.current = null;
        }
        loadTimeoutId.current = setTimeout(async ()=>{
            let url = '/table/order/list?';
            // filters
            for (let i = 0; i < state.filters.length; i++) {
                let f = state.filters[i];
                url += createParam('filterColumn[]', f.filterColumn);
                url += createParam('filterValue[]', f.filterValue);
                url += createParam('filterFormula[]', f.filterFormula);
            }
            // keywords
            for (let i = 0; i < state.keywords.length; i++) {
                let k = state.keywords[i];
                url += createParam('searchKeyword[]', k.searchKeyword);
            }
            // sorts
            if (state.sort.column) {
                url += createParam('sortColumnName', state.sort.column);
            }
            if (state.sort.direction) {
                url += createParam('sortDirection', state.sort.direction);
            }

            // limit,offset
            url += createParam('limit', state.limit);
            url += createParam('offset', state.offset);

            let data = await API.get(url);
            if (mounted.current) {
                // console.log(data);
                setListData(data || {});
            }

            // sessionStorageにstateを保存しておく
            window.sessionStorage.setItem(COMPONENT_NAME, JSON.stringify(state));

            setListLoading(false);
        }, 100);

    }, [state]);

    useEffect(() => {
        setListLoading(true);
        getListData();
    }, [
        state.filters, state.keywords, state.sort, state.limit, state.offset,
        state.columns, state.lastRefresh, getListData
    ]);

    useEffect(() => {
        // データが更新されたらスクロールをもとに戻しておく
        let scrollElement = document.querySelector('.TableWrapper');
        // 縦方向だけリセット
        scrollElement.scrollTo(scrollElement.scrollLeft, 0);
    }, [listData]);

    const handleMemoClick = (OrderId) => {
        let dialog = new OrderMemoDialog({
            OrderId: OrderId,
            ActionCallback: () => {
                // 保存してもしてなくても更新しとくか
                setState({ lastRefresh: Date.now() });
                // console.log('更新！');
            }
        });
        dialog.show();
    }

    return (
        <>
            <h1>発注データ<span className="counter">({listData.Result?.TotalCount})</span></h1>
            <div id="OrderList" className="ListContents">
                <div id="SubHeader">
                    <button type="button" id="AddItemComponent" className="PrimaryButton" onClick={addItemClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                        <span>発注データを作成</span>
                    </button>
                    <OmniSearch
                        columns={state.columns}
                        state={state}
                        setState={setState}
                        filterUrl={`/table/order/filter`}
                    />
                    <button type="button" id="RefreshComponent" className="LinkButton IconButton" onClick={refreshData}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                    </button>
                    <button type="button" id="SettingComponent" className="LinkButton IconButton" onClick={settingClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>
                    </button>
                </div>
                <section>
                    <div className="TableWrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th className="EditLinkColumn"><div>補正メモ</div></th>
{
state.columns.map(column => {
    return (column.visible) ?
        <SortableHeader key={column.key}
            column={column}
            state={state}
            setState={setState}
        >
            <div>{column.name}</div>
        </SortableHeader>
    : null;
})
}
                                <th className="EndColumn"></th>
                                </tr>
                            </thead>
                            <tbody>
{
listData.Result?.Data.map((row, index) => (
    <tr key={row.Id} className={`status_` + row.OrderStatus}>
        <td className="EditLinkColumn">
            {(row.FittingNoteLength === null || row.FittingNoteLength === 0) && (
                <div title="補正メモを登録します。">
                    <button type="button" className="LinkButton" onClick={e=>handleMemoClick(row.OrderId)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                    </button>
                </div>
            )}
            {row.FittingNoteLength > 0 && (
                <div className="hasFittingNote" title="補正メモを編集します。">
                    <button type="button" className="LinkButton" onClick={e=>handleMemoClick(row.OrderId)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3,10h11v2H3V10z M3,8h11V6H3V8z M3,16h7v-2H3V16z M18.01,12.87l0.71-0.71c0.39-0.39,1.02-0.39,1.41,0l0.71,0.71 c0.39,0.39,0.39,1.02,0,1.41l-0.71,0.71L18.01,12.87z M17.3,13.58l-5.3,5.3V21h2.12l5.3-5.3L17.3,13.58z"/></svg>
                    </button>
                </div>
            )}
        </td>
        {
        state.columns.map(column => {
            return (column.visible) ?
                <TableCell key={column.index} column={column} row={row} />
            : null;
        })
        }
        <td className="EndColumn"></td>
    </tr>
))
}
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                        {(!listData.Result || listData.Result?.TotalCount === 0) &&
                            <div className="noDataLabel">該当するデータはありません。</div>}
                        {listLoading && <Loader /> }
                    </div>
                    <div id="SubFooter">
                        <Pagination
                            listCount={listData?.Result?.ListCount}
                            totalCount={listData?.Result?.TotalCount}
                            state={state}
                            setState={setState}
                        />
                    </div>
                </section>
            </div>
        </>
    );
}
