import React, { useEffect } from 'react';
import 'css/PrivacyPolicy.css';

export default function PrivacyPolicy() {

    useEffect(() => {
        document.title = `プライバシーポリシー - UPMOST -`;
        let site = document.getElementById('Site');
        if (site) {
            site.scrollTo(0,0);
        }
    }, []);

    return (
        <div id="PrivacyPolicy">

            <h1>プライバシーポリシー</h1>
            <p>(株)大島商事（以下「当社」という）は、お客様の個人情報保護の重要性を認識するとともに、個人情報に関する法令及びその他の規範を遵守し、かつ自主的なルールおよび体制を確立し、以下の通り個人情報保護方針を定め、これを実行し継続的に見直し、改善・向上に努めております。</p>

            <div>
                <h2>個人情報の収集、利用について</h2>
                <p>個人情報とは、当社Webサイトを通じ、お客様からご提供いただくお名前、ご住所、電話番号、メールアドレス等、お客様個人を識別できる情報を意味します。お客様に個人情報の提供をお願いする際は、あらかじめその利用目的を明示いたします。個人情報を提供いただいたことで個人情報の取扱いに対するお客様の同意をいただいたものとさせていただきます。当社がWebサイトから取得した個人情報は、原則として、お客様の同意なく明示した利用目的以外に扱うことはいたしません。また、お客様が安心して当社Webサイトをご利用いただけるよう、個人情報取扱責任者を定め、お客様の個人情報の適切な取扱いに最大限の注意を払っております。</p>

                <h2>個人情報収集の内容・目的について</h2>
                <p>お客様からご提供いただく個人情報と、利用目的は以下のとおりです。</p>

                <h3>【資料請求】</h3>
                <table>
                    <tbody>
                        <tr><th>収集内容</th><td>お名前、メールアドレス、資料送付先住所、電話番号など</td></tr>
                        <tr><th>利用目的</th><td>資料の送付、 商品やサービスの詳細なご案内のため</td></tr>
                    </tbody>
                </table>
                <h3>【ご質問】</h3>
                <table>
                    <tbody>
                        <tr><th>収集内容</th><td>お名前、メールアドレス、住所、電話番号など</td></tr>
                        <tr><th>利用目的</th><td>ご質問への返答</td></tr>
                    </tbody>
                </table>

                <h2>個人情報の第三者提供について</h2>
                <p>
                    お客様からお預かりした個人情報を、次のいずれかに該当する場合を除き、第三者に提供することはありません。利用目的を達成するために業務を委託する外部業者には、機密保持に関する契約を締結した上で、サービス提供以外の目的での情報の利用を禁止しています。
                </p>
                <ol>
                    <li>お客様の同意がある場合</li>
                    <li>個人が識別できない状態で開示する場合（マーケティング資料作成のため）</li>
                    <li>ご請求いただいた資料をお届けするため宅配業者を使用する場合</li>
                    <li>法令等または行政等の要請により開示を求められた場合</li>
                    <li>個人の生命、身体、財産の保護などに必要で、かつお客様の同意を得ることが困難な場合</li>
                </ol>

                <h2>個人情報の安全管理について</h2>
                <p>当社では、お客様からご提供いただいた個人情報を、大切に管理、保管し、お客様の個人情報への不正アクセス、改ざん、流用、紛失、漏洩等が生じないよう、合理的な安全対策を講じ、安全性の確保に努めます。また、社員に対する教育啓発活動を実施するほか、個人情報を取り扱う部門ごとに管理責任者を置き、個人情報の適切な管理に努めます。</p>

                <h2>個人情報の共同利用について</h2>
                <p>当社はお客様からお預かりした個人情報を、お客様へサービスのご案内を提供するために、利用目的の範囲内でグループ間で共同利用いたします。</p>

                <h2>個人情報の開示・訂正・削除について</h2>
                <p>お客様がご自身の個人情報の内容の確認又は訂正等を希望される場合には、メールによりご連絡をいただければ、ご本人であることを確認したうえで、合理的な範囲ですみやかに対応させていただきます。</p>

                <h2>アクセスログについて</h2>
                <p>当社Webサイトでは、お客様が当社Webサイトをご覧になったという情報をアクセスログという形で記録しています。アクセスログは、使用プロバイダーの種類、使用ブラウザの種類、アクセス日時などが含まれますが、個人を特定できる情報は一切含まれません。アクセスログは当社Webサイトを改善するための統計資料として利用され、それ以外の目的で利用されることはありません。</p>

                <h2>Cookie（クッキー）について</h2>
                <p>Cookie（クッキー）とは、お客様が当社Webサイトをご覧になったという情報を、お客様のコンピュータ上に記憶させておく仕組みのことです。Cookieを通じて収集する情報には、個人を特定できる情報は一切含まれません。当社Webサイトでは、メンバーズサイト内においてのみCookieを利用しております。主に、お客様のログイン状況の確認や本サービスの各種機能の実装を目的とします。</p>

                <p>※お客様はCookie利用の可否を選択できます（選択の方法については、お客様の使用環境で異なりますのでブラウザのヘルプなどをご確認ください）。ただし、Cookieの利用を拒否した場合使用できないコンテンツが含まれる可能性がございます。</p>

                <h2>お問い合わせについて</h2>
                <p>本プライバシーポリシーおよび個人情報の取り扱いに関するお問い合わせはメールにてご連絡ください。</p>

            </div>

        </div>
    );
}
