import React, { useEffect } from 'react';
import 'css/SpecifiedCommercial.css';

export default function SpecifiedCommercial() {

    useEffect(() => {
        document.title = `特定商取引に基づく表示 - UPMOST -`;
        let site = document.getElementById('Site');
        if (site) {
            site.scrollTo(0,0);
        }
    }, []);

    return (
        <div id="SpecifiedCommercial">
            <h1>特定商取引に基づく表示</h1>
            <table>
                <tbody>
                    <tr><th>販売業者</th>
                        <td>株式会社大島商事</td></tr>
                    <tr><th>運営統括責任者</th>
                        <td>代表取締役　大島　昇</td></tr>
                    <tr><th>所在地</th>
                        <td>〒494-0003<br />
                        愛知県一宮市三条字賀37番地1 2F</td></tr>
                    <tr><th>連絡先</th>
                        <td>TEL:0586-61-6616&ensp;FAX:0586-82-6161</td></tr>
                    <tr><th>商品代金以外の料金の説明</th>
                        <td>●送料（配送手配の場合）。振込手数料（お振り込みの場合）。現金書留送料（現金書留の場合）。<br />
                            ●送料は、数量によって変わりますので、ご発注後に正確な送料を、メールもしくはＦＡＸ、またはお電話にてご案内致します。</td></tr>
                    <tr><th>不良品</th>
                        <td>弊社の過誤もしくは破損・不良等による返品は、開封・未開封を問いません。着払いにて発送してください。また、商品到着後１週間以内に返品してください。ご返品頂いた商品を確認後、折り返し、返金もしくは代替品を発送致します。</td></tr>
                    <tr><th>返品期限</th>
                        <td>到着後1週間以内に返品してください。ご返品頂いた商品を確認後、折り返し、返金もしくは代替品を発送致します。</td></tr>
                    <tr><th>返品送料</th>
                        <td>サイズ交換等の良品についてのご返品は、未開封のもののみ受付致します。返品送料はお客様負担にてお願い致します。弊社の過誤もしくは破損・不良等による返品は開封・未開封を問いません。着払いにて発送してください。いづれも商品到着後１週間以内に返品してください。ご返品頂いた商品を確認後、折り返し、返金もしくは代替品を発送致します。</td></tr>
                    <tr><th>屋号またはサービス名</th>
                        <td>upMOST　ウェッブ・サービス</td></tr>
                </tbody>
            </table>
            <p>本サイトは、upMOSTオーダー靴製造システムを利用する百貨店・販売店・メーカーの受発注をサポートしています。
            「特定商取引に基づく表示」の規定については、(株)大島商事から直接ご購入する場合に限ります。
            </p>
        </div>
    );
}
