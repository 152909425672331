import React, { useState, useReducer, useEffect, useRef } from 'react';

import * as Util from 'components/Util';
import * as API from 'components/API';
import TypeNumber from 'components/TypeNumber';


const INITIAL_LOCAL_STATE = {
    RightLast: null,
    RightBall: null,
    RightWaist: null,
    RightLength: null,
    RightWidth: null,
    RightInstep: null,
    RightAdjustDiffWidth: null,
    RightAdjustDiffInstep: null,
    LeftLast: null,
    LeftBall: null,
    LeftWaist: null,
    LeftLength: null,
    LeftWidth: null,
    LeftInstep: null,
    LeftAdjustDiffWidth: null,
    LeftAdjustDiffInstep: null,
}

function AdjustNumberSelect(props) {
    // モバイルデバイスはキーボードが無いので入力しづらいことからselectボックスで代替
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
        return (
            <select {...props}>
            {
            (()=>{
                let options = [];
                let value = -20;
                for (let i = 0; value < 22; i++) {
                    options.push(
                        <option key={'adj'+i} value={value}>{value} mm</option>
                    );
                    value += 2;
                }
                return options;
            })()
            }
            </select>
        );
    } else {
        return (
            <div className="TypeNumberWithUnit">
                <TypeNumber {...props} />
                <span className="unit">{props.unit}</span>
            </div>
        );
    }
}
export default function OrderSimulateFrame(props) {

    const state = props.state;
    // const handleChange = props.handleChange;
    const setFormState = props.setFormState;
    // const readonly = props.readonly || false;

    const localReducer = (state, event) => {
        let newfields = {...state};
        newfields = Object.assign(newfields, event);
        return newfields;
    }

    const [sizeMaster, setSizeMaster] = useState([]);
    const [localState, setLocalState] = useReducer(localReducer, {...INITIAL_LOCAL_STATE});

    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;

        // サイズマスタの情報を取得しておく
        API.get('/master/size/list?filterColumn[]=SizeCategory&filterValue[]='+state.fields.OrderCategory1+'&filterFormula[]==&limit=999')
        .then(result => {
            console.log('get sizemaster', result);

            if (mounted.current) {
                setSizeMaster(result.Result.Data);
                // サイズマスタが揃ってから
                setLocalState({
                    RightLast: state.fields.RightJudgeLast,
                    RightBall: (`00${state.fields.RightJudgeBall}`.slice(-2)),   // 0埋め2桁
                    RightWaist: (`00${state.fields.RightJudgeWaist}`.slice(-2)), // 0埋め2桁
                    RightLength: state.fields.RightJudgeLength,
                    RightWidth: state.fields.RightJudgeWidth,
                    RightInstep: state.fields.RightJudgeInstep,
                    LeftLast: state.fields.LeftJudgeLast,
                    LeftBall: (`00${state.fields.LeftJudgeBall}`.slice(-2)),   // 0埋め2桁
                    LeftWaist: (`00${state.fields.LeftJudgeWaist}`.slice(-2)), // 0埋め2桁
                    LeftLength: state.fields.LeftJudgeLength,
                    LeftWidth: state.fields.LeftJudgeWidth,
                    LeftInstep: state.fields.LeftJudgeInstep,
                });
                // 判定結果の補正
                setFormState({
                    fields: {
                        RightAdjustWidth: 0,
                        RightAdjustInstep: 0,
                        LeftAdjustWidth: 0,
                        LeftAdjustInstep: 0,
                    }
                });
            }
        });

        return () => {
            mounted.current = false;
        }
    }, [
        state.fields.OrderCategory1,
        state.fields.RightJudgeLast,
        state.fields.RightJudgeBall,
        state.fields.RightJudgeWaist,
        state.fields.RightJudgeLength,
        state.fields.RightJudgeWidth,
        state.fields.RightJudgeInstep,
        state.fields.LeftJudgeLast,
        state.fields.LeftJudgeBall,
        state.fields.LeftJudgeWaist,
        state.fields.LeftJudgeLength,
        state.fields.LeftJudgeWidth,
        state.fields.LeftJudgeInstep,
        setFormState,
    ]);


    const getSizeMasterByLast = (last) => {
        let res = sizeMaster.filter(row => row.SizeLast === last);
        return res ? res[0] : null;
    }

    const handleRightLastChange = (e) => {
        let selectedSize = getSizeMasterByLast(e.target.value);
        if (selectedSize) {
            // WEB補正と自動補正をプラスではなくて、、、
            // ラストを揃えることでずれた分を補正値に加えてつじつまを合わせる

            // 試算時の足囲と変更したラストの足囲みの差を求める
            let diffRightWidth = Util.tryParseFloat(Util.tryParseFloat(state.fields.RightJudgeWidth) - Util.tryParseFloat(selectedSize.FootWidth));
            let diffRightInstep = Util.tryParseFloat(Util.tryParseFloat(state.fields.RightJudgeInstep) - Util.tryParseFloat(selectedSize.SizeInstep));

            // excelの「ROUNDUP(G42/2,0)」をしたい
            // 2で割った後、負数の場合は絶対値に対して切り上げてからマイナス記号を付ける
            diffRightWidth = (diffRightWidth < 0)
                ? Math.ceil(Math.abs(Util.devide(diffRightWidth, 2))) * -1
                : Math.ceil(Math.abs(Util.devide(diffRightWidth, 2)));

            // 2で割った後、負数の場合は絶対値に対して切り上げてからマイナス記号を付ける
            diffRightInstep = (diffRightInstep < 0)
                ? Math.ceil(Math.abs(Util.devide(diffRightInstep, 2))) * -1
                : Math.ceil(Math.abs(Util.devide(diffRightInstep, 2)));

            setLocalState({
                RightLast: selectedSize.SizeLast,
                RightBall: `00${diffRightWidth}`.slice(-2),
                RightWaist: `00${diffRightInstep}`.slice(-2),
                RightLength: Util.tryParseFloat(selectedSize.FootLength),
                RightWidth: Util.tryParseFloat(selectedSize.FootWidth) + (diffRightWidth * 2),
                RightInstep: Util.tryParseFloat(selectedSize.SizeInstep) + (diffRightInstep * 2),
            });
        }
    }

    const handleLeftLastChange = (e) => {
        let selectedSize = getSizeMasterByLast(e.target.value);
        if (selectedSize) {
            // WEB補正と自動補正をプラスではなくて、、、
            // ラストを揃えることでずれた分を補正値に加えてつじつまを合わせる

            // 試算時の足囲と変更したラストの足囲みの差を求める
            let diffLeftWidth = Math.ceil(Util.tryParseFloat(state.fields.LeftJudgeWidth) - Util.tryParseFloat(selectedSize.FootWidth));
            let diffLeftInstep = Math.ceil(Util.tryParseFloat(state.fields.LeftJudgeInstep) - Util.tryParseFloat(selectedSize.SizeInstep));

            // excelの「ROUNDUP(G42/2,0)」をしたい
            // 2で割った後、負数の場合は絶対値に対して切り上げてからマイナス記号を付ける
            diffLeftWidth = (diffLeftWidth < 0)
                ? Math.ceil(Math.abs(Util.devide(diffLeftWidth, 2))) * -1
                : Math.ceil(Math.abs(Util.devide(diffLeftWidth, 2)));

            // 2で割った後、負数の場合は絶対値に対して切り上げてからマイナス記号を付ける
            diffLeftInstep = (diffLeftInstep < 0)
                ? Math.ceil(Math.abs(Util.devide(diffLeftInstep, 2))) * -1
                : Math.ceil(Math.abs(Util.devide(diffLeftInstep, 2)));

            setLocalState({
                LeftLast: selectedSize.SizeLast,
                LeftBall: `00${diffLeftWidth}`.slice(-2),
                LeftWaist: `00${diffLeftInstep}`.slice(-2),
                LeftLength: Util.tryParseFloat(selectedSize.FootLength),
                LeftWidth: Util.tryParseFloat(selectedSize.FootWidth) + (diffLeftWidth * 2),
                LeftInstep: Util.tryParseFloat(selectedSize.SizeInstep) + (diffLeftInstep * 2),
            });
        }
    }

    // 判定結果の補正の値を変更したとき
    const handleAdjustChange = (e) => {
        // console.log('handleAdjustChange', e.target.name, e.target.value);
        setFormState({
            fields: {
                [e.target.name]: e.target.value,
            }
        });
    }

    // 判定結果の補正(0.0の差)の計算
    useEffect(() => {
        console.log('判定結果の補正(0.0の差)の計算');
        setLocalState({
            RightAdjustDiffWidth: Util.tryParseFloat(state.fields?.RightDiffWidth) + Util.tryParseFloat(state.fields?.RightAdjustWidth),
            RightAdjustDiffInstep: Util.tryParseFloat(state.fields?.RightDiffInstep) + Util.tryParseFloat(state.fields?.RightAdjustInstep),
            LeftAdjustDiffWidth: Util.tryParseFloat(state.fields?.LeftDiffWidth) + Util.tryParseFloat(state.fields?.LeftAdjustWidth),
            LeftAdjustDiffInstep: Util.tryParseFloat(state.fields?.LeftDiffInstep) + Util.tryParseFloat(state.fields?.LeftAdjustInstep),
        });
    }, [
        state.fields.RightDiffWidth,
        state.fields.RightDiffInstep,
        state.fields.RightAdjustWidth,
        state.fields.RightAdjustInstep,
        state.fields.LeftDiffWidth,
        state.fields.LeftDiffInstep,
        state.fields.LeftAdjustWidth,
        state.fields.LeftAdjustInstep,
        setLocalState,
    ]);

    // ラストデータの完成
    useEffect(() => {
        setFormState({
            fields: {
                RightCompleteLast: localState.RightLast,
                RightCompleteBall: `00${Util.tryParseFloat(localState.RightBall) + Util.devide(state.fields?.RightAdjustWidth, 2)}`.slice(-2),
                RightCompleteWaist: `00${Util.tryParseFloat(localState.RightWaist) + Util.devide(state.fields?.RightAdjustInstep, 2)}`.slice(-2),
                RightCompleteLength: Util.tryParseFloat(localState.RightLength),
                RightCompleteWidth: Util.tryParseFloat(localState.RightWidth) + Util.tryParseFloat(state.fields?.RightAdjustWidth),
                RightCompleteInstep: Util.tryParseFloat(localState.RightInstep) + Util.tryParseFloat(state.fields?.RightAdjustInstep),

                LeftCompleteLast: localState.LeftLast,
                LeftCompleteBall: `00${Util.tryParseFloat(localState.LeftBall) + Util.devide(state.fields?.LeftAdjustWidth, 2)}`.slice(-2),
                LeftCompleteWaist: `00${Util.tryParseFloat(localState.LeftWaist) + Util.devide(state.fields?.LeftAdjustInstep, 2)}`.slice(-2),
                LeftCompleteLength: Util.tryParseFloat(localState.LeftLength),
                LeftCompleteWidth: Util.tryParseFloat(localState.LeftWidth) + Util.tryParseFloat(state.fields?.LeftAdjustWidth),
                LeftCompleteInstep: Util.tryParseFloat(localState.LeftInstep) + Util.tryParseFloat(state.fields?.LeftAdjustInstep),
            }
        });
    }, [
        localState.RightLast,
        localState.RightBall,
        localState.RightWaist,
        localState.RightLength,
        localState.RightWidth,
        localState.RightInstep,
        localState.RightAdjustDiffWidth,
        localState.RightAdjustDiffInstep,
        localState.LeftLast,
        localState.LeftBall,
        localState.LeftWaist,
        localState.LeftLength,
        localState.LeftWidth,
        localState.LeftInstep,
        localState.LeftAdjustDiffWidth,
        localState.LeftAdjustDiffInstep,
        state.fields.RightAdjustWidth,
        state.fields.RightAdjustInstep,
        state.fields.LeftAdjustWidth,
        state.fields.LeftAdjustInstep,
        setFormState,
    ]);

    let cannotProduction = false;
    if (Util.tryParseFloat(state.fields.RightCompleteBall) < 0
        || Util.tryParseFloat(state.fields.RightCompleteWaist) < 0
        || Util.tryParseFloat(state.fields.LeftCompleteBall) < 0
        || Util.tryParseFloat(state.fields.LeftCompleteWaist) < 0) {
            cannotProduction = true;
    }

    return (
        <div id="SimulateFrame">

            <h2>ウィズの確定</h2>
            <p>
                補正結果から判断し、ラストを太く(上げる)するか、細く(下げる)するかを決定します。
                左右のウィズを揃えることが“コツ”です。
                また、足長を大きくする場合は、ウィズを細くし、周囲長をほぼ同じにします。
                <span style={{whiteSpace:'nowrap'}}>例えば、350 ( 235㎜ Dウィズ ) ≒ 40c ( 240㎜ Cウィズ )</span>
            </p>
            <div id="DecisionWidth">
                <div className="rightFoot">
                    <div className="flexrow">
                        <div className="last1">
                            <h5>ラスト右</h5>
                            <select onChange={handleRightLastChange} value={localState.RightLast || ''}>
                            {sizeMaster?.map(row => (
                                <option key={'rightLast'+row.Id} value={row.SizeLast} data-id={row.Id}>{row.SizeLast}</option>
                            ))}
                            </select>
                        </div>
                        <div className="last2">
                            <h5>B/W</h5>
                            <div className="inner">{localState.RightBall || '00'}/{localState.RightWaist || '00'}</div>
                        </div>
                    </div>
                    <div className="flexrow">
                        <div className="length">
                            <h5>足長</h5>
                            <div className="inner">{Util.tryParseFloat(localState.RightLength).toFixed(1)}</div>
                        </div>
                        <div className="width">
                            <h5>足囲</h5>
                            <div className="inner">{Util.tryParseFloat(localState.RightWidth).toFixed(1)}</div>
                        </div>
                        <div className="instep">
                            <h5>甲まわり</h5>
                            <div className="inner">{Util.tryParseFloat(localState.RightInstep).toFixed(1)}</div>
                        </div>
                    </div>
                </div>
                <div className="leftFoot">
                    <div className="flexrow">
                        <div className="last1">
                            <h5>ラスト左</h5>
                            <select onChange={handleLeftLastChange} value={localState.LeftLast || ''}>
                            {sizeMaster?.map(row => (
                                <option key={'leftLast'+row.Id} value={row.SizeLast}>{row.SizeLast}</option>
                            ))}
                            </select>
                        </div>
                        <div className="last2">
                            <h5>B/W</h5>
                            <div className="inner">{localState.LeftBall || '0'}/{localState.LeftWaist || '0'}</div>
                        </div>
                    </div>
                    <div className="flexrow">
                        <div className="length">
                            <h5>足長</h5>
                            <div className="inner">{Util.tryParseFloat(localState.LeftLength).toFixed(1)}</div>
                        </div>
                        <div className="width">
                            <h5>足囲</h5>
                            <div className="inner">{Util.tryParseFloat(localState.LeftWidth).toFixed(1)}</div>
                        </div>
                        <div className="instep">
                            <h5>甲まわり</h5>
                            <div className="inner">{Util.tryParseFloat(localState.LeftInstep).toFixed(1)}</div>
                        </div>
                    </div>
                </div>

            </div>

            <h2>判定結果の補正</h2>
            <div style={{display:'flex',flexWrap:'wrap',margin:'.5rem 0'}}>
                <p style={{margin:'0'}}>左右の「足の判定」結果を調整したい場合に入力します。理想は左右同じ”すき間”です。</p>
                <div className="MessageTip info" style={{margin:'0'}}>プラス・マイナスの記号をつけて、2の倍数で入力して下さい！</div>
            </div>
            <div id="FinalAdjust">
                <div className="flexrow" style={{gap:'1rem'}}>
                    <div className="rightFoot">
                        <div className="row">
                            <div className="title">足囲とラスト右の差</div>
                            <div className="main">
                                <div className="judgeValue">{Util.tryParseFloat(state.fields?.RightDiffWidth).toFixed(1)}</div>
                                ≫
                                <AdjustNumberSelect unit="mm" step="2" name="RightAdjustWidth"
                                    value={state.fields?.RightAdjustWidth || '0'}
                                    onChange={handleAdjustChange}
                                />
                                <div className="finalDiff">{Util.tryParseFloat(localState.RightAdjustDiffWidth).toFixed(1)}の差</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">甲まわりとラスト右の差</div>
                            <div className="main">
                                <div className="judgeValue">{Util.tryParseFloat(state.fields?.RightDiffInstep).toFixed(1)}</div>
                                ≫
                                <AdjustNumberSelect unit="mm" step="2" name="RightAdjustInstep"
                                    value={state.fields?.RightAdjustInstep || '0'}
                                    onChange={handleAdjustChange}
                                />
                                <div className="finalDiff">{Util.tryParseFloat(localState.RightAdjustDiffInstep).toFixed(1)}の差</div>
                            </div>
                        </div>
                    </div>
                    <div className="leftFoot">
                        <div className="row">
                            <div className="title">足囲とラスト左の差</div>
                            <div className="main">
                                <div className="judgeValue">{Util.tryParseFloat(state.fields?.LeftDiffWidth).toFixed(1)}</div>
                                ≫
                                <AdjustNumberSelect unit="mm" step="2" name="LeftAdjustWidth"
                                    value={state.fields?.LeftAdjustWidth || '0'}
                                    onChange={handleAdjustChange}
                                />
                                <div className="finalDiff">{Util.tryParseFloat(localState.LeftAdjustDiffWidth).toFixed(1)}の差</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">甲まわりとラスト左の差</div>
                            <div className="main">
                                <div className="judgeValue">{Util.tryParseFloat(state.fields?.LeftDiffInstep).toFixed(1)}</div>
                                ≫
                                <AdjustNumberSelect unit="mm" step="2" name="LeftAdjustInstep"
                                    value={state.fields?.LeftAdjustInstep || '0'}
                                    onChange={handleAdjustChange}
                                />
                                <div className="finalDiff">{Util.tryParseFloat(localState.LeftAdjustDiffInstep).toFixed(1)}の差</div>
                            </div>
                        </div>
                    </div>
                </div>
                {(cannotProduction) && (
                    <div className="Message error" style={{marginBottom:'0'}}>ラストが大きいため生産できません。</div>
                )}
            </div>

            <h2>ラスト・データの完成</h2>
            <div id="CompleteLast">
                <div className="rightFoot">
                    <div className="result">
                        <div className="item">
                            <div className="title">ラスト-B/W</div>
                            <div className="fs1250">{state.fields?.RightCompleteLast || ''}-{state.fields?.RightCompleteBall || '0'}/{state.fields?.RightCompleteWaist || '0'}</div>
                        </div>
                        <div className="item">
                            <div className="title">足長</div>
                            <div className="fs1250">{Util.tryParseFloat(state.fields?.RightCompleteLength).toFixed(1)}</div>
                        </div>
                        <div className="item">
                            <div className="title">足囲</div>
                            <div className="fs1250">{Util.tryParseFloat(state.fields?.RightCompleteWidth).toFixed(1)}</div>
                        </div>
                        <div className="item">
                            <div className="title">甲まわり</div>
                            <div className="fs1250">{Util.tryParseFloat(state.fields?.RightCompleteInstep).toFixed(1)}</div>
                        </div>
                    </div>
                </div>
                <div className="leftFoot">
                    <div className="result">
                        <div className="item">
                            <div className="title">ラスト-B/W</div>
                            <div className="fs1250">{state.fields?.LeftCompleteLast || ''}-{state.fields?.LeftCompleteBall || '0'}/{state.fields?.LeftCompleteWaist || '0'}</div>
                        </div>
                        <div className="item">
                            <div className="title">足長</div>
                            <div className="fs1250">{Util.tryParseFloat(state.fields?.LeftCompleteLength).toFixed(1)}</div>
                        </div>
                        <div className="item">
                            <div className="title">足囲</div>
                            <div className="fs1250">{Util.tryParseFloat(state.fields?.LeftCompleteWidth).toFixed(1)}</div>
                        </div>
                        <div className="item">
                            <div className="title">甲まわり</div>
                            <div className="fs1250">{Util.tryParseFloat(state.fields?.LeftCompleteInstep).toFixed(1)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
