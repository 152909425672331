import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthUser } from 'components/Authenticator';

import 'css/OrderInput.css';

import * as API from 'components/API';
import * as Util from 'components/Util';
import OrderCustomerFrame from './OrderCustomerFrame';
import OrderFittingFrame from './OrderFittingFrame';
import Calendar from 'components/Calendar';
import MessageDialog from 'components/Dialog/MessageDialog';
import * as PreEstimate from './PreEstimate';


export default function OrderInputStep3(props) {

    const state = props.state;
    const setFormState = props.setFormState;
    const setErrors = props.setErrors;
    const setStep = props.setStep;

    const history = useHistory();
    const session = useAuthUser();

    useEffect(() => {
        // 初期値セット
        setFormState({
            fields: {
                FittingDate: Calendar.now().format('Y-m-d'),
                FittingTime: Calendar.now().format('H:i:00')
            }
        });
    }, [setFormState]);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        // 足型測定情報
        if (!fields.UserRightLength) {
            errors.UserRightLength = { Message: '足長(右)は必須入力です。' };
        }
        if (!fields.UserRightMaxWidth) {
            errors.UserRightMaxWidth = { Message: '最大足囲(右)は必須入力です。' };
        }
        if (!fields.UserRightMinWidth) {
            errors.UserRightMinWidth = { Message: '最小足囲(右)は必須入力です。' };
        }
        if (!fields.UserRightMaxInstep) {
            errors.UserRightMaxInstep = { Message: '最大甲まわり(右)は必須入力です。' };
        }
        if (!fields.UserRightMinInstep) {
            errors.UserRightMinInstep = { Message: '最小甲まわり(右)は必須入力です。' };
        }
        if (!fields.RightFootType) {
            errors.RightFootType = { Message: '足型(右)は必須入力です。' };
        }

        if (!fields.UserLeftLength) {
            errors.UserLeftLength = { Message: '足長(左)は必須入力です。' };
        }
        if (!fields.UserLeftMaxWidth) {
            errors.UserLeftMaxWidth = { Message: '最大足囲(左)は必須入力です。' };
        }
        if (!fields.UserLeftMinWidth) {
            errors.UserLeftMinWidth = { Message: '最小足囲(左)は必須入力です。' };
        }
        if (!fields.UserLeftMaxInstep) {
            errors.UserLeftMaxInstep = { Message: '最大甲まわり(左)は必須入力です。' };
        }
        if (!fields.UserLeftMinInstep) {
            errors.UserLeftMinInstep = { Message: '最小甲まわり(左)は必須入力です。' };
        }
        if (!fields.LeftFootType) {
            errors.LeftFootType = { Message: '足型(左)は必須入力です。' };
        }

        if (!fields.FootSize) {
            errors.FootSize = { Message: '申告サイズは必須入力です。' };
        } else {
            // 5mmピッチ
            let a = parseInt(fields.FootSize);
            if (a % 5 !== 0) {
                errors.FootSize = { Message: '申告サイズは5mmピッチで入力してください。' };
            }
        }
        if (!fields.FootWidth) {
            errors.FootWidth = { Message: '足幅は必須入力です。' };
        }
        if (!fields.FootLonger) {
            errors.FootLonger = { Message: '左右の足長比較は必須入力です。' };
        }
        if (!fields.FootFavorite) {
            errors.FootFavorite = { Message: 'サイズの好みは必須入力です。' };
        }
        if (!fields.SocksType) {
            errors.SocksType = { Message: 'ソックスは必須入力です。' };
        }

        if (!fields.FittingDate && !fields.FittingTime) {
            errors.FittingDate = { Message: '測定日時は必須入力です。' };
        }
        if (!fields.FittingUser) {
            errors.FittingUser = { Message: '測定者は必須入力です。' };
        }

        // B>=Wの大小差をチェック
        let rightDiffWidth = (fields.UserRightMaxWidth - fields.UserRightMinWidth);    // 青色の線
        if (rightDiffWidth > 15) {
            errors.RightFootError = { Message: '補正不可です。足囲(最大)と足囲(最小)の差を15mm以下にしてください。' };
        }
        // B>=Wの大小差をチェック
        let leftDiffWidth = (fields.UserLeftMaxWidth - fields.UserLeftMinWidth);    // 青色の線
        if (leftDiffWidth > 15) {
            errors.LeftFootError = { Message: '補正不可です。足囲(最大)と足囲(最小)の差を15mm以下にしてください。' };
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    const handleStepSubmit = () => {
        setFormState({ loading: true });

        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation() > 0) {
            // console.log(state.errors);
            setFormState({ loading: false });
            return;
        }
        console.log('client validation ok!');

        const simulateResult = PreEstimate.simulate({
            Right: {
                Length: state.fields.UserRightLength,
                MaxWidth: state.fields.UserRightMaxWidth,
                MinWidth: state.fields.UserRightMinWidth,
                MaxInstep: state.fields.UserRightMaxInstep,
                MinInstep: state.fields.UserRightMinInstep,
                DeformDegree: state.fields.RightDeformDegree || 0,
                InnerDeformDegree: state.fields.RightInnerDeformDegree || 0,
            },
            Left: {
                Length: state.fields.UserLeftLength,
                MaxWidth: state.fields.UserLeftMaxWidth,
                MinWidth: state.fields.UserLeftMinWidth,
                MaxInstep: state.fields.UserLeftMaxInstep,
                MinInstep: state.fields.UserLeftMinInstep,
                DeformDegree: state.fields.LeftDeformDegree || 0,
                InnerDeformDegree: state.fields.LeftInnerDeformDegree || 0,
            },
        });
        console.log('simulateResult >>>', simulateResult);

        // 補正不可かどうかチェック
        // B大を確認する
        if (simulateResult.RightMaxWidth === '補正不可' || simulateResult.LeftMaxWidth === '補正不可') {
            alert('補正不可です');
            setFormState({ loading: false });
            return;
        }

        // むむ、警告があるなら表示
        if (simulateResult.Warning) {
            alert(simulateResult.Warning);
        }

        console.log('state.fields >>>', state.fields);

        // サーバーに計算してもらう
        API.postAsFormData('/estimate', {...state.fields, ...simulateResult})
        .then(result => {
            console.log(result);
            if (result.Error) {
                // アラートメッセージをズンする
                MessageDialog.Error(result.Error.Title, result.Error.Message);
            } else {
                // OK
                // EstimateResultModelに補助シートの自動補正分を足し込む
                const model = result.Result;
                console.log('EstimateResultModel', model);

                model.RightJudgeBall = Util.tryParseFloat(model.RightJudgeBall) + Util.devide(simulateResult.RightAutoMinWidth, 2);
                model.RightJudgeWaist = Util.tryParseFloat(model.RightJudgeWaist) + Util.devide(simulateResult.RightAutoMinInstep, 2);
                model.RightJudgeLength = Util.tryParseFloat(model.RightJudgeLength);
                model.RightJudgeWidth = Util.tryParseFloat(model.RightJudgeWidth) + (simulateResult.RightAutoMinWidth || 0);
                model.RightJudgeInstep = Util.tryParseFloat(model.RightJudgeInstep) + (simulateResult.RightAutoMinInstep || 0);

                model.LeftJudgeBall = Util.tryParseFloat(model.LeftJudgeBall) + Util.devide(simulateResult.LeftAutoMinWidth, 2);
                model.LeftJudgeWaist = Util.tryParseFloat(model.LeftJudgeWaist) + Util.devide(simulateResult.LeftAutoMinInstep, 2);
                model.LeftJudgeLength = Util.tryParseFloat(model.LeftJudgeLength);
                model.LeftJudgeWidth = Util.tryParseFloat(model.LeftJudgeWidth) + (simulateResult.LeftAutoMinWidth || 0);
                model.LeftJudgeInstep = Util.tryParseFloat(model.LeftJudgeInstep) + (simulateResult.LeftAutoMinInstep || 0);

                setFormState({
                    fields: {...model}
                });

                // OKなら次のステップへ
                setStep(4);
            }
        })
        .finally(()=>{
            setFormState({ loading: false });
        });
    }

    const handleClearClick = () => {
        let newFields = {};

        // 足型測定情報
        newFields['UserRightLength'] = 0; // 足長(右)
        newFields['UserRightMaxWidth'] = 0; // 最大足囲(右)
        newFields['UserRightMinWidth'] = 0; // 最小足囲(右)
        newFields['UserRightMaxInstep'] = 0; // 最大甲まわり(右)
        newFields['UserRightMinInstep'] = 0; // 最小甲まわり(右)
        newFields['RightFootType'] = ''; // 足型(右)
        newFields['RightFootDisease'] = []; // 足の疾病(右)
        newFields['RightDeformDegree'] = 0; // 外反母趾角(右)
        newFields['RightInnerDeformDegree'] = 0; // 内反小趾角(右)

        newFields['UserLeftLength'] = 0; // 足長(左)
        newFields['UserLeftMaxWidth'] = 0; // 最大足囲(左)
        newFields['UserLeftMinWidth'] = 0; // 最小足囲(左)
        newFields['UserLeftMaxInstep'] = 0; // 最大甲まわり(左)
        newFields['UserLeftMinInstep'] = 0; // 最小甲まわり(左)
        newFields['LeftFootDisease'] = []; // 足の疾病
        newFields['LeftDeformDegree'] = 0; // 外反母趾角(右)
        newFields['LeftInnerDeformDegree'] = 0; // 内反小趾角(右)

        // 計算して求めたやつもクリア
        newFields['RightLength'] = 0; // 足長(右)
        newFields['RightMaxWidth'] = 0; // 最大足囲(右)
        newFields['RightMinWidth'] = 0; // 最小足囲(右)
        newFields['RightMaxInstep'] = 0; // 最大甲まわり(右)
        newFields['RightMinInstep'] = 0; // 最小甲まわり(右)

        newFields['LeftLength'] = 0; // 足長(左)
        newFields['LeftMaxWidth'] = 0; // 最大足囲(左)
        newFields['LeftMinWidth'] = 0; // 最小足囲(左)
        newFields['LeftMaxInstep'] = 0; // 最大甲まわり(左)
        newFields['LeftMinInstep'] = 0; // 最小甲まわり(左)

        newFields['FootSize'] = ''; // 申告サイズ
        newFields['FootWidth'] = ''; // 足幅
        newFields['FootFavorite'] = ''; // サイズの好み
        newFields['FootLonger'] = ''; // 左右の足長比較
        newFields['SocksType'] = ''; // ソックス
        newFields['HeelHeight'] = '90'; // ヒール高

        newFields['FittingDate'] = Calendar.now().format('Y-m-d'); // 測定日時
        newFields['FittingTime'] = Calendar.now().format('H:i:00'); // 測定日時
        newFields['FittingUser'] = session.authUser?.UserName || ''; // 測定者はログインユーザー名

        setFormState({
            fields: newFields
        });

    }

    // メンズ用サンプルデータ
    const createMENSSampleData = () => {
        let data = {};

        data['UserRightLength'] = 237; // 足長(右)
        data['UserRightMaxWidth'] = 238; // 最大足囲(右)
        data['UserRightMinWidth'] = 235; // 最小足囲(右)
        data['UserRightMaxInstep'] = 237; // 最大甲まわり(右)
        data['UserRightMinInstep'] = 233; // 最小甲まわり(右)
        data['RightFootType'] = 'EGYPT'; // 足型(右)
        data['RightFootDisease'] = []; // 足の疾病(右)
        data['RightDeformDegree'] = 0; // 外反母趾角(右)
        data['RightInnerDeformDegree'] = 0; // 内反小趾角(右)

        data['UserLeftLength'] = 235; // 足長(左)
        data['UserLeftMaxWidth'] = 237; // 最大足囲(左)
        data['UserLeftMinWidth'] = 234; // 最小足囲(左)
        data['UserLeftMaxInstep'] = 237; // 最大甲まわり(左)
        data['UserLeftMinInstep'] = 233; // 最小甲まわり(左)
        data['LeftFootType'] = 'EGYPT'; // 足型(左)
        data['LeftFootDisease'] = []; // 足の疾病
        data['LeftDeformDegree'] = 0; // 外反母趾角(右)
        data['LeftInnerDeformDegree'] = 0; // 内反小趾角(右)

        data['FootSize'] = 240; // 申告サイズ
        data['FootWidth'] = 'EE'; // 足幅
        data['FootFavorite'] = 'JUST'; // サイズの好み
        data['FootLonger'] = 'SAME'; // 左右の足長比較
        data['SocksType'] = 'THIN'; // ソックス

        return data;
    }

    // レディス用サンプルデータ
    const createLADIESSampleData = () => {
        let data = {};

        data['UserRightLength'] = 239; // 足長(右)
        data['UserRightMaxWidth'] = 235; // 最大足囲(右)
        data['UserRightMinWidth'] = 227; // 最小足囲(右)
        data['UserRightMaxInstep'] = 222; // 最大甲まわり(右)
        data['UserRightMinInstep'] = 217; // 最小甲まわり(右)
        data['RightFootType'] = 'EGYPT'; // 足型(右)
        data['RightFootDisease'] = ['DEFORM']; // 足の疾病(右)
        data['RightDeformDegree'] = 40; // 外反母趾角(右)
        data['RightInnerDeformDegree'] = 0; // 内反小趾角(右)

        data['UserLeftLength'] = 236; // 足長(左)
        data['UserLeftMaxWidth'] = 230; // 最大足囲(左)
        data['UserLeftMinWidth'] = 222; // 最小足囲(左)
        data['UserLeftMaxInstep'] = 218; // 最大甲まわり(左)
        data['UserLeftMinInstep'] = 215; // 最小甲まわり(左)
        data['LeftFootType'] = 'EGYPT'; // 足型(左)
        data['LeftFootDisease'] = []; // 足の疾病
        data['LeftDeformDegree'] = 22; // 外反母趾角(右)
        data['LeftInnerDeformDegree'] = 0; // 内反小趾角(右)

        data['FootSize'] = 240; // 申告サイズ
        data['FootWidth'] = 'E'; // 足幅
        data['FootFavorite'] = 'JUST'; // サイズの好み
        data['FootLonger'] = 'SAME'; // 左右の足長比較
        data['SocksType'] = 'TIGHTS'; // ソックス
        data['HeelHeight'] = '90'; // ヒール高

        return data;
    }

    const handleSampleClick = () => {
        let newFields = {};

        if (state.fields.OrderCategory1 === 'MENS') {
            newFields = createMENSSampleData();
        }
        else if (state.fields.OrderCategory1 === 'LADIES') {
            newFields = createLADIESSampleData();
        }
        else {
            newFields = createMENSSampleData();
        }

        newFields['FittingDate'] = Calendar.now().format('Y-m-d'); // 測定日時
        newFields['FittingTime'] = Calendar.now().format('H:i:00'); // 測定日時
        newFields['FittingUser'] = session.authUser?.UserName; // 測定者はログインユーザー名

        setFormState({
            fields: newFields
        });
    }

    const handleCancelClick = () => {
        if (window.confirm('発注データの作成をキャンセルしますか？\n入力中のデータは保存されません。')) {
            history.push('/business/order');
        }
    }
    const handleBackStepClick = () => {
        setStep(2);
    }

    return (
        <>
            <div id="OrderSheet">
                <h1>
                    <span className="OrderTitle">{state.fields.OrderCategory1} ORDER SHEET</span>
                </h1>
                <div id="OrderSheetLayout">
                    <OrderCustomerFrame {...props} readonly={true} />
                    <OrderFittingFrame {...props} />
                </div>
            </div>
            <div className="ButtonGroup">
                <button type="button" className="LinkButton" onClick={handleCancelClick}>キャンセル</button>
                <button type="button" className="LinkButton" onClick={handleBackStepClick}>前のステップへ</button>
                <button type="button" onClick={handleSampleClick}>サンプルデータ</button>
                <div className="grow1"></div>
                <button type="button" className="LinkButton" onClick={handleClearClick}>クリア</button>
                <button type="button" className="PrimaryButton" onClick={handleStepSubmit}>
                    <span>試算して次のステップへ</span>
                </button>
            </div>
        </>

    );
}