import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import 'css/TopPage.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slide1Image from 'image/gahag-0034880009-1.jpg';
import Slide2Image from 'image/gahag-0009192034-1.jpg';
import Slide3Image from 'image/publicdomainq-0033353nvn.jpg';
import Slide4Image from 'image/gahag-0014401275-1.jpg';
// import Slide5Image from 'image/gahag-0034880009-1.jpg';
// import Slide6Image from 'image/gahag-0034880009-1.jpg';

export default function TopPage() {

    useEffect(() => {
        document.title = `トップページ - UPMOST -`;
    }, []);

    const sliderSetting = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        pauseOnDotsHover: false,

    }
    return (
        <div id="TopPage">
            <Slider {...sliderSetting}>
                <div id="Slide1Cut" className="scene">
                    <h2>FITTING</h2>
                    <h3>画期的な採寸方法で足にフィット</h3>
                    <p>世界初の足型測定(特許取得済み)によるミリ単位のフィッティングを実現。</p>
                    <img alt="画像1" src={Slide1Image} />
                </div>
                <div id="Slide2Cut" className="scene">
                    <h2>COMFORTABLE</h2>
                    <h3>障害を越えた履き心地</h3>
                    <p>外反母趾や陥入爪、ハンマートウなど特徴に合わせた靴作りを最短でお届け。</p>
                    <img alt="画像2" src={Slide2Image} />
                </div>
                <div id="Slide3Cut" className="scene">
                    <h2>STYLISH</h2>
                    <h3>履きたい靴をかっこよく</h3>
                    <p>足元をスタイリッシュにキメたら心も弾む。</p>
                    <img alt="画像3" src={Slide3Image} />
                </div>
                <div id="Slide4Cut" className="scene">
                    <h2>FUNCTIONAL</h2>
                    <h3>長時間歩いても疲れない</h3>
                    <p>気持ちの良い使用感は時間を忘れるほどに。</p>
                    <img alt="画像4" src={Slide4Image} />
                </div>
            </Slider>
            <div id="CenterContents">
                <h1>NO SHOES, NO LIFE</h1>
                <Link to="/business/main" className="loginLink">
                    <div className="en">Login</div>
                    <div className="jp">メンバーズサイト</div>
                </Link>
            </div>
        </div>
    );
}
