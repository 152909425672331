
export default class Calendar {

    constructor(arg){
        try {
            if (arg) {
                if (typeof arg === 'string') {
                    arg = arg.trim();
                    arg = arg.replace(/\//g, '-'); // スラッシュをハイフンに
                    arg = arg.replace(/ /g, 'T'); // スペースをT区切りに
                    if (arg.length > 10) {
                        arg = arg + '+09:00'; // iOSの場合にタイムゾーンがUTCになってしまうため+9時間する
                    }
                }
                this.d = new Date(arg);
                if (Number.isNaN(this.d.getTime())) {
                    console.log('Invalid Date! ', arg);
                    this.d = new Date();
                }
            } else {
                this.d = new Date();
            }
        } catch (error) {
            // fallback today
            this.d = new Date();
        }
    }

    static now() {
        return new Calendar();
    }

    // Dateオブジェクトにnum値を加算、num値がマイナスの場合は減算
    modify(num, interval) {
        let cal = new Calendar(this.d);
        switch (interval) {
            case 'Y':
                cal.d.setFullYear(cal.d.getFullYear() + num);
                break;
            case 'M':
                cal.d.setMonth(cal.d.getMonth() + num);
                break;
            case 'D':
                cal.d.setDate(cal.d.getDate() + num);
                break;
            case 'H':
                cal.d.setHours(cal.d.getHours() + num);
                break;
            case 'I':
                cal.d.setMinutes(cal.d.getMinutes() + num);
                break;
            case 'S':
                cal.d.setSeconds(cal.d.getSeconds() + num);
                break;
            default:
                cal.d.setDate(cal.d.getDate() + num);
        }
        return cal;
    }

    getObject() {
        return this.d;
    }

    getYear() {
        return this.d.getFullYear();
    }
    getMonth() {
        return this.d.getMonth() + 1;
    }
    getDate() {
        return this.d.getDate();
    }
    getHours() {
        return this.d.getHours();
    }
    getMinutes() {
        return this.d.getMinutes();
    }
    getSeconds() {
        return this.d.getSeconds();
    }
    getDay() {
        return this.d.getDay(); // 曜日 sun=0,,,,sat=6
    }
    getTime() {
        return this.d.getTime();
    }

    isToday() {
        return this.format('Y-m-d') === Calendar.now().format('Y-m-d');
    }

    format(args) {
        let s = '';
        for (let i = 0; i < args.length; i++) {
            let c = args.charAt(i);
            if (c === 'Y') {
                s += this.d.getFullYear();
            }
            else if (c === 'm') {
                s += ('00'+(this.d.getMonth()+1)).slice(-2);
            }
            else if (c === 'n') {
                s += (this.d.getMonth()+1);
            }
            else if (c === 'd') {
                s += ('00'+this.d.getDate()).slice(-2);
            }
            else if (c === 'H') {
                s += ('00'+this.d.getHours()).slice(-2);
            }
            else if (c === 'h') {
                s += this.d.getHours();
            }
            else if (c === 'i') {
                s += ('00'+this.d.getMinutes()).slice(-2);
            }
            else if (c === 's') {
                s += ('00'+this.d.getSeconds()).slice(-2);
            }
            else if (c === 't') {
                // 今月の末日
                let last = new Date(this.d.getFullYear(), this.d.getMonth()+1, 0);
                s += last.getDate();
            }
            else if (c === 'j') {
                s += this.d.getDate();
            }
            else {
                s += c;
            }
        }
        return s;
    }

    addDate(num) {
        let cal = new Calendar(this.d);
        cal.d.setDate(cal.d.getDate() + num);
        return cal;
    }
    addMonth(num) {
        let cal = new Calendar(this.d);
        cal.d.setMonth(cal.d.getMonth() + num);
        return cal;
    }

    toNextMonth() {
        let ym = this.format('Y-m');
        let cal = this.addDate(28 - this.d.getDate());
        while (cal.format('Y-m') === ym) {
            cal = cal.addDate(1);
        }
        return cal;
    }
    toPrevMonth() {
        let ym = this.format('Y-m');
        let cal = this.addDate(28 - this.d.getDate());
        while (cal.format('Y-m') === ym) {
            cal = cal.addDate(-1);
        }
        return cal;
    }
    toNextDate() {
        return this.addDate(1);
    }
    toPrevDate() {
        return this.addDate(-1);
    }
    toFirstOfMonth() {
        let days = this.d.getDate();
        return this.addDate(-1 * (days-1));
    }
    toLastOfMonth() {
        let last = new Date(this.d.getFullYear(), this.d.getMonth()+1, 0);
        let cal = new Calendar(this.d);
        cal.d.setDate(last.getDate());
        return cal;
    }

    clone() {
        return new Calendar(this.d);
    }

    static toTime(seconds) {
        // return new Date(seconds * 1000).toISOString().substr(11, 8);
        let h = parseInt(seconds / 3600);
        let m = parseInt((seconds % 3600) / 60);
        let s = parseInt(seconds % 60);
        let timestr = '';
        if (h > 0) {
            timestr += (h + 'h');
        }
        if (m > 0) {
            timestr += (m + 'm');
        }
        if (s > 0) {
            timestr += (s + 's');
        }
        return timestr;
    }

    static toHMS(datetime) {
        let ts = new Calendar(datetime);
        return ts.format('H:i:s');
    }

    // Override
    toString() {
        return this.format('Y-m-d H:i:s');
    }
}

console.log('Calendar.js loaded.');
