import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';


import Site from "pages/Site";
import Business from 'pages/Business/Business';
import Master from 'pages/Master/Master';

import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import ChangePassword from 'pages/ChangePassword/ChangePassword';

export default function App() {

    const { pathname } = useLocation();

    useEffect(() => {
        // 画面遷移のたびにスクロール位置をトップに戻す
        window.scrollTo(0, 0);
        // ページ遷移アニメーション
        const root = document.getElementById('root');
        root.addEventListener('animationend', ()=>{
            root.classList.remove('effect-fadeIn');
        }, false);
        root.classList.add('effect-fadeIn');

    }, [pathname]);

    return (
        <Switch>
            <PrivateRoute path="/business" component={Business} />
            <PrivateRoute path="/master" component={Master} />

            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <PrivateRoute exact path="/changepassword" component={ChangePassword} />

            <Route path="/" component={Site} />
        </Switch>
    );
}
