import React, { useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import 'css/LeatherMasterInput.css';

import * as API from 'components/API';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import Radio from 'components/Radio';
import LinkButton from 'components/LinkButton';

import { CATEGORY, LEATHER_TYPE } from 'components/Resource';

function LeatherMasterInput(props) {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setFormState = form.setFormState;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        if (param.id) {
            let result = await API.get('/master/leather/input?id='+param.id);
            if (mounted.current) {
                setFormState({
                    fields: result.Data
                });
            }
        } else {
            // ID指定がない新規入力
        }
    }, [param.id, setFormState]);

    const handleMainScroll = (e) => {
        const h1tag = document.querySelector('.InputContents h1');
        if (h1tag) {
            if (e.target.scrollTop > 0) {
                h1tag.classList.add('small');
            } else {
                h1tag.classList.remove('small');
            }
        }
    }

    // フォーカス時にtext全選択状態にするやつ
    const selectOnFocus = (e) => e.target.select();

    useEffect(() => {
        mounted.current = true;

        const main = document.querySelector('#View main');
        main.addEventListener('scroll', handleMainScroll, false);

        if (param.id) {
            // 編集時はデータ取得
            findData();
        } else {
            // 新規登録時の初期値セット
            setFormState({
                fields: {
                    LeatherStatus: 'ENABLED'
                }
            });
        }
        return () => {
            mounted.current = false;
            main.removeEventListener('scroll', handleMainScroll, false);
        }
    }, [param.id, findData, setFormState]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.LeatherType) {
            errors.LeatherType = {
                Message: '革素材区分は必須入力です。'
            }
        }
        if (!fields.LeatherId) {
            errors.LeatherId = {
                Message: '革素材IDは必須入力です。'
            }
        }
        if (!fields.LeatherStatus) {
            errors.LeatherStatus = {
                Message: 'ステータスは必須入力です。'
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.postAsFormData('/master/leather/update', {
            'Id': param.id,
            'LeatherCategory': state.fields?.LeatherCategory || '',
            'LeatherType': state.fields?.LeatherType || '',
            'LeatherId': state.fields?.LeatherId || '',
            'LeatherName': state.fields?.LeatherName || '',
            'LeatherStatus': state.fields?.LeatherStatus || '',
            'LeatherNote': state.fields?.LeatherNote || '',
            'UpdateDatetime': state.fields?.UpdateDatetime || '',
        });
        if (result?.Result) {
            // [OK] 一覧画面に遷移する
            history.push('/master/leather/list');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result.Errors);
        }
    });

    return (
        <div id="LeatherMasterInput" className="InputContents">
            <h1>
                <Link to="/master/leather/list">
                    <svg xmlns="http://www.w3.org/2000/svg"><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
                </Link>
                <span>革素材マスタ</span>
                <div className="RequireHint"><span className="RequireMark">✱</span>必須入力</div>
            </h1>
            {state.errors?.Page?.map(message => (
                <div className="message error">{message.Message}</div>
            ))}
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">カテゴリ</div>
                    <div className="control">
                        <select name="LeatherCategory" onChange={handleChange}
                            value={state.fields?.LeatherCategory || ''}
                            className={state.errors?.LeatherCategory && 'invalid'}
                        >
                            <option value="">選択してください</option>
                            {CATEGORY.map((category, index) => (
                                <option key={('_'+category.key+'_'+index)} value={category.key}>{category.value}</option>
                            ))}
                        </select>
                        {state.errors?.LeatherCategory &&
                            <span className="messageTip error">{state.errors?.LeatherCategory.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">革素材区分</div>
                    <div className="control">
                        <select name="LeatherType" onChange={handleChange}
                            value={state.fields?.LeatherType || ''}
                            className={state.errors?.LeatherType && 'invalid'}
                        >
                            <option value="">選択してください</option>
                            {LEATHER_TYPE.map((leather, index) => (
                                <option value={leather.key}>{leather.value}</option>
                            ))}
                        </select>
                        {state.errors?.LeatherType &&
                            <span className="messageTip error">{state.errors?.LeatherType.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">革素材ID</div>
                    <div className="control">
                        <input type="text" id="LeatherId" name="LeatherId"
                            placeholder="革素材ID"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.LeatherId || ''}
                            className={state.errors?.LeatherId && 'invalid'}
                        />
                        {state.errors?.LeatherId &&
                            <span className="messageTip error">{state.errors?.LeatherId.Message}</span>
                        }
                        <p className="description">
                            カテゴリ、革素材区分と革素材IDの組み合わせを一意に登録してください。同じ組み合わせのものは登録できません。
                        </p>
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">革素材名</div>
                    <div className="control">
                        <input type="text" id="LeatherName" name="LeatherName"
                            placeholder="革素材名"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.LeatherName || ''}
                            className={state.errors?.LeatherName && 'invalid'}
                            style={{width: '25rem'}}
                        />
                        {state.errors?.LeatherName &&
                            <span className="messageTip error">{state.errors?.LeatherName.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">ステータス</div>
                    <div className="control">
                        <div className="radioGroup row">
                            <Radio label="有効" name="LeatherStatus" value="ENABLED" onChange={handleChange}
                                checked={state.fields?.LeatherStatus === 'ENABLED'}
                            />
                            <Radio label="無効" name="LeatherStatus" value="DISABLED" onChange={handleChange}
                                checked={state.fields?.LeatherStatus === 'DISABLED'}
                            />
                        </div>
                        {state.errors?.LeatherStatus &&
                            <span className="messageTip error">{state.errors?.LeatherStatus.Message}</span>
                        }
                        <p className="description">このデータが必要なくなったときはステータスを「無効」にします。「無効」にすると新しく発注データを作成するときなどで、選択肢に表示されなくなります。</p>
                    </div>
                </div>
                <div className="row">
                    <div className="label">備考</div>
                    <div className="control">
                        <textarea id="LeatherNote" name="LeatherNote" cols="30" rows="10"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.LeatherNote || ''} >
                        </textarea>
                        {state.errors?.LeatherNote &&
                            <span className="messageTip error">{state.errors?.LeatherNote.Message}</span>
                        }
                        <p className="description">このデータに関する覚書きや参考情報などを入力してください。一覧画面ではこの内容を検索して利用することも可能です。</p>
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/master/leather/list">キャンセル</LinkButton>
                <button type="submit" className="PrimaryButton">革素材を登録</button>
            </div>
        </div>
    )
}
export default withFormField(LeatherMasterInput);
