import { PickerDialog, encode } from './PickerDialog';
// import * as API from 'components/API';
import Calendar from 'components/Calendar';

import 'css/CustomerAndOrderPickerDialog.css';

export default class CustomerAndOrderPickerDialog extends PickerDialog {

    constructor(userparam) {
        let param = {
            title: '<h3>顧客を選択</h3>',
            url: '/table/customer/picker',

            // liのテキストを返す(code,name)
            getTextFunction: (row)=>{
                // エンコード追加
                // let encodedId = encode(row['Id']);
                let encodedOrderId = encode(row['OrderId']);
                let encodedOrderDate = encode(row['OrderDate']);
                let encodedItemId = encode(row['ItemId']);
                let encodedItemName = encode(row['ItemName']);
                let encodedOrderNote = encode(row['OrderNote']);
                let encodedFittingNote = encode(row['FittingNote']);
                let encodedCustomerFirstName = encode(row['CustomerFirstName']);
                let encodedCustomerLastName = encode(row['CustomerLastName']);
                let encodedCustomerFirstRuby = encode(row['CustomerFirstRuby']);
                let encodedCustomerLastRuby = encode(row['CustomerLastRuby']);
                let encodedCustomerPostalCode = encode(row['CustomerPostalCode']);
                let encodedCustomerAddress1 = encode(row['CustomerAddress1']);
                let encodedCustomerTel = encode(row['CustomerTel']);
                let encodedCustomerMail = encode(row['CustomerMail']);
                // let encodedCustomerNote = encode(row['CustomerNote']);

                let cal = new Calendar(encodedOrderDate);
                encodedOrderDate = cal.format('Y年m月d日');

                let displayHtml = '';

                displayHtml += '<div class="customerAndOrder">'

                    displayHtml += '<div class="customer">';
                        displayHtml += '<div class="names">';
                            displayHtml += '<div class="ruby"><span>' + encodedCustomerLastRuby + '</span><span>' + encodedCustomerFirstRuby + '</span></div>';
                            displayHtml += '<div class="name"><span>' + encodedCustomerLastName + '</span><span>' + encodedCustomerFirstName + '</span></div>';
                        displayHtml += '</div>';
                        displayHtml += '<div>';
                        if (encodedCustomerPostalCode) {
                            displayHtml += '<div class="postal">〒' + encodedCustomerPostalCode + '</div>';
                        }
                        if (encodedCustomerAddress1) {
                            displayHtml += '<div class="address">' + encodedCustomerAddress1 + '</div>';
                        }
                        if (encodedCustomerTel) {
                            displayHtml += '<div class="tel">';
                                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"/></svg>';
                                displayHtml += encodedCustomerTel;
                            displayHtml += '</div>';
                        }
                        if (encodedCustomerMail) {
                            displayHtml += '<div class="mail">';
                                displayHtml += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>';
                                displayHtml += encodedCustomerMail;
                            displayHtml += '</div>';
                        }
                        if (encodedFittingNote) {
                            displayHtml += '<div class="note">' + encodedFittingNote + '</div>';
                        }
                        displayHtml += '</div>';
                    displayHtml += '</div>'; // .customer end

                    displayHtml += '<div class="order">';
                        displayHtml += '<div class="key">';
                            displayHtml += '<div class="orderId">' + encodedOrderId + '</div>';
                            displayHtml += '<div class="orderDate">' + encodedOrderDate + '</div>';
                        displayHtml += '</div>';
                        displayHtml += '<div>';
                            displayHtml += '<div class="item">';
                                displayHtml += '<div class="itemId">' + encodedItemId + '</div>';
                                displayHtml += '<div class="itemName">' + encodedItemName + '</div>';
                            displayHtml += '</div>';
                            displayHtml += '<div class="note">' + encodedOrderNote + '</div>';
                        displayHtml += '</div>';
                    displayHtml += '</div>'; // .order end

                displayHtml += '</div>'; // .customerAndOrder end

                return displayHtml;
            },
            // フィルタ対象データ配列を返す(code,name)
            getFilterTargets: (row)=>{
                return [
                    // row['Id'],
                    row['OrderId'],
                    row['CustomerFirstName'],
                    row['CustomerLastName'],
                    row['CustomerLastName']+row['CustomerFirstName'],
                    row['CustomerFirstRuby'],
                    row['CustomerLastRuby'],
                    row['CustomerLastRuby']+row['CustomerFirstRuby'],
                    row['CustomerPostalCode'],
                    row['CustomerAddress1'],
                    row['CustomerTel'],
                    row['CustomerMail'],
                    row['CustomerNote'],
                ];
            },
            exceptFunction: (row)=>{
                // 二重選択を防ぐための除外リスト
                const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
                for (let i = 0; i < exceptList.length; i++) {
                    if (exceptList[i] === row['Id']) {
                        return true; // 除外
                    }
                }
                return false;
            }
        }
        if (userparam) {
            param = Object.assign(userparam, param);
        }
        super(param);
        this.container.id = 'CustomerAndOrderPickerDialog';
    }
}