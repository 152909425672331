import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router';
import 'css/TackPrint.css';

import * as API from 'components/API';
import { formatDate, createLastSize, SLMap } from '../Print';

function FootData(props) {
    const state = props.state;
    const pair = props.pair;

    let judgeLast = '';
    let size = '';
    let lastSize = '';
    let last = '';
    let instep = '';
    let footwidth = '';
    let width = '';
    if (pair === "左") {
        judgeLast = state?.LeftJudgeLast;
        instep = state?.LeftJudgeBall;
        footwidth = state?.LeftJudgeWaist;
    } else if (pair === "右") {
        judgeLast = state?.RightJudgeLast;
        instep = state?.RightJudgeBall;
        footwidth = state?.RightJudgeWaist;
    }
    if (judgeLast) {
        size = judgeLast.substr(0, 2);
        lastSize = createLastSize(size);
        last = judgeLast.substr(0, 3);
        width = last.charAt(last.length -1);
    }

    return (
        <>
            <h3>{pair}&emsp;足</h3>
            <div className="SubGrid">
                <div className="GridCell">
                    <div className="r12 c14 bb">ラストサイズ</div>
                    <div className="r12 c45 bb">色</div>
                    <div className="r23 c14 Value">{lastSize}</div>
                    <div className="r23 c45">
                        <div className={`CircleShape color`+lastSize}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px"><circle cx="12" cy="12" r="12" stroke="black"/></svg>
                        </div>
                    </div>
                </div>
                <div className="GridCell">
                    <div className="r12 c13 bb">ラスト選択</div>
                    <div className="r12 c34 bb">甲</div>
                    <div className="r12 c45 bb">足囲</div>
                    <div className="r23 c13 Value">{last}</div>
                    <div className="r23 c34 Value">{instep}</div>
                    <div className="r23 c45 Value">{footwidth}</div>
                </div>
                <div className="GridCell">
                    <div className="r12 c14 bb">ワイズ</div>
                    <div className="r12 c45 bb">色</div>
                    <div className="r23 c14 Value">{width}</div>
                    <div className="r23 c45">
                        <div className={'PolyShape color'+width}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="0,6 6,6 6,0 18,0 18,6 24,6 24,18 18,18 18,24 6,24 6,18 0,18" stroke="black"/></svg>
                        </div>
                    </div>
                </div>
                <div className="GridCell">
                    <div className="r12 c15 bb">LAST NUMBER</div>
                    <div className="r23 c15 Value">{judgeLast}</div>
                </div>
            </div>
        </>
    );
}

function Seal(props) {
    const state = props.state;
    const pair = props.pair;
    const label = props.label;

    let lastSize = '';
    let width = '';

    if (pair === "左") {
        let judgeLast = state?.LeftJudgeLast;
        if (judgeLast) {
            let size = judgeLast.substr(0, 2);
            lastSize = createLastSize(size);
            width = judgeLast.charAt(2);
        }
    }
    else if (pair === "右") {
        let judgeLast = state?.RightJudgeLast;
        if (judgeLast) {
            let size = judgeLast.substr(0, 2);
            lastSize = createLastSize(size);
            width = judgeLast.charAt(2);
        }
    }

    let leftCell = '　';
    let centerCell = '　';
    let rightCell = '　';

    if (label === '靴箱用') {
        leftCell = pair+'足';
        centerCell = state.BuyerPartyName;
        // rightCell = '';
    }
    else if (label === '甲革') {
        leftCell = state.UpperLeatherId;
        centerCell = pair+'足';
        // rightCell = '';
    }
    else if (label === '本底') {
        leftCell = state.OutsoleId;
        centerCell = pair+'足';
        rightCell = width ? SLMap[width] : '';
    }
    else if (label === '中底') {
        // leftCell = '';
        centerCell = pair+'足';
        rightCell = width ? SLMap[width] : '';
    }
    else if (label === '中敷') {
        centerCell = pair+'足';
    }

    return (
        <div className="Seal">
            <table>
                <tbody>
                    <tr><th>発注No.</th><td colSpan="3"><em>{state?.OrderId || ''}</em></td></tr>
                    <tr><th>{label}</th>
                        <td><span className="leftCell">{leftCell}</span></td>
                        <td><span className="centerCell">{centerCell}</span></td>
                        <td><span className="rightCell">{rightCell}</span></td>
                    </tr>
                    <tr><th>お名前</th>
                        <td colSpan="3"><em>{state?.CustomerLastName}&nbsp;{state?.CustomerFirstName || ''}</em>　様</td>
                    </tr>
                    <tr><th>品番</th><td colSpan="3"><em>{state?.ItemId || ''}</em></td></tr>
                    <tr><th>色</th>
                        <td colSpan="3">
                        {state?.ColorCode1 || ''}&nbsp;
                        {state?.ColorKey1 || ''}&nbsp;
                        {state?.ColorName1 || ''}
                        </td>
                    </tr>
                    <tr><th>サイズ</th>
                        <td>
                            <div className={'CircleShape color'+lastSize}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px"><circle cx="12" cy="12" r="12" stroke="black"/></svg>
                            </div>
                        </td>
                        <th>ワイズ</th>
                        <td>
                            <div className={'PolyShape color'+width}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="0,6 6,6 6,0 18,0 18,6 24,6 24,18 18,18 18,24 6,24 6,18 0,18" stroke="black"/></svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

// function SealSpike(props) {
//     const state = props.state;
//     const pair = props.pair;

//     let lastSize = '';
//     let width = '';

//     if (pair === "左") {
//         let judgeLast = state?.LeftJudgeLast;
//         if (judgeLast) {
//             let size = judgeLast.substr(0, 2);
//             lastSize = createLastSize(size);
//             width = judgeLast.charAt(2);
//         }
//     }
//     else if (pair === "右") {
//         let judgeLast = state?.RightJudgeLast;
//         if (judgeLast) {
//             let size = judgeLast.substr(0, 2);
//             lastSize = createLastSize(size);
//             width = judgeLast.charAt(2);
//         }
//     }

//     return (
//         <div className="Seal">
//             <table>
//                 <tbody>
//                     <tr><th>発注No.</th><td colSpan="3"><em>{state?.OrderId || ''}</em></td></tr>
//                     <tr><th>刃</th>
//                         <td><span className="leftCell">　</span></td>
//                         <td><span className="centerCell">{pair}足</span></td>
//                         <td><span className="rightCell">　</span></td>
//                     </tr>
//                     <tr><th>お名前</th>
//                         <td colSpan="3"><em>{state?.CustomerLastName || ''}&nbsp;{state?.CustomerFirstName || ''}</em>　様</td>
//                     </tr>
//                     <tr><th>8本刃</th><td colSpan="3">　</td></tr>
//                     <tr><th>3本刃</th><td colSpan="3">　</td></tr>
//                     <tr><th>サイズ</th>
//                         <td>
//                             <div className={'CircleShape color'+lastSize}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px"><circle cx="12" cy="12" r="12" stroke="black"/></svg>
//                             </div>
//                         </td>
//                         <th>ワイズ</th>
//                         <td>
//                             <div className={'PolyShape color'+width}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="0,6 6,6 6,0 18,0 18,6 24,6 24,18 18,18 18,24 6,24 6,18 0,18" stroke="black"/></svg>
//                             </div>
//                         </td>
//                     </tr>
//                 </tbody>
//             </table>
//         </div>
//     );
// }

// function SealPcover(props) {
//     const state = props.state;
//     const pair = props.pair;

//     let lastSize = '';
//     let width = '';

//     if (pair === "左") {
//         let judgeLast = state?.LeftJudgeLast;
//         if (judgeLast) {
//             let size = judgeLast.substr(0, 2);
//             lastSize = createLastSize(size);
//             width = judgeLast.charAt(2);
//         }
//     }
//     else if (pair === "右") {
//         let judgeLast = state?.RightJudgeLast;
//         if (judgeLast) {
//             let size = judgeLast.substr(0, 2);
//             lastSize = createLastSize(size);
//             width = judgeLast.charAt(2);
//         }
//     }

//     return (
//         <div className="Seal">
//             <table>
//                 <tbody>
//                     <tr><th>発注No.</th><td colSpan="3"><em>{state?.OrderId || ''}</em></td></tr>
//                     <tr><th>P革</th>
//                         <td><span className="leftCell">　</span></td>
//                         <td><span className="centerCell">{pair}足</span></td>
//                         <td><span className="rightCell">　</span></td>
//                     </tr>
//                     <tr><th>お名前</th>
//                         <td colSpan="3"><em>{state?.CustomerLastName || ''}&nbsp;{state?.CustomerFirstName || ''}</em>　様</td>
//                     </tr>
//                     <tr><th>樹脂塗</th><td colSpan="3">&ensp;</td></tr>
//                     <tr><th>コバ金</th><td colSpan="3">&ensp;</td></tr>
//                     <tr><th>サイズ</th>
//                         <td>
//                             <div className={'CircleShape color'+lastSize}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px"><circle cx="12" cy="12" r="12" stroke="black"/></svg>
//                             </div>
//                         </td>
//                         <th>ワイズ</th>
//                         <td>
//                             <div className={'PolyShape color'+width}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="0,6 6,6 6,0 18,0 18,6 24,6 24,18 18,18 18,24 6,24 6,18 0,18" stroke="black"/></svg>
//                             </div>
//                         </td>
//                     </tr>
//                 </tbody>
//             </table>
//         </div>
//     );
// }

// function OrderBlock(props) {
//     const state = props.state;
//     return (
//         <>
//             <div className="flexrow grow1">
//                 <h2 className="grow1">仕様指示書</h2>
//                 <div className="Version">2021/07/01 改訂</div>
//             </div>
//             <table>
//                 <tbody>
//                     <tr>
//                         <th>発注日</th>
//                         <td style={{fontSize:'1.5rem'}}>{formatDate(state?.OrderDate)}</td>
//                         <th>品番</th>
//                         <td style={{fontSize:'1.5rem'}}>{state?.ItemId}</td>
//                     </tr>
//                 </tbody>
//             </table>
//         </>
//     );
// }
// function BuyerBlock(props) {
//     const state = props.state;
//     return (
//         <>
//             <table>
//                 <tbody>
//                     <tr><th rowSpan="6" className="th1">
//                             <div className="VerticalText">発 注 者</div>
//                         </th>
//                         <th className="th2 bb">会社名</th><td className="bb">{state?.BuyerPartyName || ''}<span>御中</span></td>
//                     </tr>
//                     <tr><th className="th2 bb">店名</th><td className="bb">{state?.BuyerPlaceName || ''}</td></tr>
//                     <tr><th className="th2 bb">〒</th><td className="bb">{state?.BuyerPostalCode || ''}</td></tr>
//                     <tr><th className="th2 bb">住所1</th><td className="bb">{state?.BuyerAddress1 || ''}</td></tr>
//                     <tr><th className="th2 bb">住所2</th><td className="bb">{state?.BuyerAddress2 || ''}</td></tr>
//                     <tr><th className="th2">電話番号</th><td>{state?.BuyerTel || ''}</td></tr>
//                 </tbody>
//             </table>
//         </>
//     );
// }
function ItemBlock(props) {
    const state = props.state;
    return (
        <>
            <div className="ItemImage">
                {state?.ItemFileId && (
                    <img src={API.BASE_URL+'/data/file/upload/find?id='+state?.ItemFileId} alt="品番画像" />
                )}
                <div className="ItemColor">
                    <div>{state?.ColorKey1 || ''}</div>
                    <div>{state?.ColorName1 || ''}</div>
                </div>
            </div>
            <OutsoleLastBlock state={state} />
        </>
    );
}

function OutsoleLastBlock(props) {
    const state = props.state;
    return (
        <table>
            <tbody>
                <tr>
                    <th>本底素材</th><td style={{fontSize:'1rem'}}>{state?.OutsoleId || ''}</td>
                    <th>木型</th><td style={{fontSize:'1rem'}}>{state?.LastId || ''}</td>
                </tr>
                <tr>
                    <td colSpan="2">
                        {state?.OutsoleFileId && (
                            <img src={API.BASE_URL+'/data/file/upload/find?id='+state?.OutsoleFileId} alt="本底素材画像" />
                        )}
                        <span>{state?.OutsoleName || ''}</span>
                    </td>
                    <td colSpan="2">
                        {state?.LastFileId && (
                            <img src={API.BASE_URL+'/data/file/upload/find?id='+state?.LastFileId} alt="木型画像" />
                        )}
                        <span>{state?.LastName || ''}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
// function CustomerBlock(props) {
//     const state = props.state;

//     return (
//         <div className="CustomerBlock">
//             <table>
//                 <tbody>
//                     <tr><th rowSpan="6" className="th1">
//                             <div className="VerticalText">お 客 様</div>
//                         </th>
//                         <th style={{height:'2rem'}} className="th2 bb">お名前</th>
//                         <td style={{fontSize:'1rem'}}>{state?.CustomerLastName || ''}&nbsp;{state?.CustomerFirstName || ''}<span>様</span></td>
//                     </tr>
//                     <tr><th rowSpan="5" className="th2">備考</th>
//                         <td rowSpan="5" style={{verticalAlign:'top',borderTop:'1px solid #000'}}>
//                         <p>{state?.OrderNote || ''}</p></td>
//                     </tr>
//                 </tbody>
//             </table>
//         </div>
//     );
// }
function ColorBlock(props) {
    const state = props.state;

    return (
        <>
            <div>色1:&nbsp;
                {state?.ColorCode1 || ''}&nbsp;
                {state?.ColorKey1 || ''}&nbsp;
                {state?.ColorName1 || ''}
            </div>
            <div>色2:&nbsp;
                {state?.ColorCode2 || ''}&nbsp;
                {state?.ColorKey2 || ''}&nbsp;
                {state?.ColorName2 || ''}
            </div>
        </>
    );
}
function LeatherBlock(props) {
    const state = props.state;

    return (
        <>
            <div>甲革:
                &nbsp;{state?.UpperLeatherId || ''}
                &nbsp;{state?.UpperLeatherName || ''}
            </div>
            <div>裏革:
                &nbsp;{state?.LiningLeatherId || ''}
                &nbsp;{state?.LiningLeatherName || ''}
            </div>
        </>
    );
}
function RightFootBlock(props) {
    return (
        <FootData {...props} pair="右" />
    );
}
function LeftFootBlock(props) {
    return (
        <FootData {...props} pair="左" />
    );
}
function SealBlock(props) {
    // const state = props.state;
    return (
        <>
            <Seal {...props} pair="右" label="靴箱用" />
            <Seal {...props} pair="右" label="靴箱用" />
            <Seal {...props} pair="左" label="靴箱用" />
            <Seal {...props} pair="左" label="靴箱用" />
            <Seal {...props} pair="右" label="甲革" />
            <Seal {...props} pair="右" label="甲革" />
            <Seal {...props} pair="左" label="甲革" />
            <Seal {...props} pair="左" label="甲革" />
            <Seal {...props} pair="右" label="本底" />
            <Seal {...props} pair="右" label="本底" />
            <Seal {...props} pair="左" label="本底" />
            <Seal {...props} pair="左" label="本底" />
            <Seal {...props} pair="右" label="中底" />
            <Seal {...props} pair="右" label="中底" />
            <Seal {...props} pair="左" label="中底" />
            <Seal {...props} pair="左" label="中底" />

            {/* <SealSpike {...props} pair="右" />
            <SealPcover {...props} pair="右" />
            <SealSpike {...props} pair="左" />
            <SealPcover {...props} pair="左" /> */}

            <Seal {...props} pair="右" label="中敷" />
            <Seal {...props} pair="右" label="中敷" />
            <Seal {...props} pair="左" label="中敷" />
            <Seal {...props} pair="左" label="中敷" />

            <Seal {...props} pair="右" label="中敷" />
            <Seal {...props} pair="右" label="中敷" />
            <Seal {...props} pair="左" label="中敷" />
            <Seal {...props} pair="左" label="中敷" />
        </>
    );
}

export default function TackPrint(props) {

    const [state, setState] = useState({});

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        let result = await API.get('/print/tack?Id='+param.Id);
        if (result.Data) {
            if (mounted.current) {
                console.log(result.Data);
                setState({ ...result.Data });
            }
        } else {
            console.log('Not found data of '+param.Id);
        }
    }, [param.Id, setState]);

    useEffect(() => {
        mounted.current = true;
        if (param.Id) {
            // 参照時はデータ取得
            findData();

            document.documentElement.style.fontSize = '16px';
        } else {
            // 新規はありえない
            alert('データを指定してください。');
        }
        return () => {
            mounted.current = false;
        }
    }, [param.Id, findData]);

    return (
        <div id="TackPrint">

            <div id="PaperAction" className="noprint">
                <div>
                    <span style={{fontSize:'1.25rem'}}>ブラウザの印刷機能で印刷します。</span>
                    <button onClick={e=>window.print()} className="PrimaryButton">印刷する</button>｜
                    <button onClick={e=>window.close()}>この画面を閉じる</button>
                </div>
                <p style={{marginTop: '1rem'}}>位置を調節するには余白を指定します。（例）上: 8mm、右: 8mm、下: 4mm、左: 8mm</p>
            </div>

            <div className="Paper">
                <div className="GridLayout">

                    <div className="OrderBlock">
                        <div>
                            <div className="Version">2021/07/01 改訂</div>
                            <h2>仕様指示書</h2>
                        </div>
                        <div className="flexrowcenter grow1" style={{justifyContent:'flex-end'}}>
                            発注番号:<span style={{fontSize:'2rem',marginLeft:'3mm'}}>{state?.OrderId || ''}</span>
                            <span id="Barcode">{`*${state?.OrderId || ''}*`}</span>
                        </div>
                    </div>
                    <div className="Order2Block">
                        <table>
                            <tbody>
                                <tr>
                                    <th>品番</th>
                                    <td style={{fontSize:'1.5rem',minWidth:'7rem'}}>{state?.ItemId}</td>
                                    <th>発注日</th>
                                    <td style={{fontSize:'1.5rem',minWidth:'7rem'}}>{formatDate(state?.OrderDate)}</td>
                                    <th>納　　期</th>
                                    <td style={{fontSize:'1.5rem',minWidth:'7rem'}}>{formatDate(state?.DueDate)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="ItemBlock">
                        <ItemBlock state={state} />
                    </div>
                    <div className="BuyerCustomerBlock">
                        <table className="BuyerTable">
                            <tbody>
                                <tr><th className="th2 bb">発注会社名</th><td className="bb">{state?.BuyerPartyName || ''}<span>御中</span></td></tr>
                                <tr><th className="th2 bb">発注店名</th><td className="bb">{state?.BuyerPlaceName || ''}</td></tr>
                                {/* <tr><th className="th2 bb">〒</th><td className="bb">{state?.BuyerPostalCode || ''}</td></tr>
                                <tr><th className="th2 bb">住所1</th><td className="bb">{state?.BuyerAddress1 || ''}</td></tr>
                                <tr><th className="th2 bb">住所2</th><td className="bb">{state?.BuyerAddress2 || ''}</td></tr>
                                <tr><th className="th2 bb">電話番号</th><td className="bb">{state?.BuyerTel || ''}</td></tr> */}
                            </tbody>
                        </table>
                        <table className="CustomerTable">
                            <tbody>
                                <tr><th rowSpan="6" className="th1">
                                        <div className="VerticalText">お 客 様</div>
                                    </th>
                                    <th style={{height:'2rem'}} className="th1 bb">お名前</th>
                                    <td style={{fontSize:'1rem'}}>{state?.CustomerLastName || ''}&nbsp;{state?.CustomerFirstName || ''}<span>様</span></td>
                                </tr>
                                <tr><th rowSpan="5" className="th1">備考</th>
                                    <td rowSpan="5" style={{verticalAlign:'top',borderTop:'1px solid #000'}}>
                                    <p style={{whiteSpace: 'normal'}}>{state?.OrderNote || ''}</p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="ColorBlock">
                        <ColorBlock state={state} />
                    </div>
                    <div className="LeatherBlock">
                        <LeatherBlock state={state} />
                    </div>
                    <div className="RightFootBlock">
                        <RightFootBlock state={state} />
                    </div>
                    <div className="LeftFootBlock">
                        <LeftFootBlock state={state} />
                    </div>
                    <div className="SealBlock">
                        <SealBlock state={state} />
                    </div>
                </div>
            </div>
        </div>
    )
}
