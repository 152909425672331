import * as DOM from "../DOM.js";
import Dialog from './Dialog';

import 'css/OrderMemoDialog.css';

import * as API from 'components/API';

export default class OrderMemoDialog extends Dialog {

    constructor(params) {
        super(params);
        this.params = params;
        this.actionCallback = params.ActionCallback;

        this.container.id = 'OrderMemoDialog';
        this.dialogHeader.innerHTML = '<h3>補正メモ</h3>';

        this.contents = DOM.div([], 'contents');
        this.contents.innerHTML = '<div class="Loader Part"><div class="LoaderMark"></div></div>';
        this.setContents(this.contents);

        this.okButton.textContent = '保存する';

        this.data = null;

        const createContents = ()=>{

            const loadData = (data) => {
                this.data = data;

                let orderId = data.OrderId;
                let customerName = (data.CustomerLastName || '') + (data.CustomerFirstName || '');

                this.textarea = document.createElement('textarea');
                this.textarea.setAttribute('maxlength', '3000');
                this.textarea.placeholder = '(3000文字まで)';
                this.textarea.value = (data.FittingNote || '');

                let AttributeArea = DOM.div([
                    DOM.div([
                        DOM.span('発注番号', 'Label'),
                        DOM.span(orderId, 'Value'),
                    ], 'OrderId'),
                    DOM.div([
                        DOM.span('お客様名', 'Label'),
                        DOM.span(customerName, 'Value'),
                    ], 'Customer')
                ], 'AttributeArea');

                let MainArea = DOM.div([ this.textarea ], 'MainArea');

                this.contents.textContent = '';
                this.contents.appendChild(AttributeArea);
                this.contents.appendChild(MainArea);

                this.centering();

                setTimeout(()=>{
                    // autofocusは効かなかったのでここでやる
                    this.textarea.focus();
                }, 0);
            }

            API.get('/data/fitting/find?OrderId=' + this.params.OrderId)
            .then((result)=>{
                console.log(result);
                loadData(result.Data);
            })
            .catch((error)=>{
                console.error(error);
                this.contents.textContent = '';
                this.contents.appendChild(DOM.div([
                    DOM.div('データが取得できませんでした。フィッティング結果を保存するには発注データに関連する足型測定データが必要です。', 'Message error')
                ]));
                this.okButton.disabled = true;
            });

            this.centering();
        }

        this.beforeShow = createContents;

        super.doAction = ()=> {

            // dataはFormDataとして送信する
            let formData = new FormData();
            formData.append('Id', this.data.Id);
            formData.append('FittingNote', this.textarea.value);
            formData.append('UpdateDatetime', this.data.UpdateDatetime);

            this.textarea.disabled = true;
            this.okButton.disabled = true;
            this.cancelButton.disabled = true;

            API.post('/data/fitting/update', formData)
            .then((result)=>{
                // udpateはaffectedが0かもしれん
                if (result.Result >= 0) {
                    this.hide();
                    if (this.actionCallback) {
                        this.actionCallback();
                    }
                } else {
                    // console.error(result);
                    if (result.Error) {
                        alert(result.Error);
                    }
                }
            })
            .catch((error)=>{
                alert('保存に失敗しました');
                console.log(error);
            })
            .finally(()=>{
                this.textarea.disabled = false;
                this.okButton.disabled = false;
                this.cancelButton.disabled = false;
            });

            return false; // not close
        }
    }

}
