import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import 'css/OrderInput.css';

import * as API from 'components/API';
import OrderCustomerFrame from './OrderCustomerFrame';
import OrderFittingFrame from './OrderFittingFrame';
import OrderOrderFrame from './OrderOrderFrame';
import OrderShoesFrame from './OrderShoesFrame';
import OrderFooterFrame from './OrderFooterFrame';

import Calendar from 'components/Calendar';
import { useAuthUser } from 'components/Authenticator';

export default function OrderInputStep5(props) {

    const state = props.state;
    const setFormState = props.setFormState;
    const setErrors = props.setErrors;
    const handleSubmit = props.handleSubmit;
    const setStep = props.setStep;

    const session = useAuthUser();
    const history = useHistory();

    useEffect(() => {
        let today = new Calendar();
        setFormState({
            fields: {
                // 初期値セット
                OrderDate: today.format('Y-m-d'),
                OrderInsole: 'NO',
                OrderStatus: 'ORDERED',
            }
        });

        // 画面遷移のたびにスクロール位置をトップに戻す
        let container = document.querySelector('#Container');
        if (container) {
            container.scrollTo(0, 0);
        }
    }, [setFormState]);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.BuyerId) {
            errors.BuyerId = {
                Message: '発注者は必須入力です。'
            }
        }
        if (!fields.SellerId) {
            errors.SellerId = {
                Message: 'メーカーは必須入力です。'
            }
        }
        if (!fields.DueDate) {
            errors.DueDate = {
                Message: '納期は必須入力です。'
            }
        }
        if (!fields.DueDate) {
            errors.DueDate = {
                Message: '納期は必須入力です。'
            }
        }
        if (!fields.ItemId) {
            errors.ItemId = {
                Message: '品番は必須入力です。'
            }
        }
        if (!fields.LastId) {
            errors.LastId = {
                Message: '木型は必須入力です。'
            }
        }
        if (!fields.OutsoleId) {
            errors.OutsoleId = {
                Message: '本底素材は必須入力です。'
            }
        }
        if (!fields.ColorCode1) {
            errors.ColorCode1 = {
                Message: '色コード1は必須入力です。'
            }
        }
        if (!fields.UpperLeatherId) {
            errors.UpperLeatherId = {
                Message: '甲革素材は必須入力です。'
            }
        }
        if (!fields.LiningLeatherId) {
            errors.LiningLeatherId = {
                Message: '裏革素材は必須入力です。'
            }
        }

        // if (!fields.SellingPrice) {
        //     errors.SellingPrice = {
        //         Message: '売価は必須入力です。'
        //     }
        // }
        // if (!fields.Cost) {
        //     errors.Cost = {
        //         Message: '仕切り価格(原価)は必須入力です。'
        //     }
        // }


        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async ()=>{
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation() > 0) {
            return;
        }
        console.log('client validation ok!');

        if (window.confirm('この内容で注文します。よろしいですか？')) {
            const result = await API.postAsFormData('/table/order/update', state.fields);
            // console.log(state.fields);
            console.log(result);
            if (result?.Result) {
                // [OK] 登録が成功したら完了/印刷画面
                setFormState({
                    fields: {...result.Result}
                });
                setStep(6);
            } else {
                // サーバーサイドでもvalidationしてるから
                // エラーメッセージがあればmessagingしないかんね
                if (result?.Errors && result?.Errors?.length) {
                    setErrors(result.Errors);
                } else {
                    // result.Resultが無いし、Errorsも無い...サーバーが処理異常だろうな
                    alert('申し訳ございません、注文処理中にエラーが発生しました。');
                }
            }
        } else {
            // なにもしなーい
        }
    });

    const handleSampleClick = () => {
        let newFields = {};

        newFields['BuyerId'] = '1001'; // 発注者ID
        newFields['BuyerPlaceId'] = '001'; // 発注店ID
        newFields['BuyerPartyName'] = '(株)大島商事'; // 発注会社
        newFields['BuyerPlaceName'] = '本部'; // 発注店名
        newFields['BuyerPostalCode'] = '494-0003'; // 郵便番号
        newFields['BuyerAddress1'] = '愛知県一宮市三条字賀37-1サンジョーホーム2F'; // 住所
        newFields['BuyerTel'] = '0586-61-6616'; // 電話番号

        newFields['SellerId'] = '04'; // メーカーID
        newFields['SellerCategory'] = 'MENS'; // メーカーカテゴリ
        newFields['SellerName'] = 'MD new'; // メーカー

        let today = new Calendar();

        newFields['OrderDate'] = today.format('Y-m-d'); // 発注日
        newFields['DueDateSelector'] = '42'; // 納期選択
        newFields['DueDateSelectorLabel'] = '6週間後...42日後'; // 納期選択
        newFields['DueDate'] = today.addDate(42).format('Y-m-d'); // 納期

        newFields['ItemId'] = '132103'; // 品番ID
        newFields['ItemName'] = 'ストレートチップメダリオン・内バネ・Nラウンドトゥ'; // 品番名

        newFields['LastId'] = '32'; // 木型ID
        newFields['LastName'] = 'Nラウンドトゥ'; // 木型名

        newFields['OutsoleId'] = '02'; // 本底素材ID
        newFields['OutsoleName'] = 'ダイナイト・ソール･英国製'; // 本底素材名

        newFields['ColorCode1'] = '42'; // 色コード1
        newFields['ColorCode2'] = '99'; // 色コード2
        newFields['ColorKey1'] = 'DRD'; // 色識別1
        newFields['ColorKey2'] = 'etc'; // 色識別2
        newFields['ColorName1'] = 'ダークレッド'; // 色名1
        newFields['ColorName2'] = 'その他・別注'; // 色名2

        newFields['UpperLeatherId'] = '01'; // 甲革素材
        newFields['UpperLeatherName'] = '牛革国産キップ'; // 甲革素材

        newFields['LiningLeatherId'] = '2'; // 裏革素材
        newFields['LiningLeatherName'] = '馬裏'; // 裏革素材

        newFields['OrderInsole'] = 'NO'; // 中敷補正

        newFields['SellingPrice'] = '55556'; // 売価
        newFields['WholesalePrice'] = '32500'; // 原価

        newFields['OrderNote'] = '売価6万税込 ※仮留めなし ※バチ5㎜下げる ※ケリ付・穴飾り・内バネ ※ダイナイト茶 ※中敷：「大島製靴店」ワインR ※カラー：ワインR濃淡有　◎木型補正：右Wノセ甲4枚8㎜、左Wノセ甲3枚6㎜、右B小趾側2㎜、左B小趾側4㎜盛ります。'; // 備考

        setFormState({
            fields: newFields
        });
    }

    const handleBackStepClick = () => {
        // 試算結果をリセット
        setFormState({
            fields: {
                LeftCompleteLast: null,
                LeftCompleteBall: null,
                LeftCompleteWaist: null,
                LeftCompleteLength: null,
                LeftCompleteWidth: null,
                LeftCompleteInstep: null,
                LeftJudgeLast: null,
                LeftJudgeBall: null,
                LeftJudgeWaist: null,
                LeftJudgeLength: null,
                LeftJudgeWidth: null,
                LeftJudgeInstep: null,
                LeftJudge: null,
                LeftJudgeNote: null,
                LeftJudgeGrade: null,
                RightCompleteLast: null,
                RightCompleteBall: null,
                RightCompleteWaist: null,
                RightCompleteLength: null,
                RightCompleteWidth: null,
                RightCompleteInstep: null,
                RightJudgeLast: null,
                RightJudgeBall: null,
                RightJudgeWaist: null,
                RightJudgeLength: null,
                RightJudgeWidth: null,
                RightJudgeInstep: null,
                RightJudge: null,
                RightJudgeNote: null,
                RightJudgeGrade: null,
                RightAdjustWidth: null,
                RightAdjustInstep: null,
                LeftAdjustWidth: null,
                LeftAdjustInstep: null,
            }
        })
        setStep(3);
    }

    const handleCancelClick = () => {
        if (window.confirm('発注データの作成をキャンセルしますか？\n入力中のデータは保存されません。')) {
            history.push('/business/order');
        }
    }

    return (
        <>
            <div id="OrderSheet">
                <h1>
                    <span className="OrderTitle">{state.fields.OrderCategory1} ORDER SHEET</span>
                </h1>
                <div id="OrderSheetLayout">
                    <OrderCustomerFrame {...props} readonly={true} />
                    <OrderFittingFrame {...props} readonly={true} />
                    <OrderOrderFrame {...props} />
                    <OrderShoesFrame {...props} />
                    <OrderFooterFrame {...props} />
                </div>
            </div>
            <div className="ButtonGroup">
                <button type="button" className="LinkButton" onClick={handleCancelClick}>キャンセル</button>
                <button type="button" className="" onClick={handleBackStepClick}>足型測定データをやり直す</button>
{session.authUser?.UserLevel === 'ADMINISTRATOR' && (
                <button type="button" onClick={handleSampleClick}>サンプルデータ</button>
)}
                <div className="grow1"></div>
                <button type="submit" className="PrimaryButton">注文を確定する</button>
            </div>
        </>
    );
}
