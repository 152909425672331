import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import { useAuthUser } from 'components/Authenticator';

import 'css/OrderInput.css';

import OrderCustomerFrame from './OrderCustomerFrame';
import Calendar from 'components/Calendar';

export default function OrderInputStep2(props) {

    const state = props.state;
    const setFormState = props.setFormState;
    const setErrors = props.setErrors;
    const setStep = props.setStep;

    const history = useHistory();
    // const session = useAuthUser();

    useEffect(() => {
        // 初期値セット
        setFormState({
            fields: {
                FittingDate: Calendar.now().format('Y-m-d'),
                FittingTime: Calendar.now().format('H:i:00')
            }
        });
    }, [setFormState]);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        // 顧客情報
        if (!fields.CustomerFirstName || !fields.CustomerLastName) {
            errors.CustomerLastName = { Message: 'お名前は必須入力です。' };
        }
        // if (!fields.CustomerLastName) {
        //     errors.CustomerLastName = { Message: 'お名前(姓)は必須入力です。' };
        // }
        // if (!fields.CustomerFirstRuby) {
        //     errors.CustomerFirstRuby = { Message: 'フリガナ(姓)は必須入力です。' };
        // }
        // if (!fields.CustomerLastRuby) {
        //     errors.CustomerLastRuby = { Message: 'フリガナ(名)は必須入力です。' };
        // }
        // if (!fields.CustomerBirthday) {
        //     errors.CustomerBirthday = { Message: '生年月日は必須入力です。' };
        // }
        // if (!fields.CustomerHeight) {
        //     errors.CustomerHeight = { Message: '身長は必須入力です。' };
        // }
        // if (!fields.CustomerWeight) {
        //     errors.CustomerWeight = { Message: '体重は必須入力です。' };
        // }
        // if (!fields.CustomerPostalCode) {
        //     errors.CustomerPostalCode = { Message: '郵便番号は必須入力です。' };
        // }
        // if (!fields.CustomerAddress1) {
        //     errors.CustomerAddress1 = { Message: '住所は必須入力です。' };
        // }
        // if (!fields.CustomerTel) {
        //     errors.CustomerTel = { Message: '電話番号は必須入力です。' };
        // }
        // if (!fields.CustomerMail) {
        //     errors.CustomerMail = { Message: 'メールアドレスは必須入力です。' };
        // }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    const handleStepSubmit = () => {
        // 最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');
        // OKなら足型測定データ入力画面を表示
        setStep(3);
    }

    const handleClearClick = () => {
        let newFields = {};

        // 顧客情報
        newFields['CustomerLastName'] = ''; // お名前(姓)
        newFields['CustomerFirstName'] = ''; // お名前(名)
        newFields['CustomerLastRuby'] = ''; // フリガナ(姓)
        newFields['CustomerFirstRuby'] = ''; // フリガナ(名)
        newFields['CustomerBirthday'] = ''; // 生年月日
        newFields['CustomerHeight'] = ''; // 身長
        newFields['CustomerWeight'] = ''; // 体重
        newFields['CustomerPostalCode'] = ''; // 郵便番号
        newFields['CustomerAddress1'] = ''; // 住所1
        newFields['CustomerAddress2'] = ''; // 住所2
        newFields['CustomerOffice'] = ''; // 勤務先名/学校名
        newFields['CustomerTel'] = ''; // 電話番号
        newFields['CustomerMail'] = ''; // メールアドレス

        setFormState({
            fields: newFields
        });
    }

    const handleSampleClick = () => {
        let newFields = {};

        // 顧客情報
        newFields['CustomerLastName'] = 'テスト'; // お名前(姓)
        newFields['CustomerFirstName'] = 'データ'; // お名前(名)
        newFields['CustomerLastRuby'] = 'テスト'; // フリガナ(姓)
        newFields['CustomerFirstRuby'] = 'データ'; // フリガナ(名)
        newFields['CustomerBirthday'] = '2000-01-01'; // 生年月日
        newFields['CustomerHeight'] = 165; // 身長
        newFields['CustomerWeight'] = 55; // 体重
        newFields['CustomerPostalCode'] = '450-0003'; // 郵便番号
        newFields['CustomerAddress1'] = '愛知県名古屋市中村区名駅南2-14-19'; // 住所1
        newFields['CustomerAddress2'] = '住友⽣命名古屋ビル21階'; // 住所2
        newFields['CustomerOffice'] = '株式会社グローバルワイズ'; // 勤務先名/学校名
        newFields['CustomerTel'] = '052-581-2600'; // 電話番号
        newFields['CustomerMail'] = 'sample@example.com'; // メールアドレス

        setFormState({
            fields: newFields
        });
    }

    const handleCancelClick = () => {
        if (window.confirm('発注データの作成をキャンセルしますか？\n入力中のデータは保存されません。')) {
            history.push('/business/order');
        }
    }
    const handleBackStepClick = () => {
        setStep(1);
    }

    return (
        <>
            <div id="OrderSheet">
                <h1>
                    <span className="OrderTitle">{state.fields.OrderCategory1} ORDER SHEET</span>
                </h1>
                <div id="OrderSheetLayout">
                    <OrderCustomerFrame {...props} />
                </div>
            </div>
            <div className="ButtonGroup">
                <button type="button" className="LinkButton" onClick={handleCancelClick}>キャンセル</button>
                <button type="button" className="LinkButton" onClick={handleBackStepClick}>前のステップへ</button>
                <button type="button" onClick={handleSampleClick}>サンプル顧客データ</button>
                <div className="grow1"></div>
                <button type="button" className="LinkButton" onClick={handleClearClick}>クリア</button>
                <button type="button" className="PrimaryButton" onClick={handleStepSubmit}>
                    <span>次のステップへ</span>
                </button>
            </div>
        </>

    );
}