import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import * as API from 'components/API';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import 'css/ForgotPassword.css';
import LogoImage from 'image/logo.png';

function ForgotPassword() {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    // 1:入力画面、2:完了画面
    const [step, setStep] = useState(1);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.UserId) {
            errors.UserId = {
                Message: 'ログインIDが指定されていません。'
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    // 送信するボタン
    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.postAsFormData('/password/forgot', {
            'UserId': state.fields?.UserId || '',
        });
        if (result?.Result) {
            // 成功したら完了画面へ
            setStep(2);
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result?.Errors);
            // 入力画面からやり直してもらうか...
            setStep(1);
        }
    });

    return (
        <div id="ForgotPasswordContents">
            <div id="ForgotPassword">
            {step === 1 && (
                <>
                    <div className="title">
                        <h2>パスワード再設定</h2>
                        <p>ご登録済みのメールアドレスにパスワード再設定のご案内を送信します。
                        パスワード再設定をすると、現在のパスワードを仮パスワードに変更します。
                        仮パスワードでログイン後、再度パスワードを変更してください。
                        </p>
                    </div>
                    <div className="controls">
                        <label htmlFor="UserId">ログインID</label>
                        <input type="text" id="UserId" name="UserId" placeholder="ログインID"
                            className={(state.errors?.UserId) ? 'invalid' : '' }
                            onChange={handleChange}
                            value={state.fields?.UserId || ''}
                            tabIndex="1"
                            autoFocus={true}
                        />
                        {state.errors?.UserId &&
                            <span className="messageTip error">{state.errors?.UserId.Message}</span>
                        }
                    </div>
                    <div>
                        <button type="submit" className="PrimaryButton" tabIndex="2">送信する</button>
                        {state.errors?.Page?.map(message => (
                            <div className="messageTip error">{message.Message}</div>
                        ))}
                    </div>
                    <div className="backLink">
                        <Link to="/business/main">ログインページに戻る</Link>
                    </div>
                </>
            )}
            {step === 2 && (
                <>
                    <div className="title">
                        <h2>パスワード再設定を行いました</h2>
                        <p>パスワードを再設定し、現在のパスワードを仮パスワードに変更しました。
                        ご登録済みのメールアドレスにパスワード再設定のメールをお送りしております。
                        万が一、メールが届かない場合は、
                        迷惑メールフォルダへ振り分けられていることがございますのでご確認ください。
                        メールアドレスの間違いにつきましては、管理者にお問い合わせください。
                        </p>
                        {state.errors?.submit &&
                            <div className="message error">{state.errors?.submit.Message}</div>
                        }
                        <div className="backLink">
                            <Link to="/business/main">ログインページに戻る</Link>
                        </div>
                    </div>
                </>
            )}
            </div>
            <div className="upMOSTLink">
                <Link to="/">
                    <img src={LogoImage} alt="upMOST" />
                    <p>靴 カスタムメイド・オーダーシューズの企画・製造・販売</p>
                    <span>&copy;{(new Date()).getFullYear()}</span>
                </Link>
            </div>
        </div>
    );
}
export default withFormField(ForgotPassword);
