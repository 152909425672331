import React, { useState, useEffect } from 'react';

function DirectionIndicator(props) {

    let dir = '';
    if (props.active) {
        // activeの場合は現在のソート方向を指し示す
        dir = 'current ' + props.current;
    } else {
        // activeじゃない場合は次のソート方向を指し示す(desc)
        dir = 'desc';
    }
    return (
        <span className={'indicator ' + dir}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4 5h8l-4 6-4-6z"></path></svg>
        </span>
    )
}

export default function SortableHeader(props) {

    const state = props.state;
    const columnKey = props.column.key;

    const [currentDir, setCurrentDir] = useState('none');

    const active = (state.sort.column === columnKey);

    useEffect(()=>{
        // 自分じゃないやつはclearだね
        if (state.sort.column !== columnKey) {
            setCurrentDir('none');
        }
    }, [state.sort, columnKey]);

    const handleClick = async (e) => {
        e.stopPropagation();

        let newSort = {...state.sort};
        let newColumn = columnKey;
        let newDirection = currentDir;

        if (currentDir === 'asc') {
            newColumn = '';
            newDirection = 'none';
        } else if (currentDir === 'desc') {
            newDirection = 'asc';
        } else {
            newDirection = 'desc';
        }
        setCurrentDir(newDirection);

        newSort.column = newColumn;
        newSort.direction = newDirection;

        props.setState({
            sort: newSort,
            offset: 0
        });
    }

    const widthStyle = {
        width: props.column.width,
        minWidth: props.column.width,
        maxWidth: props.column.width,
    }
    return (
        <th className="sortable" onClick={handleClick} style={widthStyle}>
            {props.children}
            <DirectionIndicator
                active={active}
                current={currentDir}
            />
        </th>
    );
}