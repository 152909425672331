import React from 'react';

import * as API from 'components/API';
import Calendar from 'components/Calendar';
import TypeNumber from 'components/TypeNumber';

export default function OrderCustomerFrame(props) {

    const state = props.state;
    const handleChange = props.handleChange;
    const setFormState = props.setFormState;
    const readonly = props.readonly || false;
    const open = props.open || false;

    const calculateAge = (CustomerBirthday) => {
        if (CustomerBirthday) {
            // Dateインスタンスに変換
            const inputDate = new Date(CustomerBirthday);
            if (Number.isNaN(inputDate.getTime())) {
                return;
            }
            // 文字列に分解
            const birth = new Calendar(inputDate);
            const y2 = birth.format('Y');
            const m2 = birth.format('m');
            const d2 = birth.format('d');
            // 今日の日付
            const today = new Calendar();
            const y1 = today.format('Y');
            const m1 = today.format('m');
            const d1 = today.format('d');
            return Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
        }
    }

    const handlePostalCodeInput = async e => {
        let result = await API.searchZip(e.target.value);
        if (result) {
            let fullAddress = result.Address1 + result.Address2 + result.Address3;
            setFormState({
                fields: {
                    CustomerPostalCode: result.Zipcode.slice(0,3) + '-' + result.Zipcode.slice(3,7),
                    CustomerAddress1: fullAddress
                }
            });
        }
    }

    return (
        <>
        {readonly === false && (
            <>
            <details id="CustomerFrame" open>
                <summary>顧客情報</summary>
                <div className="CustomerInfo">
                    <div className="rowGroup">
                        <div className="row">
                            <div className="label">お名前</div>
                            <div className="control flexrow">
                                <div>
                                    <input type="text" id="CustomerLastName" name="CustomerLastName"
                                        placeholder="姓"
                                        value={state.fields?.CustomerLastName || ''}
                                        onChange={handleChange}
                                        className={state.errors?.CustomerLastName && 'invalid'}
                                    />
                                    {state.errors?.CustomerLastName &&
                                        <span className="messageTip error">{state.errors?.CustomerLastName.Message}</span>
                                    }
                                </div>
                                <div style={{marginLeft:'.5rem'}}>
                                    <input type="text" id="CustomerFirstName" name="CustomerFirstName"
                                        placeholder="名"
                                        value={state.fields?.CustomerFirstName || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <span style={{marginLeft:'.5rem'}}>様</span>
                        </div>
                        <div className="row">
                            <div className="label">フリガナ</div>
                            <div className="control flexrow">
                                <div>
                                    <input type="text" id="CustomerLastRuby" name="CustomerLastRuby"
                                        placeholder="フリガナ(姓)"
                                        value={state.fields?.CustomerLastRuby || ''}
                                        onChange={handleChange}
                                    />
                                    {state.errors?.CustomerLastRuby &&
                                        <span className="messageTip error">{state.errors?.CustomerLastRuby.Message}</span>
                                    }
                                </div>
                                <div style={{marginLeft:'.5rem'}}>
                                    <input type="text" id="CustomerFirstRuby" name="CustomerFirstRuby"
                                        placeholder="フリガナ(名)"
                                        value={state.fields?.CustomerFirstRuby || ''}
                                        onChange={handleChange}
                                    />
                                    {state.errors?.CustomerFirstRuby &&
                                        <span className="messageTip error">{state.errors?.CustomerFirstRuby.Message}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">生年月日</div>
                            <div className="control">
                                <div style={{maxWidth:'12.5rem'}}>
                                    <input type="date" id="CustomerBirthday" name="CustomerBirthday"
                                        pattern="^([12][0-9]{3})[-|/](0?[1-9]|1[0-2])[-|/](3[01]|[12][0-9]|0?[1-9])$"
                                        value={state.fields?.CustomerBirthday || ''}
                                        onChange={handleChange}
                                    />
                                    {state.errors?.CustomerBirthday &&
                                        <span className="messageTip error">{state.errors?.CustomerBirthday.Message}</span>
                                    }
                                </div>
                            </div>
                            <div className="label" style={{marginLeft:'1.375rem',minWidth:'0'}}>年齢</div>
                            <div className="control">
                                <>{calculateAge(state.fields?.CustomerBirthday)}</>
                                <span style={{marginLeft:'.5rem'}}>歳</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">身長</div>
                            <div className="control">
                                <div style={{maxWidth:'12.5rem'}}>
                                    <TypeNumber id="CustomerHeight" name="CustomerHeight" min="0" max="300"
                                        value={state.fields?.CustomerHeight || ''}
                                        onChange={handleChange}
                                    />
                                    <span style={{marginLeft:'.5rem'}}>cm</span>
                                    {state.errors?.CustomerHeight &&
                                        <span className="messageTip error">{state.errors?.CustomerHeight.Message}</span>
                                    }
                                </div>
                            </div>
                            <div className="label" style={{marginLeft:'1.375rem',minWidth:'0'}}>体重</div>
                            <div className="control">
                                <TypeNumber id="CustomerWeight" name="CustomerWeight" min="0" max="200"
                                    value={state.fields?.CustomerWeight || ''}
                                    onChange={handleChange}
                                />
                                <span style={{marginLeft:'.5rem'}}>kg</span>
                                {state.errors?.CustomerWeight &&
                                    <span className="messageTip error">{state.errors?.CustomerWeight.Message}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="rowGroup">
                        <div className="row">
                            <div className="label">郵便番号</div>
                            <div className="control" style={{position:'relative'}}>
                                <input type="text" id="CustomerPostalCode" name="CustomerPostalCode"
                                    placeholder="000-0000"
                                    value={state.fields?.CustomerPostalCode || ''}
                                    onChange={handleChange}
                                    onInput={handlePostalCodeInput}
                                    style={{paddingLeft:'1.75rem'}}
                                />
                                <span style={{position:'absolute',top:'.625rem',left:'.5rem'}}>〒</span>
                                {state.errors?.CustomerPostalCode &&
                                    <span className="messageTip error">{state.errors?.CustomerPostalCode.Message}</span>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">住所</div>
                            <div className="control">
                                <input type="text" id="CustomerAddress1" name="CustomerAddress1"
                                    placeholder="都道府県市区町村"
                                    value={state.fields?.CustomerAddress1 || ''}
                                    onChange={handleChange}
                                    style={{width: '28rem'}}
                                />
                                {state.errors?.CustomerAddress1 &&
                                    <span className="messageTip error">{state.errors?.CustomerAddress1.Message}</span>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="label"></div>
                            <div className="control">
                                <input type="text" id="CustomerAddress2" name="CustomerAddress2"
                                    placeholder="番地ビル・マンション名等"
                                    value={state.fields?.CustomerAddress2 || ''}
                                    onChange={handleChange}
                                    style={{width: '28rem'}}
                                />
                                {state.errors?.CustomerAddress2 &&
                                    <span className="messageTip error">{state.errors?.CustomerAddress2.Message}</span>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">勤務先名/学校名</div>
                            <div className="control">
                                <input type="text" id="CustomerOffice" name="CustomerOffice"
                                    placeholder="勤務先名/学校名"
                                    value={state.fields?.CustomerOffice || ''}
                                    onChange={handleChange}
                                    style={{width: '28rem'}}
                                />
                                {state.errors?.CustomerOffice &&
                                    <span className="messageTip error">{state.errors?.CustomerOffice.Message}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="rowGroup column">
                        <div className="row">
                            <div className="label">電話番号</div>
                            <div className="control">
                                <input type="text" id="CustomerTel" name="CustomerTel"
                                    placeholder="例) 090-XXXX-XXXX"
                                    value={state.fields?.CustomerTel || ''}
                                    onChange={handleChange}
                                />
                                {state.errors?.CustomerTel &&
                                    <span className="messageTip error">{state.errors?.CustomerTel.Message}</span>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">Eメール</div>
                            <div className="control">
                                <input type="email" id="CustomerMail" name="CustomerMail"
                                    placeholder="例) customer@email.com"
                                    value={state.fields?.CustomerMail || ''}
                                    onChange={handleChange}
                                    style={{width: '28rem'}}
                                />
                                {state.errors?.CustomerMail &&
                                    <span className="messageTip error">{state.errors?.CustomerMail.Message}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </details>
            </>
        )}
        {readonly && (
            <>
            <details id="CustomerFrame" open={open}>
                <summary>顧客情報</summary>
                <div className="CustomerInfoReadonly">
                    <div className="name">
                        <div className="entity" style={{paddingBottom: 0}}>
                            <div className="title" style={{fontSize: '.75rem'}}>フリガナ</div>
                            <div className="fs0750">{state.fields.CustomerLastRuby || ''}{state.fields.CustomerFirstRuby || ''}</div>
                        </div>
                        <div className="entity">
                            <div className="title">お名前</div>
                            <div className="fs1250">{state.fields.CustomerLastName || ''}{state.fields.CustomerFirstName || ''}</div>
                        </div>
                    </div>
                    <div className="size">
                        <div className="entity">
                            <div className="grow1">
                                <div className="flexrowcenter">
                                    <div className="title">生年月日</div>
                                    <div className="fs1000">{state.fields.CustomerBirthday || ''}</div>
                                </div>
                                <div className="flexrowcenter">
                                    <div className="title">年齢</div>
                                    <div className="fs1000">{calculateAge(state.fields?.CustomerBirthday)}</div>
                                    <div className="unit">歳</div>
                                </div>
                            </div>
                            <div className="grow1">
                                <div className="flexrowcenter">
                                    <div className="title">身長</div>
                                    <div className="fs1000">{state.fields.CustomerHeight || ''}</div>
                                    <div className="unit">cm</div>
                                </div>
                                <div className="flexrowcenter">
                                    <div className="title">体重</div>
                                    <div className="fs1000">{state.fields.CustomerWeight || ''}</div>
                                    <div className="unit">kg</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="address">
                        <div className="entity">
                            <div className="title">住所</div>
                            <div>
                                <div>〒{state.fields.CustomerPostalCode || ''}</div>
                                <div className="entity">
                                    <div className="fs1000">{state.fields.CustomerAddress1 || ''}{state.fields.CustomerAddress2 || ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="office">
                        <div className="entity">
                            <div className="title">勤務先</div>
                            <div className="fs1000">{state.fields.CustomerOffice || ''}</div>
                        </div>
                    </div>
                    <div className="tel">
                        <div className="entity">
                            <div className="title">電話番号</div>
                            <div className="fs1000">{state.fields.CustomerTel || ''}</div>
                        </div>
                    </div>
                    <div className="mail">
                        <div className="entity">
                            <div className="title">Eメール</div>
                            <div className="fs1000">{state.fields.CustomerMail || ''}</div>
                        </div>
                    </div>

                </div>
            </details>
            </>
        )}
        </>
    )
}
