import React, { useEffect } from 'react';
import 'css/Loader.css';

export default function Loader(props) {

    const isBluring = props.blur;

    useEffect(() => {

        let loader = document.createElement('div');
        loader.classList.add('Loader');
        let loaderMark = document.createElement('div');
        loaderMark.classList.add('LoaderMark');
        loader.appendChild(loaderMark);

        if (isBluring) {
            document.body.classList.add('blur');
        }
        document.documentElement.appendChild(loader);

        return () => {
            if (document.body.classList.contains('blur')) {
                document.body.classList.remove('blur')
            }
            document.documentElement.removeChild(loader);
        }
    }, [isBluring]);

    // dummy
    return (<></>);
}
