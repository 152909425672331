import React from 'react';
import { Route } from 'react-router-dom';
import Forbidden from 'components/Forbidden';

export default function LevelRoute({ component: Component, access: accessValue, ...rest }) {

    if (accessValue) {
        return (
            <Route {...rest} render={props => (
                <Component {...props} />
            )} />
        )
    }
    return <Forbidden {...rest} />
}
