import React, { useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import 'css/ColorMasterInput.css';

import * as API from 'components/API';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import Radio from 'components/Radio';
import LinkButton from 'components/LinkButton';
import { CATEGORY } from 'components/Resource';


function ColorMasterInput(props) {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setFormState = form.setFormState;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        if (param.id) {
            let result = await API.get('/master/color/input?id='+param.id);
            if (mounted.current) {
                setFormState({
                    fields: result.Data
                });
            }
        } else {
            // ID指定がない新規入力
        }
    }, [param.id, setFormState]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const handleMainScroll = (e) => {
        const h1tag = document.querySelector('.InputContents h1');
        if (h1tag) {
            if (e.target.scrollTop > 0) {
                h1tag.classList.add('small');
            } else {
                h1tag.classList.remove('small');
            }
        }
    }

    // フォーカス時にtext全選択状態にするやつ
    const selectOnFocus = (e) => e.target.select();

    useEffect(() => {
        mounted.current = true;

        const main = document.querySelector('#View main');
        main.addEventListener('scroll', handleMainScroll, false);

        if (param.id) {
            // 編集時はデータ取得
            findData();
        } else {
            // 新規登録時の初期値セット
            setFormState({
                fields: {
                    ColorStatus: 'ENABLED'
                }
            });
        }
        return () => {
            mounted.current = false;
            main.removeEventListener('scroll', handleMainScroll, false);
        }
    }, [param.id, findData, setFormState]);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.ColorCategory) {
            errors.ColorCategory = {
                Message: 'カテゴリは必須入力です。'
            }
        }
        if (!fields.ColorCode) {
            errors.ColorCode = {
                Message: '色コードは必須入力です。'
            }
        }
        // if (!fields.ColorStatus) {
        //     errors.ColorStatus = {
        //         Message: 'ステータスは必須入力です。'
        //     }
        // }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.postAsFormData('/master/color/update', {
            'Id': param.id,
            'ColorCategory': state.fields?.ColorCategory || '',
            'ColorCode': state.fields?.ColorCode || '',
            'ColorKey': state.fields?.ColorKey || '',
            'ColorName': state.fields?.ColorName || '',
            'ColorPreview': state.fields?.ColorPreview || '',
            'ColorStatus': state.fields?.ColorStatus || '',
            'ColorNote': state.fields?.ColorNote || '',
            'UpdateDatetime': state.fields?.UpdateDatetime || '',
        });
        if (result?.Result) {
            // [OK] 一覧画面に遷移する
            history.push('/master/color/list');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result.Errors);
        }
    });

    return (
        <div id="ColorMasterInput" className="InputContents">
            <h1>
                <Link to="/master/color/list">
                    <svg xmlns="http://www.w3.org/2000/svg"><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
                </Link>
                <span>色コードマスタ</span>
                <div className="RequireHint"><span className="RequireMark">✱</span>必須入力</div>
            </h1>
            {state.errors?.Page?.map(message => (
                <div className="message error">{message.Message}</div>
            ))}
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">カテゴリ</div>
                    <div className="control">
                        <select name="ColorCategory" id="" onChange={handleChange}
                            value={state.fields?.ColorCategory || ''}
                            className={state.errors?.ColorCategory && 'invalid'}
                        >
                            <option value="">選択してください</option>
                            {CATEGORY.map((cate) => (
                                <option key={cate.key} value={cate.key}>{cate.value}</option>
                            ))}
                        </select>
                        {state.errors?.ColorCategory &&
                            <span className="messageTip error">{state.errors?.ColorCategory.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">色コード</div>
                    <div className="control">
                        <input type="text" id="ColorCode" name="ColorCode"
                            placeholder="色コード"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.ColorCode || ''}
                            style={{width: '16rem'}}
                            className={state.errors?.ColorCode && 'invalid'}
                        />
                        {state.errors?.ColorCode &&
                            <span className="messageTip error">{state.errors?.ColorCode.Message}</span>
                        }
                        <p className="description">
                        カテゴリと色コードの組み合わせを一意に登録してください。同じ組み合わせのものは登録できません。
                        </p>
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">色識別</div>
                    <div className="control">
                        <input type="text" id="ColorKey" name="ColorKey"
                            placeholder="色識別"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.ColorKey || ''}
                            style={{width: '16rem'}}
                            className={state.errors?.ColorKey && 'invalid'}
                        />
                        {state.errors?.ColorKey &&
                            <span className="messageTip error">{state.errors?.ColorKey.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">色名</div>
                    <div className="control">
                        <input type="text" id="ColorName" name="ColorName"
                            placeholder="色名"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.ColorName || ''}
                            style={{width: '16rem'}}
                            className={state.errors?.ColorName && 'invalid'}
                        />
                        {state.errors?.ColorName &&
                            <span className="messageTip error">{state.errors?.ColorName.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">色プレビュー</div>
                    <div className="control">
                        <input type="color" id="ColorPreview" name="ColorPreview"
                            placeholder="色プレビュー"
                            onChange={handleChange}
                            value={state.fields?.ColorPreview || ''}
                            style={{width: '16rem'}}
                            className={state.errors?.ColorPreview && 'invalid'}
                        />
                        {state.errors?.ColorPreview &&
                            <span className="messageTip error">{state.errors?.ColorPreview.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">ステータス</div>
                    <div className="control">
                        <div className="radioGroup row">
                            <Radio label="有効" name="ColorStatus" value="ENABLED" onChange={handleChange}
                                checked={state.fields?.ColorStatus === 'ENABLED'}
                            />
                            <Radio label="無効" name="ColorStatus" value="DISABLED" onChange={handleChange}
                                checked={state.fields?.ColorStatus === 'DISABLED'}
                            />
                        </div>
                        {state.errors?.ColorStatus &&
                            <span className="messageTip error">{state.errors?.ColorStatus.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">備考</div>
                    <div className="control">
                        <textarea id="ColorNote" name="ColorNote" cols="30" rows="10"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.ColorNote || ''} >
                        </textarea>
                        {state.errors?.ColorNote &&
                            <span className="messageTip error">{state.errors?.ColorNote.Message}</span>
                        }
                        <p className="description">このデータに関する覚書きや参考情報などを入力してください。一覧画面ではこの内容を検索して利用することも可能です。</p>
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/master/color/list">キャンセル</LinkButton>
                <button type="submit" className="PrimaryButton">色コードを登録</button>
            </div>
        </div>
    )
}
export default withFormField(ColorMasterInput);
