import React from 'react';
import { useLocation } from 'react-router';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import 'css/App.css';
import 'css/View.css';
import 'css/Input.css';
import 'css/Button.css';
import 'css/Control.css';
import 'css/Effect.css';
import 'css/Component.css';

import 'css/OmniSearch.css';
import 'css/Pagination.css';

import Header from 'components/Header';
import PageLeftSide from 'components/PageLeftSide';

import LevelRoute from 'components/LevelRoute';
import { useAuthUser } from 'components/Authenticator';

import Main from './Main/Main';
import Order from './Order/Order';
import Sales from './Sales/Sales';
import Customer from './Customer/Customer';
import Fitting from './Fitting/Fitting';
import Billing from './Billing/Billing';
import Print from './Print/Print';

export default function Business() {

    const { path } = useRouteMatch();
    const { authUser } = useAuthUser();

    // ユーザーに発注者の関連がある場合はtrue
    let asBuyer = false;
    if (authUser?.UserBuyerList?.length) {
        asBuyer = true;
    }

    // ユーザーに受注者の関連がある場合はtrue
    let asSeller = false;
    if (authUser?.UserSellerList?.length) {
        asSeller = true;
    }

    // ユーザーの権限が管理者レベルのときはすべてtrue
    let asAdmin = false;
    if (authUser?.UserLevel === 'ADMINISTRATOR') {
        asAdmin = true;
        asBuyer = true;
        asSeller = true;
    }

    const location = useLocation();
    if (location.pathname.startsWith(`${path}/print`)) {
        return (
            <div id="View">
                <LevelRoute path={`${path}/print`} access={asSeller} component={Print} />
            </div>
        )
    }

    return (
        <div id="View">
            <PageLeftSide />
            <main>
                <Header />
                <div id="Container">
                    <Switch>
                        <Route path={`${path}/main`} component={Main} />

                        <LevelRoute path={`${path}/order`} access={asBuyer} component={Order} />
                        <LevelRoute path={`${path}/customer`} access={asBuyer} component={Customer} />
                        <LevelRoute path={`${path}/fitting`} access={asBuyer} component={Fitting} />

                        <LevelRoute path={`${path}/sales`} access={asSeller} component={Sales} />

                        <LevelRoute path={`${path}/billing`} access={asAdmin} component={Billing} />

                        <Redirect path={`${path}`} to={`${path}/main`} />
                    </Switch>
                </div>
            </main>
        </div>
    );
}
