import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'css/SalesList.css';

import * as API from 'components/API';
import Loader from 'components/Loader';
import OmniSearch from 'components/OmniSearch';
import Pagination from 'components/Pagination';
import ListSettingDialog from 'components/Dialog/ListSettingDialog';

import SortableHeader from 'components/SortableHeader';
import * as ListColumn from 'components/ListColumn';
import Checkbox from 'components/Checkbox';

import { ORDER_STATUS, getOrderStatusName, ORDER_CATEGORY1, ORDER_CATEGORY2 } from 'components/Resource';


let o = 0;
const DEFAULT_COLUMN_LIST = [
    // { index: o++, key: 'Id',                visible: false, width: 160, search: false, type: '',          name: 'ID'},
    { index: o++, key: 'OrderId',           visible: true,  width: 110, search: true, type: '',          name: '発注番号'},
    { index: o++, key: 'OrderCategory1',    visible: false,  width: 120, search: true,  type: '',         name: 'カテゴリ1'},
    { index: o++, key: 'OrderCategory2',    visible: false,  width: 120, search: true,  type: '',         name: 'カテゴリ2'},

    { index: o++, key: 'Buyer',             visible: true, width: 180, search: true,  type: '',           name: '発注者'},
    { index: o++, key: 'BuyerId',           visible: false, width: 120, search: true,  type: '',          name: '発注者ID'},
    { index: o++, key: 'BuyerPartyName',    visible: false,  width: 180, search: true,  type: '',         name: '発注会社'},
    { index: o++, key: 'BuyerPlaceId',      visible: false, width: 120, search: true,  type: '',          name: '発注店ID'},
    { index: o++, key: 'BuyerPlaceName',    visible: false,  width: 180, search: true,  type: '',         name: '発注店名'},

    { index: o++, key: 'ItemId',            visible: true,  width: 70,  search: true,  type: '',          name: '品番'},
    { index: o++, key: 'ItemName',          visible: true,  width: 300, search: true,  type: '',          name: '品名'},

    { index: o++, key: 'Seller',            visible: true,  width: 160, search: true,  type: '',          name: 'メーカー'},
    { index: o++, key: 'SellerId',          visible: false, width: 160, search: true,  type: '',          name: 'メーカーID'},
    { index: o++, key: 'SellerName',        visible: false, width: 160, search: true,  type: '',          name: 'メーカー名'},

    { index: o++, key: 'OrderDate',         visible: true,  width: 100, search: true,  type: 'DateRange', name: '発注日'},
    { index: o++, key: 'DueDate',           visible: true,  width: 100, search: true,  type: 'DateRange', name: '納期'},
    { index: o++, key: 'ProductStartDate',  visible: true,  width: 100, search: true,  type: 'DateRange', name: '製造開始日'},
    { index: o++, key: 'ProductEndDate',    visible: true,  width: 100, search: true,  type: 'DateRange', name: '製造完了日'},
    { index: o++, key: 'OrderStatus',       visible: true,  width: 100, search: true,  type: '',          name: '状況'},

    { index: o++, key: 'CreateUserId',      visible: false, width: 160, search: true,  type: '',          name: '作成ユーザー'},
    { index: o++, key: 'UpdateUserId',      visible: false, width: 160, search: true,  type: '',          name: '更新ユーザー'},
    { index: o++, key: 'CreateDatetime',    visible: true,  width: 160, search: true,  type: 'DateRange', name: '作成日時'},
    { index: o++, key: 'UpdateDatetime',    visible: true,  width: 160, search: true,  type: 'DateRange', name: '更新日時'},
];

const DEFAULT_CONDITION = {
    filters: [],
    keywords: [],
    sort: {
        column: '',
        direction: 'none',
    },
    limit: 20,
    offset: 0,
    lastRefresh: null,
    status: '',
    checkBox: {
        AllChecked: false,
        CheckedList: [],
    }
}
const INITIAL_STATE = {
    columns: DEFAULT_COLUMN_LIST,
    ...DEFAULT_CONDITION
}

function stateReducer(state, event) {
    return {...state, ...event};
}

function createParam(key, value) {
    return '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
}


function TableCell(props) {
    let row = props.row;
    let column = props.column;
    // ↓ここで、列ごとに個別対応が必要なものを書いていく

    // 発注番号
    if (column.key === 'OrderId') {
        return (
            <td key={column.key}>
                <div>
                    <Link to={'/business/sales/view/'+row?.Id}>{row[column.key]}</Link>
                </div>
            </td>
        );
    }
    // カテゴリ1
    if (column.key === 'OrderCategory1') {
        return <td key={column.key}><ListColumn.Choice value={row[column.key]} list={ORDER_CATEGORY1} /></td>;
    }
    // カテゴリ2
    if (column.key === 'OrderCategory2') {
        return <td key={column.key}><ListColumn.Choice value={row[column.key]} list={ORDER_CATEGORY2} /></td>;
    }
    // 発注者
    if (column.key === 'Buyer') {
        return <td key={column.key}><ListColumn.Buyer row={row} /></td>;
    }
    // メーカー
    if (column.key === 'Seller') {
        return <td key={column.key}><ListColumn.Seller row={row} /></td>;
    }
    // ステータス
    if (column.key === 'OrderStatus') {
        return (
            <td key={column.key} className={`status status_`+row[column.key]}>
                <ListColumn.OrderStatus value={row[column.key]} />
            </td>
        );
    }

    return <td key={column.key}><div>{row[column.key]}</div></td>;
}

export default function SalesList() {
    const COMPONENT_NAME = 'SalesList';

    const settingClick = e => {
        let listSetting = new ListSettingDialog({
            form: COMPONENT_NAME,
            columns: state.columns,
            limit: state.limit,
            doAction: (params) => {
                // フィルターも並びもページングもリセットが必要
                if (params.reset) {
                    setState({ columns: [...DEFAULT_COLUMN_LIST] });
                } else {
                    setState({ ...DEFAULT_CONDITION, ...params });
                }
            }
        });
        listSetting.show();
    }

    const [listData, setListData] = useState({});
    const [listLoading, setListLoading] = useState(true);
    const [state, setState] = useReducer(stateReducer, {...INITIAL_STATE});
    const mounted = useRef(false);

    const refreshData = () => {
        setState({ lastRefresh: Date.now() });
    }

    const location = useLocation();
    const InitFilterStatus = location.state?.OrderStatus;

    // ユーザー固有の設定を反映させる
    useEffect(()=>{
        mounted.current = true;

        // 外部からORDER_STATUSの条件が渡されたら初期値としてフィルタにセット
        if (InitFilterStatus) {
            setState({
                filters: [{
                    filterName: '状況',
                    filterColumn: 'OrderStatus',
                    filterFormula: '=',
                    filterValue: InitFilterStatus,
                    filterText: getOrderStatusName(InitFilterStatus),
                }],
                offset: 0
            });
        }

        // sessionStorageにstateが保存されていればそれを使う
        let sessionState = window.sessionStorage.getItem(COMPONENT_NAME);
        if (sessionState) {
            let savedState = JSON.parse(sessionState);
            setState({ ...savedState });
            console.log('load state from session!');
        }
        // sessionStorageにstateが保存されていなければ、サーバーに保存されたものを使う
        else {
            API.get('/data/user/find?DataKey=' + COMPONENT_NAME+'.ListSetting')
            .then(result => {
                if (mounted.current) {
                    if (result.Result) {
                        try {
                            const userData = JSON.parse(result.Result.DataValue);
                            setState({ ...userData });
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            });
        }
        return () => {
            mounted.current = false;
        }
    }, [InitFilterStatus]);

    const loadTimeoutId = useRef(null);
    const getListData = useCallback(()=>{

        if (loadTimeoutId.current) {
            clearTimeout(loadTimeoutId.current);
            loadTimeoutId.current = null;
        }
        loadTimeoutId.current = setTimeout(async ()=>{
            let url = '/sales/list?';
            // filters
            for (let i = 0; i < state.filters.length; i++) {
                let f = state.filters[i];
                url += createParam('filterColumn[]', f.filterColumn);
                url += createParam('filterValue[]', f.filterValue);
                url += createParam('filterFormula[]', f.filterFormula);
            }
            // keywords
            for (let i = 0; i < state.keywords.length; i++) {
                let k = state.keywords[i];
                url += createParam('searchKeyword[]', k.searchKeyword);
            }
            // sorts
            if (state.sort.column) {
                url += createParam('sortColumnName', state.sort.column);
            }
            if (state.sort.direction) {
                url += createParam('sortDirection', state.sort.direction);
            }

            // limit,offset
            url += createParam('limit', state.limit);
            url += createParam('offset', state.offset);

            let data = await API.get(url);
            if (mounted.current) {
                // console.log(data);
                setListData(data || {});
                setState({ checkBox: { CheckedList: [] } });
            }

            // sessionStorageにstateを保存しておく
            window.sessionStorage.setItem(COMPONENT_NAME, JSON.stringify(state));

            setListLoading(false);
        }, 100);

    }, [state.filters, state.keywords, state.sort, state.limit, state.offset,
        state.columns, state.lastRefresh]);

    useEffect(() => {
        setListLoading(true);
        getListData();
    }, [
        state.filters, state.keywords, state.sort, state.limit, state.offset,
        state.columns, state.lastRefresh, getListData
    ]);

    useEffect(() => {
        // データが更新されたらスクロールをもとに戻しておく
        let scrollElement = document.querySelector('.TableWrapper');
        // 縦方向だけリセット
        scrollElement.scrollTo(scrollElement.scrollLeft, 0);
    }, [listData]);

    const handleStatusChange = async(e) => {
        const statusValue = e.target.value;
        if (!statusValue) {
            return;
        }

        // dataはFormDataとして送信する
        let formData = new FormData();
        for (let i = 0; i < state.checkBox.CheckedList.length; i++) {
            let value = state.checkBox.CheckedList[i];
            formData.append('Ids[]', value);
        }
        formData.append('OrderStatus', statusValue);

        const result = await API.post('/sales/update', formData);
        if (result?.Result) {
            // [OK]
            console.log('OK');
            setState({
                status: '', // 初期値に戻しておく
                lastRefresh: Date.now()
            });
        } else {
            // サーバーサイドのエラーメッセージがあればmessagingしないかんね
            if (result.Errors) {
                alert(result.Errors);
            } else {
                alert('更新処理中にエラーが発生しました。');
            }
        }
    }

    const handleChecked = (e) => {
        console.log('checked');
        let newCheckbox = {...state.checkBox};
        if (e.target.checked) {
            newCheckbox.CheckedList = newCheckbox.CheckedList.concat([e.target.value]);
        } else {
            newCheckbox.CheckedList = newCheckbox.CheckedList.filter(v => v !== e.target.value);
        }
        setState({
            checkBox: newCheckbox
        });
    }

    const handleAllChecked = (e) => {
        console.log('All checked');
        let newCheckbox = {...state.checkBox};
        if (e.target.checked) {
            newCheckbox.AllChecked = e.target.value;
            newCheckbox.CheckedList = listData.Result?.Data.map(row => row.Id);
        } else {
            newCheckbox.AllChecked = null;
            newCheckbox.CheckedList = [];
        }
        setState({
            checkBox: newCheckbox
        });
    }

    const isCheckedRow = (value) => {
        let checked = false;
        if (state.checkBox.CheckedList && value) {
            let list = state.checkBox.CheckedList.map(v => String(v));
            checked = list.includes(String(value));
        }
        return checked;
    }

    return (
        <>
            <div id="SalesList" className="ListContents">
                <h1>受注データ<span className="counter">({listData.Result?.TotalCount})</span></h1>
                <div id="SubHeader">
                    <div className="grow1">
                        <div className="flexrowcenter">
                            <select name="status"
                                value={state.status}
                                onChange={handleStatusChange}
                                disabled={(state.checkBox.CheckedList.length === 0)}
                            >
                                <option value="">一括操作</option>
                                {ORDER_STATUS.map((status, index) => (
                                    <option key={('_'+status.key+'_'+index)} value={status.key}>{status.value} に更新する</option>
                                ))}
                            </select>
                            <span className="SelectedCounter">
                            {state.checkBox.CheckedList.length > 0 && (state.checkBox.CheckedList.length + '件を選択しています')}
                            </span>
                        </div>
                        <div className="flexrowcenter" style={{marginTop:'.5rem'}}>
                            <OmniSearch
                                columns={state.columns}
                                state={state}
                                setState={setState}
                                filterUrl={`/table/order/filter`}
                            />
                            <button type="button" id="RefreshComponent" className="LinkButton IconButton" onClick={refreshData}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                            </button>
                            <button type="button" id="SettingComponent" className="LinkButton IconButton" onClick={settingClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>
                            </button>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="TableWrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th className="EditLinkColumn"><div className="CheckColumn">
                                        <Checkbox label="" name="AllChecked" value="1"
                                            onChange={handleAllChecked}
                                            selected={state.checkBox.AllChecked}
                                        />
                                    </div></th>
{
state.columns.map(column => {
    return (column.visible) ?
        <SortableHeader key={column.key}
            column={column}
            state={state}
            setState={setState}
        >
            <div>{column.name}</div>
        </SortableHeader>
    : null;
})
}
                                <th className="EndColumn"></th>
                                </tr>
                            </thead>
                            <tbody>
{
listData.Result?.Data.map(row => (
    <tr key={row?.Id} className={isCheckedRow(row?.Id) ? 'selected' : ''}>
        <td className="EditLinkColumn"><div className="CheckColumn">
            <Checkbox label="" name="OrderChecked" value={row?.Id}
                onChange={handleChecked}
                selected={state.checkBox.CheckedList}
            />
        </div></td>
        {
        state.columns.map(column => {
            return (column.visible) ?
                <TableCell key={column.index} column={column} row={row} />
            : null;
        })
        }
        <td className="EndColumn"></td>
    </tr>
))
}
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                        {(!listData.Result || listData.Result?.TotalCount === 0) &&
                            <div className="noDataLabel">該当するデータはありません。</div>}
                        {listLoading && <Loader /> }
                    </div>
                    <div id="SubFooter">
                        <Pagination
                            listCount={listData?.Result?.ListCount}
                            totalCount={listData?.Result?.TotalCount}
                            state={state}
                            setState={setState}
                        />
                    </div>
                </section>
            </div>
        </>
    );
}
