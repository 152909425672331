import React from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';

import 'css/App.css';
import 'css/View.css';
import 'css/Input.css';
import 'css/Button.css';
import 'css/Control.css';
import 'css/Effect.css';
import 'css/Component.css';

import 'css/OmniSearch.css';
import 'css/Pagination.css';

import Header from 'components/Header';
import PageLeftSide from 'components/PageLeftSide';

import LevelRoute from 'components/LevelRoute';
import { useAuthUser } from 'components/Authenticator';

import UserMaster from './User/UserMaster';
import BuyerMaster from './Buyer/BuyerMaster';
import SellerMaster from './Seller/SellerMaster';
import ItemMaster from './Item/ItemMaster';
import ColorMaster from './Color/ColorMaster';
import SizeMaster from './Size/SizeMaster';
import LastMaster from './Last/LastMaster';
import OutsoleMaster from './Outsole/OutsoleMaster';
import LeatherMaster from './Leather/LeatherMaster';

export default function Master() {

    const { path } = useRouteMatch();
    const { authUser } = useAuthUser();

    let asAdmin = false;
    if (authUser.UserLevel === 'ADMINISTRATOR') {
        asAdmin = true;
    }

    return (
        <div id="View">
            <PageLeftSide />
            <main>
                <Header />
                <div id="Container">
                    <Switch>
                        <LevelRoute path={`${path}/buyer`} access={asAdmin} component={BuyerMaster} />
                        <LevelRoute path={`${path}/seller`} access={asAdmin} component={SellerMaster} />
                        <LevelRoute path={`${path}/item`} access={asAdmin} component={ItemMaster} />
                        <LevelRoute path={`${path}/color`} access={asAdmin} component={ColorMaster} />
                        <LevelRoute path={`${path}/size`} access={asAdmin} component={SizeMaster} />
                        <LevelRoute path={`${path}/last`} access={asAdmin} component={LastMaster} />
                        <LevelRoute path={`${path}/outsole`} access={asAdmin} component={OutsoleMaster} />
                        <LevelRoute path={`${path}/leather`} access={asAdmin} component={LeatherMaster} />
                        <LevelRoute path={`${path}/user`} access={asAdmin} component={UserMaster} />

                        <Redirect path={`${path}`} to={`${path}/buyer`} />
                    </Switch>
                </div>
            </main>
        </div>
    );
}
