import React from 'react';
import { Link } from 'react-router-dom';
import 'css/Login.css';
import LogoImage from 'image/logo.png';

import * as API from 'components/API';
import Checkbox from 'components/Checkbox';
import { withFormField, useFormContext, FormContext } from 'components/FormField';


function Login({ setAuthUser }) {
    const { state, setErrors, handleChange, handleSubmit } = useFormContext(FormContext);

    // console.log('Login.setAuthUser', setAuthUser);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        // UserIdは必須
        if (!fields.UserId) {
            errors.UserId = {
                message: 'ログインIDを入力してください。'
            };
        }
        // UserPasswordは必須
        if (!fields.UserPassword) {
            errors.UserPassword = {
                message: 'パスワードを入力してください。'
            };
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.auth({
            UserId: state.fields.UserId,
            UserPassword: state.fields.UserPassword
        });
        if (result && result.User) {
            setAuthUser({
                authUser: result?.User,
                keepLogin: state.fields?.KeepLogin
            });
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors({
                submit: {
                    message: '認証できませんでした。'
                }
            });
        }
    });

    // フォーカス時にtext全選択状態にするやつ
    const selectOnFocus = (e) => e.target.select();

    return (
        <div id="LoginContents">
            <div id="Login">
                <div className="title">
                    <h4>メンバーズサイト</h4>
                    <h2>LOGIN</h2>
                </div>

                <div className="controls">
                    <label htmlFor="UserId">ログインID</label>
                    <input type="text" id="UserId" name="UserId" placeholder="ログインID"
                        className={(state.errors?.UserId) ? 'invalid' : '' }
                        onChange={handleChange}
                        onFocus={selectOnFocus}
                        value={state.fields.UserId || ''}
                        tabIndex="1"
                        autoFocus={true}
                    />
                    {state.errors?.UserId &&
                        <span className="messageTip error">{state.errors?.UserId.message}</span>
                    }
                </div>
                <div className="controls">
                    <div className="flexrow">
                        <label htmlFor="UserPassword">パスワード</label>
                        <Link id="ForgotPasswordLink" to="/forgotpassword">パスワードをお忘れですか？</Link>
                    </div>
                    <input type="password" id="UserPassword" name="UserPassword" placeholder="パスワード"
                        className={(state.errors?.UserPassword) ? 'invalid' : '' }
                        onChange={handleChange}
                        onFocus={selectOnFocus}
                        value={state.fields.UserPassword || ''}
                        tabIndex="2"
                    />
                    {state.errors?.UserPassword &&
                        <span className="messageTip error">{state.errors?.UserPassword.message}</span>
                    }
                </div>
                <div className="controls" id="KeepLogin">
                    <Checkbox label="ログインしたままにする" name="KeepLogin" value="1"
                        onChange={handleChange}
                        selected={state.fields?.KeepLogin}
                        style={{}}
                    />
                </div>
                <div className="controls">
                    <button type="submit" className="PrimaryButton" tabIndex="4">ログイン</button>
                    {state.errors?.submit &&
                        <div className="messageTip error">{state.errors?.submit.message}</div>
                    }
                </div>
                <div className="upMOSTLink">
                    <Link to="/">
                        <img src={LogoImage} alt="upMOST" />
                        <p>靴 カスタムメイド・オーダーシューズの企画・製造・販売</p>
                        <span>&copy;{(new Date()).getFullYear()}</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default withFormField(Login);
