

// エクセルのroundと同じ結果を求める
const excelRound = (number, pricision) => {
    let _sign = (number < 0) ? -1 : 1;
    let _pow = Math.pow(10, pricision);
    return Math.round((number * _sign) * _pow) / _pow * _sign;
};

// 外反母趾角/内反小趾角による補正部材の厚み換算
const calcSpacer = (degree, w, lastDegree) => {
    let value = 0;
    if (degree) {
        if (degree > lastDegree) {
            // 補正部材の幅*(TAN(RADIANS(入力角度/2))-TAN(RADIANS(木型の設定値/2)))/2
            value = w * (Math.tan((degree / 2) * (Math.PI / 180)) - Math.tan((lastDegree / 2) * (Math.PI / 180))) / 2;
            value = (Math.round(value * 100) / 100); // 小数第二位で四捨五入
        } else {
            // 木型の設定値以下は0
        }
    }
    return value;
}

// 足長＆K足補正
const calcPreLength = (LeftLength, RightLength) => {
    // MOD(right,10)=2,MOD(left,10)=2),0
    if (RightLength % 10 === 2 && LeftLength % 10 === 2) return 0;
    if (RightLength % 10 === 7 && LeftLength % 10 === 7) return 0;
    if (RightLength % 10 === 2 && LeftLength % 10  <  2) return 2;
    if (RightLength % 10 === 7 && LeftLength % 10  <  7) return 2;
    if (RightLength % 10 === 1 && LeftLength % 10 === 1) return 1;
    if (RightLength % 10 === 6 && LeftLength % 10 === 6) return 1;
    if (RightLength % 10 === 1 && (RightLength % 10 >= (LeftLength % 10) - 1)) return 1;
    if (RightLength % 10 === 6 && (RightLength % 10 >= (LeftLength % 10) - 1)) return 1;
    return 0;
}

const calcPreKonjacWidth = (length, maxWidth, minWidth) => {
    if (length) {
        let diffwidth = (maxWidth - minWidth);
        if (diffwidth > 15) {
            return 'NG';
        } else {
            if (diffwidth > 8) {
                return diffwidth - 8;
            } else {
                return 0;
            }
        }
    }
    return 0;
}

const calcPreKonjacInstep = (length, maxinstep, mininstep) => {
    if (length) {
        let diffinstep = (maxinstep - mininstep);
        if (diffinstep > 15) {
            return 'NG';
        } else {
            if (diffinstep > 8) {
                return diffinstep - 8;
            } else {
                return 0;
            }
        }
    }
    return 0;
}


// 足囲補正
const getAutoWidth = (length, diffMinWidthInstep, total) => {
    if (length) {
        if ((total * 2) < 2) {
            if (diffMinWidthInstep < 1) {
                // 甲高の補正はなし
            } else {
                if (diffMinWidthInstep > 3) {
                    // 幅広の補正
                    return (excelRound(diffMinWidthInstep / 2, 0) -1) * 2;
                } else {
                    return 0;
                }
            }
        } else {
            return (excelRound(total, 0) * 2) + (excelRound((diffMinWidthInstep-2) / 2, 0) * 2);
        }
    }
    return 0;
}

// 甲まわり補正
const getAutoInstep = (length, diffMinWidthInstep, total) => {
    if (length) {
        if ((total * 2) < 2) {
            if (diffMinWidthInstep < 1) {
                // 甲高の補正
                // =(1-ROUND($G$14/2,0))*2
                // =( 1 - ROUND(-3/2, 0) ) * 2
                return (1 - excelRound(diffMinWidthInstep / 2, 0)) * 2;
            } else {
                if (diffMinWidthInstep > 3) {
                    // 幅広の補正はなし
                    return 0;
                } else {
                    return 0;
                }
            }
        } else {
            return excelRound(total, 0) * 2;
        }
    }
    return 0;
}

const getAssignValue = (base, kon, auto) => {
    if (kon === 'NG') {
        return '補正不可';
    }
    return base - kon - auto;
}

// 外反母趾
const PATCH_WIDTH_1 = 60; // 補正部材の幅(mm)
const LAST_DEGREE_1 = 20; // 木型の設定値(度)
// 内反小趾角
const PATCH_WIDTH_2 = 40; // 補正部材の幅(mm)
const LAST_DEGREE_2 = 25; // 木型の設定値(度)


// 補助シートの入力補正値を生成する
export function simulate(param) {
    // console.log('simulate >>>', param);

    // 戻り値
    const retv = {
        // 右足
        RightLength: 0,
        RightMaxWidth: 0,
        RightMinWidth: 0,
        RightMaxInstep: 0,
        RightMinInstep: 0,
        // 自動補正値
        RightAutoMaxWidth: 0,
        RightAutoMinWidth: 0,
        RightAutoMaxInstep: 0,
        RightAutoMinInstep: 0,

        // 左足
        LeftLength: 0,
        LeftMaxWidth: 0,
        LeftMinWidth: 0,
        LeftMaxInstep: 0,
        LeftMinInstep: 0,
        // 自動補正値
        LeftAutoMaxWidth: 0,
        LeftAutoMinWidth: 0,
        LeftAutoMaxInstep: 0,
        LeftAutoMinInstep: 0,

        Warning: null
    }

    const right = param.Right;
    const left = param.Left;

    // 補正しまーす

    // 右足から
    // Ｂ≧Ｗ大小差
    // let rightDiffWidth = (right.MaxWidth - right.MinWidth);    // 青色の線
    // let rightDiffInstep = (right.MaxInstep - right.MinInstep); // 緑色の線

    // 基準２㎜Ｂ小－Ｗ小
    // ↓これが0より小さいと「甲高です」4より大きいと「幅広です」
    let rightDiffMin = (right.MinWidth - right.MinInstep); // 赤色の線


    // 外反母趾角等の入力(肉盛部材厚み換算込み)
    let rightDeformSpace = calcSpacer(right.DeformDegree, PATCH_WIDTH_1, LAST_DEGREE_1);
    // 内反小趾角等の入力(肉盛部材厚み換算込み)
    let rightInnerDeformSpace = calcSpacer(right.InnerDeformDegree, PATCH_WIDTH_2, LAST_DEGREE_2);
    // total
    let rightTotal = (rightDeformSpace + rightInnerDeformSpace);


    // 次は左足
    // Ｂ≧Ｗ大小差
    // let leftDiffWidth = (left.MaxWidth - left.MinWidth);    // 青色の線
    // let leftDiffInstep = (left.MaxInstep - left.MinInstep); // 緑色の線

    // 基準２㎜Ｂ小－Ｗ小
    // ↓これが0より小さいと「甲高です」4より大きいと「幅広です」
    let leftDiffMin = (left.MinWidth - left.MinInstep); // 赤色の線


    // 外反母趾角等の入力(肉盛部材厚み換算込み)
    let leftDeformSpace = calcSpacer(left.DeformDegree, PATCH_WIDTH_1, LAST_DEGREE_1);
    // 内反小趾角等の入力(肉盛部材厚み換算込み)
    let leftInnerDeformSpace = calcSpacer(left.InnerDeformDegree, PATCH_WIDTH_2, LAST_DEGREE_2);
    // total
    let leftTotal = (leftDeformSpace + leftInnerDeformSpace);


    // ４㎜単位Ｗ小の差
    // お客様に左右差のご認識をご確認頂きましたか？
    let diffMinInstep4mm = Math.abs(right.MinInstep - left.mininstep);
    if (diffMinInstep4mm >= 4) {
        retv.Warning = 'お客様に左右差のご認識をご確認頂きましたか？';
    }


    // 右足から
    // 足長＆K足補正(足長)
    let rightPreLength = calcPreLength(left.Length, right.Length);
    // 足長＆K足補正(足囲(B大))
    let rightPreKonWidth = calcPreKonjacWidth(right.Length, right.MaxWidth, right.MinWidth);
    // 足長＆K足補正(甲まわり(W大))
    let rightPreKonInstep = calcPreKonjacInstep(right.Length, right.MaxInstep, right.MinInstep);

    // 次は左足
    let leftPreLength = calcPreLength(right.Length, left.Length);
    // 足長＆K足補正(足囲(B大))
    let leftPreKonWidth = calcPreKonjacWidth(left.Length, left.MaxWidth, left.MinWidth);
    // 足長＆K足補正(甲まわり(W大))
    let leftPreKonInstep = calcPreKonjacInstep(left.Length, left.MaxInstep, left.MinInstep);


    // 自動補正値

    // 右足から

    // 足囲MAX
    let rightAutoMaxWidth = getAutoWidth(right.Length, rightDiffMin, rightTotal);
    let rightAutoMinWidth = getAutoWidth(right.Length, rightDiffMin, rightTotal);
    // 甲まわり
    let rightAutoMaxInstep = getAutoInstep(right.Length, rightDiffMin, rightTotal);
    let rightAutoMinInstep = getAutoInstep(right.Length, rightDiffMin, rightTotal);

    // 次は左足

    // 足囲MAX
    let leftAutoMaxWidth = getAutoWidth(left.Length, leftDiffMin, leftTotal);
    let leftAutoMinWidth = getAutoWidth(left.Length, leftDiffMin, leftTotal);
    // 甲まわり
    let leftAutoMaxInstep = getAutoInstep(left.Length, leftDiffMin, leftTotal);
    let leftAutoMinInstep = getAutoInstep(left.Length, leftDiffMin, leftTotal);

    retv.RightAutoMaxWidth = rightAutoMaxWidth;
    retv.RightAutoMinWidth = rightAutoMinWidth;
    retv.RightAutoMaxInstep = rightAutoMaxInstep;
    retv.RightAutoMinInstep = rightAutoMinInstep;

    retv.LeftAutoMaxWidth = leftAutoMaxWidth;
    retv.LeftAutoMinWidth = leftAutoMinWidth;
    retv.LeftAutoMaxInstep = leftAutoMaxInstep;
    retv.LeftAutoMinInstep = leftAutoMinInstep;

    // いよいよ入力補正値を求めていきます。

    // 右足から

    // 足長(自動補正は無い)
    retv.RightLength = getAssignValue(right.Length, rightPreLength, 0);
    // 足囲MAX
    retv.RightMaxWidth = getAssignValue(right.MaxWidth, rightPreKonWidth, rightAutoMaxWidth);
    retv.RightMinWidth = getAssignValue(right.MinWidth, 0, rightAutoMinWidth);
    // 甲まわりMAX
    retv.RightMaxInstep = getAssignValue(right.MaxInstep, rightPreKonInstep, rightAutoMaxInstep);
    retv.RightMinInstep = getAssignValue(right.MinInstep, 0, rightAutoMinInstep);

    // 次は左足

    // 足長(自動補正は無い)
    retv.LeftLength = getAssignValue(left.Length, leftPreLength, 0);
    // 足囲MAX
    retv.LeftMaxWidth = getAssignValue(left.MaxWidth, leftPreKonWidth, leftAutoMaxWidth);
    retv.LeftMinWidth = getAssignValue(left.MinWidth, 0, leftAutoMinWidth);
    // 甲まわりMAX
    retv.LeftMaxInstep = getAssignValue(left.MaxInstep, leftPreKonInstep, leftAutoMaxInstep);
    retv.LeftMinInstep = getAssignValue(left.MinInstep, 0, leftAutoMinInstep);


    // console.log(retv);
    return retv;
}
