import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router';
import 'css/DirectionsPrint.css';

import * as API from 'components/API';
import { formatDate, createLastSize } from '../Print';

function PrintLayout(props) {
    const state = props.state;

    return (
        <div className="DirectionLayout">
            <div className="Header">
                <h1>縫製・裁断指示書</h1>
                <div className="Label">発注番号</div>
                <div className="Value">{state?.OrderId || ''}</div>
            </div>
            <div className="SubHeader">
                <div className="Item">
                    <div className="Label">発 注 日</div>
                    <div className="Value">{formatDate(state?.OrderDate)}</div>
                </div>
                <div className="Item">
                    <div className="Label">納 期</div>
                    <div className="Value">{formatDate(state?.DueDate)}</div>
                </div>
                <div className="Item Barcode">
                    <div className="Value">{`*${state?.OrderId}*`}</div>
                </div>
            </div>

            <div className="Area">
                <div className="LeftArea">
                    <div className="Item">
                        <div className="Label">品 　 番</div>
                        <div className="Value">{state?.ItemId || ''}</div>
                    </div>
                    <div className="ItemImage">
                        {state?.ItemFileId && (
                            <img src={API.BASE_URL+'/data/file/upload/find?id='+state?.ItemFileId} alt="品番画像" />
                        )}
                    </div>
                    <div className="AreaFooter">
                        <div className="Label">色<br />コード</div>
                        <div className="Item">
                            <div className="Value">
                                {state?.ColorCode1 || ''}&nbsp;
                                {state?.ColorKey1 || ''}&nbsp;
                                {state?.ColorName1 || ''}
                            </div>
                            <div className="Value">
                                {state?.ColorCode2 || ''}&nbsp;
                                {state?.ColorKey2 || ''}&nbsp;
                                {state?.ColorName2 || ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="RightArea">
                    <div className="RightAreaHeader">
                        <div className="Item">
                            <div className="Label">発注会社名</div>
                            <div className="Value">{state?.BuyerPartyName}</div>
                        </div>
                        <div className="Item">
                            <div className="Label">店　　　名</div>
                            <div className="Value">{state?.BuyerPlaceName}</div>
                        </div>
                    </div>
                    <div className="FootGroup">
                        <div className="RightFoot">
                            <div className="Item Insole">
                                <div className="Label">右</div>
                                <div className="Value">S</div>
                            </div>
                            <div className="Item Size">
                                <div className="Label">サイズ</div>
                                <div className="Value">{createLastSize(state?.RightLastSize)}</div>
                            </div>
                            <div className="Item">
                                <div className="Label">本底コード</div>
                                <div className="Value">{state?.OutsoleId || ''}</div>
                            </div>
                            <div className="Item Image">
                                {state?.OutsoleFileId && (
                                    <img src={API.BASE_URL+'/data/file/upload/find?id='+state?.OutsoleFileId} alt="本底素材画像" />
                                )}
                            </div>
                            <div className="Item Name">
                                <div className="Value">{state?.OutsoleCode || ''}</div>
                            </div>
                        </div>
                        <div className="LeftFoot">
                            <div className="Item Insole">
                                <div className="Label">左</div>
                                <div className="Value">S</div>
                            </div>
                            <div className="Item Size">
                                <div className="Label">サイズ</div>
                                <div className="Value">{createLastSize(state?.LeftLastSize)}</div>
                            </div>
                            <div className="Item">
                                <div className="Label">木型No.</div>
                                <div className="Value">{state?.LastId || ''}</div>
                            </div>
                            <div className="Item Image">
                                {state?.LastFileId && (
                                    <img src={API.BASE_URL+'/data/file/upload/find?id='+state?.LastFileId} alt="木型画像" />
                                )}
                            </div>
                            <div className="Item Name">
                                <div className="Value">{state?.LastCode || ''}</div>
                            </div>
                        </div>
                    </div>
                    <div className="AreaFooter">
                        <div className="Item">
                            <div className="Label">甲革素材</div>
                            <div className="Value">
                                {state?.UpperLeatherId || ''}&nbsp;
                                {state?.UpperLeatherName || ''}
                            </div>
                        </div>
                        <div className="Item">
                            <div className="Label">裏革素材</div>
                            <div className="Value">
                                {state?.LiningLeatherId || ''}&nbsp;
                                {state?.LiningLeatherName || ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Note">
                {/* <span>発注用備考:</span> */}
                <p>{state?.OrderNote || ''}</p>
            </div>
        </div>
    );
}

export default function DirectionsPrint(props) {

    const [state, setState] = useState({});

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        let result = await API.get('/print/directions?Id='+param.Id);
        if (result.Data) {
            if (mounted.current) {
                console.log(result.Data);
                setState({ ...result.Data });
            }
        } else {
            console.log('Not found data of '+param.Id);
        }
    }, [param.Id, setState]);

    useEffect(() => {
        mounted.current = true;
        if (param.Id) {
            // 参照時はデータ取得
            findData();
        } else {
            // 新規はありえない
            alert('データを指定してください。');
        }
        return () => {
            mounted.current = false;
        }
    }, [param.Id, findData]);

    return (
        <div id="DirectionsPrint">

            <div id="PaperAction" className="noprint">
                <div>
                    <span style={{fontSize:'1.25rem'}}>ブラウザの印刷機能で印刷します。</span>
                    <button onClick={e=>window.print()} className="PrimaryButton">印刷する</button>｜
                    <button onClick={e=>window.close()}>この画面を閉じる</button>
                </div>
                <p style={{marginTop: '1rem'}}>位置を調節するには余白を指定します。（例）上: 8mm、右: 8mm、下: 4mm、左: 8mm</p>
            </div>

            <div className="Paper">
                <PrintLayout state={state} />
                <div style={{textAlign:'right'}}>Ver1.2</div>
                <PrintLayout state={state} />
            </div>
        </div>
    )
}
