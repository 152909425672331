import React from 'react';

import * as ListColumn from 'components/ListColumn';
import { FOOT_TYPE, FAVORITE_SIZE, FOOT_LONGER, SOCKS_TYPE, FOOT_DISEASE, HEEL_HEIGHT } from 'components/Resource';

export default function OrderFooterFrame(props) {
    // const { state, setFormState, setErrors, handleChange, handleSubmit, clearState } = props;

    const state = props.state;
    // const handleChange = props.handleChange;
    // const setFormState = props.setFormState;
    // const readonly = props.readonly || false;

    return (
        <>
        <div id="FooterFrame">
            <div className="FooterInfo">
                <p>※足型測定情報は以下のデータに基づいて計算しています。</p>
                <div className="flexrow">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>右</th>
                                <th>左</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><th>足長</th>
                                <td>{state.fields?.RightLength}</td>
                                <td>{state.fields?.LeftLength}</td>
                            </tr>
                            <tr><th>足囲(最大)</th>
                                <td>{state.fields?.RightMaxWidth}</td>
                                <td>{state.fields?.LeftMaxWidth}</td>
                            </tr>
                            <tr><th>足囲(最小)</th>
                                <td>{state.fields?.RightMinWidth}</td>
                                <td>{state.fields?.LeftMinWidth}</td>
                            </tr>
                            <tr><th>甲まわり(最大)</th>
                                <td>{state.fields?.RightMaxInstep}</td>
                                <td>{state.fields?.LeftMaxInstep}</td>
                            </tr>
                            <tr><th>甲まわり(最小)</th>
                                <td>{state.fields?.RightMinInstep}</td>
                                <td>{state.fields?.LeftMinInstep}</td>
                            </tr>
                            <tr><th>足型</th>
                                <td><ListColumn.Choice value={state.fields?.RightFootType} list={FOOT_TYPE} /></td>
                                <td><ListColumn.Choice value={state.fields?.LeftFootType} list={FOOT_TYPE} /></td>
                            </tr>
                            <tr><th>足の疾病</th>
                                <td><ListColumn.MultiChoice value={state.fields?.RightFootDisease} list={FOOT_DISEASE} /></td>
                                <td><ListColumn.MultiChoice value={state.fields?.LeftFootDisease} list={FOOT_DISEASE} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr><th>申告サイズ</th>
                                <td>{state.fields?.FootSize}</td>
                            </tr>
                            <tr><th>足幅</th>
                                <td>{state.fields?.FootWidth}</td>
                            </tr>
                            <tr><th>左右の足長比較</th>
                                <td><ListColumn.Choice value={state.fields?.FootLonger} list={FOOT_LONGER} /></td>
                            </tr>
                            <tr><th>サイズの好み</th>
                                <td><ListColumn.Choice value={state.fields?.FootFavorite} list={FAVORITE_SIZE} /></td>
                            </tr>
                            <tr><th>ソックス</th>
                                <td><ListColumn.Choice value={state.fields?.SocksType} list={SOCKS_TYPE} /></td>
                            </tr>
                            {(state.fields.OrderCategory1 === 'LADIES' && state.fields.OrderCategory2 === 'LEATHER_SHOES') && (
                                <tr><th>ヒール高</th>
                                    <td><ListColumn.Choice value={state.fields?.HeelHeight} list={HEEL_HEIGHT} /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr><th>測定日時</th>
                                <td>{(state.fields?.FittingDate || '') + ' ' +(state.fields?.FittingTime || '')}</td>
                            </tr>
                            <tr><th>測定者</th>
                                <td>{state.fields?.FittingUser || ''}</td>
                            </tr>
                        </tbody>
                    </table>
                    {state.fields?.OrderId && (
                        <div className="OrderBarcode">
                            <div className="barcode">{`*${state.fields?.OrderId}*`}</div>
                            <div className="orderId">{state.fields?.OrderId}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
    )
}