import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import Calendar from 'components/Calendar';
import TackPrint from './Tack/TackPrint';
import DirectionsPrint from './Directions/DirectionsPrint';

export const SLMap = {
    "a": "S", "b": "S", "c": "S", "0": "S", "1": "S", "2": "S",
    "3": "L", "4": "L", "5": "L", "6": "L",
}

export const formatDate = (date) => {
    if (date) {
        let d = new Calendar(date);
        d = d.format('Y/m/d');
        return d;
    }
    return '';
}

export const createLastSize = (lastSize) => {
    if (lastSize) {
        // ラストワイズの先頭に2をつける
        return '2' + lastSize;
    }
    return '';
}

export default function Print() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/tack/:Id`} component={TackPrint} />
                <Route path={`${path}/directions/:Id`} component={DirectionsPrint} />

                <Redirect to={'/business/sales/list'} />
            </Switch>
        </>
    );
}