import React from 'react';
import { ORDER_STATUS } from 'components/Resource';

export function Choice(props) {
    for (let i = 0; i < props.list.length; i++) {
        let row = props.list[i];
        if (row.key === props.value) {
            return <div>{row.value}</div>;
        }
    }
    return <div>(不明)</div>;
}

export function MultiChoice(props, delim=',') {

    if (props.value) {

        let valueString = props.value;
        if (Array.isArray(props.value)) {
            valueString = props.value.join(delim);
        }

        for (let i = 0; i < props.list.length; i++) {
            let item = props.list[i];
            valueString = valueString.replace(item.key, item.value);
        }

        return <div>{valueString}</div>;
    }
    return <div>なし</div>;
}

export function OrderStatus(props) {
    let icons = {
        // 発注済
        'ORDERED': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21,12l-4.37,6.16C16.26,18.68,15.65,19,15,19h-3l0-2h3l3.55-5L15,7H5v3H3V7c0-1.1,0.9-2,2-2h10c0.65,0,1.26,0.31,1.63,0.84 L21,12z M10,15H7v-3H5v3H2v2h3v3h2v-3h3V15z"/></svg>,
        // 製造中
        'PROCESSING': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11,8v5l4.25,2.52l0.77-1.28l-3.52-2.09V8H11z M21,10V3l-2.64,2.64C16.74,4.01,14.49,3,12,3c-4.97,0-9,4.03-9,9 s4.03,9,9,9s9-4.03,9-9h-2c0,3.86-3.14,7-7,7s-7-3.14-7-7s3.14-7,7-7c1.93,0,3.68,0.79,4.95,2.05L14,10H21z"/></svg>,
        // 製造完了
        'COMPLETED': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>,
        // キャンセル
        'CANCEL': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"/></svg>,
    };
    for (let i = 0; i < ORDER_STATUS.length; i++) {
        let row = ORDER_STATUS[i];
        if (row.key === props.value) {
            let svg = null;
            if (row.key in icons) {
                svg = icons[row.key];
            }
            return (<div>{svg}<span>{row.value}</span></div>);
        }
    }
    return <div>{props.value}</div>;
}

export function Customer(props) {
    let row = props.row;
    return (
        <div>
            <div>
                <span>{(row['CustomerLastName'] || '') + (row['CustomerFirstName'] || '')}</span>
            </div>
            <span>{row['CustomerTel'] || ''}</span>
        </div>
    );
}

export function Buyer(props) {
    let row = props.row;
    return (
        <div>
            <div>{row['BuyerId'] + (row['BuyerPlaceId'] || '')}</div>
            <div>{(row['BuyerPartyName'] || '') + '' + (row['BuyerPlaceName'] || '')}</div>
        </div>
    );
}

export function Seller(props) {
    let row = props.row;
    return (
        <div>
            <div>{row['SellerId'] + ' ' + (row['SellerCategory'] || '')}</div>
            <div>{row['SellerName'] || ''}</div>
        </div>
    );
}
