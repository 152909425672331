import React from 'react';
import { useHistory } from 'react-router-dom';

import UserProfile from 'components/UserProfile';


export default function Header(props) {

    const history = useHistory();

    const handleMenuClick = e => {
        history.push('/business/main');
    };

    const handleLeftSideOpen = (e) => {
        document.querySelector('#View').classList.remove('LeftSideHide');
    }

    return (
        <header>
            <div id="LeftSideOpen" onClick={handleLeftSideOpen}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3,18h13v-2H3V18z M3,13h10v-2H3V13z M3,6v2h13V6H3z M21,15.59L17.42,12L21,8.41L19.59,7l-5,5l5,5L21,15.59z"/></svg>
            </div>
            <div id="MenuLink">
                <button type="button" className="LinkButton" onClick={handleMenuClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"/></svg>
                    <span>メニュー</span>
                </button>
            </div>
            {/* {props.children} */}
            <div className="grow1"></div>
            <UserProfile />
        </header>
    );
}
