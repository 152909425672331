import React, { useState, useRef, useEffect } from 'react';
import { APP_BASENAME } from 'index';
import { Link } from 'react-router-dom';
import { useAuthUser } from 'components/Authenticator';
import * as API from 'components/API';
import Loader from 'components/Loader';

function AccountPopup(props) {

    const accountPopupRef = useRef(null);

    const closePopup = () => {
        if (accountPopupRef.current) {
            accountPopupRef.current.open = false;
        }
    }

    useEffect(()=>{
        document.documentElement.addEventListener('click', closePopup, false);
        return () => {
            document.documentElement.removeEventListener('click', closePopup, false);
        }
    }, []);

    const handlePopupClick = (e) => {
        e.stopPropagation();
    }

    return (
        <details className="AccountPopup" ref={accountPopupRef} onClick={handlePopupClick}>
            <summary>
                <div className="UserName">{props.value}</div>
            </summary>
            <div>
                {props.children}
            </div>
        </details>
    );
}

export default function UserProfile() {

    const session = useAuthUser();
    const [ logouting, setLogouting ] = useState(false);

    const handleLogoutClick = async (e) => {
        e.stopPropagation();

        setLogouting(true);

        const result = await API.post('/logout', null);
        // console.log('logout result =', result);
        if (result?.Result) {
            // サーバー側が完了したらクライアント側もセッション終了
            session.setAuthUser(null);
            // sessionStorageもクリアだな
            window.sessionStorage.clear();
            // 最後は画面更新しとけばいいかな
            window.location.href = APP_BASENAME+'/business/main';
        } else {
            alert('ログアウト中にエラー！？');
        }
    };

    return (
        <>
        <div id="UserProfile">
            <AccountPopup value={session.authUser?.UserName}>
                <div className="block">
                    <div className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/></svg>
                    </div>
                    <div className="">
                        <h5>
                            {session.authUser?.UserLevel === 'ADMINISTRATOR' && (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm7 10c0 4.52-2.98 8.69-7 9.93-4.02-1.24-7-5.41-7-9.93V6.3l7-3.11 7 3.11V11zm-11.59.59L6 13l4 4 8-8-1.41-1.42L10 14.17z"/></svg>
                            )}
                            <span>{session.authUser?.UserName}</span>
                        </h5>
                        <p>{session.authUser?.UserId}</p>
                    </div>
                    {/* <div className="AccountEditLink">
                        <Link to={`/master/user/input/${session.authUser?.Id}`}>
                            <span>アカウント情報を変更</span>
                        </Link>
                    </div> */}
                </div>
                <div className="ChangePasswordBlock">
                    <Link to="/changepassword">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 19h-6v-4h-2.68c-1.14 2.42-3.6 4-6.32 4-3.86 0-7-3.14-7-7s3.14-7 7-7c2.72 0 5.17 1.58 6.32 4H24v6h-2v4zm-4-2h2v-4h2v-2H11.94l-.23-.67C11.01 8.34 9.11 7 7 7c-2.76 0-5 2.24-5 5s2.24 5 5 5c2.11 0 4.01-1.34 4.71-3.33l.23-.67H18v4zM7 15c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"/></svg>
                        <span>パスワード変更</span>
                    </Link>
                </div>
                <div className="LogoutBlock">
                    <button type="button" className="LogoutButton" onClick={handleLogoutClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z"/></svg>
                        <span>ログアウト</span>
                    </button>
                </div>
            </AccountPopup>
        </div>
        {logouting && <Loader blur={true} />}
        </>
    );
}
