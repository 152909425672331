import 'css/Dialog.css';

/*
<div class="shadow">
    <div class="dialog">
        <div class="dialogHeader">
            title ...
        </div>
        <div class="dialogBody">
            <div class="dialogBodyInner">
                contents ...
            </div>
        </div>
        <div class="dialogFooter">
            <button type="button" class="dialogCancelButton">キャンセル</button>
            <button type="button" class="dialogOKButton">ＯＫ</button>
        </div>
        <div class="dialogCloser">×</div>
    </div>
</div>
*/
export default class Dialog {

    constructor(params) {
        params = params || {};
        // true:モーダル, or false:otherwise
        this.modal = params.modal || false;
        this.doAction = params.doAction;
        this.doCancel = params.doCancel;
        this.beforeHide = params.beforeHide || null;
        this.afterHide = params.afterHide || null;
        this.beforeShow = params.beforeShow || null;
        this.afterShow = params.afterShow || null;
        this.shadow = document.createElement('div');
        this.shadow.classList.add('shadow');

        if (this.modal) {
            this.shadow.classList.add('modal');
        }

        this.container = document.createElement('div');
        this.container.classList.add('dialog');
        this.container.addEventListener('animationend', (e) => {
            this.container.classList.forEach((clazz) => {
                // アニメーションの名前が追加クラスを削除
                if (clazz.indexOf(e.animationName) >= 0){
                    this.container.classList.remove(clazz);
                }
            })
        }, false);

        this.moveEndListener = null;
        const startMousePosition = {};
        const dragMoveEnd = (e) => {
            const newleft = e.pageX - startMousePosition.x;
            const newtop = e.pageY - startMousePosition.y;
            this.move(newtop, newleft);
            this.container.classList.remove('moving');
            document.removeEventListener('mousemove', dragMove, false);
            document.removeEventListener('mouseup', dragMoveEnd, false);
        };
        const dragMove = (e) => {
            const newleft = e.pageX - startMousePosition.x;
            const newtop = e.pageY - startMousePosition.y;
            this.move(newtop, newleft);
        };
        const dragMoveStart = (e) => {
            startMousePosition.x = e.pageX - this.container.offsetLeft;
            startMousePosition.y = e.pageY - this.container.offsetTop;
            this.container.classList.add('moving');
            document.addEventListener('mousemove', dragMove, false);
            document.addEventListener('mouseup', dragMoveEnd, false);
        };

        this.okFunction = async () => {
            if (this.doAction) {
                let result = await this.doAction();
                if (result === true) {
                    this.hide();
                }
            } else {
                this.hide();
            }
        };
        this.cancelFunction = async () => {
            if (this.doCancel) {
                let result = await this.doCancel();
                if (result) {
                    this.hide();
                }
            } else {
                this.hide();
            }
        };

        this.validateMessageTimeout = null;
        this.validateMessage = (message) => {
            let oldMessage = this.dialogBody.querySelector('.dialogMessage');
            if (oldMessage) {
                if (this.validateMessageTimeout) {
                    clearTimeout(this.validateMessageTimeout);
                    this.validateMessageTimeout = null;
                }
                this.dialogBody.removeChild(oldMessage);
            }
            // dialogbodyの最後尾に表示
            let dialogMessage = document.createElement('div');
            dialogMessage.classList.add('dialogMessage');
            dialogMessage.innerHTML = '<svg height="1rem" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M1005.854 800.247l-438.286-767C556.173 13.30600000000004 534.967 1 512 1s-44.173 12.306-55.567 32.247l-438.286 767c-11.319 19.809-11.238 44.144 0.213 63.876C29.811 883.855 50.899 896 73.714 896h876.572c22.814 0 43.903-12.145 55.354-31.877S1017.173 820.056 1005.854 800.247zM576 768H448V640h128V768zM576 576H448V320h128V576z"/></svg>' + message;
            this.dialogBody.appendChild(dialogMessage);
            this.validateBlockFunction();
            this.validateMessageTimeout = setTimeout(() => {
                this.dialogBody.removeChild(dialogMessage);
            }, 1000 * 3); // n秒後に消滅
        }

        this.validateBlockFunction = () => {
            this.container.classList.add('effect-shake');
        }
        this.modalBlockFunction = () => {
            this.container.classList.add('effect-apper');
        }

        // header
        this.dialogHeader = document.createElement('div');
        this.dialogHeader.classList.add('dialogHeader');
        this.dialogHeader.addEventListener('mousedown', dragMoveStart, false);

        // body
        this.dialogBody = document.createElement('div');
        this.dialogBody.classList.add('dialogBody');

        // bodyInner
        this.dialogBodyInner = document.createElement('div');
        this.dialogBodyInner.classList.add('dialogBodyInner');
        this.dialogBody.appendChild(this.dialogBodyInner);

        // footer
        this.dialogFooter = document.createElement('div');
        this.dialogFooter.classList.add('dialogFooter');

        if (this.modal) {
            // モーダルモードの場合はOKのみ
            // this.dialogFooter.style.justifyContent = 'center';
        } else {
            // cancel button
            this.cancelButton = document.createElement('button');
            this.cancelButton.type = 'button';
            this.cancelButton.textContent = 'キャンセル';
            this.cancelButton.classList.add('dialogCancelButton');
            this.cancelButton.classList.add('LinkButton');
            this.cancelButton.addEventListener('click', this.cancelFunction, false);
            this.dialogFooter.appendChild(this.cancelButton);
        }

        // ok button
        this.okButton = document.createElement('button');
        this.okButton.type = 'button';
        this.okButton.textContent = 'ＯＫ';
        this.okButton.classList.add('dialogOKButton');
        this.okButton.classList.add('PrimaryButton');
        this.okButton.addEventListener('click', this.okFunction, false);
        this.dialogFooter.appendChild(this.okButton);
        this.container.appendChild(this.dialogHeader);
        this.container.appendChild(this.dialogBody);
        this.container.appendChild(this.dialogFooter);

        if (this.modal) {
            // モーダルモードの場合は閉じれない
        } else {
            this.dialogCloser = document.createElement('div');
            this.dialogCloser.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>';
            this.dialogCloser.classList.add('dialogCloser');
            this.dialogCloser.addEventListener('click', this.cancelFunction, false);
            this.dialogCloser.addEventListener('mousedown', (e) => {
                e.stopPropagation();
            });
            this.container.appendChild(this.dialogCloser);
        }
        this.shadow.appendChild(this.container);

        // windowサイズ変更時にdialogを画面中央へ配置
        this.resizeTimer = null;
        this.resizeFunction = (e) => {
            if (this.resizeTimer != null) {
                clearTimeout(this.resizeTimer);
            }
            this.resizeTimer = setTimeout(() => {
                this.centering();
            }, 200);
        };

        // ESCキーでdialogを閉じる
        this.escapeExitFunction = (e) => {
            if (e.key === 'Escape') {
                if (this.modal) {
                    // モーダルの時は閉じない
                    this.modalBlockFunction();
                } else {
                    this.cancelFunction();
                }
            }
        };

        // キャンセルボタンのクリックイベントがshadowまで透過すると
        // 二重でhideが実行されremoveChildでエラーになるためイベント伝播をストップ
        this.container.addEventListener('click', (e) => {
            e.stopPropagation();
        }, false);
    }

    setContents(contents) {
        this.dialogBodyInner.innerHTML = '';
        this.dialogBodyInner.appendChild(contents);
    }

    centering() {
        let top = ((this.shadow.offsetHeight - this.container.offsetHeight) / 2);
        if (top < 0) {
            top = 0;
        }
        this.container.style.top = top + 'px';
        let left = ((this.shadow.offsetWidth - this.container.offsetWidth) / 2);
        if (left < 0) {
            left = 0;
        }
        this.container.style.left = left + 'px';
    }

    show() {
        if (this.beforeShow) {
            this.beforeShow();
        }

        const SHOW_CONTAINER_ANIMATION_CLASS = 'effect-fadeInScale';
        const SHOW_SHADOW_ANIMATION_CLASS = 'effect-fadeIn';

        const showAnimationEndFunction = (e) => {
            this.shadow.classList.remove(SHOW_SHADOW_ANIMATION_CLASS);
            this.container.classList.remove(SHOW_CONTAINER_ANIMATION_CLASS);
        }

        this.shadow.addEventListener('animationend', showAnimationEndFunction, false);
        this.container.addEventListener('animationend', showAnimationEndFunction, false);

        this.shadow.classList.add(SHOW_SHADOW_ANIMATION_CLASS);
        this.container.classList.add(SHOW_CONTAINER_ANIMATION_CLASS);

        document.documentElement.appendChild(this.shadow);

        this.centering();

        setTimeout(() => {
            if (this.modal) {
                // モーダルモードの場合はshadowをクリックしても閉じれない
                this.shadow.addEventListener('click', this.modalBlockFunction, false);
                // ぼかしレイヤー
                document.body.classList.add('blur');
            } else {
                // dialog以外の部分をクリックすると閉じる
                this.shadow.addEventListener('click', this.cancelFunction, false);
            }
            document.documentElement.addEventListener('keydown', this.escapeExitFunction, false);
            
            window.addEventListener('resize', this.resizeFunction, false);

            if (this.afterShow) {
                this.afterShow();
            }
        }, 0);
        this.appended = true;
    }

    hide() {
        if (this.appended) {
            this.appended = false;
            // console.log('hide process start!!');

            if (this.beforeHide) {
                if (this.beforeHide() === false) {
                    return; // hideしない
                }
            }
            const HIDE_CONTAINER_ANIMATION_CLASS = 'effect-fadeOutScale';
            const HIDE_SHADOW_ANIMATION_CLASS = 'effect-fadeOut';

            const hideAnimationEndFunction = () => {
                document.documentElement.removeChild(this.shadow);
                if (this.modal) {
                    // モーダルモードの場合はlistenerが違う
                    this.shadow.removeEventListener('click', this.modalBlockFunction, false);
                } else {
                    this.shadow.removeEventListener('click', this.cancelFunction, false);
                }
                window.removeEventListener('resize', this.resizeFunction, false);
                document.documentElement.removeEventListener('keydown', this.escapeExitFunction, false);

                this.container.classList.remove(HIDE_CONTAINER_ANIMATION_CLASS);
                this.container.removeEventListener('animationend', hideAnimationEndFunction, false);

                if (this.afterHide) {
                    this.afterHide();
                }
            }
            this.container.addEventListener('animationend', hideAnimationEndFunction, false);
            this.container.classList.add(HIDE_CONTAINER_ANIMATION_CLASS);

            this.shadow.addEventListener('animationend', (e)=>{
                this.shadow.classList.remove(HIDE_SHADOW_ANIMATION_CLASS);
            }, false);
            this.shadow.classList.add(HIDE_SHADOW_ANIMATION_CLASS);

            if (this.modal) {
                // ぼかしレイヤー
                document.body.classList.remove('blur');
            }
        }

        // if (this.appended) {
        //     if (this.beforeHide) {
        //         if (this.beforeHide() === false) {
        //             return; // hideしない
        //         }
        //     }
        //     document.documentElement.removeChild(this.shadow);
        //     if (this.modal) {
        //         // モーダルモードの場合はlistenerが違う
        //         this.shadow.removeEventListener('click', this.modalBlockFunction, false);
        //         // ぼかしレイヤー
        //         document.body.classList.remove('blur');
        //     } else {
        //         this.shadow.removeEventListener('click', this.cancelFunction, false);
        //     }
        //     window.removeEventListener('resize', this.resizeFunction, false);
        //     document.documentElement.removeEventListener('keydown', this.escapeExitFunction, false);
        //     this.appended = false;
        //     if (this.afterHide) {
        //         this.afterHide();
        //     }
        // }
    }

    move(top, left) {

        // 画面外に移動できないように制限
        const maxTop = window.innerHeight - this.container.offsetHeight;
        top = Math.min(top, maxTop);
        top = Math.max(top, 0);

        const maxLeft = window.innerWidth - this.container.offsetWidth;
        left = Math.min(left, maxLeft);
        left = Math.max(left, 0);

        this.container.style.top = top + 'px';
        this.container.style.left = left + 'px';
        if (this.moveEndListener != null) {
            this.moveEndListener();
        }
    }
}