

export function node(tag, ca, cls) {
    let n = document.createElement(tag);
    if (cls) n.className = cls;
    if (ca) {
        if (Array.isArray(ca)) {
            for (let i = 0; i < ca.length; i++) {
                if (ca[i]) {
                    if (typeof ca[i] === 'string') {
                        n.appendChild(document.createTextNode(ca[i]));
                    } else {
                        n.appendChild(ca[i]);
                    }
                }
            }
        } else {
            n.insertAdjacentHTML('beforeend', ca);
        }
    }
    return n;
}
export function header(ca, cls) {
    return node('header', ca, cls);
}
export function footer(ca, cls) {
    return node('footer', ca, cls);
}
export function div(ca, cls, id) {
    let n = node('div', ca, cls);
    if (id) n.id = id;
    return n;
}
export function span(ca, cls) {
    return node('span', ca, cls);
}
export function ul(ca, cls) {
    return node('ul', ca, cls);
}
export function li(ca, cls) {
    return node('li', ca, cls);
}
export function link(ca, href, cls) {
    let n = node('a', ca, cls);
    n.href = href;
    return n;
}
export function table(ca, cls, id) {
    let n = node('table', ca, cls);
    if (id) n.id = id;
    return n;
}
export function tr(ca, cls, id) {
    let n = node('tr', ca, cls);
    if (id) n.id = id;
    return n;
}
export function td(ca, cls, id) {
    let n = node('td', ca, cls);
    if (id) n.id = id;
    return n;
}
export function th(ca, cls, id) {
    let n = node('th', ca, cls);
    if (id) n.id = id;
    return n;
}

export function positiveNumberInput(val, min, max, step) {
    let n = document.createElement('input');
    n.type = 'number';
    if (min !== null && min !== 'undefined') {
        n.setAttribute('min', min);
    }
    if (max !== null && max !== 'undefined') {
        n.setAttribute('max', max);
    }
    if (step !== null && step !== 'undefined') {
        n.setAttribute('step', step);
    }
    n.value = val;
    const INTEGER_REGEX = new RegExp('[0-9]+');
    // 半角整数のみ入力可能
    n.addEventListener('keydown', (e) => {
        if (!e.key) return;
        if (!INTEGER_REGEX.test(e.key)) {
            switch (e.key.toLowerCase()) {
                case '-':
                case 'tab':
                case 'home':
                case 'end':
                case 'delete':
                case 'backspace':
                case 'arrowup':
                case 'arrowleft':
                case 'arrowright':
                case 'arrowdown':
                    // 許可する
                    break;
                default:
                    // 拒否する
                    e.preventDefault();
                    e.stopPropagation(); // 透過させない
                    return false;
            }
        } else {
            // 許可！
        }
    }, false);
    n.addEventListener('keyup', (e) => {
        let int = parseInt(e.target.value);
        e.target.value = isNaN(int) ? 0 : int;
    }, false);
    return n;
}

export function hsplit(left, center, right) {
    let l, c, r;
    if (left) l = div([left], 'left');
    if (center) c = div([center], 'center');
    if (right) r = div([right], 'right');
    return div([l, c, r], 'hsplit');
}

export function label(f, t) {
    let n = document.createElement('label');
    n.setAttribute('for', f);
    n.textContent = t;
    return n;
}
export function output(f, t) {
    let n = document.createElement('output');
    n.setAttribute('for', f);
    // n.textContent = t;
    n.defaultValue = t;
    return n;
}
export function slider(id, min, max, step, value, oninput, onchange) {
    let n = document.createElement('input');
    n.type = 'range';
    n.id = id;
    n.min = min;
    n.max = max;
    n.step = step;
    n.value = value;
    if (oninput) n.addEventListener('input', oninput, false);
    if (onchange) n.addEventListener('change', onchange, false);
    return n;
}

export function radio(id, name, text, checked, onchange) {
    let radio = document.createElement('input');
    radio.type = 'radio';
    radio.id = id;
    radio.name = name;
    radio.checked = checked;
    if (onchange) radio.addEventListener('change', onchange, false);
    let n = div([radio, label(id, text)], 'radio');
    n.disabled = (bool) => {
        radio.disabled = bool;
    }
    n.check = (bool) => {
        if (radio.disabled) return;
        radio.checked = bool;
    }
    n.getChecked = () => {
        if (radio.disabled) return false;
        return radio.checked;
    }
    return n;
}

export function checkbox(id, text, checked, onchange) {
    let checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.id = id;
    checkbox.checked = checked;
    checkbox.addEventListener('change', onchange, false);
    let n = div([checkbox, label(id, text)], 'checkbox');
    n.disabled = (bool) => {
        checkbox.disabled = bool;
    }
    n.check = (bool) => {
        if (checkbox.disabled) return;
        checkbox.checked = bool;
    }
    n.getChecked = () => {
        if (checkbox.disabled) return false;
        return checkbox.checked;
    }
    return n;
}

export function switcher(id, checked, onchange) {
    let checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.id = id;
    checkbox.checked = checked;
    checkbox.addEventListener('change', onchange, false);
    return div([checkbox, label(id)], 'switcher');
}

export function button(t, cls, onclick) {
    let n = document.createElement('button');
    n.type = 'button';
    if (cls) n.className = cls;
    n.textContent = t;
    if (onclick) n.addEventListener('click', onclick, false);
    return n;
}
export function iconButton(i, t, cls, onclick) {
    let n = document.createElement('button');
    n.type = 'button';
    if (cls) n.className = cls;
    n.innerHTML = '<div class="icon"><i>' + i + '</i></div><span>' + t + '</span>';
    if (onclick) n.addEventListener('click', onclick, false);
    return n;
}

export function h1(t, cls) {
    let n = document.createElement('h1');
    if (cls) n.className = cls;
    n.textContent = t;
    return n;
}
export function h4(t, cls) {
    let n = document.createElement('h4');
    if (cls) n.className = cls;
    n.textContent = t;
    return n;
}
export function h5(t, cls) {
    let n = document.createElement('h5');
    if (cls) n.className = cls;
    n.textContent = t;
    return n;
}
export function p(t, cls) {
    let n = document.createElement('p');
    if (cls) n.className = cls;
    n.textContent = t;
    return n;
}
export function number(cls, name, val, min, max, step) {
    let n = document.createElement('input');
    n.type = 'number';
    n.name = name;
    if (cls) n.className = cls;
    n.value = val;
    n.setAttribute('min', min);
    n.setAttribute('max', max);
    n.setAttribute('step', step);
    return n;
}

export function dropdownItem(cls, value, text) {
    let item = li(text, cls);
    item.dataset.value = value;
    return item;
}
export function dropdown(value, label, options) {

    let hidden = document.createElement('input');
    hidden.type = 'hidden';
    hidden.value = value;

    let dropdown = div([
        hidden,
        div(label, 'label'),
        div([
            ul(options, '')
        ], 'menu'),
    ], 'dropdown');

    return dropdown;
}

console.log('DOM.js loaded.');