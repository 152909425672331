import React from 'react';
import { withRouter } from 'react-router';

function LinkButton(props) {

    const handleClick = (e) => {
        // <LinkButton onClick="...とonClickが指定されていたらそれを実行
        if (props.onClick) {
            props.onClick(e);
        }
        // onClickが優先される
        else {
            // <LinkButton to="...とtoが指定されていたらそれを実行
            if (props.to) {
                props.history.push(props.to);
            }
        }
    };

    return (
        <button type="button" className="LinkButton" onClick={handleClick}>
            {props.children}
            <span>{props.text}</span>
        </button>
    );
}

export default withRouter(LinkButton);
