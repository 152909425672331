import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'css/BuyerMasterList.css';
// import PropTypes from 'prop-types';

import * as API from 'components/API';
import Loader from 'components/Loader';
import OmniSearch from 'components/OmniSearch';
import Pagination from 'components/Pagination';
import ListSettingDialog from 'components/Dialog/ListSettingDialog';

import SortableHeader from 'components/SortableHeader';
import * as ListColumn from 'components/ListColumn';
import { MASTER_STATUS } from 'components/Resource';


let o = 0;
const DEFAULT_COLUMN_LIST = [
    // { index: o++,  key: 'Id',               visible: false, width: 160, search: false, type: '',          name: 'ID' },
    { index: o++,  key: 'BuyerId',          visible: true, width: 120, search: true,  type: '',          name: '発注者ID' },
    { index: o++,  key: 'BuyerPartyName',   visible: true, width: 240, search: true,  type: '',          name: '発注者会社名' },
    { index: o++,  key: 'BuyerPlaceId',     visible: true, width: 120, search: true,  type: '',          name: '発注者店ID' },
    { index: o++,  key: 'BuyerPlaceName',   visible: true, width: 240, search: true,  type: '',          name: '発注者店名' },
    { index: o++,  key: 'BuyerCategory',    visible: true, width: 240, search: true,  type: '',          name: 'カテゴリ' },
    { index: o++,  key: 'BuyerPostalCode',  visible: true, width: 120, search: true,  type: '',          name: '郵便番号' },
    { index: o++,  key: 'BuyerAddress1',    visible: true, width: 240, search: true,  type: '',          name: '住所1' },
    { index: o++,  key: 'BuyerAddress2',    visible: true, width: 240, search: true,  type: '',          name: '住所2' },
    { index: o++,  key: 'BuyerTel',         visible: true, width: 160, search: true,  type: '',          name: '電話番号' },
    { index: o++,  key: 'BuyerMail',        visible: true, width: 160, search: true,  type: '',          name: 'メールアドレス' },
    { index: o++,  key: 'BuyerStatus',      visible: true, width: 160, search: true,  type: '',          name: 'ステータス' },
    { index: o++,  key: 'BuyerNote',        visible: true, width: 160, search: true,  type: '',          name: '備考' },
    { index: o++,  key: 'CreateUserId',     visible: true, width: 160, search: true,  type: '',          name: '作成ユーザー' },
    { index: o++,  key: 'UpdateUserId',     visible: true, width: 160, search: true,  type: '',          name: '更新ユーザー' },
    { index: o++,  key: 'CreateDatetime',   visible: true, width: 160, search: true,  type: 'DateRange', name: '作成日時' },
    { index: o++,  key: 'UpdateDatetime',   visible: true, width: 160, search: true,  type: 'DateRange', name: '更新日時' },
];

const DEFAULT_CONDITION = {
    filters: [],
    keywords: [],
    sort: {
        column: '',
        direction: 'none',
    },
    limit: 20,
    offset: 0,
    lastRefresh: null,
}
const INITIAL_STATE = {
    columns: DEFAULT_COLUMN_LIST,
    ...DEFAULT_CONDITION
}

function stateReducer(state, event) {
    return {...state, ...event};
}

function createParam(key, value) {
    return '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
}


function TableCell(props) {
    let row = props.row;
    let column = props.column;
    // ↓ここで、列ごとに個別対応が必要なものを書いていく

    // カテゴリ(複数カンマ区切り)
    if (column.key === 'BuyerCategory') {
        if (row[column.key]) {
            let category = row[column.key].split(',');
            let jsx = [];
            for (let i = 0; i < category.length; i++) {
                jsx.push(<span key={i} className={`category ${category[i]}`}>{category[i]}</span>);
            }
            return (<td><div>{jsx}</div></td>);
        }
    }
    // ステータス
    else if (column.key === 'BuyerStatus') {
        return (<td><ListColumn.Choice value={row[column.key]} list={MASTER_STATUS} /></td>);
    }

    return <td><div>{row[column.key]}</div></td>;
}

export default function BuyerMasterList() {
    const COMPONENT_NAME = 'BuyerMasterList';

    const routerHistory = useHistory();
    const addItemClick = e => {
        routerHistory.push('/master/buyer/input');
    }
    const settingClick = e => {
        let listSetting = new ListSettingDialog({
            form: COMPONENT_NAME,
            columns: state.columns,
            limit: state.limit,
            doAction: (params) => {
                // フィルターも並びもページングもリセットが必要
                if (params.reset) {
                    setState({ columns: [...DEFAULT_COLUMN_LIST] });
                } else {
                    setState({ ...DEFAULT_CONDITION, ...params });
                }
            }
        });
        listSetting.show();
    }

    const [listData, setListData] = useState({});
    const [listLoading, setListLoading] = useState(true);
    const [state, setState] = useReducer(stateReducer, {...INITIAL_STATE});
    const mounted = useRef(false);

    const refreshData = () => {
        setState({ lastRefresh: Date.now() });
    }

    // ユーザー固有の設定を反映させる
    useEffect(()=>{
        mounted.current = true;

        // sessionStorageにstateが保存されていればそれを使う
        let sessionState = window.sessionStorage.getItem(COMPONENT_NAME);
        if (sessionState) {
            let savedState = JSON.parse(sessionState);
            setState({ ...savedState });
            console.log('load state from session!');
        }
        // sessionStorageにstateが保存されていなければ、サーバーに保存されたものを使う
        else {
            API.get('/data/user/find?DataKey=' + COMPONENT_NAME+'.ListSetting')
            .then(result => {
                if (result.Result) {
                    if (mounted.current) {
                        try {
                            const userData = JSON.parse(result.Result.DataValue);
                            setState({ ...userData });
                        } catch (error) {
                            console.error(error);
                        }
                        console.log('load state.columns from server!');
                    }
                }
            });
        }

        return () => {
            mounted.current = false;
        }
    }, []);

    const getListData = useCallback(async ()=>{
        let url = '/master/buyer/list?';
        // filters
        for (let i = 0; i < state.filters.length; i++) {
            let f = state.filters[i];
            url += createParam('filterColumn[]', f.filterColumn);
            url += createParam('filterValue[]', f.filterValue);
            url += createParam('filterFormula[]', f.filterFormula);
        }
        // keywords
        for (let i = 0; i < state.keywords.length; i++) {
            let k = state.keywords[i];
            url += createParam('searchKeyword[]', k.searchKeyword);
        }
        // sorts
        if (state.sort.column) {
            url += createParam('sortColumnName', state.sort.column);
        }
        if (state.sort.direction) {
            url += createParam('sortDirection', state.sort.direction);
        }

        // limit,offset
        url += createParam('limit', state.limit);
        url += createParam('offset', state.offset);

        let data = await API.get(url);
        if (mounted.current) {
            setListData(data || {});
        }

        // sessionStorageにstateを保存しておく
        window.sessionStorage.setItem(COMPONENT_NAME, JSON.stringify(state));

        setListLoading(false);

    }, [state]);

    useEffect(() => {
        setListLoading(true);
        getListData();
    }, [
        state.filters, state.keywords, state.sort, state.limit, state.offset,
        state.columns, state.lastRefresh, getListData
    ]);

    return (
        <>
            <h1>発注者マスタ<span className="counter">({listData.Result?.TotalCount})</span></h1>
            <div id="BuyerMasterList" className="ListContents">
                <div id="SubHeader">
                    <button type="button" id="AddItemComponent" className="PrimaryButton" onClick={addItemClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                        <span>発注者を作成</span>
                    </button>
                    <OmniSearch
                        columns={state.columns}
                        state={state}
                        setState={setState}
                        filterUrl={`/master/buyer/filter`}
                    />
                    <button type="button" id="RefreshComponent" className="LinkButton IconButton" onClick={refreshData}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                    </button>
                    <button type="button" id="SettingComponent" className="LinkButton IconButton" onClick={settingClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>
                    </button>
                </div>
                <section>
                    <div className="TableWrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th className="EditLinkColumn"></th>
{
state.columns.map(column => {
    return (column.visible) ?
        <SortableHeader key={column.key}
            column={column}
            state={state}
            setState={setState}
        >
            <div>{column.name}</div>
        </SortableHeader>
    : null;
})
}
                                <th className="EndColumn"></th>
                                </tr>
                            </thead>
                            <tbody>
{
listData.Result?.Data.map(row => (
    <tr key={row?.Id} className={row?.BuyerStatus}>
        <td className="EditLinkColumn">
            <div>
                <Link to={`/master/buyer/input/${row?.Id}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
                </Link>
            </div>
        </td>
        {
        state.columns.map(column => {
            return (column.visible) ?
                <TableCell key={column.index} column={column} row={row} />
            : null;
        })
        }
        <td className="EndColumn"></td>
    </tr>
))
}
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                        {listData.Result?.TotalCount === 0 && <div className="noDataLabel">該当するデータはありません。</div>}
                        {listLoading && <Loader /> }
                    </div>
                    <div id="SubFooter">
                        <Pagination
                            listCount={listData?.Result?.ListCount}
                            totalCount={listData?.Result?.TotalCount}
                            state={state}
                            setState={setState}
                        />
                    </div>
                </section>
            </div>
        </>
    )
}
