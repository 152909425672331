import React, { useEffect } from 'react';

import * as API from 'components/API';
import * as PickerDialog from 'components/Dialog/PickerDialog';
import DesignPickerDialog from 'components/Dialog/DesignPickerDialog';
import OutsolePickerDialog from 'components/Dialog/OutsolePickerDialog';

import Radio from 'components/Radio';
import TypeNumber from 'components/TypeNumber';


function stretch(element) {

    // 文字幅測定用span
    const ruler = document.createElement('span');
    ruler.style.visibility = 'hidden';
    ruler.style.position = 'absolute';
    ruler.style.display = 'inline-block';
    ruler.style.whiteSpace = 'nowrap';
    document.documentElement.appendChild(ruler);

    const style = window.getComputedStyle(element);
    ruler.style.fontFamily = style.fontFamily;
    ruler.style.fontSize = style.fontSize;
    ruler.style.padding = style.padding;
    ruler.textContent = element.value;

    element.style.width = (ruler.offsetWidth + 16) + 'px'; // 文字幅

    document.documentElement.removeChild(ruler);
}

export default function OrderShoesFrame(props) {

    const state = props.state;
    const setFormState = props.setFormState;
    const handleChange = props.handleChange;

    const readonly = props.readonly || false;
    // const sales = props.sales || false;

    // デザイン選択ボタンクリック
    const handleDesignClick = (e) => {
        if (!state.fields.BuyerId) {
            alert('先に発注者を選択してください。');
            return;
        }
        if (!state.fields.SellerId) {
            alert('先にメーカーを選択してください。');
            return;
        }
        const picker = new DesignPickerDialog({
            SellerId: state.fields.SellerId,
            SellerCategory: state.fields.SellerCategory
        });
        picker.setSelectedCallback((selected)=>{
            setFormState({fields: {...selected}});
        });
        picker.show();
    }

    // 本底素材選択ボタンクリック
    const handleOutsoleClick = (e) => {
        if (!state.fields.BuyerId) {
            alert('先に発注者を選択してください。');
            return;
        }
        if (!state.fields.SellerId) {
            alert('先にメーカーを選択してください。');
            return;
        }
        const picker = new OutsolePickerDialog({
            SellerId: state.fields.SellerId,
            SellerCategory: state.fields.SellerCategory
        });
        picker.setSelectedCallback((selected)=>{
            setFormState({fields: {...selected}});
        });
        picker.show();
    }

    // 色コード選択クリック
    const handleColorClick = (e) => {
        const picker = PickerDialog.createColorPicker();
        picker.setSelectedCallback((selected)=>{
            console.log(e.target.name+' selected', selected);
            // target.nameの最後の文字(数字)を付加してキーを作る
            const num = e.target.name.slice(-1);

            let colorFields = {};
            colorFields['ColorCode'+num] = selected.ColorCode;
            colorFields['ColorKey'+num] = selected.ColorKey;
            colorFields['ColorName'+num] = selected.ColorName;
            setFormState({ fields: colorFields });
            stretch(e.target);
        });
        picker.show();
    }

    // 甲革素材選択クリック
    const handleUpperLeatherClick = (e) => {
        const picker = PickerDialog.createLeatherPicker({
            LeatherType: 'UPPER',
            LeatherCategory: state.fields.OrderCategory1
        });
        picker.setSelectedCallback((selected)=>{
            console.log('UpperLeather selected', selected);
            setFormState({
                fields: {
                    UpperLeatherId: selected.LeatherId,
                    UpperLeatherName: selected.LeatherName,
                }
            });
            stretch(e.target);
        });
        picker.show();
    }

    // 裏革素材選択クリック
    const handleLiningLeatherClick = (e) => {
        const picker = PickerDialog.createLeatherPicker({
            LeatherType: 'LINING'
        });
        picker.setSelectedCallback((selected)=>{
            console.log('LiningLeather selected', selected);
            setFormState({
                fields: {
                    LiningLeatherId: selected.LeatherId,
                    LiningLeatherName: selected.LeatherName,
                }
            });
            stretch(e.target);
        });
        picker.show();
    }

    const handleToggleClick = (e) => {
        let price = document.querySelector('#WholesalePrice');
        if (price.classList.contains('WholesalePriceHide')) {
            price.classList.remove('WholesalePriceHide');
            document.querySelector('#toggleButton').textContent = '非表示';
        } else {
            price.classList.add('WholesalePriceHide');
            document.querySelector('#toggleButton').textContent = '表示';
        }
    }

    useEffect(()=>{
        console.log('state.fields.OrderId', state.fields.OrderId);
        if (state.fields.OrderId) {
            // すでに登録済みの場合はクリアしない
            return;
        }
        // メーカーの変更を検知して入力内容クリア
        setFormState({
            fields: {
                ItemId: null,
                ItemName: null,
                ItemFileId: null,

                LastId: null,
                LastCode: null,
                LastName: null,
                LastFileId: null,

                OutsoleId: null,
                OutsoleName: null,
                OutsoleFileId: null,

                ColorCode1: null,
                ColorKey1: null,
                ColorName1: null,
                ColorPreview1: null,

                ColorCode2: null,
                ColorKey2: null,
                ColorName2: null,
                ColorPreview2: null,

                UpperLeatherId: null,
                UpperLeatherName: null,

                LiningLeatherId: null,
                LiningLeatherName: null,

                OrderInsole: null,
            }
        });
    }, [state.fields.SellerId, setFormState, state.fields.OrderId]);

    const handleNoteTemplateClick = (e) => {
        let note = '';
        note += '売価 ■■■ 円\n';
        note += '※仮留めにて対応します\n';
        note += '※ダイナイトＳ( ■■■ )\n';
        note += '※中敷 ■■■\n';
        note += 'ネーム:「 ■■■ 」\n';
        note += '◎木型補正:\n';
        note += '右Ｂ前ノセ ■■■ 枚 ■■■ mm、右Ｂ母趾側 ■■■ mm 小趾側 ■■■ mm、\n';
        note += '左Ｂ前ノセ ■■■ 枚 ■■■ mm、左Ｂ母趾側 ■■■ mm 小趾側 ■■■ mm、\n';
        note += '右Ｗノセ甲 ■■■ 枚 ■■■ mm、\n';
        note += '左Ｗノセ甲 ■■■ 枚 ■■■ mm\n';
        note += '盛ります。';
        setFormState({
            fields: {
                OrderNote: note
            }
        });
    }
    const handleNoteClearClick = (e) => {
        setFormState({
            fields: {
                OrderNote: ''
            }
        });
    }

    return (
        <>
        {readonly === false && (
            <details id="ShoesFrame" open>
                <summary>靴情報</summary>
                <div className="ShoesInfo">

                    <div style={{marginBottom:'1rem'}}>
                        <button type="button" onClick={handleDesignClick}>デザイン選択</button>
                        <button type="button" onClick={handleOutsoleClick} style={{marginLeft:'.5rem'}}>本底素材選択</button>
                    </div>

                    <div className="shoesBasic">
                        <div className="entity">
                            <div className="title">品番</div>
                            <div className="design">
                                <div className="spec Item">
                                    <div className="code">{state.fields.ItemId}</div>
                                    <div className="name">{state.fields.ItemName}</div>
                                </div>
                                <div className="pict">
                                    {state.fields.ItemFileId && (
                                        <img src={API.BASE_URL+'/data/file/upload/find?id='+ state.fields.ItemFileId} alt="品番画像" />
                                    )}
                                </div>
                            </div>
                            {state.errors?.ItemId &&
                                <span className="MessageTip error" style={{margin:'1rem'}}>{state.errors?.ItemId.Message}</span>
                            }
                        </div>
                        <div className="entity">
                            <div className="title">木型</div>
                            <div className="design">
                                <div className="spec Last">
                                    <div className="id">{state.fields.LastId}</div>
                                    <div className="code">{state.fields.LastCode}</div>
                                    <div className="name">{state.fields.LastName}</div>
                                </div>
                                <div className="pict">
                                    {state.fields.LastFileId && (
                                        <img src={API.BASE_URL+'/data/file/upload/find?id='+ state.fields.LastFileId} alt="木型画像" />
                                    )}
                                </div>
                            </div>
                            {state.errors?.LastId &&
                                <span className="MessageTip error" style={{margin:'1rem'}}>{state.errors?.LastId.Message}</span>
                            }
                        </div>
                        <div className="entity">
                            <div className="title">本底素材</div>
                            <div className="design">
                                <div className="spec Outsole">
                                    <div className="code">{state.fields.OutsoleId}</div>
                                    <div className="name">{state.fields.OutsoleName}</div>
                                </div>
                                <div className="pict">
                                    {state.fields.OutsoleFileId && (
                                        <img src={API.BASE_URL+'/data/file/upload/find?id='+ state.fields.OutsoleFileId} alt="本底素材画像" />
                                    )}
                                </div>
                            </div>
                            {state.errors?.OutsoleId &&
                                <span className="MessageTip error" style={{margin:'1rem'}}>{state.errors?.OutsoleId.Message}</span>
                            }
                        </div>
                    </div>

                    <div className="shoesOption">
                        <div className="row">
                            <div className="label">色コード</div>
                            <div className="flexrow">
                                <div>
                                    <input type="text" name="ColorCode1" placeholder="色コード1" readOnly={true}
                                        onClick={handleColorClick}
                                        value={([(state.fields.ColorCode1 || ''), (state.fields.ColorKey1 || ''), (state.fields.ColorName1 || '')].join(' '))}
                                        className={state.errors?.ColorCode1 && 'invalid'}
                                    />
                                    {state.errors?.ColorCode1 &&
                                        <span className="MessageTip error">{state.errors?.ColorCode1.Message}</span>
                                    }
                                </div>
                                <div className="byby"></div>
                                <div>
                                    <input type="text" name="ColorCode2" placeholder="色コード2" readOnly={true}
                                        onClick={handleColorClick}
                                        value={([(state.fields.ColorCode2 || ''), (state.fields.ColorKey2 || ''), (state.fields.ColorName2 || '')].join(' '))}
                                        className={state.errors?.ColorCode2 && 'invalid'}
                                    />
                                    {state.errors?.ColorCode2 &&
                                        <span className="MessageTip error">{state.errors?.ColorCode2.Message}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">甲革素材</div>
                            <div className="">
                                <input type="text" name="UpperLeatherId"
                                    placeholder="甲革素材を選択"
                                    readOnly={true}
                                    onClick={handleUpperLeatherClick}
                                    value={([(state.fields.UpperLeatherId || ''), (state.fields.UpperLeatherName || '')].join(' '))}
                                    className={state.errors?.UpperLeatherId && 'invalid'}
                                />
                                {state.errors?.UpperLeatherId &&
                                    <span className="MessageTip error">{state.errors?.UpperLeatherId.Message}</span>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">裏革素材</div>
                            <div className="">
                                <input type="text" name="LiningLeatherId"
                                    placeholder="裏革素材を選択"
                                    readOnly={true}
                                    onClick={handleLiningLeatherClick}
                                    value={([(state.fields.LiningLeatherId || ''), (state.fields.LiningLeatherName || '')].join(' '))}
                                    className={state.errors?.LiningLeatherId && 'invalid'}
                                />
                                {state.errors?.LiningLeatherId &&
                                    <span className="MessageTip error">{state.errors?.LiningLeatherId.Message}</span>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">中敷補正</div>
                            <div className="control">
                                <div className="radioGroup row">
                                    <Radio label="あり" name="OrderInsole" value="YES" onChange={handleChange}
                                        checked={state.fields?.OrderInsole === 'YES'}
                                    />
                                    <Radio label="なし" name="OrderInsole" value="NO" onChange={handleChange}
                                        checked={state.fields?.OrderInsole === 'NO'}
                                    />
                                </div>
                                {state.errors?.OrderInsole &&
                                    <span className="MessageTip error">{state.errors?.OrderInsole.Message}</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="shoesElse">
                        <div className="flexrow">
                            <div className="row">
                                <div className="label">売価</div>
                                <div className="control">
                                    <div className="flexrowcenter">
                                        <TypeNumber name="SellingPrice" min="0" step="1"
                                            value={state.fields?.SellingPrice || '0'}
                                            onChange={handleChange}
                                            className={state.errors?.SellingPrice && 'invalid'}
                                        />
                                        <span>円</span>
                                    </div>
                                    {state.errors?.SellingPrice &&
                                        <span className="MessageTip error">{state.errors?.SellingPrice.Message}</span>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div id="WholesalePrice" className="flexrow">
                                    <div className="label">原価</div>
                                    <div className="control">
                                        <TypeNumber name="WholesalePrice" min="0" step="1"
                                            value={state.fields?.WholesalePrice || '0'}
                                            onChange={handleChange}
                                            className={state.errors?.WholesalePrice && 'invalid'}
                                        />
                                        <span>円</span>
                                    </div>
                                </div>
                                <button type="button" id="toggleButton" onClick={handleToggleClick}>非表示</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">備考</div>
                            <div className="control">
                                <textarea name="OrderNote" cols="30" rows="5" style={{minHeight:'16rem'}}
                                    onChange={handleChange}
                                    value={state.fields?.OrderNote || ''}
                                ></textarea>
                                <div style={{display:'flex'}}>
                                    <button type="button" onClick={handleNoteClearClick}>クリア</button>
                                    <div style={{marginLeft:'.25rem'}}>
                                        <button type="button" onClick={handleNoteTemplateClick}>備考テンプレート</button>
                                        <em style={{fontSize:'.75rem'}}>（■■■を変更すると備考を容易に作成出来ます。）</em>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </details>
        )}
        {readonly && (
            <details id="ShoesFrame" open>
                <summary>靴情報</summary>
                <div className="ShoesInfoReadonly">

                    <div className="shoesBasic">
                        <div className="entity">
                            <div className="title">品番</div>
                            <div className="design">
                                <div className="spec Item">
                                    <div className="code">{state.fields.ItemId}</div>
                                    <div className="name">{state.fields.ItemName}</div>
                                </div>
                                <div className="pict">
                                    {state.fields.ItemFileId && (
                                        <img src={API.BASE_URL+'/data/file/upload/find?id='+ state.fields.ItemFileId} alt="品番画像"/>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="entity">

                            <div className="title">木型</div>
                            <div className="design">
                                <div className="spec Last">
                                    <div className="id">{state.fields.LastId}</div>
                                    <div className="code">{state.fields.LastCode}</div>
                                    <div className="name">{state.fields.LastName}</div>
                                </div>
                                <div className="pict">
                                    {state.fields.LastFileId && (
                                        <img src={API.BASE_URL+'/data/file/upload/find?id='+ state.fields.LastFileId} alt="木型画像"/>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="entity">
                            <div className="title">本底素材</div>
                            <div className="design">
                                <div className="spec Outsole">
                                    <div className="code">{state.fields.OutsoleId}</div>
                                    <div className="name">{state.fields.OutsoleName}</div>
                                </div>
                                <div className="pict">
                                    {state.fields.OutsoleFileId && (
                                        <img src={API.BASE_URL+'/data/file/upload/find?id='+ state.fields.OutsoleFileId} alt="本底画像"/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shoesOption">
                        <div className="row">
                            <div className="title">色コード</div>
                            <div className="value flexrowcenter">
                                <div className="flexrowcenter">
                                    <span className="preview" style={{backgroundColor: state.fields.ColorPreview1 }}></span>
                                    <span>{([(state.fields.ColorCode1 || ''), (state.fields.ColorKey1 || ''), (state.fields.ColorName1 || '')].join(' '))}</span>
                                </div>
                                <div className="byby"></div>
                                <div className="flexrowcenter">
                                    <span className="preview" style={{backgroundColor: state.fields.ColorPreview2 }}></span>
                                    <span>{([(state.fields.ColorCode2 || ''), (state.fields.ColorKey2 || ''), (state.fields.ColorName2 || '')].join(' '))}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flexrow">
                            <div className="row">
                                <div className="title">甲革素材</div>
                                <div className="value">
                                    <div>{([(state.fields.UpperLeatherId || ''), (state.fields.UpperLeatherName || '')].join(' '))}</div>
                                </div>
                            </div>
                            <div className="row" style={{marginLeft:'1rem'}}>
                                <div className="title">裏革素材</div>
                                <div className="value">
                                    <div>{([(state.fields.LiningLeatherId || ''), (state.fields.LiningLeatherName || '')].join(' '))}</div>
                                </div>
                            </div>
                            <div className="row" style={{marginLeft:'1rem'}}>
                                <div className="title">中敷補正</div>
                                <div className="value">
                                    <div>{(state.fields?.OrderInsole && state.fields?.OrderInsole[0]) === "1" ? 'あり' : 'なし'}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shoesElse">
                        <div className="flexrow">

                            <div className="row">
                                <div className="title">売価</div>
                                <div className="value">
                                    <div>{state.fields?.SellingPrice || ''}<span>円</span></div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="title">原価</div>
                                <div className="value">
                                    <div>{state.fields?.WholesalePrice || ''}<span>円</span></div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="title">担当者</div>
                                <div className="value">
                                    <div>{state.fields?.CreateUserName || ''}</div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="title">備考</div>
                            <div className="control">
                                <p>{state.fields?.OrderNote || ''}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </details>
        )}
        </>
    )
}
