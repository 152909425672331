import React, { useState, useEffect } from 'react';
import { useAuthUser } from 'components/Authenticator';
import { useHistory } from 'react-router-dom';

import Radio from 'components/Radio';
import LinkButton from 'components/LinkButton';
import CustomerAndOrderPickerDialog from 'components/Dialog/CustomerAndOrderPickerDialog';

import OrderCustomerFrame from './OrderCustomerFrame';
import OrderFittingFrame from './OrderFittingFrame';

import { ORDER_CATEGORY1, getCategoryName } from 'components/Resource';

export default function OrderInputStep1(props) {

    const state = props.state;
    const setFormState = props.setFormState;
    const setErrors = props.setErrors;
    const handleChange = props.handleChange;
    const setStep = props.setStep;

    const session = useAuthUser();
    const history = useHistory();
    const [enableCategory, setEnableCategory] = useState([]);

    useEffect(() => {

        let cateMap = new Map(); // 同じキーを一意にしたいのでmapを使う

        let buyers = session.authUser?.UserBuyerList || [];
        for (let i = 0; i < buyers.length; i++) {
            let cates = buyers[i].BuyerCategory.split(',');
            for (let j = 0; j < cates.length; j++) {
                cateMap.set(cates[j], '');
            }
        }
        if (cateMap.size === 0) {
            // 無い人はダメ！でも...管理者だけは全部いいよ
            if (session.authUser?.UserLevel === 'ADMINISTRATOR') {
                for (let i = 0; i < ORDER_CATEGORY1.length; i++) {
                    cateMap.set(ORDER_CATEGORY1[i].key, ORDER_CATEGORY1[i].value);
                }
            } else {
                alert('ユーザーに関連する発注者情報および発注者カテゴリが無いため、発注データを作成することはできません。');
                history.push('/business/order/list');
            }
        }

        let cateArray = Array.from(cateMap.keys());
        console.log(cateArray);
        setEnableCategory(cateArray);

        // 初期値セット
        setFormState({
            fields: {
                OrderCategory1: cateArray[0],
                OrderCategory2: 'LEATHER_SHOES',
            }
        });
    }, [setFormState, history, session.authUser]);

    const handleCustomerClick = e => {
        const picker = new CustomerAndOrderPickerDialog();
        picker.setSelectedCallback((selected)=>{
            console.log(selected);
            setFormState({
                fields: {
                    ...selected,
                    UserLeftLength: selected.LeftLength,
                    UserLeftMaxWidth: selected.LeftMaxWidth,
                    UserLeftMinWidth: selected.LeftMinWidth,
                    UserLeftMaxInstep: selected.LeftMaxInstep,
                    UserLeftMinInstep: selected.LeftMinInstep,
                    UserRightLength: selected.RightLength,
                    UserRightMaxWidth: selected.RightMaxWidth,
                    UserRightMinWidth: selected.RightMinWidth,
                    UserRightMaxInstep: selected.RightMaxInstep,
                    UserRightMinInstep: selected.RightMinInstep,
                    // 発注日は受け継がない
                    OrderDate: null,
                }
            });
            // // detailsはopen状態にする
            // let details = document.querySelectorAll('.CustomerSelectPreview details');
            // for (let i = 0; i < details.length; i++) {
            //     details[i].open = true;
            // }
        });
        picker.show();
    }

    const validation = () => {
        // let fields = state.fields;
        let errors = {};

        setErrors(errors);
        return Object.keys(errors).length;
    }

    const handleStepSubmit = () => {
        // 最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');
        // OKなら入力画面を表示
        if (state.fields.OrderId) {
            // 発注番号あれば追加発注。step2,3,4は省略
            setStep(5);
        } else {
            setStep(2);
        }
    }

    const handleClearClick = () => {
        let newFields = {...state.fields};

        // 顧客情報
        newFields['CustomerFirstName'] = ''; // お名前(姓)
        newFields['CustomerLastName'] = ''; // お名前(名)
        newFields['CustomerFirstRuby'] = ''; // フリガナ(姓)
        newFields['CustomerLastRuby'] = ''; // フリガナ(名)
        newFields['CustomerBirthday'] = ''; // 生年月日
        newFields['CustomerHeight'] = ''; // 身長
        newFields['CustomerWeight'] = ''; // 体重
        newFields['CustomerPostalCode'] = ''; // 郵便番号
        newFields['CustomerAddress1'] = ''; // 住所1
        newFields['CustomerAddress2'] = ''; // 住所2
        newFields['CustomerOffice'] = ''; // 勤務先名/学校名
        newFields['CustomerTel'] = ''; // 電話番号
        newFields['CustomerMail'] = ''; // メールアドレス

        // 足型測定情報
        newFields['LeftJudgeLast'] = ''; // ラスト(左)
        newFields['LeftJudgeLength'] = 0; // 足長(左)
        newFields['LeftJudgeWidth'] = 0; // 足囲(左)
        newFields['LeftJudgeInstep'] = 0; // 甲まわり(左)
        newFields['LeftJudge'] = 0; // 判定(左)
        newFields['LeftJudgeNote'] = 0; // 判定備考(左)
        newFields['RightJudgeLast'] = ''; // ラスト(右)
        newFields['RightJudgeLength'] = 0; // 足長(右)
        newFields['RightJudgeWidth'] = 0; // 足囲(右)
        newFields['RightJudgeInstep'] = 0; // 甲まわり(右)
        newFields['RightJudge'] = 0; // 判定(右)
        newFields['RightJudgeNote'] = 0; // 判定備考(右)

        setFormState({
            fields: newFields
        });

        // // detailsはclose状態にする
        // let details = document.querySelectorAll('.CustomerSelectPreview details');
        // for (let i = 0; i < details.length; i++) {
        //     details[i].open = false;
        // }
    }

    // categoryをクリックしたとき
    // radioボタンのクリックと同じだけど、枠をクリックしたら同じ動作をしたい
    const categoryClick = (category) => {
        setFormState({
            fields: {
                OrderCategory1: category
            }
        });
    }

    return (
        <>
            <div id="OrderSheet">
                <h1><span className="OrderTitle">ORDER SHEET</span></h1>
                <div id="OrderSheetLayout">
                    <div className="Premise">
                        <h4>カテゴリの選択</h4>
                        <p>以下のいずれかのオプションを選択して、ORDER SHEET を作成します。</p>

                        <div id="CategoryOption1">
{enableCategory.map((cate)=>{
    let categoryLabel = getCategoryName(cate);
    return (
        <div key={`cate${cate}`}
            className={state.fields?.OrderCategory1 === cate ? 'category active' : 'category'}
            onClick={e=>categoryClick(cate)}>
            <Radio label={categoryLabel} name="OrderCategory1" value={cate} onChange={handleChange}
                checked={state.fields?.OrderCategory1}
            />
            <p>{categoryLabel}用の ORDER SHEET を作成します。</p>
        </div>
    );
})}
                        </div>

                        <p>次に靴の種類を選択します。</p>
                        <div id="CategoryOption2">
                            <Radio label="革靴" name="OrderCategory2" value="LEATHER_SHOES" onChange={handleChange}
                                checked={state.fields?.OrderCategory2}
                            />
                            <Radio label="スニーカー" name="OrderCategory2" value="SNEAKERS" onChange={handleChange}
                                checked={state.fields?.OrderCategory2}
                            />
                            <Radio label="ゴルフシューズ" name="OrderCategory2" value="GOLF_SHOES" onChange={handleChange}
                                checked={state.fields?.OrderCategory2}
                            />
                        </div>
                    </div>

                    <div className="Premise">
                        <h4>過去の注文履歴から顧客データおよび足型測定データを選択</h4>
                        <p>追加注文の場合は顧客データ選択ボタンをクリックして既存のお客様を選択するとステップ2,3,4を省略できます。</p>
                        <div id="RepeatCustomer">
                            <button type="button" onClick={handleCustomerClick}>顧客データ選択</button>
                            <button type="button" onClick={handleClearClick} style={{marginLeft:'1rem'}}>クリア</button>
                            <div className="CustomerSelectPreview">
                                <OrderCustomerFrame {...props} readonly={true} open={state.fields.OrderId ? true : false}/>
                                <OrderFittingFrame {...props} readonly={true} open={state.fields.OrderId ? true : false} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/business/order">キャンセル</LinkButton>
                <div className="grow1"></div>
                <button type="button" onClick={handleStepSubmit}>
                    <span>次のステップへ</span>
                </button>
            </div>
        </>
    );
}
