import React, { useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import 'css/CustomerInput.css';

import * as API from 'components/API';
import Calendar from 'components/Calendar';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

// import Radio from 'components/Radio';
import LinkButton from 'components/LinkButton';
import TypeNumber from 'components/TypeNumber';

function CustomerInput(props) {
	const form = useFormContext(FormContext);

    const state = form.state;
    const setFormState = form.setFormState;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        let result = await API.get('/table/customer/input?id='+param.id);
        if (result.Data) {
            if (mounted.current) {
                setFormState({
                    fields: result.Data
                });
            }
        }
    }, [param.id, setFormState]);

    const handleMainScroll = (e) => {
        const h1tag = document.querySelector('.InputContents h1');
        if (h1tag) {
            if (e.target.scrollTop > 0) {
                h1tag.classList.add('small');
            } else {
                h1tag.classList.remove('small');
            }
        }
    }

    useEffect(() => {
        mounted.current = true;

        const main = document.querySelector('#View main');
        main.addEventListener('scroll', handleMainScroll, false);

        if (param.id) {
            // 編集時はデータ取得
            findData();
        } else {
            // 新規登録時の初期値セット
            setFormState({
                fields: {
                    CustomerStatus: 'ENABLED'
                }
            });
        }
        return () => {
            mounted.current = false;
            main.removeEventListener('scroll', handleMainScroll, false);
        }
    }, [param.id, findData, setFormState]);

	const validation = () => {
        let fields = state.fields;
        let errors = {};

        // if (!fields.CustomerFirstName) {
        //     errors.CustomerFirstName = {
        //         Message: 'お名前(姓)は必須入力です。'
        //     }
        // }
        if (!fields.CustomerLastName && !fields.CustomerFirstName) {
            errors.CustomerLastName = {
                Message: 'お名前は必須入力です。'
            }
        }
        // if (!fields.CustomerFirstRuby) {
        //     errors.CustomerFirstRuby = {
        //         Message: 'フリガナ(姓)は必須入力です。'
        //     }
        // }
        // if (!fields.CustomerLastRuby) {
        //     errors.CustomerLastRuby = {
        //         Message: 'フリガナ(名)は必須入力です。'
        //     }
        // }
        // if (!fields.CustomerBirthday) {
        //     errors.CustomerBirthday = {
        //         Message: '生年月日は必須入力です。'
        //     }
        // }
        // if (!fields.CustomerHeight) {
        //     errors.CustomerHeight = {
        //         Message: '身長は必須入力です。'
        //     }
        // }
        // if (!fields.CustomerWeight) {
        //     errors.CustomerWeight = {
        //         Message: '体重は必須入力です。'
        //     }
        // }
        // if (!fields.CustomerPostalCode) {
        //     errors.CustomerPostalCode = {
        //         Message: '郵便番号は必須入力です。'
        //     }
        // }
        // if (!fields.CustomerAddress1) {
        //     errors.CustomerAddress1 = {
        //         Message: '住所は必須入力です。'
        //     }
        // }
        // if (!fields.CustomerTel) {
        //     errors.CustomerTel = {
        //         Message: '電話番号は必須入力です。'
        //     }
        // }
        // if (!fields.CustomerMail) {
        //     errors.CustomerMail = {
        //         Message: 'メールアドレスは必須入力です。'
        //     }
        // }

        setErrors(errors);
        return Object.keys(errors).length;
    }

	handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.postAsFormData('/table/customer/update', {
            'Id': param.id,
            'OrderId': state.fields?.OrderId || '',
            'CustomerFirstName': state.fields?.CustomerFirstName || '',
			'CustomerLastName': state.fields?.CustomerLastName || '',
			'CustomerFirstRuby': state.fields?.CustomerFirstRuby || '',
			'CustomerLastRuby': state.fields?.CustomerLastRuby || '',
			'CustomerBirthday': state.fields?.CustomerBirthday || '',
			'CustomerHeight': state.fields?.CustomerHeight || '',
			'CustomerWeight': state.fields?.CustomerWeight || '',
			'CustomerPostalCode': state.fields?.CustomerPostalCode || '',
			'CustomerAddress1': state.fields?.CustomerAddress1 || '',
			'CustomerAddress2': state.fields?.CustomerAddress2 || '',
			'CustomerOffice': state.fields?.CustomerOffice || '',
			'CustomerTel': state.fields?.CustomerTel || '',
            'CustomerMail': state.fields?.CustomerMail || '',
            'CustomerNote': state.fields?.CustomerNote || '',
            'UpdateDatetime': state.fields?.UpdateDatetime || '', // 排他チェック
        });
        if (result?.Result) {
            // [OK] 一覧画面に遷移する
            history.push('/business/customer/list');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result.Errors);
        }
    });

	const calculateAge = (CustomerBirthday) => {
        if (CustomerBirthday) {
            // Dateインスタンスに変換
            const inputDate = new Date(CustomerBirthday);
            if (Number.isNaN(inputDate.getTime())) {
                return;
            }
            // 文字列に分解
            const birth = new Calendar(inputDate);
            const y2 = birth.format('Y');
            const m2 = birth.format('m');
            const d2 = birth.format('d');
            // 今日の日付
            const today = new Calendar();
            const y1 = today.format('Y');
            const m1 = today.format('m');
            const d1 = today.format('d');
            return Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
        }
        return '-';
    }

    const handlePostalCodeInput = async e => {
        let result = await API.searchZip(e.target.value);
        if (result) {
            let fullAddress = result.Address1 + result.Address2 + result.Address3;
            setFormState({
                fields: {
                    CustomerPostalCode: result.Zipcode.slice(0,3) + '-' + result.Zipcode.slice(3,7),
                    CustomerAddress1: fullAddress
                }
            });
        }
    }

    return (
        <div id="CustomerInput" className="InputContents">
            <h1>
                <Link to="/business/customer/list">
                    <svg xmlns="http://www.w3.org/2000/svg"><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
                </Link>
                <span>顧客データ</span>
                <div className="RequireHint"><span className="RequireMark">✱</span>必須入力</div>
            </h1>
            {state.errors?.Page?.map(message => (
                <div className="message error">{message.Message}</div>
            ))}
            <div className="rowGroup">
{param.id && (
                <div className="row">
                    <div className="label">発注番号</div>
                    <div className="control flexrow">
                        <div>
                            <div>{state.fields?.OrderId}</div>
                            {state.errors?.OrderId &&
                                <span className="messageTip error">{state.errors?.OrderId.Message}</span>
                            }
                        </div>
                    </div>
                </div>
)}
                <div className="row">
                    <div className="label require">お名前</div>
                    <div className="control flexrow">
                        <div>
                            <input type="text" id="CustomerLastName" name="CustomerLastName"
                                placeholder="姓"
                                value={state.fields?.CustomerLastName || ''}
                                onChange={handleChange}
                            />
                            {state.errors?.CustomerLastName &&
                                <span className="messageTip error">{state.errors?.CustomerLastName.Message}</span>
                            }
                        </div>
                        <div style={{marginLeft:'.5rem',maxWidth:'12.5rem'}}>
                            <input type="text" id="CustomerFirstName" name="CustomerFirstName"
                                placeholder="名"
                                value={state.fields?.CustomerFirstName || ''}
                                onChange={handleChange}
                            />
                            {state.errors?.CustomerFirstName &&
                                <span className="messageTip error">{state.errors?.CustomerFirstName.Message}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="label">フリガナ</div>
                    <div className="control flexrow">
                        <div>
                            <input type="text" id="CustomerLastRuby" name="CustomerLastRuby"
                                placeholder="フリガナ(姓)"
                                value={state.fields?.CustomerLastRuby || ''}
                                onChange={handleChange}
                            />
                            {state.errors?.CustomerLastRuby &&
                                <span className="messageTip error">{state.errors?.CustomerLastRuby.Message}</span>
                            }
                        </div>
                        <div style={{marginLeft:'.5rem'}}>
                            <input type="text" id="CustomerFirstRuby" name="CustomerFirstRuby"
                                placeholder="フリガナ(名)"
                                value={state.fields?.CustomerFirstRuby || ''}
                                onChange={handleChange}
                            />
                            {state.errors?.CustomerFirstRuby &&
                                <span className="messageTip error">{state.errors?.CustomerFirstRuby.Message}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="label">生年月日</div>
                    <div className="control">
                        <div>
                            <input type="date" id="CustomerBirthday" name="CustomerBirthday"
                                pattern="^([12][0-9]{3})[-|/](0?[1-9]|1[0-2])[-|/](3[01]|[12][0-9]|0?[1-9])$"
                                value={state.fields?.CustomerBirthday || ''}
                                onChange={handleChange}
                            />
                            {state.errors?.CustomerBirthday &&
                                <span className="messageTip error">{state.errors?.CustomerBirthday.Message}</span>
                            }
                        </div>
                    </div>
                    <div className="label" style={{marginLeft:'1.375rem',minWidth:'0'}}>年齢</div>
                    <div className="control">
                        <>{calculateAge(state.fields?.CustomerBirthday)}</>
                        <span style={{marginLeft:'.5rem'}}>歳</span>
                    </div>
                </div>
                <div className="row">
                    <div className="label">身長</div>
                    <div className="control">
                        <div>
                            <TypeNumber id="CustomerHeight" name="CustomerHeight" min="0" max="300"
                                value={state.fields?.CustomerHeight || ''}
                                onChange={handleChange}
                            />
                            <span style={{marginLeft:'.5rem'}}>cm</span>
                            {state.errors?.CustomerHeight &&
                                <span className="messageTip error">{state.errors?.CustomerHeight.Message}</span>
                            }
                        </div>
                    </div>
                    <div className="label" style={{marginLeft:'1.375rem',minWidth:'0'}}>体重</div>
                    <div className="control">
                        <TypeNumber id="CustomerWeight" name="CustomerWeight" min="0" max="200"
                            value={state.fields?.CustomerWeight || ''}
                            onChange={handleChange}
                        />
                        <span style={{marginLeft:'.5rem'}}>kg</span>
                        {state.errors?.CustomerWeight &&
                            <span className="messageTip error">{state.errors?.CustomerWeight.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">郵便番号</div>
                    <div className="control" style={{position:'relative'}}>
                        <span className="zipmark">〒</span>
                        <input type="tel" id="CustomerPostalCode" name="CustomerPostalCode"
                            placeholder="000-0000"
                            value={state.fields?.CustomerPostalCode || ''}
                            onChange={handleChange}
                            onInput={handlePostalCodeInput}
                        />
                        {state.errors?.CustomerPostalCode &&
                            <span className="messageTip error">{state.errors?.CustomerPostalCode.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">住所</div>
                    <div className="control">
                        <input type="text" id="CustomerAddress1" name="CustomerAddress1"
                            placeholder="都道府県市区町村"
                            value={state.fields?.CustomerAddress1 || ''}
                            onChange={handleChange}
                            style={{width: '28rem'}}
                        />
                        {state.errors?.CustomerAddress1 &&
                            <span className="messageTip error">{state.errors?.CustomerAddress1.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label"></div>
                    <div className="control">
                        <input type="text" id="CustomerAddress2" name="CustomerAddress2"
                            placeholder="番地ビル・マンション名等"
                            value={state.fields?.CustomerAddress2 || ''}
                            onChange={handleChange}
                            style={{width: '28rem'}}
                        />
                        {state.errors?.CustomerAddress2 &&
                            <span className="messageTip error">{state.errors?.CustomerAddress2.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">勤務先名/学校名</div>
                    <div className="control">
                        <input type="text" id="CustomerOffice" name="CustomerOffice"
                            placeholder="勤務先名/学校名"
                            value={state.fields?.CustomerOffice || ''}
                            onChange={handleChange}
                            style={{width: '28rem'}}
                        />
                        {state.errors?.CustomerOffice &&
                            <span className="messageTip error">{state.errors?.CustomerOffice.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup column">
                <div className="row">
                    <div className="label">電話番号</div>
                    <div className="control">
                        <input type="tel" id="CustomerTel" name="CustomerTel"
                            placeholder="例) 090-XXXX-XXXX"
                            value={state.fields?.CustomerTel || ''}
                            onChange={handleChange}
                        />
                        {state.errors?.CustomerTel &&
                            <span className="messageTip error">{state.errors?.CustomerTel.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">Eメール</div>
                    <div className="control">
                        <input type="text" id="CustomerMail" name="CustomerMail"
                            placeholder="例) customer@email.com"
                            value={state.fields?.CustomerMail || ''}
                            onChange={handleChange}
                            style={{width: '28rem'}}
                        />
                        {state.errors?.CustomerMail &&
                            <span className="messageTip error">{state.errors?.CustomerMail.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">備考</div>
                    <div className="control">
                        <textarea id="CustomerNote" name="CustomerNote" cols="70" rows="5"
                            onChange={handleChange}
                            value={state.fields?.CustomerNote || ''} >
                        </textarea>
                        {state.errors?.CustomerNote &&
                            <span className="messageTip error">{state.errors?.CustomerNote.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/business/customer/list">キャンセル</LinkButton>
                <button type="submit" className="PrimaryButton">顧客を登録</button>
            </div>
        </div>
    );
}
export default withFormField(CustomerInput);
