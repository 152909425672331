import { useState } from 'react';

// webStorageに保存する認証情報のキー
export const STORAGE_AUTH_KEY = window.location.host + '.authkey';


// 認証情報をlocalStorageに保管するー
// localStorageはクライアントのPCに保存しちゃうよー
// つまり、サインインしたままにする的なことができるよー
// sessionStorageはブラウザ閉じると消えるよー
export function useAuthUser() {

    const getAuthUser = () => {

        let tokenString = localStorage.getItem(STORAGE_AUTH_KEY);
        // console.log('tokenString from localStorage', tokenString);
        if (!tokenString) {
            // localStorageに無ければsessionStorageから
            tokenString = sessionStorage.getItem(STORAGE_AUTH_KEY);
            // console.log('tokenString from sessionStorage', tokenString);
        }
        if (tokenString) {
            return JSON.parse(tokenString);
        }
        // console.log('getAuthUser', tokenString);
        return null;
    };

    const [authUser, setAuthUser] = useState(getAuthUser());

    const saveAuthUser = param => {
        const tokenString = param?.authUser ? JSON.stringify(param.authUser) : null;
        if (tokenString) {
            if (param?.keepLogin) {
                // console.log('tokenString from sessionStorage', param);
                // ログインしたままにする場合にlocalStorageへ
                localStorage.setItem(STORAGE_AUTH_KEY, tokenString);
            } else {
                // それ以外はsessionStorageへ
                // console.log('tokenString from sessionStorage', param);
                sessionStorage.setItem(STORAGE_AUTH_KEY, tokenString);
            }
        } else {
            // tokenStringがnullの場合はwebStorageからも削除(ログアウト)
            localStorage.removeItem(STORAGE_AUTH_KEY, null);
            sessionStorage.removeItem(STORAGE_AUTH_KEY, null);
        }
        // console.log('setAuthUser', tokenString);
        setAuthUser(JSON.parse(tokenString));
    };

    // useEffect(() => {
    //     if (authUser) {
    //         const timeoutFunction = () => {
    //             alert('session timeout 1 min! bye bye!');
    //             saveAuthUser(null);
    //             sessionStorage.setItem('session.timer', null);
    //             window.location.reload();
    //         };
    //         let timer = sessionStorage.getItem('session.timer');
    //         if (timer) {
    //             clearTimeout(timer);
    //             timer = null;
    //         }
    //         timer = setTimeout(timeoutFunction, (60 * 1) * 1000); // 30分
    //         sessionStorage.setItem('session.timer', timer);
    //         console.log('timeout timer start!');
    //     }
    // }, [authUser]);

    return {
        authUser,
        setAuthUser: saveAuthUser
    };
}
