import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';

import 'css/Site.css';
import 'css/Input.css';
import 'css/Button.css';
import 'css/Effect.css';
import 'css/Loader.css';
import LogoImage from 'image/logo.png';

import TopPage from 'pages/TopPage/TopPage';
import SpecifiedCommercial from "pages/SpecifiedCommercial/SpecifiedCommercial";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import CompanyOverview from "pages/CompanyOverview/CompanyOverview";
import ContactUs from "pages/ContactUs/ContactUs";


export default function Site() {

    return (
        <div id="Site">
            <header>
                <div id="SiteLogo">
                    <Link to="/"><img src={LogoImage} alt="upMOST"/></Link>
                </div>
                <div className="grow1">
                    <span id="SiteDescription">靴 カスタムメイド・オーダーシューズの企画・製造・販売</span>
                </div>
                <div id="SiteContact">
                    <Link to="contactus">お問い合わせ</Link>
                </div>
            </header>
            <main>
                <Switch>
                    <Route exact path="/" component={TopPage} />
                    <Route exact path="/specifiedcommercial" component={SpecifiedCommercial} />
                    <Route exact path="/privacypolicy" component={PrivacyPolicy} />
                    <Route exact path="/companyoverview" component={CompanyOverview} />
                    <Route exact path="/contactus" component={ContactUs} />

                    <Redirect path="*" to="/" />
                </Switch>
            </main>
            <footer>
                <div className="linkContentWrapper">
                    <Link to="/companyoverview" className="linkContent">
                        <div className="en">Company Overview</div>
                        <div className="jp">会社概要</div>
                    </Link>
                    <Link to="/privacypolicy" className="linkContent">
                        <div className="en">Privacy Policy</div>
                        <div className="jp">プライバシーポリシー</div>
                    </Link>
                    <Link to="/specifiedcommercial" className="linkContent">
                        <div className="en">Specified Commercial Transaction</div>
                        <div className="jp">特定商取引に基づく表示</div>
                    </Link>
                </div>
                <address>Copyright (C) upMOST 2021 All Rights Reserved.</address>
            </footer>
        </div>
    );
}
