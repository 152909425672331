import React, { useState, useRef } from 'react';


function PaginationSelector(props) {

    const pageInputRef = useRef(null);

    const handlePageKeyUp = (e) => {
        if (e.key === 'Escape') {
            this.hide();
        } else if (e.key === 'Enter') {
            let number;
            try {
                number = parseInt(e.target.value);
                if (!number) {
                    return;
                }
            } catch (error) {
                return;
            }
            let page = Math.min(Math.max((number-1), 0), parseInt(props.maxPage)-1);
            props.callback(page);
            props.close(false);
        }
    }
    const handleInputClick = e => {
        e.stopPropagation();
        e.target.select();
    }
    const handleOKClick = e => {
        e.stopPropagation();
        let number = pageInputRef.current.value;
        let page = Math.min(Math.max((number-1), 0), parseInt(props.maxPage)-1);
        props.callback(page);
        props.close(false);
    }
    const handleCloseClick = (e) => {
        e.stopPropagation();
        props.close(false);
    }

    return (
        <div id="PaginationSelector" onClick={e => e.stopPropagation()}>
            <h5>ページ番号を指定してください</h5>
            <p className="note">1～{props.maxPage}ページまでの番号を入力して<br />Enterキーを押すか、OKボタンをクリックします。</p>
            <div className="wp">
                <input type="number"
                    min={1}
                    max={props.maxPage}
                    defaultValue={(props.currentPage + 1)}
                    ref={pageInputRef}
                    autoFocus={true}
                    onClick={handleInputClick}
                    onKeyUp={handlePageKeyUp}
                />
                <button type="button" onClick={handleOKClick}>OK</button>
            </div>
            <div className="closer" onClick={handleCloseClick}>✖</div>
        </div>
    );
}

export default function Pagination(props) {

    const [showPaginationSelector, setShowPaginationSelector] = useState(false);

    const listCount = parseInt(props.listCount);
    const totalCount = (props.totalCount) ? parseInt(props.totalCount) : 0;
    const offset = parseInt(props.state.offset);
    const limit = parseInt(props.state.limit);

    // 前のページへ
    let prevOffset = offset === 0 ? 0 : (offset - limit);
    // 全部で何ページ必要か
    let maxPage = parseInt(Math.ceil(totalCount / limit));
    // 現在のページ番号は
    let currentPage = parseInt(offset / limit);

    // 次のページへ
    let nextOffset = 0;
    if (listCount === 0) {
        // 取得件数が0件のものは次ページなんぞない
        nextOffset = offset;
    } else {
        // すでに最大ページまでと到達している場合はoffsetを更新しない
        if (currentPage + 1 === maxPage) {
            nextOffset = offset;
        } else {
            nextOffset = offset + limit;
        }
    }
    // 最後のページへ
    let lastPageOffset = (maxPage-1) * limit;

    const handleFirstPageClick = (e) => {
        e.stopPropagation();
        props.setState({ offset: 0 });

    }
    const handlePrevPageClick = (e) => {
        e.stopPropagation();
        props.setState({ offset: prevOffset });
    }
    const handleTotalizerClick = (e) => {
        e.stopPropagation();
        setShowPaginationSelector(true);
    }
    const handleNextPageClick = (e) => {
        e.stopPropagation();
        props.setState({ offset: nextOffset });
    }
    const handleLastPageClick = (e) => {
        e.stopPropagation();
        props.setState({ offset: lastPageOffset });
    }

    const handleSelectorCallback = (specifiedPage) => {
        let newOffset = props.state.limit * specifiedPage;
        props.setState({ offset: newOffset });
    }

    let showCurrentPage = 0;
    if (totalCount > 0) {
        showCurrentPage = currentPage + 1;
    }

    return (
        <div id="Pagination">
            <div className="pageLink" title="最初のページへ" onClick={handleFirstPageClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path></svg>
            </div>
            <div className="pageLink" title="前のページへ" onClick={handlePrevPageClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
            </div>
            <div className="totalizer" title="" onClick={handleTotalizerClick}>
                {`${showCurrentPage} / ${maxPage}`}
                {showPaginationSelector && (
                    <PaginationSelector
                        maxPage={maxPage}
                        currentPage={currentPage}
                        callback={handleSelectorCallback}
                        close={setShowPaginationSelector}
                    />
                )}
            </div>
            <div className="pageLink" title="次のページへ" onClick={handleNextPageClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg>
            </div>
            <div className="pageLink" title="最後のページへ" onClick={handleLastPageClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path></svg>
            </div>
        </div>
    );
}
