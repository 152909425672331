import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
// import { useLocation } from 'react-router-dom';
import 'css/FittingList.css';

import * as API from 'components/API';
import * as ListColumn from 'components/ListColumn';
import Loader from 'components/Loader';
import OmniSearch from 'components/OmniSearch';
import Pagination from 'components/Pagination';
import ListSettingDialog from 'components/Dialog/ListSettingDialog';

import SortableHeader from 'components/SortableHeader';
import { FOOT_TYPE, FOOT_DISEASE, FAVORITE_SIZE, FOOT_LONGER, SOCKS_TYPE } from 'components/Resource';


let o = 0;
const DEFAULT_COLUMN_LIST = [
    { index: o++, key: 'Id',               visible: false, width: 100, search: false, type: '',              name: 'ID'},
    { index: o++, key: 'OrderId',          visible: true,  width: 140, search: true,  type: '',              name: '発注番号'},
    { index: o++, key: 'OrderCategory1',   visible: false, width: 140, search: true,  type: '',              name: '発注カテゴリ1'},

    { index: o++, key: 'LeftLength',       visible: true,  width: 140, search: true,  type: 'NumberFormula', name: '足長(左)'},
    { index: o++, key: 'LeftMaxWidth',     visible: true,  width: 140, search: true,  type: 'NumberFormula', name: '最大足囲(左)'},
    { index: o++, key: 'LeftMinWidth',     visible: true,  width: 140, search: true,  type: 'NumberFormula', name: '最小足囲(左)'},
    { index: o++, key: 'LeftMaxInstep',    visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '最大甲まわり(左)'},
    { index: o++, key: 'LeftMinInstep',    visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '最小甲まわり(左)'},
    { index: o++, key: 'LeftFootType',     visible: true,  width: 140, search: true,  type: '',              name: '足型(左)'},
    { index: o++, key: 'LeftFootDisease',  visible: true,  width: 140, search: true,  type: '',              name: '疾病(左)'},

    { index: o++, key: 'LeftDeformDegree',  visible: true,  width: 140, search: true,  type: 'NumberFormula',     name: '外反母趾角(左)'},
    { index: o++, key: 'LeftInnerDeformDegree',  visible: true,  width: 140, search: true,  type: 'NumberFormula', name: '内反小趾角(左)'},

    { index: o++, key: 'RightLength',      visible: true,  width: 140, search: true,  type: 'NumberFormula', name: '足長(右)'},
    { index: o++, key: 'RightMaxWidth',    visible: true,  width: 140, search: true,  type: 'NumberFormula', name: '最大足囲(右)'},
    { index: o++, key: 'RightMinWidth',    visible: true,  width: 140, search: true,  type: 'NumberFormula', name: '最小足囲(右)'},
    { index: o++, key: 'RightMaxInstep',   visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '最大甲まわり(右)'},
    { index: o++, key: 'RightMinInstep',   visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '最小甲まわり(右)'},
    { index: o++, key: 'RightFootType',    visible: true,  width: 160, search: true,  type: '',              name: '足型(右)'},
    { index: o++, key: 'RightFootDisease', visible: true,  width: 160, search: true,  type: '',              name: '疾病(右)'},

    { index: o++, key: 'RightDeformDegree',  visible: true,  width: 140, search: true,  type: 'NumberFormula',     name: '外反母趾角(右)'},
    { index: o++, key: 'RightInnerDeformDegree',  visible: true,  width: 140, search: true,  type: 'NumberFormula', name: '内反小趾角(右)'},

    { index: o++, key: 'FootSize',         visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '申告サイズ'},
    { index: o++, key: 'FootWidth',        visible: true,  width: 160, search: true,  type: '',              name: '足幅'},
    { index: o++, key: 'FootFavorite',     visible: true,  width: 160, search: true,  type: '',              name: 'サイズの好み'},
    { index: o++, key: 'FootLonger',       visible: true,  width: 160, search: true,  type: '',              name: '足長比較'},
    { index: o++, key: 'SocksType',        visible: true,  width: 160, search: true,  type: '',              name: 'ソックス'},
    { index: o++, key: 'HeelHeight',        visible: true,  width: 120, search: true,  type: '',              name: 'ヒール高'},

    { index: o++, key: 'LeftJudgeLast',    visible: true,  width: 140, search: true,  type: '',              name: '判定ラスト(左)'},
    { index: o++, key: 'LeftJudgeBall',    visible: true,  width: 120, search: true,  type: '',              name: '判定Ball(左)'},
    { index: o++, key: 'LeftJudgeWaist',    visible: true,  width: 120, search: true,  type: '',              name: '判定Waist(左)'},
    { index: o++, key: 'LeftJudgeLength',  visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '判定足長(左)'},
    { index: o++, key: 'LeftJudgeWidth',   visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '判定足囲(左)'},
    { index: o++, key: 'LeftJudgeInstep',  visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '判定甲まわり(左)'},
    { index: o++, key: 'LeftJudge',        visible: true,  width: 240, search: true,  type: '',              name: '判定(左)'},
    { index: o++, key: 'LeftJudgeNote',    visible: false, width: 500, search: true,  type: '',              name: '判定備考(左)'},
    { index: o++, key: 'LeftJudgeGrade',   visible: false, width: 500, search: true,  type: '',              name: '判定グレード(左)'},

    { index: o++, key: 'RightJudgeLast',   visible: true,  width: 140, search: true,  type: '',              name: '判定ラスト(右)'},
    { index: o++, key: 'RightJudgeBall',    visible: true,  width: 120, search: true,  type: '',              name: '判定Ball(右)'},
    { index: o++, key: 'RightJudgeWaist',    visible: true,  width: 120, search: true,  type: '',              name: '判定Waist(右)'},
    { index: o++, key: 'RightJudgeLength', visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '判定足長(右)'},
    { index: o++, key: 'RightJudgeWidth',  visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '判定足囲(右)'},
    { index: o++, key: 'RightJudgeInstep', visible: true,  width: 160, search: true,  type: 'NumberFormula', name: '判定甲まわり(右)'},
    { index: o++, key: 'RightJudge',       visible: true,  width: 240, search: true,  type: '',              name: '判定(右)'},
    { index: o++, key: 'RightJudgeNote',   visible: false, width: 500, search: true,  type: '',              name: '判定備考(右)'},
    { index: o++, key: 'RightJudgeGrade',  visible: false, width: 500, search: true,  type: '',              name: '判定グレード(右)'},

    { index: o++, key: 'LeftAdjustWidth',  visible: false, width: 140, search: true,  type: 'NumberFormula',  name: '足囲とラストの差(左)'},
    { index: o++, key: 'LeftAdjustInstep',  visible: false, width: 140, search: true,  type: 'NumberFormula', name: '甲まわりとラストの差(左)'},
    { index: o++, key: 'RightAdjustWidth',  visible: false, width: 140, search: true,  type: 'NumberFormula',  name: '足囲とラストの差(右)'},
    { index: o++, key: 'RightAdjustInstep',  visible: false, width: 140, search: true,  type: 'NumberFormula', name: '甲まわりとラストの差(右)'},

    { index: o++, key: 'LeftHeelWidth',  visible: false, width: 140, search: true,  type: 'NumberFormula', name: '踵幅(左)'},
    { index: o++, key: 'RightHeelWidth',  visible: false, width: 140, search: true,  type: 'NumberFormula',  name: '踵幅(右)'},

    { index: o++, key: 'FittingDatetime',  visible: true,  width: 160, search: true,  type: 'DateRange',     name: '測定日時'},
    { index: o++, key: 'FittingUser',      visible: true,  width: 160, search: true,  type: '',              name: '測定者'},
    // { index: o++, key: 'CreateUserId',     visible: true,  width: 160, search: true,  type: '',              name: '作成ユーザー'},
    // { index: o++, key: 'UpdateUserId',     visible: true,  width: 160, search: true,  type: '',              name: '更新ユーザー'},
    { index: o++, key: 'CreateDatetime',   visible: true,  width: 160, search: true,  type: 'DateRange',     name: '作成日時'},
    { index: o++, key: 'UpdateDatetime',   visible: true,  width: 160, search: true,  type: 'DateRange',     name: '更新日時'},
];

const DEFAULT_CONDITION = {
    filters: [],
    keywords: [],
    sort: {
        column: '',
        direction: 'none',
    },
    limit: 20,
    offset: 0,
    lastRefresh: null,
}
const INITIAL_STATE = {
    columns: DEFAULT_COLUMN_LIST,
    ...DEFAULT_CONDITION
}

function stateReducer(state, event) {
    return {...state, ...event};
}

function createParam(key, value) {
    return '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
}


function TableCell(props) {
    let row = props.row;
    let column = props.column;
    // ↓ここで、列ごとに個別対応が必要なものを書いていく
    let value = row[column.key];

    // 足型
    if (column.key === 'LeftFootType' || column.key === 'RightFootType') {
        let type = FOOT_TYPE.filter(f => f.key === value);
        if (type && type[0]) value = type[0]?.value;
        return (
            <td key={column.key}><div>{value}</div></td>
        );
    }
    // 疾病
    if (column.key === 'LeftFootDisease' || column.key === 'RightFootDisease') {
        return (
            <td key={column.key}>
                <ListColumn.MultiChoice value={value} list={FOOT_DISEASE} />
            </td>
        )
    }
    // 足幅はそのまま表示
    // サイズの好み
    if (column.key === 'FootFavorite') {
        return (
            <td key={column.key}>
                <ListColumn.Choice value={value} list={FAVORITE_SIZE} />
            </td>
        )
    }
    // 足長比較
    if (column.key === 'FootLonger') {
        let longer = FOOT_LONGER.filter(f => f.key === value);
        if (longer && longer[0]) value = longer[0]?.value;
        return (
            <td key={column.key}><div>{value}</div></td>
        );
    }
    // ソックス
    if (column.key === 'SocksType') {
        let socks = SOCKS_TYPE.filter(f => f.key === value);
        if (socks && socks[0]) value = socks[0]?.value;
        return (
            <td key={column.key}><div>{value}</div></td>
        );
    }

    return <td key={column.key}><div>{value}</div></td>;
}

export default function FittingList() {
    const COMPONENT_NAME = 'FittingList';

	// const routerHistory = useHistory();
    // const addItemClick = e => {
    //     routerHistory.push('/business/fitting/input');
    // }
    const settingClick = e => {
        let listSetting = new ListSettingDialog({
            form: COMPONENT_NAME,
            columns: state.columns,
            limit: state.limit,
            doAction: (params) => {
                // フィルターも並びもページングもリセットが必要
                if (params.reset) {
                    setState({ columns: [...DEFAULT_COLUMN_LIST] });
                } else {
                    setState({ ...DEFAULT_CONDITION, ...params });
                }
            }
        });
        listSetting.show();
    }

    const [listData, setListData] = useState({});
    const [listLoading, setListLoading] = useState(true);
    const [state, setState] = useReducer(stateReducer, {...INITIAL_STATE});
    const mounted = useRef(false);

    const refreshData = () => {
        setState({ lastRefresh: Date.now() });
    }

    // ユーザー固有の設定を反映させる
    useEffect(()=>{
        mounted.current = true;
        API.get('/data/user/find?DataKey=' + COMPONENT_NAME+'.ListSetting')
        .then(result => {
            if (result.Result) {
                if (mounted.current) {
                    try {
                        const userData = JSON.parse(result.Result.DataValue);
                        setState({ ...userData });
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        });
        return () => {
            mounted.current = false;
        }
    }, []);

    const getListData = useCallback(async ()=>{
        let url = '/data/fitting/list?';
        // filters
        for (let i = 0; i < state.filters.length; i++) {
            let f = state.filters[i];
            url += createParam('filterColumn[]', f.filterColumn);
            url += createParam('filterValue[]', f.filterValue);
            url += createParam('filterFormula[]', f.filterFormula);
        }
        // keywords
        for (let i = 0; i < state.keywords.length; i++) {
            let k = state.keywords[i];
            url += createParam('searchKeyword[]', k.searchKeyword);
        }
        // sorts
        if (state.sort.column) {
            url += createParam('sortColumnName', state.sort.column);
        }
        if (state.sort.direction) {
            url += createParam('sortDirection', state.sort.direction);
        }

        // limit,offset
        url += createParam('limit', state.limit);
        url += createParam('offset', state.offset);

        let data = await API.get(url);
        if (mounted.current) {
            setListData(data || {});
        }
        setListLoading(false);

    }, [state]);

    useEffect(() => {
        setListLoading(true);
        getListData();
    }, [
        state.filters, state.keywords, state.sort, state.limit, state.offset,
        state.columns, state.lastRefresh, getListData
    ]);

    const handleCSVDownload = async(e) => {
        window.location.href = API.BASE_URL+'/data/fitting/download';
        // alert('Yes! you can now CSV Downlooooooooooad!');
    }

	return (
        <>
            <h1>足型計測データ<span className="counter">({listData.Result?.TotalCount})</span></h1>
            <div id="FittingList" className="ListContents">
                <div id="SubHeader">
                    <OmniSearch
                        columns={state.columns}
                        state={state}
                        setState={setState}
                        filterUrl={`/data/fitting/filter`}
                    />
                    <div style={{marginRight:'1rem'}}>
                        <button type="button" className="PrimaryButton" onClick={handleCSVDownload}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"/></svg>
                            <span>CSV出力</span>
                        </button>
                    </div>
                    <button type="button" id="RefreshComponent" className="LinkButton IconButton" onClick={refreshData}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                    </button>
                    <button type="button" id="SettingComponent" className="LinkButton IconButton" onClick={settingClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/></svg>
                    </button>
                </div>
                <section>
                    <div className="TableWrapper">
                        <table>
                            <thead>
                                <tr>
                                    {/* <th className="EditLinkColumn"></th> */}
    {
    state.columns.map(column => {
        return (column.visible) ?
            <SortableHeader key={column.key}
                column={column}
                state={state}
                setState={setState}
            >
                <div>{column.name}</div>
            </SortableHeader>
        : null;
    })
    }
                                <th className="EndColumn"></th>
                                </tr>
                            </thead>
                            <tbody>
    {
    listData?.Result?.Data.map(row => (
        <tr key={row?.Id}>
            {/* <td className="EditLinkColumn">
                <div>
                    <Link to={`/business/fitting/input/${row?.Id}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
                    </Link>
                </div>
            </td> */}
            {
            state.columns.map(column => {
                return (column.visible) ?
                    <TableCell key={column.index} column={column} row={row} />
                : null;
            })
            }
            <td className="EndColumn"></td>
        </tr>
    ))
    }
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                        {listData.Result?.TotalCount === 0 && <div className="noDataLabel">該当するデータはありません。</div>}
                        {listLoading && <Loader /> }
                    </div>
                    <div id="SubFooter">
                        <Pagination
                            listCount={listData?.Result?.ListCount}
                            totalCount={listData?.Result?.TotalCount}
                            state={state}
                            setState={setState}
                        />
                    </div>
                </section>
            </div>
        </>
    );
}
