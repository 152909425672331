import React from 'react';


export default function TypeNumber(props) {

    const INTEGER_REGEX = new RegExp('[0-9]');

    let min = props?.min ? parseInt(props?.min) : null;
    // let max = props?.max ? parseInt(props?.max) : null;

    const handleKeyDown = (e) => {
        if (e.key) {
            if (!INTEGER_REGEX.test(e.key)) {
                switch (e.key.toLowerCase()) {
                    case '-':
                        if (min === null || min < 0) {
                            // minの指定が無い場合は、マイナスを入力できるとする
                            // 許可する
                        } else {
                            // 拒否
                            e.preventDefault();
                            e.stopPropagation(); // 透過させない
                            return false;
                        }
                        break;
                    case 'tab':
                    case 'home':
                    case 'end':
                    case 'delete':
                    case 'backspace':
                    case 'arrowup':
                    case 'arrowleft':
                    case 'arrowright':
                    case 'arrowdown':
                        // 許可する
                        break;
                    default:
                        // 拒否する
                        e.preventDefault();
                        e.stopPropagation(); // 透過させない
                        return false;
                }
            } else {
                // INTEGER_REGEXに一致する数値は許可！
                // console.log(e.key);
            }
        }
    };

    const handleKeyUp = (e) => {
        if (props.onKeyUp) {
            props.onKeyUp(e);
        }
    }

    const handleBlur = (e) => {
        if (props.onBlur) {
            props.onBlur(e);
        }
    }

    // フォーカスされたら選択状態
    const handleFocus = (e) => {
        e.target.select();
        if (props.onFocus) {
            props.onFocus(e);
        }
    }

    const handleInput = (e) => {
        // 強制数値化
        try {
            let v = parseFloat(e.target.value);
            if (isNaN(v)) v = 0;
            e.target.value = v;
        } catch(e){
            e.target.value = 0;
        }
    }

    return (
        <input type="number" {...props}
            step={props?.step}
            max={props?.max}
            min={props?.min}
            onClick={e=>e.target.select()}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onInput={handleInput}
            pattern="\d*"
        />
    );
}