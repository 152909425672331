
import * as DOM from "../DOM.js";
import Dialog from "./Dialog.js";

import 'css/ListSettingDialog.css';

import * as API from 'components/API';

export default class ListSettingDialog extends Dialog {

    constructor(params) {
        super(params);
        this.params = params;

        this.columns = params.columns;
        this.formName = params.form;
        this.container.id = 'ListSettingDialog';

        this.content = DOM.div([], 'content');
        this.content.innerHTML = '<div class="Loader Part"><div class="LoaderMark"></div></div>';

        // this.dialogHeader.innerHTML = '<h3>表示の設定</h3><p>一覧に表示する項目の並び順および、列幅、表示/非表示、表示件数を指定します。並び順は項目のつまみをドラッグして移動します。</p>';
        this.dialogHeader.innerHTML = '<h3>表示の設定</h3>';
        // this.okButton.textContent = '保存する';

        // 列表示非表示のcheckboxをｸﾘｸﾘしたときの処理
        const checkboxClickListener = (e)=> {
            e.stopPropagation();
            const li = e.target.parentElement.parentElement;
            const index = parseInt(li.dataset.index);
            const column = this.columns[index];
            column.visible = e.target.checked;

            if (column.visible) {
                li.classList.remove('unvisible');
            } else {
                li.classList.add('unvisible');
            }
        }

        // 幅ピクセルinput
        const restrictNumberFunction = (e)=>{
            const allow = ['0','1','2','3','4','5','6','7','8','9','Delete','Backspace','ArrowLeft','ArrowRight','ArrowDown','ArrowUp','Tab'];
            for (let i = 0; i < allow.length; i++) {
                if (e.key.indexOf(allow[i]) === 0) {
                    if (parseInt(e.target.value) > MAX_COLUMN_WIDTH) {
                        e.target.value = MAX_COLUMN_WIDTH;
                    }
                    return;
                }
            }
            e.preventDefault();
        };
        const MAX_COLUMN_WIDTH = 990;
        const acceptNumberFunction = (e)=> {
            if (e.target.value && !isNaN(e.target.value)) {
                let width = parseInt(e.target.value);
                if (width > MAX_COLUMN_WIDTH) {
                    width = MAX_COLUMN_WIDTH;
                    e.target.value = width;
                }
                const i = parseInt(e.target.parentElement.dataset.index);
                this.columns[i].width = width;
            }
        };
        const createWidthAdujster = (initvalue)=>{
            let wi = DOM.number(null, 'columnWidth', initvalue, 0, MAX_COLUMN_WIDTH, 5);
            wi.tabIndex = 0;
            wi.addEventListener('keydown', restrictNumberFunction, false);
            wi.addEventListener('keyup', acceptNumberFunction, false);
            wi.addEventListener('change', acceptNumberFunction, false);
            return wi;
        };

        let dragging = false;
        let dragEnable = false;
        const handlePointerDown = (e)=> {
            if (dragging) return;
            dragEnable = true;
        }
        const handlePointerUp = (e)=> {
            dragEnable = false;
        }
        let draggingElement = null;
        const handleDragStart = (e)=> {
            if (dragEnable === false) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            dragging = true;
            let li = e.target;
            li.classList.add('dragstart');
            this.columnList.classList.add('dragging');
            e.dataTransfer.effectAllowed = 'move';
            // e.dataTransfer.setData('text/html', li.innerHTML);
            draggingElement = li;
        }
        const handleDragEnter = (e)=> {
            if (e.target.tagName.toUpperCase() === 'LI') {
                // console.log('handleDragEnter', e.target.tagName);
                let li = e.target;
                li.classList.add('dragover');
            }
        }
        const handleDragOver = (e)=> {
            if (e.preventDefault) {
                e.preventDefault(); // Necessary. Allows us to drop.
            }
            if (e.target.tagName.toUpperCase() === 'LI') {
                // console.log('handleDragOver', e.target.tagName);
                e.dataTransfer.dropEffect = 'move';
            }
            return false;
        }
        const handleDragLeave = (e)=> {
            if (e.target.tagName.toUpperCase() === 'LI') {
                // console.log('handleDragLeave', e.target.tagName);
                let li = e.target;
                li.classList.remove('dragover');
            }
        }
        const handleDrop = (e)=> {
            if (e.stopPropagation) {
                e.stopPropagation(); // stops the browser from redirecting.
            }
            let li = e.target;
            // ドラッグターゲットが異なる場合のみ入れ替え
            if (draggingElement !== li) {
                // liを作り直してswap
                // console.log(`${draggingElement.dataset.index} から ${li.dataset.index} へ入れ替え`);

                let srcIndex = parseInt(draggingElement.dataset.index);
                let destIndex = parseInt(li.dataset.index);

                let columnA = this.columns[destIndex];
                let liA = createColumnSettingLi(columnA);
                liA.dataset.index = srcIndex;
                columnA.index = srcIndex;

                let columnB = this.columns[srcIndex];
                let liB = createColumnSettingLi(columnB);
                liB.dataset.index = destIndex;
                columnB.index = destIndex;

                this.columnList.replaceChild(liA, draggingElement);
                this.columnList.replaceChild(liB, li);

                // this.columnsを入れ替えておく
                this.columns[columnA.index] = columnA;
                this.columns[columnB.index] = columnB;
            }
            return false;
        }
        const handleDragEnd = (e)=> {
            // console.log('handleDragEnd');
            [].forEach.call(this.columnList.children, function (li) {
                li.classList.remove('dragover');
            });
            this.columnList.classList.remove('dragging');
            draggingElement.classList.remove('dragstart');
            dragging = false;
            dragEnable = false;
        }

        // 列設定liタグの作成
        const createColumnSettingLi = (columnSettingObject)=> {
            const span = DOM.span(columnSettingObject.name, 'grow1');
            const switcher = DOM.switcher('visible_'+columnSettingObject.key, columnSettingObject.visible, checkboxClickListener);
            const width = createWidthAdujster(columnSettingObject.width);

            const move = DOM.div([], 'dragicon');
            move.addEventListener('mousedown', handlePointerDown, false);
            move.addEventListener('mouseup', handlePointerUp, false);
            move.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>';

            const li = DOM.li([move, span, width, switcher]);
            li.draggable = true;
            li.addEventListener('dragstart', handleDragStart, false);
            li.addEventListener('dragenter', handleDragEnter, false);
            li.addEventListener('dragover', handleDragOver, false);
            li.addEventListener('dragleave', handleDragLeave, false);
            li.addEventListener('drop', handleDrop, false);
            li.addEventListener('dragend', handleDragEnd, false);

            if (!columnSettingObject.visible) {
                li.classList.add('unvisible');
            }
            return li;
        }

        const createContents = ()=>{
            this.form = document.createElement('form');
            this.form.name = 'dialogForm';

            const tabChangeFunction = (e)=>{
                let current = this.container.querySelector('.tabFolder .active');
                if (current) {
                    current.classList.remove('active');
                }
                current = this.container.querySelector('.tabContents .active');
                if (current) {
                    current.classList.remove('active');
                }
                e.target.classList.add('active');
                document.getElementById(e.target.dataset.content).classList.add('active');
            };

            let tab_columnSetting = DOM.div('表示列設定', 'tab active');
            tab_columnSetting.dataset.content = 'columnSettingContents';
            let tab_limitSetting = DOM.div('ページサイズ', 'tab');
            tab_limitSetting.dataset.content = 'limitSettingContents';
            let tab_clearSetting = DOM.div('リセット', 'tab');
            tab_clearSetting.dataset.content = 'clearSettingContents';

            tab_columnSetting.addEventListener('click', tabChangeFunction, false);
            tab_limitSetting.addEventListener('click', tabChangeFunction, false);
            tab_clearSetting.addEventListener('click', tabChangeFunction, false);
            const tabs = DOM.div([tab_columnSetting, tab_limitSetting, tab_clearSetting], 'tabFolder');

            // 列設定
            const listTitle = DOM.div([DOM.span('順', 'order'), DOM.span('項目', 'name'), DOM.span('幅', 'width'), DOM.span('表示', 'visible'),], 'listTitle');
            this.columnList = DOM.ul([], 'columnList grow1');
            const columnSettingContents = DOM.div([listTitle, this.columnList], 'tabContent active');
            columnSettingContents.id = 'columnSettingContents';

            // 表示件数の変更
            const limitTitle = DOM.div([
                DOM.p('一度に表示するデータ件数を 20 ～ 200 の間で 10件ごとに調節できます。この件数より多いデータは次のページとなります。')
            ], 'limitTitle');

            const onInputFunction = function(){
                output.value = this.value;
            }
            this.limitSlider = DOM.slider('limitInput', 20, 200, 10, this.params.limit, onInputFunction, null);
            const output = DOM.output(this.limitSlider.id, this.params.limit);
            let limitChanger = DOM.div([this.limitSlider, output], 'limitChanger');
            const limitSettingContents = DOM.div([limitTitle, limitChanger], 'tabContent');
            limitSettingContents.id = 'limitSettingContents';

            const clearTitle = DOM.div([
                DOM.p('表示設定を初期値に戻します。')
            ], 'clearTitle');

            const clearMessage = DOM.div([], 'clearMessage error');
            const clearButton = DOM.button('初期化する', '', ()=>{
                clearButton.disabled = true;
                clearMessage.textContent = '';

                // dataはFormDataとして送信する
                let formData = new FormData();
                formData.append('form', this.params.form);
                API.post('/column/delete', formData)
                .then((result)=>{
                    if (result.Result > 0) {
                        // OK
                        params.doAction({ reset: true });
                        this.hide();
                    } else {
                        // NG
                        clearMessage.textContent = '初期化に失敗しました。';
                        clearButton.disabled = false;
                    }
                })
                .catch((error)=>{
                    console.error(error);
                    clearMessage.textContent = '初期化に失敗しました。';
                    clearButton.disabled = false;
                })
                .finally(()=>{
                });
            });
            const clearSettingContents = DOM.div([
                clearTitle,
                DOM.div([clearButton], 'clearInner'),
                clearMessage
            ], 'tabContent');
            clearSettingContents.id = 'clearSettingContents';

            const tabContents = DOM.div([columnSettingContents, limitSettingContents, clearSettingContents], 'tabContents');
            this.form.appendChild(tabs);
            this.form.appendChild(tabContents);

            // fragmentで一括追加(こうするとDOMの更新が1回で済みより高速になる)
            const fragment = document.createDocumentFragment();
            for (let i = 0; i < this.columns.length; i++) {
                const columnSettingObject = this.columns[i];
                columnSettingObject.index = i;
                const li = createColumnSettingLi(columnSettingObject);
                li.dataset.index = i;
                fragment.appendChild(li);
            }
            this.columnList.appendChild(fragment);

            this.content.textContent = '';
            this.content.appendChild(this.form);
            this.centering();
        }

        this.beforeShow = createContents;

        super.doAction = ()=>{

            // dataはFormDataとして送信する
            let formData = new FormData();
            formData.append('form', this.params.form);
            formData.append('columns', JSON.stringify(this.columns));
            formData.append('limit', this.limitSlider.value);

            API.post('/column/update', formData)
            .then((result)=>{
                // udpateはaffectedが0かもしれん
                if (result.Result >= 0) {
                    params.doAction({
                        columns: this.columns,
                        limit: this.limitSlider.value
                    });
                    this.hide();
                } else {
                    console.error(result);
                    if (result.Error) {
                        alert(result.Error);
                    }
                }
            })
            .catch((error)=>{
                alert('保存に失敗しました');
                console.log(error);
            });
            return false; // not close
        }

        this.setContents(this.content);
    }

}

console.log('ListSettingDialog.js loaded.');
