import React from 'react';
import { Route } from 'react-router-dom';

import Login from 'pages/Login/Login';
import { useAuthUser } from 'components/Authenticator';

export default function PrivateRoute({ component: Component, ...rest }) {

    const { authUser, setAuthUser } = useAuthUser();

    return (
        <Route {...rest} render={props => (
                authUser
                ? <Component {...props} />
                : (
                <Login setAuthUser={setAuthUser}/>
                )
            )}
        />
    );
}
