import { PickerDialog, encode } from './PickerDialog';
import * as API from 'components/API';

import 'css/OutsolePickerDialog.css';

export default class OutsolePickerDialog extends PickerDialog {

    constructor(userparam) {

        let seller = userparam.SellerId || '';
        let sellerCategory = userparam.SellerCategory || '';

        let param = {
            title: '<h3>本底素材を選択</h3>',
            url: '/master/outsole/picker?SellerId='+seller+'&SellerCategory='+sellerCategory,
            // liのテキストを返す(code,name)
            getTextFunction: (row)=>{
                // エンコード追加
                let encodedOutsoleId = encode(row['OutsoleId']);
                // let encodedOutsoleCode = encode(row['OutsoleCode']);
                let encodedOutsoleName = encode(row['OutsoleName']);

                let displayHtml = '';
                displayHtml += '<div class="outsole">';

                displayHtml += '<div class="spec">';
                    displayHtml += '<div class="key">';
                        displayHtml += '<div class="OutsoleId">' + encodedOutsoleId + '</div>';
                        // displayHtml += '<div class="OutsoleCode">' + encodedOutsoleCode + '</div>';
                        displayHtml += '<div class="Name">' + encodedOutsoleName + '</div>';
                    displayHtml += '</div>';
                displayHtml += '</div>'; // .spec end

                displayHtml += '<div class="pict">';
                displayHtml += '<img src="'+API.BASE_URL+'/data/file/upload/find?id='+ row['OutsoleFileId'] +'">';
                displayHtml += '</div>'; // .pict end

                displayHtml += '</div>'; // .design end
                return displayHtml;
            },
            // フィルタ対象データ配列を返す(code,name)
            getFilterTargets: (row)=>{
                return [
                    row['OutsoleId'],
                    // row['OutsoleCode'],
                    row['OutsoleName'],
                ];
            },
            exceptFunction: (row)=>{
                // 二重選択を防ぐための除外リスト
                const exceptList = (userparam && userparam.exceptList) ? userparam.exceptList : [];
                for (let i = 0; i < exceptList.length; i++) {
                    if (exceptList[i] === row['Id']) {
                        return true; // 除外
                    }
                }
                return false;
            }
        }
        if (userparam) {
            param = Object.assign(userparam, param);
        }

        super(param);

        this.container.id = 'OutsolePickerDialog';
    }
}