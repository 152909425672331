import React, { useState, useEffect, useRef, useCallback } from 'react';

import * as API from 'components/API';

function ColumnSelector(props) {

    const handleClick = (e) => {
        e.stopPropagation();
        let key = e.target.dataset.columnName;
        let text = e.target.textContent;
        props.selected(key, text);
    }

    const assistRef = useRef(null);
    const mounted = useRef(null);

    // 画面の大きさチェックしてはみ出ないようにする
    const adjustSize = useCallback(() => {
        if (assistRef.current) {
            let rect = assistRef.current.getBoundingClientRect();

            const displayedMaxHeight = window.innerHeight - rect.y - 20; // 1rem=16px
            // console.log(rect, window.innerHeight, displayedMaxHeight);

            if ((rect.y + rect.height) > displayedMaxHeight) {
                assistRef.current.style.maxHeight = displayedMaxHeight + 'px';
            } else {
                assistRef.current.style.maxHeight = '';
            }
        }
    }, []);

    useEffect(()=>{
        mounted.current = true;
        setTimeout(adjustSize, 200);
        window.addEventListener('resize', adjustSize, false);
        return () => {
            mounted.current = false;
            window.removeEventListener('resize', adjustSize, false);
        }
    }, [adjustSize]);

    return (
        <div ref={assistRef} className="assist columnSelector" onClick={e=>e.stopPropagation()}>
            <ul>
            {
            props.columns.map(column => (
                <li key={column.key}
                    data-column-name={column.key}
                    onClick={handleClick}
                >{column.name}</li>
            ))
            }
            </ul>
        </div>
    );
}

function ValueSelector(props) {

    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [withFilter, setWithFilter] = useState(false); // 件数が多いときにtrue
    const [counterText, setCounterText] = useState(null);

    const loadData = useRef([]);
    const mainUL = useRef(null);
    const visibleIndex = useRef(0);
    const filterInputRef = useRef(null);

    const assistRef = useRef(null);
    const mounted = useRef(null);

    const RENDERING_LIMIT = 100;

    // 画面の大きさチェックしてはみ出ないようにする
    const adjustSize = () => {
        if (assistRef.current) {
            let rect = assistRef.current.getBoundingClientRect();

            const displayedMaxHeight = window.innerHeight - rect.y - 20; // 1rem=16px
            // console.log(rect, window.innerHeight, displayedMaxHeight);

            if ((rect.y + rect.height) > displayedMaxHeight) {
                assistRef.current.style.maxHeight = displayedMaxHeight + 'px';
            } else {
                assistRef.current.style.maxHeight = '';
            }
        }
    }

    useEffect(()=>{
        mounted.current = true;
        // setTimeout(adjustSize, 200);
        window.addEventListener('resize', adjustSize, false);
        return () => {
            mounted.current = false;
            window.removeEventListener('resize', adjustSize, false);
        }
    }, []);

    useEffect(() => {
        setLoading(true);

        let url = props.url + '?column=' + props.column;
        API.get(url).then(data => {
            if (mounted.current) {
                loadData.current = data;
                setCounterText(data.length + '/' + loadData.current.length);
                setWithFilter(data.length > 50);
                setListData(data);
            }
        }).finally(()=>{
            setLoading(false);
        });
    }, [props.url, props.column]);

    const filteringData = text => {
        if (!text || text.length === 0) {
            return loadData.current;
        }
        console.time(`Filtering text=${text}`);
        let matches = [];
        const regex = new RegExp(text,'iu');
        for (let i = 0; i < loadData.current.length; i++) {
            const row = loadData.current[i];
            if (regex.test(row.text)) {
                matches.push(row);
            }
        }
        console.timeEnd(`Filtering text=${text}`);
        return matches;
    }

    const handleItemClick = useCallback((e) => {
        e.stopPropagation();
        const item = listData[e.target.dataset.index];
        let value = item.value || '(空)';
        props.selected('=', value, item.text);
    }, [listData, props]);

    let scrollTimeoutId = null;
    const handleAssistScroll = (e) => {
        let mainScrollMax80 = (e.target.scrollHeight - e.target.clientHeight) * 0.8;
        if (mainScrollMax80 > e.target.scrollTop) {
            // スクロールがMAXの80%に満たない場合(まだ最後の方までスクロールしていない)は追加処理をしない
            return;
        }
        if (scrollTimeoutId) {
            clearTimeout(scrollTimeoutId);
            scrollTimeoutId = null;
        }
        scrollTimeoutId = setTimeout(()=>{
            // 150ms経過したとき、まだfilteredDataにデータが残っていたらね
            if (listData) {
                const sliceMax = Math.min(visibleIndex.current + RENDERING_LIMIT, listData.length);
                const renderData = listData.slice(visibleIndex.current, sliceMax);
                if (renderData.length === 0) {
                    // 出尽くしたら終わり
                    return;
                }
                renderItemList(renderData, visibleIndex.current);
                visibleIndex.current = sliceMax;
            }
        }, 150);
    }

    let filterTimeoutId = null;
    const handleKeyUp = (e) => {
        if (e.key === 'Escape') {
            filterInputRef.current.value = '';
        }
        if (filterTimeoutId) {
            clearTimeout(filterTimeoutId);
            filterTimeoutId = null;
        }
        filterTimeoutId = setTimeout(()=>{
            let data = filteringData(filterInputRef.current.value);
            setCounterText(data.length + '/' + loadData.current.length);
            setListData(data);
        }, 200);
    }

    const renderItemList = useCallback((data, offsetIndex) => {
        if (mainUL.current) {
            const fragment = document.createDocumentFragment();
            for (let i = 0; i < data.length; i++) {
                const row = data[i];

                const li = document.createElement('li');
                li.dataset.index = (i + offsetIndex);
                li.textContent = row.text;

                li.addEventListener('click', handleItemClick, false);
                fragment.appendChild(li);
            }
            mainUL.current.appendChild(fragment);
            adjustSize();
        }
    }, [handleItemClick]);

    useEffect(() => {
        // スクロールをもとに戻すか
        assistRef.current.scrollTo(0, 0);

        // RENDERING_LIMITを超える場合は分割してあとでappend
        const sliceMax = Math.min(0 + RENDERING_LIMIT, listData.length);
        const renderData = listData.slice(0, sliceMax);
        mainUL.current.textContent = ''; // clear
        renderItemList(renderData, 0);
        visibleIndex.current = sliceMax;

    }, [listData, renderItemList]);

    return (
        <div className="assist valueSelector"
            onClick={e=>e.stopPropagation()}
            onScroll={handleAssistScroll}
            ref={assistRef}
        >
            {loading && (
                <div className="InlineLoader"><div className="LoaderMark"></div></div>
            )}
            {withFilter && (
                <div className="top">
                    <input type="text" placeholder="フィルター" ref={filterInputRef}
                        spellCheck="false"
                        autoComplete="false"
                        autoFocus={true}
                        onKeyUp={handleKeyUp}
                    />
                    <span>{counterText}</span>
                </div>
            )}
            <div className="main">
                <ul ref={mainUL}></ul>
            </div>
        </div>
    );
}

function NumberFormulaSelector(props) {

    const [message, setMessage] = useState('');
    const selectedFormula = useRef('=');
    const inputValue = useRef(null);

    const okFunction = () => {
        if (!inputValue.current || inputValue.current.length === 0) {
            setMessage('入力がありません');
            setTimeout(()=>{
                setMessage('');
            }, 3000);
            return;
        }
        props.selected(selectedFormula.current, inputValue.current, inputValue.current);
    }
    const handleChangeFormula = (e) => {
        selectedFormula.current = e.target.value;
    }
    const handleNumberInput = (e) => {
        if (e.key === 'Enter') {
            okFunction();
        } else {
            inputValue.current = e.target.value;
        }
    }
    const handleOKButtonClick = (e) => {
        okFunction();
    }
    return (
        <div className="assist numberFormulaSelector" onClick={e=>e.stopPropagation()}>
            <p>数値条件を指定してください</p>
            <div>
                <select onChange={handleChangeFormula}>
                    <option value="=" title="同じ">=</option>
                    <option value="≠" title="同じではない">≠</option>
                    <option value="≦" title="以下">≦</option>
                    <option value="≧" title="以上">≧</option>
                </select>
                <input type="number" onInput={handleNumberInput} autoFocus={true} />
                <button type="button" onClick={handleOKButtonClick} style={{marginLeft: '0.5rem'}}>OK</button>
            </div>
            {message && (
                <span style={{marginTop: '0.5rem'}}>{message}</span>
            )}
        </div>
    );
}

function DateRangeSelector(props) {

    const [message, setMessage] = useState('');
    const rangeStartRef = useRef(null);
    const rangeEndRef = useRef(null);

    const okFunction = () => {
        // 日付が入力されていないのにokをclickされた場合は入力を促す
        if (rangeStartRef.current === null || rangeEndRef.current === null
            || rangeStartRef.current.value.length === 0 || rangeEndRef.current.value.length === 0) {
            setMessage('日付は「YYYY-MM-DD」の形式で入力してください');
            return;
        }
        // 日付範囲は start～end だけど end～start と入力されているかもしれんので
        // validateを兼ねてプログラムで逆転しておくか...
        try {
            let s = new Date(rangeStartRef.current.value);
            let e = new Date(rangeEndRef.current.value);
            if (Number.isNaN(s.getTime())) {
                throw new Error('invalid date');
            }
            if (Number.isNaN(e.getTime())) {
                throw new Error('invalid date');
            }
            if (s.getTime() > e.getTime()) {
                // これはswap
                let w = rangeEndRef.current.value;
                rangeEndRef.current.value = rangeStartRef.current.value;
                rangeStartRef.current.value = w;
            }
        } catch (e) {
            // validate失敗なのでmessagingして再入力してもらう
            setMessage('日付は「YYYY-MM-DD」の形式で入力してください');
            return;
        }
        if (rangeStartRef.current.value === rangeEndRef.current.value) {
            // 開始と終了の日付が同じ場合は＝イコールで検索
            props.selected('=', rangeStartRef.current.value, rangeStartRef.current.value);
        } else {
            // 開始と終了が異なっていれば～betweenで検索
            let range = rangeStartRef.current.value + '～' + rangeEndRef.current.value;
            props.selected('between', range, range);
        }
    }

    const handleOKButtonClick = (e) => {
        okFunction();
    }

    return (
        <div className="assist dateRangeSelector" onClick={e=>e.stopPropagation()}>
            <p>日付範囲を指定してください</p>
            <div>
                <div>
                    <input type="date" ref={rangeStartRef} placeholder="日付"
                        pattern="^([12][0-9]{3})[-|/](0?[1-9]|1[0-2])[-|/](3[01]|[12][0-9]|0?[1-9])$"
                    />
                </div>
                ～
                <div>
                    <input type="date" ref={rangeEndRef} placeholder="日付"
                        pattern="^([12][0-9]{3})[-|/](0?[1-9]|1[0-2])[-|/](3[01]|[12][0-9]|0?[1-9])$"
                    />
                </div>
                <button type="button" onClick={handleOKButtonClick} style={{marginLeft: '0.5rem'}}>OK</button>
            </div>
            {message && (
                <span style={{marginTop: '0.5rem'}}>{message}</span>
            )}
        </div>
    );
}

function SelectorSwitch(props) {
    if (props.type === 'NumberFormula') {
        return <NumberFormulaSelector {...props} />
    } else if (props.type === 'DateRange') {
        return <DateRangeSelector {...props} />
    }
    return <ValueSelector {...props} />
}


export default function OmniSearch(props) {

    // visible:true and search:true のやつだけ
    let searchColumns = props.columns.filter(col => col.visible && col.search);

    let state = props.state;

    // すでに選択済みを除外する
    for (let i = 0; i < state.filters.length; i++) {
        searchColumns = searchColumns.filter(col => col.key !== state.filters[i].filterColumn);
    }

    const [omniActive, setOmniActive] = useState(false);
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [showValueSelector, setShowValueSelector] = useState(false);
    const [selectorType, setSelectorType] = useState(null);

    // 以下は自身で管理する
    let filterName = useRef(null);
    let filterColumn = useRef(null);
    let filterFormula = useRef(null);
    let filterValue = useRef(null);
    let filterText = useRef(null);
    let searchKeyword = useRef(null);

    const clearFilter = ()=>{
        filterName.current = null;
        filterColumn.current = null;
        filterFormula.current = null;
        filterValue.current = null;
        filterText.current = null;
        searchKeyword.current = null;
    };

    const searchInputRef = useRef(null);

    const columnSelectedCallback = (key, name) => {
        filterName.current = name;
        filterColumn.current = key;
        filterFormula.current = null;
        filterValue.current = null;
        filterText.current = null;
        searchKeyword.current = null;
        searchInputRef.current.value = name;

        let column = searchColumns.filter(col => col.key === key);
        // filterタイプ
        setSelectorType(column[0].type);
        // カラム選択assistを非表示にして
        setShowColumnSelector(false);
        // 続きまして値入力assist表示
        setShowValueSelector(true);
    }

    const valueSelectedCallback = (formula, value, text) => {
        filterFormula.current = formula;
        filterValue.current = value;
        filterText.current = text;

        let filterArray = [...state.filters];
        // フィルタ条件追加
        filterArray.push({
            filterName: filterName.current,
            filterColumn: filterColumn.current,
            filterFormula: filterFormula.current,
            filterValue: filterValue.current,
            filterText: filterText.current,
        });
        props.setState({
            filters: filterArray,
            offset: 0
        });
        inactiveFunction();
    }

    const inactiveFunction = () => {
        setShowValueSelector(false);
        setShowColumnSelector(false);

        clearFilter();

        if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.value = '';
            if (document.activeElement !== searchInputRef.current) {
                setOmniActive(false);
                document.documentElement.removeEventListener('click', handleDocumentClick, false);
            }
        }
    }

    const activeFunction = () => {
        if (filterColumn.current) {
            // カラムが選択されている場合は継続して値入力assistへ
            setShowValueSelector(true);
            setShowColumnSelector(false);
        } else {
            // カラムが選択されてない場合は新規カラム選択assistへ
            setShowColumnSelector(true);
            setShowValueSelector(false);
        }
    }

    const handleDocumentClick = (e) => {
        e.stopPropagation();
        inactiveFunction();
    }

    // inputにフォーカスがあたったら、assist開始
    const handleSearchInputFocus = (e) => {
        e.stopPropagation();
        setOmniActive(true);
        document.documentElement.addEventListener('click', handleDocumentClick, false);
        activeFunction();
    }

    // searchInputのclick時はフォーカス時と同じか
    const handleSearchInputClick = (e) => {
        e.stopPropagation();
        activeFunction();
    }

    // 日本語入力時の未確定プロセスが開始されたかどうかを検知して
    // Enterキー押下時のsubmitを制御する
    let isComposition = false;
    // Macのsafariはイベントの発火順序が違い、compositionEndが発火した後にkeydownが発火するので
    // Enterキー押下時に必ず isComposition === false になってしまう。
    // これをsetTimeoutでタイミングをずらしてやり、keydownを先に処理してから、isCompositionをfalseにする
    let isCompositionTimeout = null;
    const handleSearchInputCompositionStart = (e) => {
        if (isCompositionTimeout) {
            clearTimeout(isCompositionTimeout);
            isCompositionTimeout = null;
        }
        isComposition = true;
        // console.log('isComposition true');
    }
    const handleSearchInputCompositionEnd = (e) => {
        if (isCompositionTimeout) {
            clearTimeout(isCompositionTimeout);
            isCompositionTimeout = null;
        }
        isCompositionTimeout = setTimeout(()=>{
            isComposition = false;
            // console.log('isComposition false');
        }, 200);
    }

    // searchInputの入力時
    const handleSearchInputKeyUp = (e) => {
        // console.log(e);
        if (e.key === 'Escape') {
            isComposition = false;
            clearFilter();
            searchInputRef.current.value = '';
            inactiveFunction();
        }
    }
    const handleSearchInputKeyDown = (e) => {
        // 日本語入力未確定中ではないこと、でもってEnterはsearch開始！
        if (isComposition === false && e.key === 'Enter') {
            // console.log('keydown', e);
            // 空白だけはダメよ
            const inputKeyword = searchInputRef.current.value.trim();
            if (inputKeyword) {
                // 入力があるときだけ
                filterName.current = null;
                filterColumn.current = null;
                filterFormula.current = null;
                filterValue.current = null;
                filterText.current = null;
                searchKeyword.current = inputKeyword;
                // キーワード条件追加
                let keywordArray = [...state.keywords];
                keywordArray.push({ searchKeyword: inputKeyword });
                props.setState({
                    keywords: keywordArray,
                    offset: 0
                });
                inactiveFunction();
            }
        }
    }

    // 既存のフィルタ条件を削除
    const handleClearFilterItem = (e) => {
        let newarray = state.filters.filter(item => item.filterColumn !== e.currentTarget.dataset.column);
        props.setState({
            filters: newarray,
            offset: 0
        });
        inactiveFunction();
    }
    // 既存のキーワード条件を削除
    const handleClearKeywordItem = (e) => {
        let newarray = state.keywords.filter(item => item.searchKeyword !== e.currentTarget.dataset.value);
        props.setState({
            keywords: newarray,
            offset: 0
        });
        inactiveFunction();
    }

    return (
        <div id="OmniSearch" className={omniActive ? `active` : ''}>
            <div id="OmniSearchInner">
                <div id="OmniSearchActionLink" title="検索">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                </div>
                <div id="OmniSearchFrame">
{
state.filters.map(item => (
    <div key={item.filterColumn} className="searchItem" data-column={item.filterColumn} onClick={handleClearFilterItem}>
        <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M3,4c2.01,2.59,7,9,7,9v7h4v-7c0,0,4.98-6.41,7-9H3z"></path></g></svg>
        </div>
        <div className="itemText">{item.filterName + (item.filterFormula === 'between' ? ':' : item.filterFormula) + item.filterText}</div>
        <div className="clearLink" title="このフィルタをクリア">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
            クリア
        </div>
    </div>
))
}
{
state.keywords.map(item => (
    <div key={item.searchKeyword} className="searchItem" data-value={item.searchKeyword} onClick={handleClearKeywordItem}>
        <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 5H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2V8zm0 3h2v2h-2v-2zM8 8h2v2H8V8zm0 3h2v2H8v-2zm-1 2H5v-2h2v2zm0-3H5V8h2v2zm9 7H8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2V8h2v2zm3 3h-2v-2h2v2zm0-3h-2V8h2v2z"></path></svg>
        </div>
        <div className="itemText">{item.searchKeyword}</div>
        <div className="clearLink" title="このキーワードをクリア">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
        </div>
    </div>
))
}
                    <div id="OmniSearchWrapper">
                        <input type="text"
                            id="OmniSearchInput"
                            placeholder="項目によるフィルター、またはキーワードによる検索"
                            autoComplete="off"
                            spellCheck="false"
                            ref={searchInputRef}
                            onFocus={handleSearchInputFocus}
                            onClick={handleSearchInputClick}
                            onKeyDown={handleSearchInputKeyDown}
                            onKeyUp={handleSearchInputKeyUp}
                            onCompositionStart={handleSearchInputCompositionStart}
                            onCompositionEnd={handleSearchInputCompositionEnd}
                        />
                        {showColumnSelector && (
                            <ColumnSelector
                                columns={searchColumns}
                                selected={columnSelectedCallback}
                            />
                        )}
                        {showValueSelector && (
                            <SelectorSwitch
                                type={selectorType}
                                url={props.filterUrl}
                                column={filterColumn.current}
                                selected={valueSelectedCallback}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
