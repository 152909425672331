import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import 'css/OrderInput.css';

// import * as API from 'components/API';
import OrderCustomerFrame from './OrderCustomerFrame';
import OrderFittingFrame from './OrderFittingFrame';
import OrderOrderFrame from './OrderOrderFrame';
import OrderShoesFrame from './OrderShoesFrame';
import OrderFooterFrame from './OrderFooterFrame';


export default function OrderInputStep6(props) {

    const state = props.state;

    const history = useHistory();
    const handleCancelClick = () => {
        history.push('/business/order');
    }

    const handlePrintClick = (e) => {
        // 閉じているdetailsはすべてopenにしておく
        let details = document.querySelectorAll('#OrderSheetLayout details');
        console.log('details', details.length);
        if (details) {
            for (let i = 0; i < details.length; i++) {
                details[i].open = true;
            }
        }
        // スクロールを一番上にしておく
        document.querySelector('#Container').scrollTo(0, 0);
        window.print();
    }


    useEffect(() => {
        // 閉じているdetailsはすべてopenにしておく
        let details = document.querySelectorAll('#OrderSheetLayout details');
        if (details) {
            for (let i = 0; i < details.length; i++) {
                details[i].open = true;
            }
        }
    }, []);

    useEffect(() => {
        // もとに戻す用
        let beforeTitle = document.title;
        // そしてページタイトルを変更しておく(これがPDF保存するときのファイル名になるから)
        document.title = `ORDER_SHEET_${state.fields.OrderCategory1}_${state.fields.OrderId}`;
        return () => {
            document.title = beforeTitle;
        }
    }, [state.fields.OrderCategory1, state.fields.OrderId]);

    return (
        <>
            <div id="OrderSheet">
                <h1>
                    <span className="OrderTitle">{state.fields.OrderCategory1} ORDER SHEET</span>
                </h1>
                <div id="OrderSheetLayout">
                    <OrderOrderFrame {...props} readonly={true} />
                    <OrderCustomerFrame {...props} readonly={true} />
                    <OrderFittingFrame {...props} readonly={true} />
                    <OrderShoesFrame {...props} readonly={true} />
                    <OrderFooterFrame {...props} />
                </div>
            </div>
            <div className="ButtonGroup">
                <button type="button" className="LinkButton" onClick={handleCancelClick}>一覧に戻る</button>
                <div className="grow1"></div>
                <button type="button" className="PrimaryButton" onClick={handlePrintClick}>印刷する</button>
            </div>
        </>
    );
}
