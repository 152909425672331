import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import OrderList from './OrderList';
import OrderInput from './OrderInput';
import OrderView from './OrderView';

export default function Order() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/list`} component={OrderList} />
                <Route path={`${path}/input/:id`} component={OrderInput} />
                <Route path={`${path}/input`} component={OrderInput} />
                <Route path={`${path}/view/:Id`} component={OrderView} />

                <Redirect path={`${path}`} to={`${path}/list`} />
            </Switch>
        </>
    );
}
