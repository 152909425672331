import React, { useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import 'css/ItemMasterInput.css';

import * as API from 'components/API';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import Radio from 'components/Radio';
import LinkButton from 'components/LinkButton';
import * as PickerDialog from 'components/Dialog/PickerDialog';
import UploadFile from 'components/UploadFile';

function ItemMasterInput(props) {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setFormState = form.setFormState;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        if (param.id) {
            let result = await API.get('/master/item/input?id='+param.id);
            if (mounted.current) {
                setFormState({
                    fields: result.Data
                });
            }
        } else {
            // ID指定がない新規入力
        }
    }, [param.id, setFormState]);

    const handleMainScroll = (e) => {
        const h1tag = document.querySelector('.InputContents h1');
        if (h1tag) {
            if (e.target.scrollTop > 0) {
                h1tag.classList.add('small');
            } else {
                h1tag.classList.remove('small');
            }
        }
    }

    // フォーカス時にtext全選択状態にするやつ
    const selectOnFocus = (e) => e.target.select();

    useEffect(() => {
        mounted.current = true;

        const main = document.querySelector('#View main');
        main.addEventListener('scroll', handleMainScroll, false);

        if (param.id) {
            // 編集時はデータ取得
            findData();
        } else {
            // 新規登録時の初期値セット
            setFormState({
                fields: {
                    ItemStatus: 'ENABLED'
                }
            });
        }
        return () => {
            mounted.current = false;
            main.removeEventListener('scroll', handleMainScroll, false);
        }
    }, [param.id, findData, setFormState]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.SellerId) {
            errors.SellerId = {
                Message: 'メーカーは必須入力です。'
            }
        }
        if (!fields.ItemId) {
            errors.ItemId = {
                Message: '品番IDは必須入力です。'
            }
        }
        if (!fields.ItemName) {
            errors.ItemName = {
                Message: '品名は必須入力です。'
            }
        }
        if (!fields.LastId) {
            errors.LastId = {
                Message: '木型は必須入力です。'
            }
        }
        if (!fields.ItemStatus) {
            errors.ItemStatus = {
                Message: 'ステータスは必須入力です。'
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.postAsFormData('/master/item/update', {
            'Id': param.id,
            'SellerId': state.fields?.SellerId || '',
            'SellerCategory': state.fields?.SellerCategory || '',
            'ItemId': state.fields?.ItemId || '',
            'ItemName': state.fields?.ItemName || '',
            'ItemFileId': state.fields?.ItemFileId || '',
            'LastId': state.fields?.LastId || '',
            'ItemStatus': state.fields?.ItemStatus || '',
            'ItemNote': state.fields?.ItemNote || '',
            'UpdateDatetime': state.fields?.UpdateDatetime || '',
        });
        if (result?.Result) {
            // [OK] 一覧画面に遷移する
            history.push('/master/item/list');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result.Errors);
        }
    });

    const handleSellerClick = (e) => {
        const picker = PickerDialog.createSellerPicker();
        picker.setSelectedCallback((selected)=>{
            console.log(selected);
            setFormState({
                fields: {
                    SellerCategory: selected.SellerCategory,
                    SellerId: selected.SellerId,
                    SellerName: selected.SellerName
                }
            });
        });
        picker.show();
    }

    const handleLastPicker = (e) => {
        const picker = PickerDialog.createLastPicker({
            SellerId: state.fields.SellerId,
            SellerCategory: state.fields.SellerCategory
        });
        picker.setSelectedCallback((selected)=>{
            console.log(selected);
            setFormState({
                fields: {
                    LastId: selected.LastId,
                    LastName: selected.LastName
                }
            });
        });
        picker.show();
    }

    const handleUploadCallback = (data) => {
        console.log('upload success!');
        setFormState({
            fields: {
                ItemFileId: data.Id
            }
        });
    }

    const handleDeleteCallback = (data) => {
        console.log('delete');
        setFormState({
            fields: {
                ItemFileId: null
            }
        });
    }

    return (
        <div id="ItemMasterInput" className="InputContents">
            <h1>
                <Link to="/master/item/list">
                    <svg xmlns="http://www.w3.org/2000/svg"><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
                </Link>
                <span>品番マスタ</span>
                <div className="RequireHint"><span className="RequireMark">✱</span>必須入力</div>
            </h1>
            {state.errors?.Page?.map(message => (
                <div className="message error">{message.Message}</div>
            ))}
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">メーカー</div>
                    <div className="control">
                        <input type="text" id="SellerId" name="SellerId" readOnly={true}
                            placeholder="メーカーを選択"
                            onClick={handleSellerClick}
                            value={(state.fields?.SellerId || '') + ' ' + (state.fields?.SellerName || '') + ' ' + (state.fields?.SellerCategory || '')}
                            className={state.errors?.SellerId && 'invalid'}
                            style={{width: '20rem'}}
                        />
                        {state.errors?.SellerId &&
                            <span className="messageTip error">{state.errors?.SellerId.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">品番</div>
                    <div className="control">
                        <input type="text" id="ItemId" name="ItemId"
                            placeholder="品番ID"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.ItemId || ''}
                            className={state.errors?.ItemId && 'invalid'}
                        />
                        {state.errors?.ItemId &&
                            <span className="messageTip error">{state.errors?.ItemId.Message}</span>
                        }
                        <p className="description">
                        メーカーと品番IDの組み合わせを一意に登録してください。同じ組み合わせのものは登録できません。
                        </p>
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">品名</div>
                    <div className="control">
                        <input type="text" id="ItemName" name="ItemName"
                            placeholder="品名"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.ItemName || ''}
                            style={{width: '40rem'}}
                            className={state.errors?.ItemName && 'invalid'}
                        />
                        {state.errors?.ItemName &&
                            <span className="messageTip error">{state.errors?.ItemName.Message}</span>
                        }

                    </div>
                </div>
                <div className="row">
                    <div className="label">ファイル</div>
                    <div className="control">
                        <UploadFile
                            uploadCallback={handleUploadCallback}
                            deleteCallback={handleDeleteCallback}
                            value={state.fields?.ItemFileId}
                        >
                        </UploadFile>
                        {state.errors?.FileId &&
                            <span className="messageTip error">{state.errors?.ItemFileId.Message}</span>
                        }
                        <p className="description">
                            商品の写真を登録します、アップロードされた画像は「ORDER SHEET」に表示されます。
                            画像の縦横比は 4:3、解像度は最大 640x480 に加工されます。画像フォーマットは、JPG, PNG, GIF, SVG をサポートしています。
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="label require">木型</div>
                    <div className="control">
                        <input type="text" id="LastId" name="LastId" readOnly={true}
                            placeholder="木型を選択"
                            onClick={handleLastPicker}
                            value={(state.fields?.LastId || '')+ ' ' +(state.fields?.LastName || '')}
                            className={state.errors?.LastId && 'invalid'}
                            style={{width: '20rem'}}
                        />
                        {state.errors?.LastId &&
                            <span className="messageTip error">{state.errors?.LastId.Message}</span>
                        }

                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">ステータス</div>
                    <div className="control">
                        <div className="radioGroup row">
                            <Radio label="有効" name="ItemStatus" value="ENABLED" onChange={handleChange}
                                checked={state.fields?.ItemStatus === 'ENABLED'}
                            />
                            <Radio label="無効" name="ItemStatus" value="DISABLED" onChange={handleChange}
                                checked={state.fields?.ItemStatus === 'DISABLED'}
                            />
                        </div>
                        {state.errors?.ItemStatus &&
                            <span className="messageTip error">{state.errors?.ItemStatus.Message}</span>
                        }
                        <p className="description">このデータが必要なくなったときはステータスを「無効」にします。「無効」にすると新しく発注データを作成するときなどで、選択肢に表示されなくなります。</p>
                    </div>
                </div>
                <div className="row">
                    <div className="label">備考</div>
                    <div className="control">
                        <textarea id="ItemNote" name="ItemNote" cols="30" rows="10"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.ItemNote}
                        >
                        </textarea>
                        {state.errors?.ItemNote &&
                            <span className="messageTip error">{state.errors?.ItemNote.Message}</span>
                        }
                        <p className="description">このデータに関する覚書きや参考情報などを入力してください。一覧画面ではこの内容を検索して利用することも可能です。</p>
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/master/item/list">キャンセル</LinkButton>
                <button type="submit" className="PrimaryButton">品番を登録</button>
            </div>
        </div>
    )
}
export default withFormField(ItemMasterInput);
