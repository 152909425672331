import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import * as API from 'components/API';
import Checkbox from 'components/Checkbox';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import 'css/ContactUs.css';

function ContactUs() {
    const { state, setFormState, setErrors, handleChange, handleSubmit } = useFormContext(FormContext);

    // 1:入力画面、2:確認画面、3:完了画面
    const [step, setStep] = useState(1);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.ContactName) {
            errors.ContactName = {
                Message: 'お名前は必須入力です。'
            }
        }
        if (!fields.ContactMailaddress) {
            errors.ContactMailaddress = {
                Message: 'メールアドレスは必須入力です。'
            }
        }
        if (!fields.ContactMessage) {
            errors.ContactMessage = {
                Message: 'お問合せ内容は必須入力です。'
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    // 入力確認画面へボタン
    const handleStep1Submit = () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');
        // OKなら確認画面を表示
        setStep(2);
    }
    // 入力画面へ戻るボタン
    const handleBackButton = (e) => {
        // STEP1へ戻るだけ
        setStep(1);
    }

    // 送信するボタン
    handleSubmit(async () => {
        const result = await API.postAsFormData('/table/contact/update', {
            'ContactName': state.fields?.ContactName || '',
            'ContactNameKana': state.fields?.ContactNameKana || '',
            'ContactZipcode': state.fields?.ContactZipcode || '',
            'ContactAddress': state.fields?.ContactAddress || '',
            'ContactPhoneNumber': state.fields?.ContactPhoneNumber || '',
            'ContactMailaddress': state.fields?.ContactMailaddress || '',
            'ContactMessage': state.fields?.ContactMessage || '',
        });
        // console.log(result);
        if (result?.Result) {
            // 成功したら完了画面へ
            setStep(3);
            // clearState();
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result?.Errors);
            // 入力画面からやり直してもらうか...
            setStep(1);
        }
    });

    const handlePostalCodeInput = async e => {
        let result = await API.searchZip(e.target.value);
        if (result) {
            let fullAddress = result.Address1 + result.Address2 + result.Address3;
            setFormState({
                fields: {
                    ContactZipcode: result.Zipcode.slice(0,3) + '-' + result.Zipcode.slice(3,7),
                    ContactAddress: fullAddress
                }
            });
        }
    }

    useEffect(() => {
        document.title = `お問い合わせ - UPMOST -`;
    }, []);

    return (
        <div id="ContactUs">
            {step === 1 && (
                <>
                    <h1>お問い合わせ</h1>
                    <p>当社ホームページをご覧頂き、ありがとうございます。お見積もりは無料となっております。お気軽にご相談、お問い合わせください。</p>
                    {state.errors?.submit &&
                        <div className="message error">{state.errors?.submit.Message}</div>
                    }
                    <table>
                        <tbody>
                            <tr><th><span>必須</span>お名前</th>
                                <td>
                                    <input type="text" id="ContactName" name="ContactName"
                                        placeholder="例）小田 修造"
                                        style={{width: '20rem'}}
                                        onChange={handleChange}
                                        value={state.fields?.ContactName || ''}
                                        className={state.errors?.ContactName && 'invalid'}
                                    />
                                    {state.errors?.ContactName &&
                                        <span className="messageTip error">{state.errors?.ContactName.Message}</span>
                                    }
                                </td></tr>
                            <tr><th>ふりがな</th>
                                <td>
                                    <input type="text" id="ContactNameKana" name="ContactNameKana"
                                        placeholder="例）おだ しゅうぞう"
                                        style={{width: '20rem'}}
                                        onChange={handleChange}
                                        value={state.fields?.ContactNameKana || ''}
                                        className={state.errors?.ContactNameKana && 'invalid'}
                                    />
                                    {state.errors?.ContactNameKana &&
                                        <span className="messageTip error">{state.errors?.ContactNameKana.Message}</span>
                                    }
                                </td></tr>
                            <tr><th>ご住所</th>
                                <td>
                                    <div style={{position: 'relative'}}>
                                        <span className="zipmark">〒</span>
                                        <input type="text" id="ContactZipcode" name="ContactZipcode"
                                            placeholder="例）480-0101"
                                            style={{minWidth: '10rem', maxWidth: '10rem'}}
                                            onChange={handleChange}
                                            onInput={handlePostalCodeInput}
                                            value={state.fields?.ContactZipcode || ''}
                                            className={state.errors?.ContactZipcode && 'invalid'}
                                        />
                                        {state.errors?.ContactZipcode &&
                                            <span className="messageTip error">{state.errors?.ContactZipcode.Message}</span>
                                        }
                                    </div>
                                    <div style={{marginTop: '0.5rem'}}>
                                        <input type="text" id="ContactAddress" name="ContactAddress"
                                            placeholder="例）愛知県一宮市三条字賀37-1"
                                            style={{width: '25rem'}}
                                            onChange={handleChange}
                                            value={state.fields?.ContactAddress || ''}
                                            className={state.errors?.ContactAddress && 'invalid'}
                                        />
                                        {state.errors?.ContactAddress &&
                                            <span className="messageTip error">{state.errors?.ContactAddress.Message}</span>
                                        }
                                    </div>
                                </td></tr>
                            <tr><th>連絡先電話番号</th>
                                <td>
                                    <input type="tel" id="ContactPhoneNumber" name="ContactPhoneNumber"
                                        placeholder="例）052-111-2222"
                                        style={{width: '20rem'}}
                                        onChange={handleChange}
                                        value={state.fields?.ContactPhoneNumber || ''}
                                        className={state.errors?.ContactPhoneNumber && 'invalid'}
                                    />
                                    {state.errors?.ContactPhoneNumber &&
                                        <span className="messageTip error">{state.errors?.ContactPhoneNumber.Message}</span>
                                    }
                                </td></tr>
                            <tr><th><span>必須</span>連絡先メールアドレス</th>
                                <td>
                                    <input type="email" id="ContactMailaddress" name="ContactMailaddress"
                                        placeholder="例）youraddress@email.com"
                                        style={{width: '20rem'}}
                                        onChange={handleChange}
                                        value={state.fields?.ContactMailaddress || ''}
                                        className={state.errors?.ContactMailaddress && 'invalid'}
                                    />
                                    {state.errors?.ContactMailaddress &&
                                        <span className="messageTip error">{state.errors?.ContactMailaddress.Message}</span>
                                    }
                                </td></tr>
                            <tr><th><span>必須</span>お問合せ内容</th>
                                <td>
                                    <textarea id="ContactMessage" name="ContactMessage"
                                        maxLength="3000"
                                        placeholder="（3000文字以内）"
                                        onChange={handleChange}
                                        style={{width: '80%', minHeight: '8rem'}}
                                        value={state.fields?.ContactMessage || ''}
                                        className={state.errors?.ContactMessage && 'invalid'}
                                    />
                                    {state.errors?.ContactMessage &&
                                        <span className="messageTip error">{state.errors?.ContactMessage.Message}</span>
                                    }
                                    <p className="advice">※お問い合わせ内容に、個人情報は記載しないようお願いいたします。</p>
                                </td></tr>
                        </tbody>
                    </table>
                    <div className="formFooter">
                        <div className="privacyConfirm">
                            <Checkbox label="個人情報の取扱いに関する事項に同意する" name="AgreementCheck" value="agree"
                                onChange={handleChange}
                                selected={state.fields?.AgreementCheck}
                            />
                            <br />
                            <Link to="/privacypolicy" target="_blank">プライバシーポリシー</Link>
                        </div>
                        <div><p>[入力内容の確認画面へ] ボタンをクリックして入力内容のご確認をお願いします。<br />
                                ご入力誠にありがとうございました。</p>
                        </div>
                        <div>
                            <button type="button" className="PrimaryButton"
                                onClick={handleStep1Submit}
                                disabled={!(state.fields?.AgreementCheck?.length)}
                            >入力内容の確認画面へ</button>
                        </div>
                    </div>
                </>
            )}
            {step === 2 && (
                <>
                    <h1>お問い合わせ</h1>
                    <p>当社ホームページをご覧頂き、ありがとうございます。お見積もりは無料となっております。お気軽にご相談、お問い合わせください。</p>
                    {state.errors?.submit &&
                        <div className="message error">{state.errors?.submit.message}</div>
                    }
                    <table>
                        <tbody>
                            <tr><th>お名前</th><td>{state.fields.ContactName || ''}</td></tr>
                            <tr><th>ふりがな</th><td>{state.fields.ContactNameKana || ''}</td></tr>
                            <tr><th>ご住所</th><td>
                                    <div><span>〒</span>{state.fields.ContactZipcode || ''}</div>
                                    <div style={{marginTop: '0.5rem'}}>{state.fields.ContactAddress || ''}</div>
                                </td></tr>
                            <tr><th>連絡先電話番号</th><td>{state.fields.ContactPhoneNumber || ''}</td></tr>
                            <tr><th>連絡先メールアドレス</th><td>{state.fields.ContactMailaddress || ''}</td></tr>
                            <tr><th>お問合せ内容</th><td>{state.fields.ContactMessage || ''}</td></tr>
                        </tbody>
                    </table>
                    <div className="formFooter">
                        <div><p>入力内容に問題がなければ送信するボタンをクリックしてください。</p></div>
                        <div className="step2Buttons">
                            <button type="button" className="" onClick={handleBackButton}>入力画面に戻る</button>
                            <button type="submit" className="PrimaryButton">送信する</button>
                        </div>
                    </div>
                </>
            )}
            {step === 3 && (
                <div className="complete">
                    <h1>お問い合わせいただきありがとうございます。</h1>
                    <div className="completeText">
                        <p>数日中にご連絡いたしますので、今しばらくお待ちください。</p>
                        <div>
                        ご入力いただきましたメールアドレス宛に自動送信メールをお送りしております。<br/>
                        万が一、メールが届かない場合は、メールアドレスの入力間違い、<br/>
                        または迷惑メールフォルダへ振り分けられていることがございますのでご確認ください。<br/>
                        メールアドレスの入力間違いにつきましては返信メールが届きませんので<br/>
                        お手数ですが、再度お問い合わせをお願いいたします。
                        </div>
                    </div>
                    <div className="backLink">
                        <Link to="/">トップページに戻る</Link>
                    </div>
                </div>
            )}
        </div>
    );
}
export default withFormField(ContactUs);
