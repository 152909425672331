import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import 'css/UserMasterInput.css';

import * as API from 'components/API';
import * as Resource from 'components/Resource';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import Radio from 'components/Radio';
import LinkButton from 'components/LinkButton';
import * as PickerDialog from 'components/Dialog/PickerDialog';

function UserMasterInput(props) {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setFormState = form.setFormState;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const [admin, setAdmin] = useState(false);

    let close = false;
    close = (state.fields?.UserLevel === 'ADMINISTRATOR' ? true : false);

    const findData = useCallback(async ()=>{
        let result = await API.get('/master/user/input?id='+param.id);
        if (result.Data) {
            if (mounted.current) {
                console.log(result.Data);
                setFormState({
                    fields: result.Data
                });

                if (result.Data?.UserId === 'admin') {
                    setAdmin(true);
                }
            }
        }
    }, [param.id, setFormState]);

    const handleMainScroll = (e) => {
        const h1tag = document.querySelector('.InputContents h1');
        if (h1tag) {
            if (e.target.scrollTop > 0) {
                h1tag.classList.add('small');
            } else {
                h1tag.classList.remove('small');
            }
        }
    }

    // フォーカス時にtext全選択状態にするやつ
    const selectOnFocus = (e) => e.target.select();

    useEffect(() => {
        mounted.current = true;

        const main = document.querySelector('#View main');
        main.addEventListener('scroll', handleMainScroll, false);

        if (param.id) {
            // 編集時はデータ取得
            findData();
        } else {
            // 新規登録時の初期値セット
            setFormState({
                fields: {
                    UserLevel: 'NORMAL',
                    UserBuyerList: [],
                    UserSellerList: [],
                    UserStatus: 'ENABLED'
                }
            });
        }
        return () => {
            mounted.current = false;
            main.removeEventListener('scroll', handleMainScroll, false);
        }
    }, [param.id, findData, setFormState]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.UserId) {
            errors.UserId = {
                Message: 'ユーザーIDは必須入力です。'
            }
        }
        if (!fields.UserPassword) {
            errors.UserPassword = {
                Message: 'パスワードは必須入力です。'
            }
        }
        if (!fields.UserName) {
            errors.UserName = {
                Message: 'ユーザー名は必須入力です。'
            }
        }
        if (!fields.UserStatus) {
            errors.UserStatus = {
                Message: 'ステータスは必須入力です。'
            }
        }
        if (fields.UserLevel === 'NORMAL') {
            if (!fields.UserBuyerList?.length && !fields.UserSellerList?.length) {
                errors.UserSellerList = {
                    Message: '発注者またはメーカーいずれかの関連付けは必須です。'
                }
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        // 発注者リストとメーカーリストは手作りしなかんでなぁ。。。
        let formData = new FormData();

        formData.append('Id', param.id);
        formData.append('UserId', state.fields?.UserId || '');
        formData.append('UserName', state.fields?.UserName || '');
        formData.append('UserPassword', state.fields?.UserPassword || '');
        for (let i = 0; i < state.fields?.UserBuyerList?.length; i++) {
            let buyer = state.fields?.UserBuyerList[i];
            formData.append('UserBuyerList[]', buyer.Id); // Idを渡しておくか
        }
        for (let i = 0; i < state.fields?.UserSellerList?.length; i++) {
            let seller = state.fields?.UserSellerList[i];
            formData.append('UserSellerList[]', seller.Id); // Idを渡しておくか
        }
        formData.append('UserLevel', state.fields?.UserLevel || '');
        formData.append('UserMail', state.fields?.UserMail || '');
        formData.append('UserStatus', state.fields?.UserStatus || '');
        formData.append('UserNote', state.fields?.UserNote || '');
        formData.append('UpdateDatetime', state.fields?.UpdateDatetime || '');

        const result = await API.post('/master/user/update', formData);
        if (result?.Result) {
            // [OK] 一覧画面に遷移する
            history.push('/master/user/list');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result.Errors);
        }
    });

    // 発注者を選択するやつ
    const handleAddBuyerClick = (e) => {
        e.stopPropagation();

        let buyers = state.fields.UserBuyerList || [];
        let selectedIds = buyers.map(buyer => buyer.Id);
        const picker = PickerDialog.createBuyerPicker({
            exceptList: selectedIds
        });
        picker.setSelectedCallback((selected)=>{
            // console.log(selected);
            const newList = [...buyers, selected];
            setFormState({
                fields: {
                    UserBuyerList: newList
                }
            });
        });
        picker.show();
    }
    // 発注者を削除する×ボタン
    const handleBuyerRemove = (target) => {
        let buyers = state.fields.UserBuyerList || [];
        const newList = buyers.filter(x => x.Id !== target.Id);
        setFormState({
            fields: {
                UserBuyerList: newList
            }
        });
    }

    // メーカーを選択するやつ
    const handleAddSellerClick = (e) => {
        e.stopPropagation();
        let sellers = state.fields.UserSellerList || [];
        let selectedIds = sellers.map(seller => seller.Id);
        const picker = PickerDialog.createSellerPicker({
            exceptList: selectedIds
        });
        picker.setSelectedCallback((selected)=>{
            // console.log(selected);
            const newList = [...sellers, selected];
            setFormState({
                fields: {
                    UserSellerList: newList
                }
            });
        });

        picker.show();
    }
    // メーカーを削除する×ボタン
    const handleSellerRemove = (target) => {
        let sellers = state.fields.UserSellerList || [];
        const newList = sellers.filter(x => x.Id !== target.Id);
        setFormState({
            fields: {
                UserSellerList: newList
            }
        });
    }

    return (
        <div id="UserMasterInput" className="InputContents">
            <h1>
                <Link to="/master/user/list">
                    <svg xmlns="http://www.w3.org/2000/svg"><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
                </Link>
                <span>ユーザーの登録</span>
                <div className="RequireHint"><span className="RequireMark">✱</span>必須入力</div>
            </h1>
            {state.errors?.Page?.map(message => (
                <div className="message error">{message.Message}</div>
            ))}
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">ユーザーID</div>
                    <div className="control">
                        <div>
                            <input type="text" id="UserId" name="UserId"
                                placeholder="ユーザーID"
                                onChange={handleChange}
                                onFocus={selectOnFocus}
                                value={state.fields?.UserId || ''}
                                style={{width: '16rem'}}
                                className={state.errors?.UserId && 'invalid'}
                                disabled={admin}
                            />
                        </div>
                        {state.errors?.UserId &&
                            <span className="MessageTip error">{state.errors?.UserId.Message}</span>
                        }
                        <p className="description">ログインに使用するIDを半角英数字で入力してください。すでに登録されているIDは登録できません。</p>
                    </div>
                </div>
                <div className="row">
                    <div className="label require">パスワード</div>
                    <div className="control">
                        <div>
                            <input type="password" id="UserPassword" name="UserPassword"
                                placeholder="パスワード"
                                onChange={handleChange}
                                onFocus={selectOnFocus}
                                value={state.fields?.UserPassword || ''}
                                style={{width: '16rem'}}
                                className={state.errors?.UserPassword && 'invalid'}
                            />
                        </div>
                        {state.errors?.UserPassword &&
                            <span className="MessageTip error">{state.errors?.UserPassword.Message}</span>
                        }
                        <p className="description">ログインに使用するパスワードを入力してください。大文字・小文字・記号を組み合わせた8文字以上を推奨します。</p>
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">ユーザー名</div>
                    <div className="control">
                        <input type="text" id="UserName" name="UserName"
                            placeholder="ユーザー名"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.UserName || ''}
                            style={{width: '20rem'}}
                            className={state.errors?.UserName && 'invalid'}
                            disabled={admin}
                        />
                        {state.errors?.UserName &&
                            <span className="MessageTip error">{state.errors?.UserName.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">メールアドレス</div>
                    <div className="control">
                        <div>
                            <input type="text" id="UserMail" name="UserMail"
                                placeholder="メールアドレス"
                                onChange={handleChange}
                                onFocus={selectOnFocus}
                                value={state.fields?.UserMail || ''}
                                style={{width: '20rem'}}
                                className={state.errors?.UserMail && 'invalid'}
                            />
                        </div>
                        <p className="description">パスワードを忘れた場合にパスワード再設定案内メールをこのアドレスへ送信します。メールアドレスの登録が無い場合はユーザーがパスワードを忘れた場合は、このシステムの管理者へ問い合わせる必要があります。</p>
                        {state.errors?.UserMail &&
                            <span className="MessageTip error">{state.errors?.UserMail.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">権限</div>
                    <div className="control">
                        <select name="UserLevel" onChange={handleChange}
                            value={state.fields?.UserLevel || ''}
                            className={state.errors?.UserLevel && 'invalid'}
                            disabled={admin}
                        >
                            {Resource.USER_LEVEL.map((level, index) => (
                                <option key={level.key} value={level.key}>{level.value}</option>
                            ))}
                        </select>
                        {state.errors?.UserLevel &&
                            <span className="MessageTip error">{state.errors?.UserLevel.Message}</span>
                        }
                        <p className="description">
                            管理者権限を選択するとメンテナンスメニューを含むすべての機能が利用できるようになります。<br />
                            一般を選択する場合は、発注者またはメーカーのいずれか1つ以上を関連付けてください。
                        </p>
                    </div>
                </div>
            </div>
            {!close && (
                <div className="rowGroup">
                    <div className="row">
                        <div className="label require"></div>
                        <div className="control">
                            <p className="description">
                                ユーザーには1つ以上の発注者またはメーカーを関連付けてください。1つのユーザーで複数の発注者およびメーカーを使い分けることができます。
                                発注者に関連があるユーザーには発注者用のメニューが利用できるようになります。また、メーカーに関連があるユーザーにはメーカー用のメニューが利用できるようになります。
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">関連する発注者</div>
                        <div className="control">
                            <div id="UserBuyerList" name="UserBuyerList">
{
(state.fields?.UserBuyerList?.length || 0) === 0 && (
    <div className="Message info">
        <h5>Help</h5>
        <p>発注者を追加ボタンをクリックして表示される発注者の一覧からこのユーザーに関連付ける発注者を選択します。</p>
    </div>
)
}
{
((buyers)=>{
    if (!buyers || buyers.length === 0) {
        return <></>;
    }
    // UserBuyerIdでソートしたい(登録順)
    buyers.sort((a, b) => a.UserBuyerId - b.UserBuyerId);

    let jsx = [];
    for (let i = 0; i < buyers.length; i++) {
        let row = buyers[i];
        jsx.push(
            <UserBuyerItem key={'buyer'+row.Id} buyer={row} handleBuyerRemove={handleBuyerRemove}/>
        );
    }
    return jsx;
})(state.fields?.UserBuyerList)
}
                                <div style={{marginTop:'.5rem'}}>
                                    <button type="button" onClick={handleAddBuyerClick}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                                        <span>発注者を追加</span>
                                    </button>
                                </div>
                            </div>
                            {state.errors?.UserBuyerList &&
                                <span className="MessageTip error">{state.errors?.UserBuyerList.Message}</span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">関連するメーカー</div>
                        <div className="control">
                            <div id="UserSellerList">
{
(state.fields?.UserSellerList?.length || 0) === 0 && (
    <div className="Message info">
        <h5>Help</h5>
        <p>メーカーを追加ボタンをクリックして表示されるメーカーの一覧からこのユーザーに関連付けるメーカーを選択します。</p>
    </div>
)
}
{
((sellers)=>{
    if (!sellers || sellers.length === 0) {
        return <></>;
    }
    // UserSellerIdでソートしたい(登録順)
    sellers.sort((a, b) => a.UserSellerId - b.UserSellerId);

    let jsx = [];
    for (let i = 0; i < sellers.length; i++) {
        let row = sellers[i];
        jsx.push(
            <UserSellerItem key={'seller'+row.Id} seller={row} handleSellerRemove={handleSellerRemove}/>
        );
    }
    return jsx;
})(state.fields?.UserSellerList)
}
                                <div style={{marginTop:'.5rem'}}>
                                    <button type="button" onClick={handleAddSellerClick}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                                        <span>メーカーを追加</span>
                                    </button>
                                </div>
                            </div>
                            {state.errors?.UserSellerList &&
                                <span className="MessageTip error">{state.errors?.UserSellerList.Message}</span>
                            }
                        </div>
                    </div>
                </div>
            )}
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">ステータス</div>
                    <div className="control">
                        <div className="radioGroup row">
                            <Radio label="有効" name="UserStatus" value="ENABLED" onChange={handleChange}
                                checked={state.fields?.UserStatus === 'ENABLED'}
                                disabled={admin}
                            />
                            <Radio label="無効" name="UserStatus" value="DISABLED" onChange={handleChange}
                                checked={state.fields?.UserStatus === 'DISABLED'}
                                disabled={admin}
                            />
                        </div>
                        {state.errors?.UserStatus &&
                            <span className="MessageTip error">{state.errors?.UserStatus.Message}</span>
                        }
                        <p className="description">このデータが必要なくなったときはステータスを「無効」にします。「無効」にするとこのユーザーはログインすることができなくなります。</p>
                    </div>
                </div>
                <div className="row">
                    <div className="label">備考</div>
                    <div className="control">
                        <textarea id="UserNote" name="UserNote" cols="70" rows="5"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.UserNote || ''}
                        />
                        {state.errors?.UserNote &&
                            <span className="MessageTip error">{state.errors?.UserNote.Message}</span>
                        }
                        <p className="description">このデータに関する覚書きや参考情報などを入力してください。一覧画面ではこの内容を検索して利用することも可能です。</p>
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/master/user/list">キャンセル</LinkButton>
                <button type="submit" className="PrimaryButton">ユーザーを登録</button>
            </div>
        </div>
    )
}
export default withFormField(UserMasterInput);


function UserBuyerItem(props) {

    const buyer = props.buyer;

    const handleEraserClick = (e) => {
        e.stopPropagation();
        props.handleBuyerRemove(buyer);
    }

    return (
        <li className="UserBuyer">
            <div className="item">
                <span style={{marginRight:'.5rem'}}>
                    {buyer.BuyerId}
                    {buyer.BuyerPlaceId}
                </span>
                <span>
                    {buyer.BuyerPartyName}
                    {buyer.BuyerPlaceName}
                </span>
            </div>
            <button type="button" className="LinkButton eraser" onClick={handleEraserClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
            </button>
        </li>
    );
}

function UserSellerItem(props) {

    const seller = props.seller;

    const handleEraserClick = (e) => {
        e.stopPropagation();
        props.handleSellerRemove(seller);
    }

    return (
        <li className="UserSeller" key={seller.Id}>
            <div className="item">
                <span style={{marginRight:'.5rem'}}>
                    {seller.SellerId}
                </span>
                <span style={{marginRight:'.5rem'}}>
                    {Resource.getCategoryName(seller.SellerCategory)}
                </span>
                <span>
                    {seller.SellerName}
                </span>
            </div>
            <button type="button" className="LinkButton eraser" onClick={handleEraserClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
            </button>
        </li>
    );
}