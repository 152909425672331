import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import 'css/Main.css';

import * as API from 'components/API';
import Calendar from 'components/Calendar';
import { useAuthUser } from 'components/Authenticator';
import Forbidden from 'components/Forbidden';


function CountUp(props) {

    const SPEED = 10;
    const TARGET = props.counter || 0;
    const countUpRef = useRef(null);

    let intervalId = useRef(null);

    // target-20の値からstartにするか...
    let counter = 0;
    if (TARGET > 0) {
        counter = Math.max(TARGET - 20, 0);
    }

    useEffect(() => {
        // countUpRef.current.textContent = '-';
        if (TARGET >= 0) {
            // 初回レンダリング後に実行されるため、
            // refは今このタイミングで設定される
            intervalId.current = setInterval(()=>{
                if (countUpRef.current) {
                    countUpRef.current.textContent = counter++;
                    if (counter > TARGET) {
                        clearInterval(intervalId.current);
                    }
                } else {
                    clearInterval(intervalId.current);
                }
            }, SPEED);
        }
    }, [TARGET, counter]);

    return (
        <span className="CountUp" ref={countUpRef}>-</span>
    );
}

function BuyerMenu(props) {

    const { authUser } = useAuthUser();
    const [summaryData, setSummaryData] = useState([]);
    const history = useHistory();

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;

        API.get('/main/order/summary')
        .then(result => {
            console.log(result);
            if (mounted.current) {
                try {
                    setSummaryData(result);
                } catch (error) {
                    console.error(error);
                }
            }
        });
        return () => {
            mounted.current = false;
        }
    }, []);

    const handleOrderInputClick = (e) => {
        history.push('/business/order/input');
    }

    const orderTotalCount = summaryData?.ORDERED + summaryData?.PROCESSING + summaryData?.COMPLETED + summaryData?.CANCEL;

    const handleCountItemClick = (status) => {
        window.sessionStorage.removeItem('OrderList');
        history.push({
            pathname: '/business/order/list',
            state: { 'OrderStatus': status }
        });
    }

    return (
        <div className="Block OrderBlock">
            <h2>発注者</h2>
            <div className="OrderButton">
                <button type="button" className="PrimaryButton" onClick={handleOrderInputClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                    <span>発注データ作成</span>
                </button>
            </div>
            <div className="flexrow">
                <div className="OrderLink">
                    <div className="LinkTag">
                        <Link to="/business/order">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"/></g></svg>
                            <span>発注データ</span>
                        </Link>
                    </div>
                    <div className="LastUpdate">{summaryData?.LastUpdateDatetime || '-'}</div>
                    <div className="CountValue"><CountUp counter={orderTotalCount} />件</div>
                </div>
                <div className="CountGroup">
                    <div className="CountItem" onClick={e=>handleCountItemClick('ORDERED')}>
                        <div className="CountName ORDERED">発注済</div>
                        <div className="CountValue"><CountUp counter={summaryData?.ORDERED} />件</div>
                    </div>
                    <div className="CountItem" onClick={e=>handleCountItemClick('PROCESSING')}>
                        <div className="CountName PROCESSING">製造中</div>
                        <div className="CountValue"><CountUp counter={summaryData?.PROCESSING} />件</div>
                    </div>
                    <div className="CountItem" onClick={e=>handleCountItemClick('COMPLETED')}>
                        <div className="CountName COMPLETED">製造完了</div>
                        <div className="CountValue"><CountUp counter={summaryData?.COMPLETED} />件</div>
                    </div>
                    <div className="CountItem" onClick={e=>handleCountItemClick('CANCEL')}>
                        <div className="CountName CANCEL">キャンセル</div>
                        <div className="CountValue"><CountUp counter={summaryData?.CANCEL} />件</div>
                    </div>
                </div>
            </div>
            <ul className="UserBuyer">
{
authUser?.UserBuyerList?.map((buyer, index) => {

    let categories = (buyer.BuyerCategory || []).split(',');

    let catejsx = [];
    for (let i = 0; i < categories.length; i++) {
        catejsx.push(<span key={i} className={`category ${categories[i]}`}>{categories[i]}</span>);
    }

    return (
        <li key={'buyer' + index}>
            <div className="flexrow">
                <span className="BuyerId">{buyer.BuyerId}</span>
                <span className="BuyerPlaceId">{buyer.BuyerPlaceId}</span>
                <span className="BuyerPartyName">{buyer.BuyerPartyName}</span>
                <span className="BuyerPlaceName">{buyer.BuyerPlaceName}</span>
                <span className="BuyerCategory">{catejsx}</span>
            </div>
            <div>
                <span className="BuyerPostalCode">{buyer.BuyerPostalCode && `〒`+buyer.BuyerPostalCode}</span>
                <span className="BuyerAddress1">{buyer.BuyerAddress1}</span>
                <span className="BuyerAddress2">{buyer.BuyerAddress2}</span>
            </div>
            <div>
                <span className="BuyerTel">{buyer.BuyerTel && `tel: `+buyer.BuyerTel}</span>
                <span className="BuyerMail">{buyer.BuyerMail}</span>
            </div>
        </li>
    );
})
}
            </ul>
        </div>
    )
}

function SellerMenu(props) {

    const { authUser } = useAuthUser();
    const [summaryData, setSummaryData] = useState([]);
    const history = useHistory();

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;

        API.get('/main/sales/summary')
        .then(result => {
            console.log(result);
            if (mounted.current) {
                try {
                    setSummaryData(result);
                } catch (error) {
                    console.error(error);
                }
            }
        });
        return () => {
            mounted.current = false;
        }
    }, []);

    const salesTotalCount = summaryData?.ORDERED + summaryData?.PROCESSING + summaryData?.COMPLETED + summaryData?.CANCEL;

    const handleCountItemClick = (status) => {
        window.sessionStorage.removeItem('SalesList');
        history.push({
            pathname: '/business/sales/list',
            state: { 'OrderStatus': status }
        });
    }

    return (
        <div className="Block SalesBlock">
            <h2>受注者</h2>
            <div>
                <div className="flexrow">
                    <div className="SalesLink">
                        <div className="LinkTag">
                            <Link to="/business/sales">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5l1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/></svg>
                                <span>受注データ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={salesTotalCount} />件</div>
                    </div>
                    <div className="CountGroup">
                        <div className="CountItem" onClick={e=>handleCountItemClick('ORDERED')}>
                            <div className="CountName ORDERED">発注済</div>
                            <div className="CountValue"><CountUp counter={summaryData?.ORDERED} />件</div>
                        </div>
                        <div className="CountItem" onClick={e=>handleCountItemClick('PROCESSING')}>
                            <div className="CountName PROCESSING">製造中</div>
                            <div className="CountValue"><CountUp counter={summaryData?.PROCESSING} />件</div>
                        </div>
                        <div className="CountItem" onClick={e=>handleCountItemClick('COMPLETED')}>
                            <div className="CountName COMPLETED">製造完了</div>
                            <div className="CountValue"><CountUp counter={summaryData?.COMPLETED} />件</div>
                        </div>
                        <div className="CountItem" onClick={e=>handleCountItemClick('CANCEL')}>
                            <div className="CountName CANCEL">キャンセル</div>
                            <div className="CountValue"><CountUp counter={summaryData?.CANCEL} />件</div>
                        </div>
                    </div>
                </div>
                <ul className="UserSeller">
{
authUser?.UserSellerList?.map((seller, index) => {
    return (
        <li key={'seller' + index}>
            <div>
                <span className="SellerId">{seller.SellerId}</span>
                <span className="SellerCategory">{seller.SellerCategory}</span>
                <span className="SellerName">{seller.SellerName}</span>
            </div>
            <div>
                <span className="SellerPostalCode">{seller.SellerPostalCode && `〒`+seller.SellerPostalCode}</span>
                <span className="SellerAddress1">{seller.SellerAddress1}</span>
                <span className="SellerAddress2">{seller.SellerAddress2}</span>
            </div>
            <div>
                <span className="SellerTel">{seller.SellerTel && `tel: `+seller.SellerTel}</span>
            </div>
        </li>
    );
})
}
                </ul>
            </div>
        </div>
    )
}

function AdminMenu(props) {

    const [summaryData, setSummaryData] = useState([]);
    const history = useHistory();

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;

        API.get('/main/admin/summary')
        .then(result => {
            // console.log('/main/admin/summary', result);
            if (mounted.current) {
                try {
                    setSummaryData(result);
                } catch (error) {
                    console.error(error);
                }
            }
        });
        return () => {
            mounted.current = false;
        }
    }, []);

    let billingLastUpdate = '';
    let billingKensu12MonthAgo = 0;

    let firstYearMonth = null;
    let lastYearMonth = null;

    if (summaryData?.Billing) {
        let validBilling = (summaryData.Billing.find(function(item){ return (item.Kensu > 0) }));
        billingLastUpdate = validBilling?.LastUpdateDatetime || '-';
        billingKensu12MonthAgo = (summaryData.Billing.reduce(function(total, item){ return (total += item.Kensu) }, 0));

        firstYearMonth = new Calendar(summaryData.Billing[0].ProductEndMonth+'-01');
        lastYearMonth = new Calendar(summaryData.Billing[summaryData.Billing.length-1].ProductEndMonth+'-01');
    }

    const handleCountItemClick = (ym) => {
        history.push({
            pathname: '/business/billing/list',
            state: {'Range': ym}
        });
    }

    const today = new Calendar();

    return (
        <div className="Block AdminBlock">
            <h2>管理者</h2>
            <div>
                <div className="flexrow">
                    <div className="BillingLink">
                        <span className="Label">今月({today.format('n月分')})</span>
                        <div className="LinkTag">
                            <Link to="/business/billing">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z M6,20V4h7v4h5v12H6z M11,19h2v-1h1 c0.55,0,1-0.45,1-1v-3c0-0.55-0.45-1-1-1h-3v-1h4v-2h-2V9h-2v1h-1c-0.55,0-1,0.45-1,1v3c0,0.55,0.45,1,1,1h3v1H9v2h2V19z"/></svg>
                                <span>課金データ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{billingLastUpdate || '-'}</div>
                        <div className="CountValue"><CountUp counter={billingKensu12MonthAgo || 0} />件</div>
                    </div>
                    <div className="MonthlySummary">
                        <span className="Label">{firstYearMonth?.format('Y年m月')} から {lastYearMonth?.format('Y年m月')} まで</span>
                        <ul>
{
summaryData?.Billing?.map((row, index) => {
    const cal = new Calendar(row.ProductEndMonth);
    return (
        <li key={'billing' + index} onClick={e=>handleCountItemClick(cal.format('Y-m-01'))}>
            <h5 title={cal.format('Y年m月')}>{cal.format('n')}月</h5>
            <div className="CountValue"><CountUp counter={row.Kensu} />件</div>
        </li>
    );
})
}
                        </ul>
                    </div>
                </div>
                <ul className="MasterList">
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/buyer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>発注者マスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.BuyerMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.BuyerMaster?.Kensu} /></div>
                    </li>
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/seller">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>メーカーマスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.SellerMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.SellerMaster?.Kensu} /></div>
                    </li>
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/item">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>品番マスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.ItemMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.ItemMaster?.Kensu} /></div>
                    </li>
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/color">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>色コードマスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.ColorMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.ColorMaster?.Kensu} /></div>
                    </li>
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/size">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>サイズマスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.SizeMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.SizeMaster?.Kensu} /></div>
                    </li>
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/last">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>木型マスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.LastMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.LastMaster?.Kensu} /></div>
                    </li>
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/outsole">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>本底素材マスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.OutsoleMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.OutsoleMaster?.Kensu} /></div>
                    </li>
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/leather">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>革素材マスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.LeatherMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.LeatherMaster?.Kensu} /></div>
                    </li>
                    <li>
                        <div className="MasterLink">
                            <Link to="/master/user">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>
                                <span>ユーザーマスタ</span>
                            </Link>
                        </div>
                        <div className="LastUpdate">{summaryData?.UserMaster?.LastUpdateDatetime || '-'}</div>
                        <div className="CountValue"><CountUp counter={summaryData?.UserMaster?.Kensu} /></div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default function Main() {

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const { authUser } = useAuthUser();

    // ユーザーに関連する発注者および受注者がある場合それぞれの立場で必要なメニューが決まる

    // ユーザーの権限が管理者レベルのときはすべてtrue
    let asAdmin = (authUser.UserLevel === 'ADMINISTRATOR');
    if (asAdmin) {
        // システム管理者はぜーんぶ
        return (
            <div id="Main">
                <div className="Inner">
                    <BuyerMenu />
                    <SellerMenu />
                    <AdminMenu />
                </div>
            </div>
        );
    } else {
        // システム管理者ではない

        // ユーザーに発注者の関連がある場合はtrue
        let asBuyer = (authUser.UserBuyerList?.length);
        // ユーザーに受注者の関連がある場合はtrue
        let asSeller = (authUser.UserSellerList?.length);

        if (asBuyer) {
            if (asSeller) {
                // 発注者と受注者の場合
                return (
                    <div id="Main">
                        <div className="Inner">
                            <BuyerMenu />
                            <SellerMenu />
                        </div>
                    </div>
                );
            } else {
                // 発注者のみの場合
                return (
                    <div id="Main">
                        <div className="Inner">
                            <BuyerMenu />
                        </div>
                    </div>
                );
            }
        } else {
            // ここは発注者ではない
            if (asSeller) {
                // 受注者のみの場合
                return (
                    <div id="Main">
                        <div className="Inner">
                            <SellerMenu />
                        </div>
                    </div>
                );
            }
        }
    }
    return <Forbidden />
}
