import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import ColorMasterList from './ColorMasterList';
import ColorMasterInput from './ColorMasterInput';

export default function ColorMaster() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/list`} component={ColorMasterList} />
                <Route path={`${path}/input/:id`} component={ColorMasterInput} />
                <Route path={`${path}/input`} component={ColorMasterInput} />

                <Redirect path={`${path}`} to={`${path}/list`} />
            </Switch>
        </>
    );
}
