import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import CustomerList from './CustomerList';
import CustomerInput from './CustomerInput';

export default function Customer() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/list`} component={CustomerList} />
                <Route path={`${path}/input/:id`} component={CustomerInput} />
                <Route path={`${path}/input`} component={CustomerInput} />

                <Redirect path={`${path}`} to={`${path}/list`} />
            </Switch>
        </>
    );
}
