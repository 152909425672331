import React, { useState, useEffect } from 'react';
import 'css/OrderInput.css';

import { withFormField, useFormContext, FormContext } from 'components/FormField';

// 発注画面のステップ
import OrderInputStep1 from './OrderInputStep1';
import OrderInputStep2 from './OrderInputStep2';
import OrderInputStep3 from './OrderInputStep3';
import OrderInputStep4 from './OrderInputStep4';
import OrderInputStep5 from './OrderInputStep5';
import OrderInputStep6 from './OrderInputStep6';


function OrderInput() {

    // const { state, setFormState, setErrors, handleChange, handleSubmit, clearState } = useFormContext(FormContext);
    const form = useFormContext(FormContext);

    // ステップは1から
    const [step, setStep] = useState(1);

    useEffect(() => {
        // 画面遷移(stepが変わる)のたびにスクロール位置をトップに戻す
        let scrollPange = document.querySelector('main');
        if (scrollPange) {
            scrollPange.scrollTo(0, 0);
            // console.log('scrollをリセット');
        }
    }, [step]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHideForce');
            view.classList.remove('LeftSideHide');
        }
        return () => {
            setStep(1);
            if (view) {
                view.classList.remove('LeftSideHideForce');
            }
        }
    }, []);

    return (
        <>
            <div id="OrderInput">
                <h1>発注データの作成</h1>

                <div id="StepProgress">
                    <ul>
                        <li className={step === 1 ? 'progress active' : ''}>
                            <h4>STEP.1</h4>
                            <p>カテゴリを選択します。</p>
                        </li>
                        <li className={step === 2 ? 'progress active' : ''}>
                            <h4>STEP.2</h4>
                            <p>顧客情報を入力をします。</p>
                        </li>
                        <li className={step === 3 ? 'progress active' : ''}>
                            <h4>STEP.3</h4>
                            <p>足型測定データを入力をします。</p>
                        </li>
                        <li className={step === 4 ? 'progress active' : ''}>
                            <h4>STEP.4</h4>
                            <p>補正値をもとにワイズの確定をします。</p>
                        </li>
                        <li className={step === 5 ? 'progress active' : ''}>
                            <h4>STEP.5</h4>
                            <p>発注情報と品番を入力します。</p>
                        </li>
                        <li className={step === 6 ? 'progress active' : ''}>
                            <h4>STEP.6</h4>
                            <p>発注完了</p>
                        </li>
                    </ul>
                </div>

                {step === 1 && (
                    <OrderInputStep1 {...form} setStep={setStep} />
                )}
                {step === 2 && (
                    <OrderInputStep2 {...form} setStep={setStep} />
                )}
                {step === 3 && (
                    <OrderInputStep3 {...form} setStep={setStep} />
                )}
                {step === 4 && (
                    <OrderInputStep4 {...form} setStep={setStep} />
                )}
                {step === 5 && (
                    <OrderInputStep5 {...form} setStep={setStep} />
                )}
                {step === 6 && (
                    <OrderInputStep6 {...form} setStep={setStep} />
                )}
            </div>
        </>
    );
}
export default withFormField(OrderInput);
