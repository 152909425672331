

// UUIDの生成
export function generateUUID() {
    const chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
    for (let i = 0, len = chars.length; i < len; i++) {
        switch (chars[i]) {
            case "x":
                chars[i] = Math.floor(Math.random() * 16).toString(16);
                break;
            case "y":
                chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                break;
            default:
                break;
        }
    }
    return chars.join("");
}

export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.ceil(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

// よく使う伝播止め
export function stopPropagation(e) {
    if (e.stopPropagation) {
        e.stopPropagation();
    } else {
        return false;
    }
}

// スネークケースをキャメルケースに変換
export function toCamel(s) {
    const camel = s.replace(/_./g, function (str) {
        return str.charAt(1).toUpperCase();
    });
    return camel.charAt(0).toUpperCase() + camel.substring(1)
}

// 未定義、null、空文字を判定
export function isEmpty(o) {
    return (typeof o === 'undefined' || o === null || o === '');
}

// 浮動小数点型に変換
export function tryParseFloat(fval, fallback=0) {
    if (isEmpty(fval) || isNaN(fallback)) {
        fallback = 0;
    }
    try {
        const parsedValue = parseFloat(fval);
        if (isNaN(parsedValue)) {
            return fallback;
        }
        return parsedValue;
    } catch (e) {
        return fallback;
    }
}

// 0割りエラーを回避したい
export function devide(a, b) {
    try {
        let res = a / b;
        if (isNaN(res)) {
            return 0.0;
        }
        return parseFloat(res);
    } catch (e) {
        return 0.0;
    }
}

// 丸め、precisionは小数点以下の桁数
export function round(value, precision) {
    const digit = Math.pow(10, precision);
    return Math.round(value * digit) / digit;
}

// 秒を時間分秒の文字列に変換
export function formatTime(seconds) {
    if (seconds === 0) return '';
    let result = '';
    const hour = Math.floor((seconds / 60) / 60);
    const min = Math.floor((seconds / 60) % 60);
    const sec = Math.floor(seconds % 60);
    if (hour > 0) result += hour + '時間';
    if (min > 0) result += min + '分';
    if (sec > 0) result += sec + '秒';
    return result;
}

export function formDisabled(form, bool) {
    const fs = form.querySelectorAll('input,select,button');
    for (let i = 0; i < fs.length; i++) {
        fs[i].disabled = bool;
    }
}

// 透明なガラスを最前面に配置してイベントをブロックするやつ
export function getGlass(){
    const glass = document.createElement('div');
    glass.style.position = 'fixed';
    glass.style.top = '0px';
    glass.style.left = '0px';
    glass.style.right = '0px';
    glass.style.bottom = '0px';
    glass.style.zIndex = '99999999';
    glass.style.backgroundColor = 'rgba(0,0,0,.125)';
    glass.style.fontSize = '2rem';
    glass.style.color = '#fff';
    glass.style.display = 'flex';
    glass.style.flexFlow = 'row nowrap';
    glass.style.alignItems = 'center';
    glass.style.justifyContent = 'center';
    glass.style.userSelect = 'none';
    glass.innerHTML = '<div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"><div class="loader"></div></div>';
    // glass.style.pointerEvents = 'none';
    glass.addEventListener('keydown', e=>{e.stopPropagation()}, false);
    glass.addEventListener('keypress', e=>{e.stopPropagation()}, false);
    glass.addEventListener('keyup', e=>{e.stopPropagation()}, false);
    glass.addEventListener('scroll', e=>{e.stopPropagation()}, false);
    glass.addEventListener('click', e=>{e.stopPropagation()}, false);
    glass.addEventListener('mousedown', e=>{e.stopPropagation()}, false);
    glass.addEventListener('mouseup', e=>{e.stopPropagation()}, false);
    glass.addEventListener('touchstart', e=>{e.stopPropagation()}, false);
    glass.addEventListener('touchend', e=>{e.stopPropagation()}, false);
    glass.append = false;
    glass.show = function(){
        if (glass.append === false) {
            document.documentElement.appendChild(this);
            glass.append = true;
        }
    }
    glass.remove = function(){
        if (glass.append) {
            document.documentElement.removeChild(this);
            glass.append = false;
        }
    }
    return glass;
}

// inputタグの幅をvalue文字列の幅に合わせる
export function fixedInputWidthByValue(input)
{
    const css = window.getComputedStyle(input);
    // 文字幅測定用span
    const ruler = document.createElement('span');
    ruler.style.visibility = 'hidden';
    ruler.style.position = 'absolute';
    ruler.style.whiteSpace = 'nowrap';
    ruler.style.fontSize = css.fontSize;
    ruler.textContent = input.value;
    document.body.appendChild(ruler);
    const textWidth = ruler.offsetWidth; // 文字幅
    document.body.removeChild(ruler);
    const left = parseFloat(css.paddingLeft.replace('px',''));
    const right = parseFloat(css.paddingRight.replace('px',''));
    input.style.width = (parseInt(left + textWidth + right) + 6) + 'px';
}

// export function getDiffSecond(st, et) {
//     if (st && et) {
//         var sd = new Date('2099/01/01 '+st); // 年月日は関係ない
//         var ed = new Date('2099/01/01 '+et); // 年月日は関係ない
//         if (sd.getTime() > ed.getTime()) {
//             ed = addDate(ed, 1, 'D'); // 1日追加
//         }
//         return (ed.getTime() - sd.getTime()) / 1000;
//     }
//     return 0;
// }

console.log('Util.js loaded.');
