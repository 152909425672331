import React, { useEffect, useCallback, useRef } from 'react';
import * as PickerDialog from 'components/Dialog/PickerDialog';
import { DUE_DATE_TYPE } from 'components/Resource';
import { useAuthUser } from 'components/Authenticator';
import Calendar from 'components/Calendar';


export default function OrderOrderFrame(props) {

    const state = props.state;
    const setFormState = props.setFormState;
    const handleChange = props.handleChange;

    const readonly = props.readonly || false;
    const pickerRef = useRef(null);

    const showSellerPicker = useCallback(() => {
        if (pickerRef.current) {
            pickerRef.current.hide();
            pickerRef.current = null;
        }
        // カテゴリで絞る
        const picker = PickerDialog.createSellerPicker({
            condition: {
                BuyerId: state.fields.BuyerId,
                BuyerPlaceId: state.fields.BuyerPlaceId,
                SellerCategory: state.fields.OrderCategory1
            }
        });
        picker.setSelectedCallback((selected)=>{
            console.log(selected);
            setFormState({fields: {
                ...selected,
            }});
        });
        picker.show();
        pickerRef.current = picker;
    }, [setFormState, state.fields.OrderCategory1, state.fields.BuyerId, state.fields.BuyerPlaceId]);

    const showBuyerPicker = useCallback(() => {
        if (pickerRef.current) {
            pickerRef.current.hide();
            pickerRef.current = null;
        }
        // カテゴリで絞る
        const picker = PickerDialog.createBuyerPicker({
            condition: {
                BuyerCategory: state.fields.OrderCategory1
            }
        });
        picker.setSelectedCallback((selected)=>{
            console.log(selected);
            setFormState({fields: {
                ...selected,
                SellerId: null,
                SellerCategory: null,
                SellerName: null,
            }});
            console.log('showBuyerPickerのshowSellerPicker');
            showSellerPicker();
        });
        picker.show();
        pickerRef.current = picker;
    }, [setFormState, state.fields.OrderCategory1, showSellerPicker]);

    // 発注者選択
    const handleBuyerClick = (e) => {
        showBuyerPicker();
    }

    // 受注者選択
    const handleSellerAction = (e) => {
        if (state.fields.BuyerId) {
            console.log('handleSellerActionのshowSellerPicker');
            showSellerPicker();
        } else {
            // 発注者がセットされていない場合はピッカーも表示しない
            // alert('先に発注者を決定してください。');
        }
    }

    // 納期を選択
    const hanldeDueDateSelectorChange = (e) => {
        if (!e.target.value || !state.fields?.OrderDate) return;

        const selectedText = e.target.options[e.target.selectedIndex].text;

        // 発注日に納期分の日付を足す
        let dueDate = new Calendar(state.fields.OrderDate);

        const add = parseInt(e.target.value);
        dueDate = dueDate.addDate(add);
        setFormState({
            fields: {
                DueDateSelector: e.target.value,
                DueDateSelectorLabel: selectedText,
                DueDate: dueDate.format('Y-m-d')
            }
        });
    }

    const convertDueDateToWeek = (orderDate, dueDate) => {
        if (orderDate && dueDate) {
            let day1 = new Date(orderDate);
            let day2 = new Date(dueDate);
            let diff = (day2 - day1);
            if (diff > 0) {
                let diffDay = diff / (86400 * 1000);
                return diffDay / 7;
            }
        }
        return 0;
    }

    const session = useAuthUser();
    useEffect(()=>{
        if (!state.fields.BuyerId) {
            // 発注者がセットされてなければ、ユーザーに紐づく発注者の最初の1つ目をセット(カテゴリに一致する)
            if (session.authUser.UserBuyerList?.length) {
                let firstBuyer = null;
                for (let i = 0; i < session.authUser.UserBuyerList.length; i++) {
                    let b = session.authUser.UserBuyerList[i];
                    if (b.BuyerCategory.indexOf(state.fields.OrderCategory1) >= 0) {
                        firstBuyer = b;
                        break;
                    }
                }
                // let firstBuyer = session.authUser.UserBuyerList[0];
                console.log(firstBuyer);
                setFormState({fields: {...firstBuyer}});
            } else {
                showBuyerPicker();
            }
        } else {
            // 発注者がセットされていればpickerを発動したい
            // いきなりshowSellerPicker()とすると、登録後のreadonlyの画面でもpickerが表示されてしまうので
            // elementがあるときを条件に
            let sellerIdElement = document.querySelector('input[name=SellerId]');
            if (sellerIdElement) {
                showSellerPicker();
            }
        }
    }, [
        state.fields.OrderCategory1, state.fields.BuyerId, 
        session.authUser.UserBuyerList, setFormState, showBuyerPicker, showSellerPicker
    ]);

    return (
        <>
        {readonly === false && (
            <>
            <details id="OrderFrame" open>
                <summary>発注情報</summary>
                <div className="OrderInfo">
                    <div className="flexrow">
                        <div className="grow1">
                            <div className="row">
                                <div className="label">メーカー</div>
                                <div className="control">
                                    <input type="text" name="SellerId" readOnly={true}
                                        placeholder="メーカーを選択"
                                        onFocus={handleSellerAction}
                                        onClick={handleSellerAction}
                                        value={(state.fields?.SellerId || '') + ' ' + (state.fields?.SellerName || '')}
                                        style={{minWidth:'12rem'}}
                                        className={state.errors?.SellerId && 'invalid'}
                                    />
                                    {state.errors?.SellerId &&
                                        <span className="messageTip error">{state.errors?.SellerId.Message}</span>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="label">発注日</div>
                                <div className="control">
                                    <input type="date" id="OrderDate" name="OrderDate"
                                        value={state.fields?.OrderDate || ''}
                                        onChange={handleChange}
                                        style={{minWidth:'12rem'}}
                                        className={state.errors?.OrderDate && 'invalid'}
                                    />
                                    {state.errors?.OrderDate &&
                                        <span className="messageTip error">{state.errors?.OrderDate.Message}</span>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="label"></div>
                                <div className="control">
                                    <div className="flexrow">
                                        <select name="DueDateSelector" id="DueDateSelector"
                                            value={state.fields?.DueDateSelector || ''}
                                            onChange={hanldeDueDateSelectorChange}
                                            style={{minWidth:'12rem'}}
                                        >
                                            <option value="" style={{fontWeight:'bold'}}>↓ 納期を指定</option>
                                            {DUE_DATE_TYPE.map((row, index) => (
                                                <option key={row.key} value={row.key}>{row.value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="label">納期</div>
                                <div className="control">
                                    <input type="date" id="DueDate" name="DueDate"
                                        value={state.fields?.DueDate || ''}
                                        onChange={handleChange}
                                        style={{minWidth:'12rem'}}
                                        className={state.errors?.DueDate && 'invalid'}
                                    />
                                    {state.errors?.DueDate &&
                                        <span className="messageTip error">{state.errors?.DueDate.Message}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="grow1">
                            <div className="row">
                                <div className="label">発注者</div>
                                <div className="control">
                                    <input type="text" name="BuyerId" readOnly={true}
                                        placeholder="発注者を選択"
                                        onClick={handleBuyerClick}
                                        value={(state.fields.BuyerId || '')+(state.fields.BuyerPlaceId || '')}
                                        style={{minWidth:'12rem'}}
                                        className={state.errors?.BuyerId && 'invalid'}
                                    />
                                    {state.errors?.BuyerId &&
                                        <span className="messageTip error">{state.errors?.BuyerId.Message}</span>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="label"></div>
                                <div className="control">
                                    <div className="value" style={{marginTop:'.25rem'}}>
                                        <span style={{display:'inline-block'}}>{state.fields?.BuyerPartyName || ''}</span>
                                        <span style={{display:'inline-block'}}>{state.fields?.BuyerPlaceName || ''}</span>
                                    </div>
                                    <div>
                                        <div style={{marginTop:'.25rem'}}>〒{state.fields?.BuyerPostalCode || ''}</div>
                                        <div style={{marginTop:'.25rem'}}>
                                            <span style={{display:'inline-block'}}>{state.fields?.BuyerAddress1 || ''}</span>
                                            <span style={{display:'inline-block'}}>{state.fields?.BuyerAddress2 || ''}</span>
                                        </div>
                                        <div style={{marginTop:'.25rem'}}>{state.fields?.BuyerTel || ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </details>
            </>
        )}
        {readonly && (
            <>
            <details id="OrderFrame" open>
                <summary>発注情報</summary>
                <div className="OrderInfoReadonly">

                    <div className="flexrow">
                        <div className="grow1">
                            <div className="row">
                                <div className="title">発注番号</div>
                                <div className="value orderNumber">
                                    <div>{state.fields.OrderId || ''}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">メーカー</div>
                                <div className="value">
                                    <div>{(state.fields.SellerId || '') + ' ' + (state.fields.SellerName || '')}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">発注日</div>
                                <div className="value">
                                    <div>{state.fields.OrderDate ? (new Calendar(state.fields.OrderDate)).format('Y年m月d日') : ''}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">納期</div>
                                <div className="value">
                                    <div>
                                        {state.fields.DueDate ? (new Calendar(state.fields.DueDate)).format('Y年m月d日') : ''}
                                        （{convertDueDateToWeek(state.fields.OrderDate, state.fields.DueDate)}週間後）
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grow1 row">
                            <div className="title" style={{alignSelf:'flex-start',marginTop:'.5rem'}}>発注者</div>
                            <div className="value" style={{alignSelf:'flex-start'}}>
                                <div className="buyerId">{(state.fields.BuyerId || '') + (state.fields.BuyerPlaceId || '')}</div>
                                <div className="buyerName">{(state.fields.BuyerPartyName || '') + ' ' + (state.fields.BuyerPlaceName || '')}</div>
                                <div className="postalCode">〒{state.fields.BuyerPostalCode || ''}</div>
                                <div className="address">{(state.fields.BuyerAddress1 || '')+(state.fields.BuyerAddress2 || '')}</div>
                                <div className="tel">{state.fields.BuyerTel || ''}</div>
                                <div className="mail">{state.fields.BuyerMail || ''}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </details>
            </>
        )}
        </>
    )
}
