import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import LastMasterList from './LastMasterList';
import LastMasterInput from './LastMasterInput';

export default function LastMaster() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/list`} component={LastMasterList} />
                <Route path={`${path}/input/:id`} component={LastMasterInput} />
                <Route path={`${path}/input`} component={LastMasterInput} />

                <Redirect path={`${path}`} to={`${path}/list`} />
            </Switch>
        </>
    );
}
