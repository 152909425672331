import React from 'react';
import { useHistory } from 'react-router-dom';
// import { useAuthUser } from 'components/Authenticator';

import 'css/OrderInput.css';

import * as Util from 'components/Util';
// import * as API from 'components/API';
import OrderCustomerFrame from './OrderCustomerFrame';
import OrderFittingFrame from './OrderFittingFrame';
import OrderSimulateFrame from './OrderSimulateFrame';

// import Calendar from 'components/Calendar';
// import MessageDialog from 'components/Dialog/MessageDialog';

export default function OrderInputStep4(props) {

    const state = props.state;
    const setFormState = props.setFormState;
    // const setErrors = props.setErrors;
    const setStep = props.setStep;

    // console.log('OrderInputStep4', props.state);

    const history = useHistory();
    // const session = useAuthUser();

    const handleStepSubmit = () => {
        setFormState({
            fields: {
                // ユーザーの入力をDBカラムに置き換え
                LeftLength: state.fields.UserLeftLength,
                LeftMaxWidth: state.fields.UserLeftMaxWidth,
                LeftMinWidth: state.fields.UserLeftMinWidth,
                LeftMaxInstep: state.fields.UserLeftMaxInstep,
                LeftMinInstep: state.fields.UserLeftMinInstep,
                RightLength: state.fields.UserRightLength,
                RightMaxWidth: state.fields.UserRightMaxWidth,
                RightMinWidth: state.fields.UserRightMinWidth,
                RightMaxInstep: state.fields.UserRightMaxInstep,
                RightMinInstep: state.fields.UserRightMinInstep,
                // completeをjudgeに置き換え
                LeftJudgeLast: state.fields.LeftCompleteLast,
                LeftJudgeBall: state.fields.LeftCompleteBall,
                LeftJudgeWaist: state.fields.LeftCompleteWaist,
                LeftJudgeLength: state.fields.LeftCompleteLength,
                LeftJudgeWidth: state.fields.LeftCompleteWidth,
                LeftJudgeInstep: state.fields.LeftCompleteInstep,
                RightJudgeLast: state.fields.RightCompleteLast,
                RightJudgeBall: state.fields.RightCompleteBall,
                RightJudgeWaist: state.fields.RightCompleteWaist,
                RightJudgeLength: state.fields.RightCompleteLength,
                RightJudgeWidth: state.fields.RightCompleteWidth,
                RightJudgeInstep: state.fields.RightCompleteInstep,
            }
        })
        setStep(5);
    }

    const handleCancelClick = () => {
        if (window.confirm('発注データの作成をキャンセルしますか？\n入力中のデータは保存されません。')) {
            history.push('/business/order');
        }
    }
    const handlePrevStepClick = () => {
        // 試算結果をリセット
        setFormState({
            fields: {
                LeftCompleteLast: null,
                LeftCompleteBall: null,
                LeftCompleteWaist: null,
                LeftCompleteLength: null,
                LeftCompleteWidth: null,
                LeftCompleteInstep: null,
                LeftJudgeLast: null,
                LeftJudgeBall: null,
                LeftJudgeWaist: null,
                LeftJudgeLength: null,
                LeftJudgeWidth: null,
                LeftJudgeInstep: null,
                LeftJudge: null,
                LeftJudgeNote: null,
                LeftJudgeGrade: null,
                RightCompleteLast: null,
                RightCompleteBall: null,
                RightCompleteWaist: null,
                RightCompleteLength: null,
                RightCompleteWidth: null,
                RightCompleteInstep: null,
                RightJudgeLast: null,
                RightJudgeBall: null,
                RightJudgeWaist: null,
                RightJudgeLength: null,
                RightJudgeWidth: null,
                RightJudgeInstep: null,
                RightJudge: null,
                RightJudgeNote: null,
                RightJudgeGrade: null,
                RightAdjustWidth: null,
                RightAdjustInstep: null,
                LeftAdjustWidth: null,
                LeftAdjustInstep: null,
            }
        })
        setStep(3);
    }

    let cannotProduction = false;
    if (Util.tryParseFloat(state.fields.RightCompleteBall) < 0
        || Util.tryParseFloat(state.fields.RightCompleteWaist) < 0
        || Util.tryParseFloat(state.fields.LeftCompleteBall) < 0
        || Util.tryParseFloat(state.fields.LeftCompleteWaist) < 0) {
            cannotProduction = true;
    }

    return (
        <>
            <div id="OrderSheet">
                <h1>
                    <span className="OrderTitle">{state.fields.OrderCategory1} ORDER SHEET</span>
                </h1>
                <div id="OrderSheetLayout">
                    <OrderCustomerFrame {...props} readonly={true} />
                    <OrderFittingFrame {...props} readonly={true} open={true}/>
                    <OrderSimulateFrame {...props} />
                </div>
            </div>
            <div className="ButtonGroup">
                <button type="button" className="LinkButton" onClick={handleCancelClick}>キャンセル</button>
                <button type="button" className="LinkButton" onClick={handlePrevStepClick}>前のステップへ</button>
                <div className="grow1"></div>
                <button type="button" className="PrimaryButton" onClick={handleStepSubmit} disabled={cannotProduction}>
                    <span>次のステップへ</span>
                </button>
            </div>
        </>

    );
}