import React, { useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import 'css/BuyerMasterInput.css';

import * as API from 'components/API';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import Radio from 'components/Radio';
import LinkButton from 'components/LinkButton';
import Checkbox from 'components/Checkbox';
// import * as PickerDialog from 'components/Dialog/PickerDialog';
import { getCategoryName, ORDER_CATEGORY1 } from 'components/Resource';
import * as PickerDialog from 'components/Dialog/PickerDialog';


function BuyerMasterInput(props) {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setFormState = form.setFormState;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        if (param.id) {
            let result = await API.get('/master/buyer/input?id='+param.id);
            if (mounted.current) {
                console.log(result.Data);
                setFormState({
                    fields: result.Data,
                    lastRefresh: Date.now
                });
            }
        } else {
            // ID指定がない新規入力
        }
    }, [param.id, setFormState]);

    const handleMainScroll = (e) => {
        const h1tag = document.querySelector('.InputContents h1');
        if (h1tag) {
            if (e.target.scrollTop > 0) {
                h1tag.classList.add('small');
            } else {
                h1tag.classList.remove('small');
            }
        }
    }

    // フォーカス時にtext全選択状態にするやつ
    const selectOnFocus = (e) => e.target.select();

    useEffect(() => {
        mounted.current = true;

        const main = document.querySelector('#View main');
        main.addEventListener('scroll', handleMainScroll, false);

        if (param.id) {
            // 編集時はデータ取得
            findData();
        } else {
            // 新規登録時の初期値セット
            setFormState({
                fields: {
                    BuyerStatus: 'ENABLED'
                }
            });
        }
        return () => {
            mounted.current = false;
            main.removeEventListener('scroll', handleMainScroll, false);
        }
    }, [param.id, findData, setFormState]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.BuyerId) {
            errors.BuyerId = {
                Message: '発注者IDは必須入力です。'
            }
        }
        if (!fields.BuyerPlaceId) {
            errors.BuyerPlaceId = {
                Message: '発注者店舗IDは必須入力です。'
            }
        }
        if (!fields.BuyerPartyName) {
            errors.BuyerPartyName = {
                Message: '会社名は必須入力です。'
            }
        }
        if (!fields.BuyerSellerList?.length) {
            errors.BuyerSellerList = {
                Message: 'メーカーの関連付けは必須です。'
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        // 発注者リストとメーカーリストは手作りしなかんでなぁ。。。
        let formData = new FormData();

        formData.append('Id', param.id);
        formData.append('BuyerId', state.fields?.BuyerId || '');
        formData.append('BuyerPartyName', state.fields?.BuyerPartyName || '');
        formData.append('BuyerPlaceId', state.fields?.BuyerPlaceId || '');
        formData.append('BuyerPlaceName', state.fields?.BuyerPlaceName || '');
        formData.append('BuyerCategory', state.fields?.BuyerCategory || '');
        formData.append('BuyerPostalCode', state.fields?.BuyerPostalCode || '');
        formData.append('BuyerAddress1', state.fields?.BuyerAddress1 || '');
        formData.append('BuyerAddress2', state.fields?.BuyerAddress2 || '');
        formData.append('BuyerTel', state.fields?.BuyerTel || '');
        formData.append('BuyerMail', state.fields?.BuyerMail || '');
        formData.append('BuyerStatus', state.fields?.BuyerStatus || '');
        formData.append('BuyerNote', state.fields?.BuyerNote || '');

        for (let i = 0; i < state.fields?.BuyerSellerList?.length; i++) {
            let seller = state.fields?.BuyerSellerList[i];
            formData.append('BuyerSellerList[]', seller.Id); // Idを渡しておくか
        }

        formData.append('UpdateDatetime', state.fields?.UpdateDatetime || '');

        const result = await API.post('/master/buyer/update', formData);
        if (result?.Result) {
            // [OK] 一覧画面に遷移する
            history.push('/master/buyer/list');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result.Errors);
        }
    });

    const handlePostalCodeInput = async e => {
        let result = await API.searchZip(e.target.value);
        if (result) {
            let fullAddress = result.Address1 + result.Address2 + result.Address3;
            setFormState({
                fields: {
                    BuyerPostalCode: result.Zipcode.slice(0,3) + '-' + result.Zipcode.slice(3,7),
                    BuyerAddress1: fullAddress
                }
            });
        }
    }


    // メーカーを選択するやつ
    const handleAddSellerClick = (e) => {
        e.stopPropagation();
        let sellers = state.fields.BuyerSellerList || [];
        let selectedIds = sellers.map(seller => seller.Id);
        const picker = PickerDialog.createSellerPicker({
            exceptList: selectedIds
        });
        picker.setSelectedCallback((selected)=>{
            // console.log(selected);
            const newList = [...sellers, selected];
            setFormState({
                fields: {
                    BuyerSellerList: newList
                }
            });
        });

        picker.show();
    }
    // メーカーを削除する×ボタン
    const handleSellerRemove = (target) => {
        let sellers = state.fields.BuyerSellerList || [];
        const newList = sellers.filter(x => x.Id !== target.Id);
        setFormState({
            fields: {
                BuyerSellerList: newList
            }
        });
    }

    return (
        <div id="BuyerMasterInput" className="InputContents">
            <h1>
                <Link to="/master/buyer/list">
                    <svg xmlns="http://www.w3.org/2000/svg"><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
                </Link>
                <span>発注者マスタ</span>
                <div className="RequireHint"><span className="RequireMark">✱</span>必須入力</div>
            </h1>
            {state.errors?.Page?.map(message => (
                <div className="message error">{message.Message}</div>
            ))}
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">発注者ID</div>
                    <div className="control">
                        <input type="text" id="BuyerId" name="BuyerId"
                            placeholder="発注者ID"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerId || ''}
                            className={state.errors?.BuyerId && 'invalid'}
                        />
                        {state.errors?.BuyerId &&
                            <span className="MessageTip error">{state.errors?.BuyerId.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">発注者店舗ID</div>
                    <div className="control">
                        <input type="text" id="BuyerPlaceId" name="BuyerPlaceId"
                            placeholder="店舗ID"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerPlaceId || ''}
                            className={state.errors?.BuyerPlaceId && 'invalid'}
                        />
                        {state.errors?.BuyerPlaceId &&
                            <span className="MessageTip error">{state.errors?.BuyerPlaceId.Message}</span>
                        }
                        <p className="description">
                            発注者IDと発注者店舗IDの組み合わせを一意に登録してください。同じ組み合わせのものは登録できません。
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="label">カテゴリ</div>
                    <div className="control">
                        <div>
                        {ORDER_CATEGORY1.map((category, index) => (
                            <Checkbox key={`BuyerCategory_${category.key}`}
                                label={`${category.key}(${category.value})`}
                                name="BuyerCategory"
                                value={category.key}
                                selected={state.fields?.BuyerCategory}
                                onChange={handleChange}
                            />
                        ))}
                        </div>
                        {state.errors?.BuyerCategory &&
                            <span className="MessageTip error">{state.errors?.BuyerCategory.Message}</span>
                        }
                        <p className="description">
                            発注データ作成時に選択できるカテゴリにチェックを入れます。チェックがないカテゴリの発注データは作成できません。
                        </p>
                    </div>
                </div>
            </div>

            <div className="rowGroup">
                <div className="row">
                    <div className="label require"></div>
                    <div className="control">
                        <p className="description">この発注者が発注できる1つ以上のメーカーを関連付けてください。</p>
                    </div>
                </div>
                <div className="row">
                    <div className="label">関連するメーカー</div>
                    <div className="control">
                        <div id="BuyerSellerList">
{
(state.fields?.BuyerSellerList?.length || 0) === 0 && (
    <div className="Message info">
        <h5>Help</h5>
        <p>メーカーを追加ボタンをクリックして表示されるメーカーの一覧からこの発注者に関連付けるメーカーを選択します。</p>
    </div>
)
}
{
((buyerSeller)=>{
    if (!buyerSeller || buyerSeller.length === 0) {
        return <></>;
    }
    // BuyerSellerIdでソートしたい(登録順)
    buyerSeller.sort((a, b) => a.BuyerSellerId - b.BuyerSellerId);

    let jsx = [];
    for (let i = 0; i < buyerSeller.length; i++) {
        let row = buyerSeller[i];
        jsx.push(
            <BuyerSellerItem key={'seller'+row.Id} seller={row} handleSellerRemove={handleSellerRemove}/>
        );
    }
    return jsx;

})(state.fields?.BuyerSellerList)
}
                            <div style={{marginTop:'.5rem'}}>
                                <button type="button" onClick={handleAddSellerClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                                    <span>メーカーを追加</span>
                                </button>
                            </div>
                        </div>
                        {state.errors?.BuyerSellerList &&
                            <span className="MessageTip error">{state.errors?.BuyerSellerList.Message}</span>
                        }
                    </div>
                </div>
            </div>

            <div className="rowGroup">
                <div className="row">
                    <div className="label require">会社名</div>
                    <div className="control">
                        <input type="text" id="BuyerPartyName" name="BuyerPartyName"
                            placeholder="会社名"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerPartyName || ''}
                            style={{width: '25rem'}}
                            className={state.errors?.BuyerPartyName && 'invalid'}
                        />
                        {state.errors?.BuyerPartyName &&
                            <span className="MessageTip error">{state.errors?.BuyerPartyName.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">店舗名</div>
                    <div className="control">
                        <input type="text" id="BuyerPlaceName" name="BuyerPlaceName"
                            placeholder="店舗名"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerPlaceName || ''}
                            style={{width: '25rem'}}
                            className={state.errors?.BuyerPlaceName && 'invalid'}
                        />
                        {state.errors?.BuyerPlaceName &&
                            <span className="MessageTip error">{state.errors?.BuyerPlaceName.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">郵便番号</div>
                    <div className="control">
                        <span className="zipmark">〒</span>
                        <input type="text" id="BuyerPostalCode" name="BuyerPostalCode"
                            placeholder="例）480-0101"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            onInput={handlePostalCodeInput}
                            value={state.fields?.BuyerPostalCode || ''}
                            className={state.errors?.BuyerPostalCode && 'invalid'}
                        />
                        {state.errors?.BuyerPostalCode &&
                            <span className="MessageTip error">{state.errors?.BuyerPostalCode.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label">住所</div>
                    <div className="control">
                        <input type="text" id="BuyerAddress1" name="BuyerAddress1"
                            placeholder="都道府県市区町村（郵便番号より自動引用）"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerAddress1 || ''}
                            style={{width: '28rem'}}
                            className={state.errors?.BuyerAddress1 && 'invalid'}
                        />
                        {state.errors?.BuyerAddress1 &&
                            <span className="MessageTip error">{state.errors?.BuyerAddress1.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label"></div>
                    <div className="control">
                        <input type="text" id="BuyerAddress2" name="BuyerAddress2"
                            placeholder="番地ビル建物名（他残りの住所）"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerAddress2 || ''}
                            style={{width: '28rem'}}
                            className={state.errors?.BuyerAddress2 && 'invalid'}
                        />
                        {state.errors?.BuyerAddress2 &&
                            <span className="MessageTip error">{state.errors?.BuyerAddress2.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">電話番号</div>
                    <div className="control">
                        <input type="text" id="BuyerTel" name="BuyerTel"
                            placeholder="例）090-1234-5678"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerTel || ''}
                            className={state.errors?.BuyerTel && 'invalid'}
                        />
                        {state.errors?.BuyerTel &&
                            <span className="MessageTip error">{state.errors?.BuyerTel.Message}</span>
                        }
                        <p className="description">電話番号はハイフンを付けてください。</p>
                    </div>
                </div>
                <div className="row">
                    <div className="label">Eメール</div>
                    <div className="control">
                        <input type="text" id="BuyerMail" name="BuyerMail"
                            placeholder="例) buyername@email.com"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerMail || ''}
                            style={{width: '28rem'}}
                            className={state.errors?.BuyerMail && 'invalid'}
                        />
                        {state.errors?.BuyerMail &&
                            <span className="MessageTip error">{state.errors?.BuyerMail.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label">ステータス</div>
                    <div className="control">
                        <div className="radioGroup row">
                            <Radio label="有効" name="BuyerStatus" value="ENABLED" onChange={handleChange}
                                checked={state.fields?.BuyerStatus === 'ENABLED'}
                            />
                            <Radio label="無効" name="BuyerStatus" value="DISABLED" onChange={handleChange}
                                checked={state.fields?.BuyerStatus === 'DISABLED'}
                            />
                        </div>
                        {state.errors?.BuyerStatus &&
                            <span className="MessageTip error">{state.errors?.BuyerStatus.Message}</span>
                        }
                        <p className="Advice">無効にすると選択肢に表示されなくなります。</p>
                    </div>
                </div>
                <div className="row">
                    <div className="label">備考</div>
                    <div className="control">
                        <textarea id="BuyerNote" name="BuyerNote" cols="30" rows="5"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.BuyerNote || ''}
                        ></textarea>
                        {state.errors?.BuyerNote &&
                            <span className="MessageTip error">{state.errors?.BuyerNote.Message}</span>
                        }
                        <p className="description">このデータに関する覚書きや参考情報などを入力してください。一覧画面ではこの内容を検索して利用することも可能です。</p>
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/master/buyer/list">キャンセル</LinkButton>
                <button type="submit" className="PrimaryButton">発注者を登録</button>
            </div>
        </div>
    )
}
export default withFormField(BuyerMasterInput);



function BuyerSellerItem(props) {

    const seller = props.seller;

    const handleEraserClick = (e) => {
        e.stopPropagation();
        props.handleSellerRemove(seller);
    }

    return (
        <li className="BuyerSeller" key={seller.Id}>
            <div className="item">
                <span style={{marginRight:'.5rem'}}>
                    {seller.SellerId}
                </span>
                <span style={{marginRight:'.5rem'}}>
                    {getCategoryName(seller.SellerCategory)}
                </span>
                <span>
                    {seller.SellerName}
                </span>
            </div>
            <button type="button" className="LinkButton eraser" onClick={handleEraserClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
            </button>
        </li>
    );
}
