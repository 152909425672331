import React, { useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router';
import 'css/OrderInput.css';

import * as API from 'components/API';
// import * as Resourse from 'components/Resource';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import OrderCustomerFrame from 'pages/Business/Order/OrderCustomerFrame';
import OrderFittingFrame from 'pages/Business/Order/OrderFittingFrame';
import OrderOrderFrame from 'pages/Business/Order/OrderOrderFrame';
import OrderShoesFrame from 'pages/Business/Order/OrderShoesFrame';
import OrderFooterFrame from 'pages/Business/Order/OrderFooterFrame';
import { APP_BASENAME } from 'index';

function SalesView(props) {

    const form = useFormContext(FormContext);
    const state = form.state;
    const setFormState = form.setFormState;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async () => {
        setFormState({ loading: true });

        let result = await API.get('/sales/input?Id='+param.Id);
        if (result.Data) {
            if (mounted.current) {
                // console.log(result.Data);
                setFormState({
                    fields: result.Data
                });
                setTimeout(()=>{
                    // 閉じているdetailsはすべてopenにしておく
                    let details = document.querySelectorAll('#OrderSheetLayout details');
                    if (details) for (let i = 0; i < details.length; i++) details[i].open = true;
                }, 0);
            }
        } else {
            // 存在しないOrderIdを指定されたか、閲覧権限のないデータだったかのどちらか
            console.log('Not found data of '+param.Id);
        }
        setFormState({ loading: false });

    }, [param.Id, setFormState]);

    useEffect(() => {
        mounted.current = true;
        if (param.Id) {
            // 参照時はデータ取得
            findData();
        } else {
            // 新規はありえない
            alert('データを指定してください。');
        }
        return () => {
            mounted.current = false;
        }
    }, [param.Id, findData]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const handleCancelClick = () => {
        history.push('/business/sales/list');
    }

    // 受注画面印刷
    const handleScreenPrintClick = (e) => {
        // 閉じているdetailsはすべてopenにしておく
        let details = document.querySelectorAll('#OrderSheetLayout details');
        if (details) {
            for (let i = 0; i < details.length; i++) {
                details[i].open = true;
            }
        }
        // スクロールを一番上にしておく
        document.querySelector('#Container').scrollTo(0, 0);
        window.print();
    }

    // タックシール印刷
    const handleTackPrintClick = (e) => {
        window.open(APP_BASENAME+'/business/print/tack/'+param.Id, '_blank');
    }
    // 縫製・裁断指示書印刷
    const handleDirectionsPrintClick = (e) => {
        window.open(APP_BASENAME+'/business/print/directions/'+param.Id, '_blank');
    }

    return (
        <>
            <div id="SalesView">
                <div id="OrderSheet" className={`status_` + state.fields?.OrderStatus}>
                    <h1>
                        <span className="OrderTitle">{state.fields.OrderCategory1} ORDER SHEET</span>
                    </h1>
                    <div id="OrderSheetLayout">
                    {state.loading === false && (
                        state.fields.Id ? (
                            <>
                            <OrderOrderFrame {...form} readonly={true} />
                            <OrderCustomerFrame {...form} readonly={true} />
                            <OrderFittingFrame {...form} readonly={true} />
                            <OrderShoesFrame {...form} readonly={true} />
                            <OrderFooterFrame {...form} />
                            </>
                        ) : (
                            <div style={{textAlign:'center'}}>
                                <p>データは見つかりませんでした。</p>
                            </div>
                        )
                    )}
                    </div>
                </div>
                <div className="ButtonGroup">
                    <button type="button" className="LinkButton" onClick={handleCancelClick}>一覧に戻る</button>
                    <div className="grow1"></div>
                    <button type="button" className="PrimaryButton" onClick={handleTackPrintClick}>タックシール印刷</button>
                    <button type="button" className="PrimaryButton" onClick={handleScreenPrintClick}>受注画面印刷</button>
                    <button type="button" className="PrimaryButton" onClick={handleDirectionsPrintClick}>縫製・裁断指示書印刷</button>
                </div>
            </div>
        </>
    );
}
export default withFormField(SalesView);