import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import OutsoleMasterList from './OutsoleMasterList';
import OutsoleMasterInput from './OutsoleMasterInput';

export default function OutsoleMaster() {
    const { path } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${path}/list`} component={OutsoleMasterList} />
                <Route path={`${path}/input/:id`} component={OutsoleMasterInput} />
                <Route path={`${path}/input`} component={OutsoleMasterInput} />

                <Redirect path={`${path}`} to={`${path}/list`} />
            </Switch>
        </>
    );
}
