import React, { useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import 'css/SizeMasterInput.css';

import * as API from 'components/API';
import { withFormField, useFormContext, FormContext } from 'components/FormField';

import LinkButton from 'components/LinkButton';
import TypeNumber from 'components/TypeNumber';
import { SIZE_CATEGORY } from 'components/Resource';

function SizeMasterInput(props) {
    const form = useFormContext(FormContext);

    const state = form.state;
    const setFormState = form.setFormState;
    const setErrors = form.setErrors;
    const handleChange = form.handleChange;
    const handleSubmit = form.handleSubmit;

    const history = useHistory();

    const param = useParams();
    const mounted = useRef(false);

    const findData = useCallback(async ()=>{
        if (param.id) {
            let result = await API.get('/master/size/input?id='+param.id);
            if (mounted.current) {
                setFormState({
                    fields: result.Data
                });
            }
        } else {
            // ID指定がない新規入力
        }
    }, [param.id, setFormState]);

    const handleMainScroll = (e) => {
        const h1tag = document.querySelector('.InputContents h1');
        if (h1tag) {
            if (e.target.scrollTop > 0) {
                h1tag.classList.add('small');
            } else {
                h1tag.classList.remove('small');
            }
        }
    }

    // フォーカス時にtext全選択状態にするやつ
    const selectOnFocus = (e) => e.target.select();

    useEffect(() => {
        mounted.current = true;

        const main = document.querySelector('#View main');
        main.addEventListener('scroll', handleMainScroll, false);

        if (param.id) {
            // 編集時はデータ取得
            findData();
        } else {
            // 新規登録時の初期値セット
        }
        return () => {
            mounted.current = false;
            main.removeEventListener('scroll', handleMainScroll, false);
        }
    }, [param.id, findData]);

    useEffect(() => {
        // 左のメニューを引っ込めておく
        let view = document.querySelector('#View');
        if (view) {
            view.classList.add('LeftSideHide');
        }
        return () => {
            if (view) {
                view.classList.remove('LeftSideHide');
            }
        }
    }, []);

    const validation = () => {
        let fields = state.fields;
        let errors = {};

        if (!fields.SizeCategory) {
            errors.SizeCategory = {
                Message: 'カテゴリは必須入力です。'
            }
        }
        if (!fields.FootLength) {
            errors.FootLength = {
                Message: '足長は必須入力です。'
            }
        }
        if (!fields.FootWidth) {
            errors.FootWidth = {
                Message: '足囲は必須入力です。'
            }
        }
        if (!fields.SizeInstep) {
            errors.SizeInstep = {
                Message: '甲まわりは必須入力です。'
            }
        }
        if (!fields.SizeWidth) {
            errors.SizeWidth = {
                Message: 'ワイズは必須入力です。'
            }
        }
        if (!fields.SizeLast) {
            errors.SizeLast = {
                Message: 'ラストは必須入力です。'
            }
        }

        setErrors(errors);
        return Object.keys(errors).length;
    }

    handleSubmit(async () => {
        // サーバーサイドにアクセスする前に最低限のvalidationしとく
        if (validation()) {
            return;
        }
        console.log('client validation ok!');

        const result = await API.postAsFormData('/master/size/update', {
            'Id': param.id,
            'SizeCategory': state.fields?.SizeCategory || '',
            'FootLength': state.fields?.FootLength || '',
            'FootWidth': state.fields?.FootWidth || '',
            'SizeInstep': state.fields?.SizeInstep || '',
            'SizeWidth': state.fields?.SizeWidth || '',
            'SizeLast': state.fields?.SizeLast || '',
            'UpdateDatetime': state.fields?.UpdateDatetime || '',
        });
        if (result?.Result) {
            // [OK] 一覧画面に遷移する
            history.push('/master/size/list');
        } else {
            // サーバーサイドでもvalidationしてるから
            // エラーメッセージがあればmessagingしないかんね
            setErrors(result.Errors);
        }
    });

    return (
        <div id="SizeMasterInput" className="InputContents">
            <h1>
                <Link to="/master/size/list">
                    <svg xmlns="http://www.w3.org/2000/svg"><path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/></svg>
                </Link>
                <span>サイズマスタ</span>
                <div className="RequireHint"><span className="RequireMark">✱</span>必須入力</div>
            </h1>
            {state.errors?.Page?.map(message => (
                <div className="message error">{message.Message}</div>
            ))}
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">カテゴリ</div>
                    <div className="control">
                        <select name="SizeCategory" id="" onChange={handleChange}
                            value={state.fields?.SizeCategory || ''}
                            className={state.errors?.SizeCategory && 'invalid'}
                        >
                            <option value="">選択してください</option>
                            {SIZE_CATEGORY.map((cate) => (
                                <option key={cate.key} value={cate.key}>{cate.value}</option>
                            ))}
                        </select>
                        {state.errors?.SizeCategory &&
                            <span className="messageTip error">{state.errors?.SizeCategory.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="rowGroup">
                <div className="row">
                    <div className="label require">足長</div>
                    <div className="control">
                        <div>
                            <TypeNumber type="number" id="FootLength" name="FootLength" min="0"
                                placeholder="足長"
                                onChange={handleChange}
                                onFocus={selectOnFocus}
                                value={state.fields?.FootLength || '0'}
                                className={state.errors?.FootLength && 'invalid'}
                            />
                            <span>mm</span>
                        </div>
                        {state.errors?.FootLength &&
                            <span className="messageTip error">{state.errors?.FootLength.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">足囲</div>
                    <div className="control">
                        <div>
                            <TypeNumber id="FootWidth" name="FootWidth"
                                placeholder="足囲"
                                onChange={handleChange}
                                onFocus={selectOnFocus}
                                value={state.fields?.FootWidth || '0'}
                                className={state.errors?.FootWidth && 'invalid'}
                            />
                            <span>mm</span>
                        </div>
                        {state.errors?.FootWidth &&
                            <span className="messageTip error">{state.errors?.FootWidth.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">甲まわり</div>
                    <div className="control">
                        <div>
                            <TypeNumber id="SizeInstep" name="SizeInstep"
                                placeholder="甲まわり"
                                onChange={handleChange}
                                onFocus={selectOnFocus}
                                value={state.fields?.SizeInstep || '0'}
                                className={state.errors?.SizeInstep && 'invalid'}
                            />
                            <span>mm</span>
                        </div>
                        {state.errors?.SizeInstep &&
                            <span className="messageTip error">{state.errors?.SizeInstep.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">ワイズ</div>
                    <div className="control">
                        <input type="text" id="SizeWidth" name="SizeWidth"
                            placeholder="ワイズ"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.SizeWidth || ''}
                            className={state.errors?.SizeWidth && 'invalid'}
                        />
                        {state.errors?.SizeWidth &&
                            <span className="messageTip error">{state.errors?.SizeWidth.Message}</span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="label require">ラスト</div>
                    <div className="control">
                        <input type="text" id="SizeLast" name="SizeLast"
                            placeholder="ラスト"
                            onChange={handleChange}
                            onFocus={selectOnFocus}
                            value={state.fields?.SizeLast || ''}
                            className={state.errors?.SizeLast && 'invalid'}
                        />
                        {state.errors?.SizeLast &&
                            <span className="messageTip error">{state.errors?.SizeLast.Message}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="ButtonGroup">
                <LinkButton to="/master/size/list">キャンセル</LinkButton>
                <button type="submit" className="PrimaryButton">サイズを登録</button>
            </div>
        </div>
    )
}
export default withFormField(SizeMasterInput);
