import React, { useEffect, useRef } from 'react';

import {
    FOOT_TYPE,
    FOOT_WIDTH,
    FAVORITE_SIZE,
    FOOT_LONGER,
    SOCKS_TYPE,
    FOOT_DISEASE,
    HEEL_HEIGHT
} from 'components/Resource';
// import Radio from 'components/Radio';
import * as Util from 'components/Util';
import Calendar from 'components/Calendar';
import Checkbox from 'components/Checkbox';
import TypeNumber from 'components/TypeNumber';


function TypeNumberWithUnit(props) {
    return (
        <div className="TypeNumberWithUnit">
            <TypeNumber {...props} />
            <span className="unit">{props.unit}</span>
        </div>
    );
}

export default function OrderFittingFrame(props) {

    const state = props.state;
    const handleChange = props.handleChange;
    const setFormState = props.setFormState;
    const readonly = props.readonly || false;
    const open = props.open || false;

    const heelHeightRef = useRef(null);

    // 大小差を計算する
    useEffect(()=>{
        let rightWidthDiff = (state.fields?.UserRightMaxWidth - state.fields?.UserRightMinWidth);
        let rightInstepDiff = (state.fields?.UserRightMaxInstep - state.fields?.UserRightMinInstep);
        let rightMinDiff = (state.fields?.UserRightMinWidth - state.fields?.UserRightMinInstep);

        let leftWidthDiff = (state.fields?.UserLeftMaxWidth - state.fields?.UserLeftMinWidth);
        let leftInstepDiff = (state.fields?.UserLeftMaxInstep - state.fields?.UserLeftMinInstep);
        let leftMinDiff = (state.fields?.UserLeftMinWidth - state.fields?.UserLeftMinInstep);

        let minInstepDiff = Math.abs(state.fields?.UserRightMinInstep - state.fields?.UserLeftMinInstep);
        let heelHeight = (state.fields.HeelHeight || '90mm');

        setFormState({
            fields: {
                RightWidthDiff: rightWidthDiff,
                RightInstepDiff: rightInstepDiff,
                RightMinDiff: rightMinDiff,
                LeftWidthDiff: leftWidthDiff,
                LeftInstepDiff: leftInstepDiff,
                LeftMinDiff: leftMinDiff,
                MinInstepDiff: minInstepDiff,
                HeelHeight: heelHeight,
            }
        });
    }, [
        state.fields.UserRightMaxWidth,
        state.fields.UserRightMinWidth,
        state.fields.UserRightMaxInstep,
        state.fields.UserRightMinInstep,
        state.fields.UserLeftMaxWidth,
        state.fields.UserLeftMinWidth,
        state.fields.UserLeftMaxInstep,
        state.fields.UserLeftMinInstep,
        state.fields.HeelHeight,
        setFormState,
    ]);


    let tabIndex = 1;

    return (
        <>
        {readonly === false && (
            <>
            <details id="FittingFrame" open>
                <summary>足型測定情報</summary>
                <div className="FootInfo">
                    <div className="MessageTip info">ハンマートウは採寸時に注意が必要です。1サイズ上のラインで採寸してください。</div>

                    <div className="rowGroup" style={{marginTop:'.75rem',marginBottom: '1.5rem'}}>
                        <div className="feetgrid">
                            <div className="rightFoot">
                                <h3>右足</h3>
                                {state.fields?.RightMinDiff > 4 && (
                                    <div className="Feature">幅広</div>
                                )}
                                {state.fields?.RightMinDiff < 0 && (
                                    <div className="Feature">甲高</div>
                                )}
                                <div className="flexrow">
                                    <div>
                                        <div className="row">
                                            <div className="label">足長</div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserRightLength" name="UserRightLength" min="0" unit="mm"
                                                        value={state.fields?.UserRightLength || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserRightLength && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserRightLength &&
                                                    <span className="MessageTip error">{state.errors?.UserRightLength.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div>
                                                <div className="label">
                                                    <div>足囲(最大)</div>
                                                    <div className="advice">力を入れた状態</div>
                                                </div>
                                            </div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserRightMaxWidth" name="UserRightMaxWidth" min="0" unit="mm"
                                                        value={state.fields?.UserRightMaxWidth || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserRightMaxWidth && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserRightMaxWidth &&
                                                    <span className="MessageTip error">{state.errors?.UserRightMaxWidth.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="label">
                                                <div>足囲(最小)</div>
                                                <div className="advice">力を入れない状態</div>
                                            </div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserRightMinWidth" name="UserRightMinWidth" min="0" unit="mm"
                                                        value={state.fields?.UserRightMinWidth || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserRightMinWidth && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserRightMinWidth &&
                                                    <span className="MessageTip error">{state.errors?.UserRightMinWidth.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="label">
                                                <div>甲まわり(最大)</div>
                                                <div className="advice">力を入れた状態</div>
                                            </div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserRightMaxInstep" name="UserRightMaxInstep" min="0" unit="mm"
                                                        value={state.fields?.UserRightMaxInstep || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserRightMaxInstep && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserRightMaxInstep &&
                                                    <span className="MessageTip error">{state.errors?.UserRightMaxInstep.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="label">
                                                <div>甲まわり(最小)</div>
                                                <div className="advice">力を入れない状態</div>
                                            </div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserRightMinInstep" name="UserRightMinInstep" min="0" unit="mm"
                                                        value={state.fields?.UserRightMinInstep || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserRightMinInstep && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserRightMinInstep &&
                                                    <span className="MessageTip error">{state.errors?.UserRightMinInstep.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="label">足型</div>
                                            <div className="control">
                                                <select name="RightFootType" id="RightFootType"
                                                    value={state.fields?.RightFootType}
                                                    onChange={handleChange}
                                                    tabIndex={tabIndex++}
                                                    className={state.errors?.RightFootType && 'invalid'}
                                                >
                                                    <option value="">--足型--</option>
                                                    {FOOT_TYPE.map((foot, index) => (
                                                        <option key={('_'+foot.key+'_'+index)} value={foot.key}>{foot.value}</option>
                                                    ))}
                                                </select>
                                                {state.errors?.RightFootType &&
                                                    <span className="MessageTip error">{state.errors?.RightFootType.Message}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="DiffIndicate">
                                        <h4>大小差</h4>
                                        <div className="BallDiff">{state.fields?.RightWidthDiff || 0}</div>
                                        <div className="WaistDiff">{state.fields?.RightInstepDiff || 0}</div>
                                        <div className="MinDiff">{state.fields?.RightMinDiff || 0}</div>
                                    </div>
                                    <div>
                                        <div className="Degree">
                                            <div className="label">外反母趾角</div>
                                            <div className="control">
                                                <TypeNumberWithUnit min="0" max="90" name="RightDeformDegree" unit="度"
                                                    value={state.fields?.RightDeformDegree || '0'}
                                                    onChange={handleChange}
                                                    tabIndex={tabIndex++}
                                                />
                                            </div>
                                            <div className="label">内反小趾角</div>
                                            <div className="control">
                                                <TypeNumberWithUnit min="0" max="90" name="RightInnerDeformDegree" unit="度"
                                                    value={state.fields?.RightInnerDeformDegree || '0'}
                                                    onChange={handleChange}
                                                    tabIndex={tabIndex++}
                                                />
                                            </div>
                                        </div>
                                        <div className="Disease">
                                            <div className="label">足の疾病</div>
                                            <div className="control flexcol">
                                                {FOOT_DISEASE.map(row => (
                                                    <Checkbox key={row.key} label={row.value} name="RightFootDisease" value={row.key} onChange={handleChange}
                                                        selected={state.fields?.RightFootDisease}
                                                        tabIndex={tabIndex++}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {state.errors?.RightFootError &&
                                    <span className="MessageTip error">{state.errors?.RightFootError.Message}</span>
                                }
                            </div>
                            <div className="leftFoot">
                                <h3>左足</h3>
                                {state.fields?.LeftMinDiff > 4 && (
                                    <div className="Feature">幅広</div>
                                )}
                                {state.fields?.LeftMinDiff < 0 && (
                                    <div className="Feature">甲高</div>
                                )}
                                <div className="flexrow">
                                    <div>
                                        <div className="row">
                                            <div className="label">足長</div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserLeftLength" name="UserLeftLength" min="0" unit="mm"
                                                        value={state.fields?.UserLeftLength || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserLeftLength && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserLeftLength &&
                                                    <span className="MessageTip error">{state.errors?.UserLeftLength.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div>
                                                <div className="label">
                                                    <div>足囲(最大)</div>
                                                    <div className="advice">力を入れた状態</div>
                                                </div>
                                            </div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserLeftMaxWidth" name="UserLeftMaxWidth" min="0" unit="mm"
                                                        value={state.fields?.UserLeftMaxWidth || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserLeftMaxWidth && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserLeftMaxWidth &&
                                                    <span className="MessageTip error">{state.errors?.UserLeftMaxWidth.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="label">
                                                <div>足囲(最小)</div>
                                                <div className="advice">力を入れない状態</div>
                                            </div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserLeftMinWidth" name="UserLeftMinWidth" min="0" unit="mm"
                                                        value={state.fields?.UserLeftMinWidth || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserLeftMinWidth && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserLeftMinWidth &&
                                                    <span className="MessageTip error">{state.errors?.UserLeftMinWidth.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="label">
                                                <div>甲まわり(最大)</div>
                                                <div className="advice">力を入れた状態</div>
                                            </div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserLeftMaxInstep" name="UserLeftMaxInstep" min="0" unit="mm"
                                                        value={state.fields?.UserLeftMaxInstep || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserLeftMaxInstep && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserLeftMaxInstep &&
                                                    <span className="MessageTip error">{state.errors?.UserLeftMaxInstep.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="label">
                                                <div>甲まわり(最小)</div>
                                                <div className="advice">力を入れない状態</div>
                                            </div>
                                            <div className="control">
                                                <div className="flexrowcenter">
                                                    <TypeNumberWithUnit id="UserLeftMinInstep" name="UserLeftMinInstep" min="0" unit="mm"
                                                        value={state.fields?.UserLeftMinInstep || '0'}
                                                        onChange={handleChange}
                                                        tabIndex={tabIndex++}
                                                        className={state.errors?.UserLeftMinInstep && 'invalid'}
                                                    />
                                                </div>
                                                {state.errors?.UserLeftMinInstep &&
                                                    <span className="MessageTip error">{state.errors?.UserLeftMinInstep.Message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="label">足型</div>
                                            <div className="control">
                                                <select name="LeftFootType" id="LeftFootType"
                                                    value={state.fields?.LeftFootType || '0'}
                                                    onChange={handleChange}
                                                    tabIndex={tabIndex++}
                                                    className={state.errors?.LeftFootType && 'invalid'}
                                                >
                                                    <option value="">--足型--</option>
                                                    {FOOT_TYPE.map((foot, index) => (
                                                        <option key={('_'+foot.key+'_'+index)} value={foot.key}>{foot.value}</option>
                                                    ))}
                                                </select>
                                                {state.errors?.LeftFootType &&
                                                    <span className="MessageTip error">{state.errors?.LeftFootType.Message}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="DiffIndicate">
                                        <h4>大小差</h4>
                                        <div className="BallDiff">{state.fields?.LeftWidthDiff || 0}</div>
                                        <div className="WaistDiff">{state.fields?.LeftInstepDiff || 0}</div>
                                        <div className="MinDiff">{state.fields?.LeftMinDiff || 0}</div>
                                    </div>

                                    <div>
                                        <div className="Degree">
                                            <div className="label">外反母趾角</div>
                                            <div className="control">
                                                <TypeNumberWithUnit min="0" max="90" name="LeftDeformDegree" unit="度"
                                                    value={state.fields?.LeftDeformDegree || '0'}
                                                    onChange={handleChange}
                                                    tabIndex={tabIndex++}
                                                />
                                            </div>
                                            <div className="label">内反小趾角</div>
                                            <div className="control">
                                                <TypeNumberWithUnit min="0" max="90" name="LeftInnerDeformDegree" unit="度"
                                                    value={state.fields?.LeftInnerDeformDegree || '0'}
                                                    onChange={handleChange}
                                                    tabIndex={tabIndex++}
                                                />
                                            </div>
                                        </div>
                                        <div className="Disease">
                                            <div className="label">足の疾病</div>
                                            <div className="control flexcol">
                                                {FOOT_DISEASE.map(row => (
                                                    <Checkbox key={row.key} label={row.value} name="LeftFootDisease" value={row.key} onChange={handleChange}
                                                        selected={state.fields?.LeftFootDisease}
                                                        tabIndex={tabIndex++}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {state.errors?.LeftFootError &&
                                    <span className="MessageTip error">{state.errors?.LeftFootError.Message}</span>
                                }
                            </div>
                        </div>
                        {state.fields?.MinInstepDiff >= 4 && (
                            <div className="Message error">お客様に左右差のご認識をご確認ください。</div>
                        )}

                        <div className="footNote">
                            <div className="flexrow" style={{flexWrap:'wrap'}}>
                                <div className="row">
                                    <div className="label">
                                        <div>申告サイズ</div>
                                        <div className="advice">普段履いている靴サイズ</div>
                                    </div>
                                    <div className="control" style={{minWidth:'10.5rem', maxWidth:'10.5rem'}}>
                                        <TypeNumberWithUnit id="FootSize" name="FootSize" min="0" unit="mm" step="5"
                                            value={state.fields?.FootSize || '0'}
                                            onChange={handleChange}
                                            tabIndex={tabIndex++}
                                            className={state.errors?.FootSize && 'invalid'}
                                        />
                                        {state.errors?.FootSize &&
                                            <span className="MessageTip error">{state.errors?.FootSize.Message}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="label">
                                        <div>足幅</div>
                                    </div>
                                    <div className="control" style={{minWidth:'10.5rem', maxWidth:'10.5rem'}}>
                                        <select name="FootWidth" id="FootWidth"
                                            value={state.fields?.FootWidth}
                                            onChange={handleChange}
                                            style={{minWidth:'8rem'}}
                                            tabIndex={tabIndex++}
                                            className={state.errors?.FootWidth && 'invalid'}
                                        >
                                            <option value="">--足幅--</option>
                                            {FOOT_WIDTH.map((width, index) => (
                                                <option key={('_'+width+'_'+index)} value={width}>{width}</option>
                                            ))}
                                        </select>
                                        {state.errors?.FootWidth &&
                                            <span className="MessageTip error">{state.errors?.FootWidth.Message}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="label">
                                        <div>左右の足長比較</div>
                                        <div className="advice">左右どちらの足が長いか</div>
                                    </div>
                                    <div className="control" style={{minWidth:'10.5rem', maxWidth:'10.5rem'}}>
                                        <select name="FootLonger" id="FootLonger"
                                            value={state.fields?.FootLonger}
                                            onChange={handleChange}
                                            style={{minWidth: '9.625rem'}}
                                            tabIndex={tabIndex++}
                                            className={state.errors?.FootLonger && 'invalid'}
                                        >
                                            <option value="">--足長比較--</option>
                                            {FOOT_LONGER.map((long, index) => (
                                                <option key={('_'+long+'_'+index)} value={long.key}>{long.value}</option>
                                            ))}
                                        </select>
                                        {state.errors?.FootLonger &&
                                            <span className="MessageTip error">{state.errors?.FootLonger.Message}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="label">
                                        <div>サイズの好み</div>
                                        <div className="advice">靴の締まり具合の好み</div>
                                    </div>
                                    <div className="control" style={{minWidth:'10.5rem', maxWidth:'10.5rem'}}>
                                        <select name="FootFavorite" id="FootFavorite"
                                            value={state.fields?.FootFavorite}
                                            onChange={handleChange}
                                            tabIndex={tabIndex++}
                                            className={state.errors?.FootFavorite && 'invalid'}
                                        >
                                            <option value="">--サイズの好み--</option>
                                            {FAVORITE_SIZE.map((size, index) => (
                                                <option key={('_'+size+'_'+index)} value={size.key}>{size.value}</option>
                                            ))}
                                        </select>
                                        {state.errors?.FootFavorite &&
                                            <span className="MessageTip error">{state.errors?.FootFavorite.Message}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="label">
                                        <div>ソックス</div>
                                        <div className="advice">採寸時のソックスの種類</div>
                                    </div>
                                    <div className="control" style={{minWidth:'10.5rem', maxWidth:'10.5rem'}}>
                                        <select name="SocksType" id="SocksType"
                                            value={state.fields?.SocksType}
                                            onChange={handleChange}
                                            style={{minWidth:'8rem'}}
                                            tabIndex={tabIndex++}
                                            className={state.errors?.SocksType && 'invalid'}
                                        >
                                            <option value="">--ソックス--</option>
                                            {SOCKS_TYPE.map((socks, index) => (
                                                <option key={('_'+socks+'_'+index)} value={socks.key}>{socks.value}</option>
                                            ))}
                                        </select>
                                        {state.errors?.SocksType &&
                                            <span className="MessageTip error">{state.errors?.SocksType.Message}</span>
                                        }
                                    </div>
                                </div>

                                {(state.fields.OrderCategory1 === 'LADIES' && state.fields.OrderCategory2 === 'LEATHER_SHOES') && (
                                    <div className="row">
                                        <div className="label">
                                            <div>ヒール高</div>
                                        </div>
                                        <div className="control" style={{minWidth:'10.5rem', maxWidth:'10.5rem'}}>
                                            <select ref={heelHeightRef} name="HeelHeight"
                                                value={state.fields?.HeelHeight || '90mm'}
                                                onChange={handleChange}
                                                style={{minWidth:'8rem'}}
                                                tabIndex={tabIndex++}
                                            >
                                            {HEEL_HEIGHT.map((heel, index) => (
                                                <option key={('_'+heel+'_'+index)} value={heel.key}>{heel.value}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    <div className="MessageTip info">採寸時間は非常に重要です。お客様がむくみ等で一番大きいと感じる時間に採寸してください。</div>

                    <div className="rowGroup" style={{marginTop:'.75rem'}}>
                        <div className="fittingNote">
                            <div className="flexrow" style={{flexWrap:'wrap'}}>
                                <div className="row">
                                    <div className="label">測定日時</div>
                                    <div className="control flexrow">
                                        <div>
                                            <input type="date" id="FittingDate" name="FittingDate"
                                                value={state.fields?.FittingDate || Calendar.now().format('Y-m-d')}
                                                onChange={handleChange}
                                                tabIndex={tabIndex++}
                                                className={state.errors?.FittingDate && 'invalid'}
                                            />
                                        </div>
                                        <div style={{marginLeft: '.5rem'}}>
                                            <input type="time" id="FittingTime" name="FittingTime"
                                                value={state.fields?.FittingTime || Calendar.now().format('H:i:00')}
                                                onChange={handleChange}
                                                tabIndex={tabIndex++}
                                                className={state.errors?.FittingTime && 'invalid'}
                                            />
                                        </div>
                                    </div>
                                    {state.errors?.FittingDate &&
                                        <span className="MessageTip error">{state.errors?.FittingDate.Message}</span>
                                    }
                                    {state.errors?.FittingTime &&
                                        <span className="MessageTip error">{state.errors?.FittingTime.Message}</span>
                                    }
                                </div>
                                <div className="row">
                                <div className="flexrow">
                                    <div className="label">測定者</div>
                                    <div className="control">
                                        <input type="text" id="FittingUser" name="FittingUser"
                                            placeholder="測定者"
                                            value={state.fields?.FittingUser || ''}
                                            onChange={handleChange}
                                            tabIndex={tabIndex++}
                                            className={state.errors?.FittingUser && 'invalid'}
                                        />
                                        {state.errors?.FittingUser &&
                                            <span className="MessageTip error">{state.errors?.FittingUser.Message}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </details>
            </>
        )}
        {readonly && (
            <>
            <details id="FittingFrame" open={open}>
                <summary>足型測定情報</summary>
                <div>
                    <div className="FootInfoReadonly">
                        <div className="rightFoot">
                            <h3>右足</h3>
                            <div className="result">
                                <div className="item">
                                    <div className="title">ラスト-B/W</div>
                                    <div className="fs1250">{state.fields?.RightJudgeLast}-{(`00${state.fields.RightJudgeBall || ''}`.slice(-2))}/{(`00${state.fields.RightJudgeWaist || ''}`.slice(-2))}</div>
                                </div>
                                <div className="item">
                                    <div className="title">足長</div>
                                    <div className="fs1250">{Util.tryParseFloat(state.fields?.RightJudgeLength).toFixed(1) || ''}</div>
                                </div>
                                <div className="item">
                                    <div className="title">足囲</div>
                                    <div className="fs1250">{Util.tryParseFloat(state.fields?.RightJudgeWidth).toFixed(1) || ''}</div>
                                </div>
                                <div className="item">
                                    <div className="title">甲まわり</div>
                                    <div className="fs1250">{Util.tryParseFloat(state.fields?.RightJudgeInstep).toFixed(1) || ''}</div>
                                </div>
                            </div>
                            <div className="summary">{state.fields?.RightJudge || ''}</div>
                            <div className="note">{state.fields?.RightJudgeNote || ''}</div>
                            <div className="diff">
                                {Util.tryParseFloat(state.fields.RightAdjustWidth) !== 0 && (
                                    <div>足囲は木型との差に <em>{state.fields?.RightAdjustWidth > 0 ? '+' : ''}{state.fields?.RightAdjustWidth}</em>mm 補正</div>
                                )}
                                {Util.tryParseFloat(state.fields.RightAdjustInstep) !== 0 && (
                                    <div>甲まわりは木型との差に <em>{state.fields?.RightAdjustInstep > 0 ? '+' : ''}{state.fields?.RightAdjustInstep}</em>mm 補正</div>
                                )}
                            </div>
                            <div className="grade">{state.fields?.RightJudgeGrade || ''}</div>
                        </div>
                        <div className="leftFoot">
                            <h3>左足</h3>
                            <div className="result">
                                <div className="item">
                                    <div className="title">ラスト-B/W</div>
                                    <div className="fs1250">{state.fields?.LeftJudgeLast}-{(`00${state.fields.LeftJudgeBall || ''}`.slice(-2))}/{(`00${state.fields.LeftJudgeWaist || ''}`.slice(-2))}</div>
                                </div>
                                <div className="item">
                                    <div className="title">足長</div>
                                    <div className="fs1250">{Util.tryParseFloat(state.fields?.LeftJudgeLength).toFixed(1) || ''}</div>
                                </div>
                                <div className="item">
                                    <div className="title">足囲</div>
                                    <div className="fs1250">{Util.tryParseFloat(state.fields?.LeftJudgeWidth).toFixed(1) || ''}</div>
                                </div>
                                <div className="item">
                                    <div className="title">甲まわり</div>
                                    <div className="fs1250">{Util.tryParseFloat(state.fields?.LeftJudgeInstep).toFixed(1) || ''}</div>
                                </div>
                            </div>
                            <div className="summary">{state.fields?.LeftJudge || ''}</div>
                            <div className="note">{state.fields?.LeftJudgeNote || ''}</div>
                            <div className="diff">
                                {Util.tryParseFloat(state.fields?.LeftAdjustWidth) !== 0 && (
                                    <div>足囲は木型との差に <em>{state.fields?.LeftAdjustWidth > 0 ? '+' : ''}{state.fields?.LeftAdjustWidth}</em>mm 補正</div>
                                )}
                                {Util.tryParseFloat(state.fields?.LeftAdjustInstep) !== 0 && (
                                    <div>甲まわりは木型との差に <em>{state.fields?.LeftAdjustInstep > 0 ? '+' : ''}{state.fields?.LeftAdjustInstep}</em>mm 補正</div>
                                )}
                            </div>
                            <div className="grade">{state.fields?.LeftJudgeGrade || ''}</div>
                        </div>
                    </div>
                </div>
            </details>
            </>
        )}
        </>
    )
}
